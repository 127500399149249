import { Injectable } from '@angular/core';
import { CrudService } from '../../service/crud-service';
import { Definition } from '../../definition/definition';
import { FitType } from './fit-type.enum';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class FitService extends CrudService<Definition<FitType>> {

  protected path = '/fit';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): Definition<FitType> {
    return new Definition<FitType>(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Definition<FitType>> {
    return new Pageable<Definition<FitType>>(raw, Definition);
  }
}

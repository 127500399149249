<div class="store-demographic-profile-panel-container container" [ngClass]="{'default-padding': !hasStandardLicense}">
  <div class="header-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Demographic Profile</h1>
    <mtn-what-is-this [text]="PANEL_DESCRIPTION"></mtn-what-is-this>
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="region-container" fxLayout="row" fxLayoutGap="5px" [ngSwitch]="form.get('region').value">
        <span class="color-muted">Current Region:</span>
        <span *ngSwitchCase="RegionType.MARKET_AREA">Market Area</span>
        <span *ngSwitchCase="RegionType.DRIVE_TIME">{{form.get('driveTimeMinutes').value}}-minute Drive Time</span>
        <span *ngSwitchCase="RegionType.RING">{{form.get('ringMiles').value}}-mile Ring</span>
      </div>
      <mtn-icon-button matTooltip="Options" [matMenuTriggerFor]="optionsMenu"
                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                       [analyticsEventParameters]="{'target': 'demographic-profile-options-button'}">
        <fa-icon icon="cog"></fa-icon>
      </mtn-icon-button>
      <mtn-icon-button color="accent" (click)="openDetailsWizard()" matTooltip="View Details"
                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                       [analyticsEventParameters]="{'target': 'demographic-profile-view-all-button'}">
        <fa-icon icon="ellipsis-h"></fa-icon>
      </mtn-icon-button>
    </div>
  </div>
  <hr style="margin-bottom: 30px;"/>
  <div class="content-container">
    <div class="presentation-container container" fxLayout="row wrap" fxLayoutGap="15px" *ngIf="demographics">
      <div fxLayout="row wrap" fxLayoutGap="15px">
        <div class="ethnicity-container">
          <h2 class="mat-h2 text-small-caps text-center no-bottom-margin">Ethnicity</h2>
          <hr/>
          <div class="pie-chart-container">
          <mtn-pie-chart-legend [datasets]="ethnicityDataSet"
                                [labels]="ethnicityLabels"></mtn-pie-chart-legend>
          </div>
        </div>
        <div class="demographic-card-container">
          <h2 class="mat-h2 text-small-caps text-center no-bottom-margin">Population</h2>
          <hr/>
          <div class="card-container" fxLayout="row" fxLayoutGap="10px">

            <mtn-demographic-card icon="history" color="#3d7cc9" label="Historical 2yr Growth"
                                  [value]="pop2yrChangeText"
                                  matTooltip="Population growth in the past two years"></mtn-demographic-card>
            <mtn-demographic-card icon="users" color="#3d7cc9" label="Total Population"
                                  [value]="demographics.populationEstimate?.toLocaleString()"
                                  matTooltip="Estimated current population"></mtn-demographic-card>
            <mtn-demographic-card icon="chart-line" color="#3d7cc9" label="Projected 5yr Growth"
                                  [value]="pop5yrProjectionText"
                                  matTooltip="Projected growth over the next 5 years"></mtn-demographic-card>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="15px">
        <div class="demographic-card-container">
          <h2 class="mat-h2 text-small-caps text-center no-bottom-margin">People</h2>
          <hr/>
          <div class="card-container" fxLayout="row" fxLayoutGap="10px">
            <mtn-demographic-card [icon]="['far', 'clock']" color="#0033a1" label="Average Age"
                                  [value]="demographics.ageAverage?.toFixed(1)"
                                  matTooltip="Average age"></mtn-demographic-card>
            <mtn-demographic-card icon="graduation-cap" color="#0033a1" label="College Educated"
                                  [value]="round(demographics.educationCollegeOrDegree * 100, 2) + '%'"
                                  matTooltip="Percentage of the population that has completed at least some college education"></mtn-demographic-card>
            <mtn-demographic-card icon="home" color="#0033a1" label="Household Size"
                                  [value]="demographics.householdsPersonsPer?.toFixed(1)"
                                  matTooltip="Average persons per household"></mtn-demographic-card>
          </div>
        </div>
        <div class="demographic-card-container">
          <h2 class="mat-h2 text-small-caps text-center no-bottom-margin">Income</h2>
          <hr/>
          <div class="card-container" fxLayout="row" fxLayoutGap="10px">
            <mtn-demographic-card icon="dollar-sign" secondaryIcon="home" color="#68478d" label="Average Income"
                                  [value]="incomeAverage" matTooltip="Average household income"></mtn-demographic-card>
            <mtn-demographic-card icon="dollar-sign" secondaryIcon="home" color="#68478d" label="Median Income"
                                  [value]="incomeMedian" matTooltip="Median household income"></mtn-demographic-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="large-area-warning" *ngIf="isLoading && isLargeArea" fxLayoutAlign="center center">
    <mtn-simple-panel color="blue">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <fa-icon icon="info-circle" size="2x"></fa-icon>
        <div>Larger areas contain a lot data, and so it may take us just a bit to process it all for you. Please
          wait...
        </div>
      </div>
    </mtn-simple-panel>
  </div>
  <br/>
  <mtn-partner-attribution-container>
    <mtn-partner-attribution [partnerType]="PartnerType.SYNERGOS"></mtn-partner-attribution>
  </mtn-partner-attribution-container>
  <mtn-spinner [on]="isLoading" [size]="SpinnerSize.LARGE"></mtn-spinner>
  <mtn-restricted-license-overlay *ngIf="!hasStandardLicense"></mtn-restricted-license-overlay>
</div>

<mat-menu class="right-aligned-menu" #optionsMenu="matMenu">
  <form class="default-padding" [formGroup]="form" fxLayout="column" (click)="stopPropagation($event)">
    <mtn-region-field [form]="form" [isMarketAreaAvailable]="!!marketArea"></mtn-region-field>
    <mtn-drive-time-minutes-field [form]="form"
                                  *ngIf="form.get('region').value === RegionType.DRIVE_TIME"></mtn-drive-time-minutes-field>
    <mtn-ring-miles-field [form]="form" *ngIf="form.get('region').value === RegionType.RING"></mtn-ring-miles-field>

  </form>
</mat-menu>

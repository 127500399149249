<div class="store-alerts-panel-container container" fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="15px">
  <mtn-simple-alert title="Insufficient Data for Analysis" color="purple"
                    text="Some analysis may be unavailable below"
                    *ngIf="!volumeHistory?.length || !store.grocerySalesArea"></mtn-simple-alert>
  <mtn-simple-alert title="Historical Store(s)" color="blue"
                    [text]="'We found data for ' + otherStores.length + ' other store' + (otherStores.length === 1 ? '' : 's') + ' at this location.'"
                    *ngIf="hasHistoricalStores" (onClick)="openHistoricalStoresWizard()"
                    mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                    [analyticsEventParameters]="{'target': 'store-details-historical-stores-alert-button'}"></mtn-simple-alert>
  <mtn-simple-alert title="Historical Store" color="purple" text="This is not the current store at this location!"
                    mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                    [analyticsEventParameters]="{'target': 'store-details-historical-store-alert-button'}"
                    *ngIf="isHistoricalStore" (onClick)="openHistoricalStoresWizard()"></mtn-simple-alert>
  <mtn-simple-alert title="Need More Insight?" color="blue" text="Get personalized help from our Expert Analysts"
                    (onClick)="openRequestMoreInsightWizard()" mtnAnalyticsEventTrigger
                    [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                    [analyticsEventParameters]="{'target': 'store-details-request-more-insight-alert-button'}"></mtn-simple-alert>
</div>

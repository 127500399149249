import { Component } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { StoreComparison } from '../../../core/store/ranking/store-comparison';
import { StoreComparisonService } from '../../../core/store/ranking/store-comparison.service';
import { FeatureCollection, Store } from '../../../core/models';
import { DetailState, selectDetailState } from '../../detail-state';
import { filter } from 'rxjs/operators';
import { KeyIndicatorType } from '../../../core/user-preferences/key-indicator-type.enum';
import { RegionType } from '../../../core/location/region-type.enum';
import * as _ from 'lodash';
import { ComparisonType } from '../../../core/store/ranking/comparison-type.enum';
import { StoreVolume } from '../../../core/store/volume/store-volume';

@Component({
  selector: 'mtn-store-sales-performance-panel',
  templateUrl: './store-sales-performance-panel.component.html',
  styleUrls: ['./store-sales-performance-panel.component.scss']
})
export class StoreSalesPerformancePanelComponent extends AuthorizationAwareComponent {

  comparisonRequest1A: StoreComparison;
  comparisonRequest1B: StoreComparison;
  comparisonRequest1C: StoreComparison;
  comparisonRequest2A: StoreComparison;
  comparisonRequest2B: StoreComparison;
  comparisonRequest2C: StoreComparison;
  store: Store;
  volume: StoreVolume;

  private _isInitialized = false;
  private _marketArea: FeatureCollection;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private storeComparisonService: StoreComparisonService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.loadStore();
    this.initComparisonRequests();
  }

  private initComparisonRequests(): void {
    const defaultComparisons = this.storeComparisonService.buildDefaultComparisons(this.store, this.volume, this._marketArea, this.userPreferences);

    this.comparisonRequest1A = _.find(defaultComparisons, (comparison: StoreComparison) => {
      return comparison.keyIndicator === KeyIndicatorType.SALES_VOLUME && comparison.region === RegionType.DRIVE_TIME;
    });
    this.comparisonRequest1B = _.find(defaultComparisons, (comparison: StoreComparison) => {
      return comparison.keyIndicator === KeyIndicatorType.SALES_VOLUME && comparison.region === RegionType.MARKET_AREA && comparison.comparisonType === ComparisonType.ALL;
    });
    this.comparisonRequest1C = _.find(defaultComparisons, (comparison: StoreComparison) => {
      return comparison.keyIndicator === KeyIndicatorType.SALES_VOLUME && comparison.region === RegionType.MARKET_AREA && comparison.comparisonType === ComparisonType.BANNER;
    });

    this.comparisonRequest2A = _.find(defaultComparisons, (comparison: StoreComparison) => {
      return comparison.keyIndicator === KeyIndicatorType.SALES_SQFT && comparison.region === RegionType.DRIVE_TIME;
    });
    this.comparisonRequest2B = _.find(defaultComparisons, (comparison: StoreComparison) => {
      return comparison.keyIndicator === KeyIndicatorType.SALES_SQFT && comparison.region === RegionType.MARKET_AREA && comparison.comparisonType === ComparisonType.ALL;
    });
    this.comparisonRequest2C = _.find(defaultComparisons, (comparison: StoreComparison) => {
      return comparison.keyIndicator === KeyIndicatorType.SALES_SQFT && comparison.region === RegionType.MARKET_AREA && comparison.comparisonType === ComparisonType.BANNER;
    });
  }

  private loadStore(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .pipe(filter((state: DetailState) => !!state.store))
        .subscribe((state: DetailState) => {
          this.store = state.store;
          this.volume = state.volume;
          this._marketArea = state.marketArea;

          if (this.store && !this.store.isFuture() && this.volume && !this._isInitialized) {
            this._isInitialized = true;
            this.initComparisonRequests();
          }
        })
    );
  }

}

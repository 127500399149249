import { Component, Input, OnInit } from '@angular/core';
import { Location } from '../../models';

@Component({
  selector: 'mtn-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit {

  @Input()
  location: Location;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import * as p5 from 'p5';
import { StoreRanking } from '../../store/ranking/store-ranking';

export class ChartPoint {
  protected ip5: p5;
  protected _position: p5.Vector;
  protected _color: p5.Color;
  protected _storeRanking: StoreRanking;
  protected _radius: number = 3.5;
  protected _diameter: number = this._radius * 2;
  protected _isHovered = false;

  constructor(ip5: p5, pos: p5.Vector, color: p5.Color, storeRanking: StoreRanking) {
    this.ip5 = ip5;
    this._position = pos;
    this._color = color;
    this._storeRanking = storeRanking;
  }

  update() {
    this._isHovered = this.ip5.dist(this.ip5.mouseX, this.ip5.mouseY, this._position.x, this._position.y) < this._radius;
  }

  draw() {
    this.ip5.push();
    if (this._isHovered) {
      this._color.setAlpha(255);
      this.ip5.strokeWeight(2);
      this.ip5.fill(255);
      this.ip5.stroke(this._color);
    } else {
      this._color.setAlpha(.5 * 255);
      this.ip5.strokeWeight(1);
      this.ip5.stroke('rgba(255,255,255,0.3)');
      this.ip5.fill(this._color);
    }
    this.ip5.circle(this._position.x, this._position.y, this._diameter);
    this.ip5.pop();
  }

  get isHovered() {
    return this._isHovered;
  }

  get val() {
    return this._storeRanking.value;
  }

  get x() {
    return this._position.x;
  }

  get y() {
    return this._position.y;
  }

  get color() {
    return this._color;
  }

  get storeName() {
    return this._storeRanking.store.name;
  }

  get storeNumber() {
    return this._storeRanking.store.number;
  }

}

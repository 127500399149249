import { AuditableEntity } from '../../auditable-entity';
import { FeatureType } from '../feature/feature-type.enum';
import { UserProfile } from '../../user-profile/user-profile';
import { SavedSearch } from '../filter/saved-search';
import { CompanyCollection } from './company-collection';
import * as _ from 'lodash';

export class Collection extends AuditableEntity {

  description: string;
  featureType: FeatureType;
  name: string;
  owner: UserProfile;
  savedSearch: SavedSearch;

  excludedFeatures: string[] = [];
  favoritedBy: string[] = [];
  includedFeatures: string[] = [];
  shares: CompanyCollection[] = [];

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.shares = [];

      if (raw.featureType) {
        // @ts-ignore
        this.featureType = FeatureType[<string>raw.featureType];
      }
      if (raw.owner) {
        this.owner = new UserProfile(raw.owner);
      }
      if (raw.savedSearch) {
        this.savedSearch = new SavedSearch(raw.savedSearch);
      }
      if (raw.shares?.length) {
        this.shares = raw.shares.map((share: any) => new CompanyCollection(share));
      }
    }
  }

  isFavoritedBy(uuid: string): boolean {
    return _.includes(this.favoritedBy, uuid);
  }
}

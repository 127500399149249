import { Wizard } from '../wizard/wizard';
import { EmailVerificationPageComponent } from './email-verification-page/email-verification-page.component';

export class EmailVerificationWizard extends Wizard<any> {
  key = 'email-verification';
  pageTypeMap = {
    'email-verification': EmailVerificationPageComponent
  };
  startingPageKey = 'email-verification';
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class MtnValidators {

  static equals(value: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value && control.value === value;
      return isValid ? null : {'equals': `Must equal '${value}'`};
    }
  }

  static lengthEquals(value: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid: boolean = !control.value || control.value.length === value;
      return isValid ? null : {'lengthequals': `Must be ${value} characters in length`};
    };
  }

  static matchesPattern(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const isValid = regex.test(value);
      return isValid ? null : error;
    }
  }

  static requiresMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selection: any = control.value;
      if (typeof selection === 'string' && selection) {
        return {requiresMatch: true};
      }
      return null;
    }
  }

}

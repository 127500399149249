import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  faBuilding,
  faCheck,
  faCircle,
  faClock,
  faEnvelope,
  faExternalLinkAlt,
  faFilter,
  faInfoCircle,
  faLock,
  faMoon,
  faSearchMinus,
  faSearchPlus,
  faStar,
  faUserCircle,
  faUserLock
} from '@fortawesome/pro-regular-svg-icons';
import { MatIconRegistry } from '@angular/material/icon';
import {
  faArrowCircleRight as fasArrowCircleRight,
  faArrowCircleUp as fasArrowCircleUp,
  faBagsShopping as fasBagsShopping,
  faBalanceScale as fasBalanceScale,
  faBan as fasBan,
  faBell as fasBell,
  faBookmark as fasBookmark,
  faCaretDown as fasCaretDown,
  faCaretUp as fasCaretUp,
  faChartLine as fasChartLine,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircle as fasCircle,
  faClone as fasClone,
  faCloudDownloadAlt as fasCloudDownloadAlt,
  faCog as fasCog,
  faCogs as fasCogs,
  faCopy as fasCopy,
  faDatabase as fasDatabase,
  faDollarSign as fasDollarSign,
  faDoorOpen as fasDoorOpen,
  faDrawPolygon as fasDrawPolygon,
  faEllipsisH as fasEllipsisH,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExternalLinkSquareAlt as fasExternalLinkSquareAlt,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFilter as fasFilter,
  faFolder as fasFolder,
  faFolderPlus as fasFolderPlus,
  faFolders as fasFolders,
  faGraduationCap as fasGraduationCap,
  faHandPointer as fasHandPointer,
  faHistory as fasHistory,
  faHome as fasHome,
  faInfoCircle as fasInfoCircle,
  faLasso as fasLasso,
  faLayerGroup as fasLayerGroup,
  faLink as fasLink,
  faListUl as fasListUl,
  faLocation as fasLocation,
  faLongArrowAltRight as fasLongArrowAltRight,
  faMapMarked as fasMapMarked,
  faMapMarkerAlt as fasMapMarkerAlt,
  faMinusSquare as fasMinusSquare,
  faMousePointer as fasMousePointer,
  faPencilAlt as fasPencilAlt,
  faPlay as fasPlay,
  faPlus as fasPlus,
  faPlusSquare as fasPlusSquare,
  faQuestion as fasQuestion,
  faSatellite as fasSatellite,
  faSave as fasSave,
  faSearchLocation as fasSearchLocation,
  faShareAlt as fasShareAlt,
  faSignOutAlt as fasSignOutAlt,
  faSortAmountDown as fasSortAmountDown,
  faSortAmountUp as fasSortAmountUp,
  faStar as fasStar,
  faStarHalfAlt as fasStarHalfAlt,
  faStoreAlt as fasStoreAlt,
  faSync as fasSync,
  faTag as fasTag,
  faTimes as fasTimes,
  faToolbox as fasToolbox,
  faTrash as fasTrash,
  faTrashAlt as fasTrashAlt,
  faUnlock as fasUnlock,
  faUser as fasUser,
  faUserMinus as fasUserMinus,
  faUserPlus as fasUserPlus,
  faUsers as fasUsers
} from '@fortawesome/pro-solid-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@NgModule({
  declarations: [],
  exports: [FontAwesomeModule],
  imports: [
    CommonModule,
    FontAwesomeModule
  ]
})
export class MtnFontModule {

  constructor(private faIconLibrary: FaIconLibrary, private matIconRegistry: MatIconRegistry) {
    const icons: IconDefinition[] = [
      faBuilding,
      faCheck,
      faCircle,
      faClock,
      faEnvelope,
      faExternalLinkAlt,
      faFilter,
      faInfoCircle,
      faLock,
      faMoon,
      faSearchMinus,
      faSearchPlus,
      faStar,
      faUserCircle,
      faUserLock,

      fasArrowCircleRight,
      fasArrowCircleUp,
      fasBagsShopping,
      fasBalanceScale,
      fasBan,
      fasBell,
      fasBookmark,
      fasCaretDown,
      fasCaretUp,
      fasChartLine,
      fasCheck,
      fasCheckCircle,
      fasChevronLeft,
      fasChevronRight,
      fasCircle,
      fasClone,
      fasCloudDownloadAlt,
      fasCog,
      fasCogs,
      fasCopy,
      fasDatabase,
      fasDollarSign,
      fasDoorOpen,
      fasDrawPolygon,
      fasEllipsisH,
      fasExclamationCircle,
      fasExclamationTriangle,
      fasExternalLinkSquareAlt,
      fasEye,
      fasEyeSlash,
      fasFilter,
      fasFolder,
      fasFolderPlus,
      fasFolders,
      fasGraduationCap,
      fasHandPointer,
      fasHistory,
      fasHome,
      fasInfoCircle,
      fasLasso,
      fasLayerGroup,
      fasLink,
      fasListUl,
      fasLocation,
      fasLongArrowAltRight,
      fasMapMarked,
      fasMapMarkerAlt,
      fasMinusSquare,
      fasMousePointer,
      fasPencilAlt,
      fasPlay,
      fasPlus,
      fasPlusSquare,
      fasQuestion,
      fasSatellite,
      fasSave,
      fasSearchLocation,
      fasShareAlt,
      fasSignOutAlt,
      fasSortAmountDown,
      fasSortAmountUp,
      fasStar,
      fasStarHalfAlt,
      fasStoreAlt,
      fasSync,
      fasTag,
      fasTimes,
      fasToolbox,
      fasTrash,
      fasTrashAlt,
      fasUnlock,
      fasUser,
      fasUserMinus,
      fasUserPlus,
      fasUsers,
    ];

    this.faIconLibrary.addIcons(...icons);

    this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    this.matIconRegistry.setDefaultFontSetClass('fa');
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { Definition } from './definition';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class LicenseTypeService extends CrudService<Definition<LicenseType>> {

  protected path = '/license-type';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): Definition<LicenseType> {
    return new Definition<LicenseType>(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Definition<LicenseType>> {
    return new Pageable<Definition<LicenseType>>(raw, Definition);
  }


}

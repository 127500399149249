import { AuditableEntity } from '../auditable-entity';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import { Named } from '../util/named';
import { Company } from '../company/company';
import { DateUtil } from '../util/date-util';

export class UserProfile extends AuditableEntity implements Named {

  avatarLink: string;
  company: Company;
  email: string;
  firstName: string;
  hubspotId: string;
  isCompanyAdministrator = false;
  lastLoginDate: Date;
  lastName: string;
  password: string;
  phoneNumber: string;
  title: string;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);

      if (raw.company) {
        this.company = new Company(raw.company);
      }
      if (raw.lastLoginDate) {
        this.lastLoginDate = DateUtil.buildFromTimestamp(raw.lastLoginDate);
      }
      if (raw.licenseType) {
        // @ts-ignore
        this.licenseType = LicenseType[<string>raw.licenseType];
      }
    }
  }

  get licenseType(): LicenseType {
    return this.company?.license?.type;
  }

  get name(): string {
    return this.getDisplayName();
  }

  getDisplayName(): string {
    if (this.firstName) {
      return `${this.firstName} ${this.lastName}`.trim();
    } else {
      return this.email;
    }
  }
}

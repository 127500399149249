import { KeyIndicatorType } from '../user-preferences/key-indicator-type.enum';
import { SalesSqftDisplayType } from '../user-preferences/sales-sqft-display-type.enum';
import { Store } from '../models';

export class BannerRankingRequest {

  uuid: string;
  fips: string;
  keyIndicator: KeyIndicatorType;
  salesSqftDisplayType: SalesSqftDisplayType;

  //This is only used internally by the UI
  store: Store;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.keyIndicator) {
        // @ts-ignore
        this.keyIndicator = KeyIndicatorType[<string>raw.keyIndicator];
      }
      if (raw.salesSqftDisplayType) {
        // @ts-ignore
        this.salesSqftDisplayType = SalesSqftDisplayType[<string>raw.salesSqftDisplayType];
      }
    }
  }
}

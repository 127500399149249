import { UserProfile } from '../../core/user-profile/user-profile';
import { Company } from '../../core/company/company';

export class RegistrationRequest {
  company: Company;
  companyType: string;
  companyTypeDescription: string;
  hasRegistered = false;
  hope: string;
  recaptchaToken: string;
  userCount: string;
  userProfile: UserProfile;
}

import * as moment from 'moment';

export class DateUtil {

  static buildFromTimestamp(value: string): Date {
    return value ? moment(value).toDate() : null;
  }

  static monthsBetween(d1: Date, d2: Date) {
    let diff = (d2.getTime() - d1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 30.417); // seconds in a month
    return Math.abs(Math.round(diff));
  }

  /**
   * Handles null values
   * @param date1
   * @param date2
   * returns 0, 1, or -1 for use in sorting
   */
  static compareDates(date1: Date, date2: Date): number {
    if (date1 && date2) {
      return date1.getTime() - date2.getTime();
    }
    return !date1 && !date2 ? 0 : date1 ? -1 : 1;
  }

  static nDaysAgo(count: number): Date {
    return moment().subtract(count, 'days').toDate();
  }

}

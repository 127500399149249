import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { Space, Store } from '../models';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpaceService extends CrudService<Space> {

  protected path = '/space';

  constructor(protected http: HttpClient) {
    super(http);
  }

  findOnesStores(uuid: string): Observable<Store[]> {
    const url = this.buildUrl(`/${uuid}/store`);
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new Store(result))));
  }

  protected buildInstance(raw: any): Space {
    return new Space(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Space> {
    return new Pageable<Space>(raw, Space);
  }
}

import { BasicEntity } from '../basic-entity';
import { DateUtil } from '../util/date-util';


export class MaintenanceNotification extends BasicEntity {
  estimatedDurationMinutes: number;
  startDate: Date;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      if (raw.startDate) {
        this.startDate = DateUtil.buildFromTimestamp(raw.startDate);
      }
    }
  }
}

import { WizardPageComponent } from './wizard-page-component';
import { ComponentRef, Type } from '@angular/core';
import * as _ from 'lodash';

export abstract class Wizard<T> {

  /**
   * The key is used for analytics reporting. I went to use the component name at first, which worked fine locally, but
   * when built for test or production, files are minified, and the class names with them. This resulted in meaningless
   * data being reported to analytics.
   */
  abstract key: string;
  /**
   * The page keys defined here map the component classes to a simple key we can reference in the wizard to navigate
   * across pages. Again, component class name had minification problems...
   */
  abstract pageTypeMap: { [key: string]: Type<WizardPageComponent<Wizard<T>>> };
  /**
   * Plain and simple, which key, out of the ones we defined in the pageTypeMap, should the wizard start with? After
   * that, it's up to each page to navigate to the correct next page by key.
   */
  abstract startingPageKey: string;

  currentPage: WizardPageComponent<Wizard<T>>;
  isFooterCheckboxChecked = false;
  model: T;
  navigationHistory: { [key: string]: string } = {};
  pageCount = 0;
  pageReferenceMap: { [key: string]: ComponentRef<WizardPageComponent<Wizard<T>>> } = {};
  width = 450;

  getPreviousPageKey(): string {
    let key: string = null;

    _.forIn(this.navigationHistory, (to: string, from: string) => {
      if (to == this.currentPage.key) {
        key = from;
      }
    });

    return key;
  }

  recordNavigation(from: string, to: string): void {
    if (from !== to) {
      this.navigationHistory[from] = to;
    }
  }

  updatePageCount(): void {
    const pageKeys = _.keys(this.pageTypeMap);
    let pageCount = 0;
    pageKeys.forEach((key: string) => {
      const page = this.pageReferenceMap[key];
      if (page.instance.isVisible) {
        pageCount++;
      }
    });
    this.pageCount = pageCount;
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'insightsTimestamp'
})
export class InsightsTimestampPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: Date): string {
    let format = 'MM/dd/yyyy h:mma';
    return this.datePipe.transform(value, format);
  }
}

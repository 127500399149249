import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { UserSetLastActivityDate } from '../../user-actions';

@Injectable()
export class ActivityMonitoringInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(tap(() => {
        if (this.isInterceptorRegisteredForPath(request.url)) {
          this.store.dispatch(UserSetLastActivityDate({lastActivityDate: new Date()}));
        }
      }));
  }

  private isInterceptorRegisteredForPath(url: string): boolean {
    return url.includes('/api') && !url.includes('/api/insights/auth/renew');
  }
}

<div class="public-landing-page-container container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="80px">
  <!--adjust the scss for announcement container if announcement is needed for the landing page-->
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutAlign="space-between center"
       class="announcement-container">

  </div>
  <mtn-sign-up-sign-in-panel></mtn-sign-up-sign-in-panel>
  <mtn-coverage-map></mtn-coverage-map>
</div>


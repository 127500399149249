import { Wizard } from '../../../../../core/wizard/wizard';
import { Company } from '../../../../../core/company/company';
import { License } from '../../../../../core/license/license';
import { UpdateLicensePageComponent } from './update-license-page/update-license-page.component';

export class UpdateLicenseWizard extends Wizard<UpdateLicenseWizardModel> {
  key = 'update-license';
  pageTypeMap = {
    'update-license': UpdateLicensePageComponent
  };
  startingPageKey = 'update-license';
}

export interface UpdateLicenseWizardModel {
  company: Company;
  isUpdated?: boolean;
  license?: License;
}

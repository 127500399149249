import { Component, Input, OnChanges } from '@angular/core';
import { Definition } from '../definition';
import { DefinitionType } from '../definition-type.enum';
import { DefinitionService } from '../definition.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-definition-view',
  templateUrl: './definition-view.component.html',
  styleUrls: ['./definition-view.component.scss']
})
export class DefinitionViewComponent implements OnChanges {

  @Input()
  definitionType: DefinitionType;
  @Input()
  systemName: string;

  definition: Definition<any>;

  constructor(private definitionService: DefinitionService) {
  }

  ngOnChanges(): void {
    this.loadDefinition();
  }

  private loadDefinition() {
    this.definition = null;
    let task: Observable<Definition<any>[]>;

    switch (this.definitionType) {
      case DefinitionType.FIT:
        task = this.definitionService.findAllFits();
        break;
      case DefinitionType.FORMAT:
        task = this.definitionService.findAllFormats();
        break;
      case DefinitionType.INTERSECTION_TYPE:
        task = this.definitionService.findAllIntersectionTypes();
        break;
      case DefinitionType.LICENSE_TYPE:
        task = this.definitionService.findAllLicenseTypes();
        break;
      case DefinitionType.QUADRANT:
        task = this.definitionService.findAllQuadrantTypes();
        break;
      case DefinitionType.SHOPPING_CENTER_TYPE:
        task = this.definitionService.findAllShoppingCenterTypes();
        break;
      case DefinitionType.STORE_STATUS:
        task = this.definitionService.findAllStoreStatusTypes();
        break;
      default:
        break;
    }

    task.subscribe((definitions: Definition<any>[]) => {
      setTimeout(() => this.definition = _.find(definitions, (definition: Definition<any>) => definition.systemName === this.systemName));
    });
  }

}

<div class="profile-preferences-container container default-padding" fxLayout="column" fxLayoutGap="15px">
  <mtn-simple-panel color="blue">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <fa-icon icon="info-circle"></fa-icon>
      <span>Changes made here are saved automatically</span>
    </div>
  </mtn-simple-panel>
  <ng-template #spinner>
    <div class="spinner-container">
      <mtn-spinner [on]="true" [size]="SpinnerSize.MEDIUM"></mtn-spinner>
    </div>
  </ng-template>
  <div class="preferences-container container" [formGroup]="form" *ngIf="preferences; else spinner" fxLayout="column"
       fxLayoutGap="30px">
    <div fxLayout="column">
      <h3 class="mat-h3 color-primary">Key Indicator Preference</h3>
      <div class="panel-container container" fxFlex fxLayout="column" fxLayoutGap="15px">
        <p>{{Dictionary.labelKeyIndicatorPreferenceDescription}}</p>
        <mat-radio-group color="primary" formControlName="primaryKeyIndicator" fxLayout="column" fxLayoutGap="5px">
          <mat-radio-button [value]="KeyIndicatorType.SALES_SQFT">Sales /sqft</mat-radio-button>
          <mat-radio-button [value]="KeyIndicatorType.SALES_VOLUME">Sales Volume</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="column">
      <h3 class="mat-h3 color-primary">Map Settings</h3>
      <div class="panel-container container" fxLayout="row" fxLayoutGap="15px">
        <div class="map-preferences-container container" fxFlex fxLayout="column">
          <p>{{Dictionary.labelMapDefaultModePreferenceDescription}}</p>
          <mat-radio-group formControlName="mapDefaultMode" fxLayout="column" fxLayoutGap="5px" color="primary">
            <mat-radio-button [value]="MapMode.CLASSIC">Classic</mat-radio-button>
            <mat-radio-button [value]="MapMode.DARK">Dark</mat-radio-button>
          </mat-radio-group>
          <br/>
          <p>{{Dictionary.labelMapDefaultTypePreferenceDescription}}</p>
          <mat-radio-group formControlName="mapDefaultType" fxLayout="column" fxLayoutGap="5px" color="primary">
            <mat-radio-button [value]="MapTypeId.ROADMAP">Road</mat-radio-button>
            <mat-radio-button [value]="MapTypeId.HYBRID">Satellite</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <p class="color-muted">Example</p>
          <div class="map-container container">
            <mtn-map [options]="mapOptions" *ngIf="mapOptions" (ready)="onMapReady($event)"></mtn-map>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column">
      <h3 class="mat-h3 color-primary">Sales Volume Display</h3>
      <div class="panel-container container" fxFlex fxLayout="column" fxLayoutGap="15px">
        <p>{{Dictionary.labelSalesVolumePreferenceDescription}}</p>
        <mat-radio-group color="primary" formControlName="salesVolumeDisplayMode" fxLayout="column" fxLayoutGap="5px">
          <mat-radio-button [value]="SalesVolumeDisplayModeType.ANNUAL">Annual Sales</mat-radio-button>
          <mat-radio-button [value]="SalesVolumeDisplayModeType.WEEKLY">Weekly Sales</mat-radio-button>
        </mat-radio-group>
        <div class="container">
          <p class="color-muted">Example</p>
          <mtn-sales-volume-view [volume]="sampleVolume"></mtn-sales-volume-view>
        </div>
      </div>
    </div>
    <div fxLayout="column">
      <h3 class="mat-h3 color-primary">Sales Volume Display (/sqft)</h3>
      <div class="panel-container container" fxFlex fxLayout="column" fxLayoutGap="15px">
        <p>{{Dictionary.labelSalesSqftPreferenceDescription}}</p>
        <mat-radio-group color="primary" formControlName="salesSqftDisplayMode" fxLayout="column" fxLayoutGap="5px">
          <mat-radio-button [value]="SalesSqftDisplayModeType.SALES_AREA">Use Sales Area</mat-radio-button>
          <mat-radio-button [value]="SalesSqftDisplayModeType.TOTAL_AREA">Use Total Area</mat-radio-button>
        </mat-radio-group>
        <div class="container">
          <p class="color-muted">Example</p>
          <mtn-sales-sqft-view [volume]="sampleVolume"></mtn-sales-sqft-view>
        </div>
      </div>
    </div>
  </div>
</div>

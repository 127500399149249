import { Component, Input } from '@angular/core';
import { AddOnService } from '../add-on.service';
import { CompanyService } from '../company.service';
import { Company } from '../company';
import { AddOn } from '../add-on';
import { AddOnType } from '../add-on-type.enum';
import * as _ from 'lodash';
import { DefinitionService } from '../../definition/definition.service';
import { Definition } from '../../definition/definition';
import { AuthorizationAwareComponent } from '../../authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { WizardRunnerService } from '../../wizard/wizard-runner.service';
import { ToggleAddOnWizard } from './toggle-add-on-wizard/toggle-add-on-wizard';
import { CustomExtractionService } from './custom-extraction.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'mtn-company-add-on-list',
  templateUrl: './company-add-on-list.component.html',
  styleUrls: ['./company-add-on-list.component.scss']
})
export class CompanyAddOnListComponent extends AuthorizationAwareComponent {

  @Input()
  company: Company;

  AddOnType = AddOnType;
  addOns: AddOn[] = [];
  companyAddOns: AddOnType[] = [];
  hasCustomExtractionAddOn = false;
  isExtracting = false;

  private definitions: Definition<AddOnType>[];

  constructor(private addOnService: AddOnService,
              private companyService: CompanyService,
              private customExtractionService: CustomExtractionService,
              private definitionService: DefinitionService,
              protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.loadDefinitions();
  }

  downloadCustomExtraction(): void {
    if (!this.isExtracting) {
      this.isExtracting = true;

      this.customExtractionService.extract()
        .pipe(finalize(() => this.isExtracting = false))
        .subscribe();
    }
  }

  hasAddOn(type: AddOnType): boolean {
    return _.includes(this.companyAddOns, type);
  }

  openToggleAddOnWizard(addOn: AddOn): void {
    if (this.hasInternalLicense) {
      const wizard = new ToggleAddOnWizard();
      wizard.model = {
        company: this.company,
        companyAddOns: this.companyAddOns,
        addOn: addOn
      };

      this.wizardRunner.run(wizard)
        .afterClosed()
        .subscribe((result: ToggleAddOnWizard) => {
          if (result.model.isCompleted) {
            this.loadCompanyAddOns();
          }
        });
    }
  }

  private loadAddOns(): void {
    this.addOnService.findAll()
      .subscribe((results: AddOn[]) => {
        this.matchDefinitions(results);
        this.addOns = _.orderBy(results, 'type');
      });
  }

  private loadCompanyAddOns(): void {
    this.companyService.findOnesAddOns(this.company.uuid)
      .subscribe((results: AddOn[]) => {
        this.matchDefinitions(results);
        this.companyAddOns = results.map((result: AddOn) => result.type);

        this.hasCustomExtractionAddOn = this.hasAddOn(AddOnType.CUSTOM_EXTRACTION);
      });
  }

  private loadDefinitions(): void {
    this.definitionService.findAllAddOnTypes()
      .subscribe((definitions: Definition<AddOnType>[]) => {
        this.definitions = definitions;
        this.loadAddOns();
        this.loadCompanyAddOns();
      });
  }

  private matchDefinitions(results: AddOn[]): void {
    results.forEach((result: AddOn) => {
      result.typeDefinition = _.find(this.definitions, (definition: Definition<AddOnType>) => definition.systemName === result.type);
    });
  }

}

<div class="map-layers-assembly container">
  <mtn-map-toolbar-button icon="layer-group" [badgeCount]="toolbarState.badgeCountLayers"
                          [color]="toolbarState.badgeCountLayers ? 'green' : 'grey'"
                          [iconColor]="toolbarState.badgeCountLayers ? 'green' : 'default'"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                          [analyticsEventParameters]="{'target': 'map-layers-menu'}"
                          matTooltip="Layers" [matMenuTriggerFor]="layersMenu"></mtn-map-toolbar-button>
</div>

<mat-menu class="wide-menu" #layersMenu="matMenu">
  <div class="layers-menu-container toolbar-menu container" mtnClickTrap [preventDefault]="false">
    <div fxLayout="row">
      <strong>Layers</strong>
      <div fxFlex></div>
      <a class="color-accent" href="javascript:void(0)" (click)="clearAll()">Clear All</a>
    </div>
    <hr/>
    <div fxLayout="column" [formGroup]="form">
      <mat-checkbox color="accent" [disabled]="true">MTN Shopping Centers (Coming Soon)</mat-checkbox>
      <mat-checkbox color="accent" formControlName="isStoreLayerEnabled">MTN Stores</mat-checkbox>
      <mat-checkbox color="accent" formControlName="isPointsOfInterestLayerEnabled">Points of Interest (Google)
      </mat-checkbox>
      <mat-checkbox color="accent" [disabled]="true">Thematics (Coming Soon)</mat-checkbox>
    </div>
  </div>
</mat-menu>

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FipsService } from '../fips.service';
import { FipsObject } from '../fips-object';
import { tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'mtn-geographic-area-selection-list',
  templateUrl: './geographic-area-selection-list.component.html',
  styleUrls: ['./geographic-area-selection-list.component.scss']
})
export class GeographicAreaSelectionListComponent extends BaseComponent implements AfterViewInit {

  @Output()
  onSelectionChange = new EventEmitter<FipsObject[]>();

  @Input()
  initialCbsas: string[] = [];
  @Input()
  initialStates: string[] = [];

  cbsas: FipsObject[] = [];
  form = new UntypedFormGroup({
    filter: new UntypedFormControl(),
    selectedForm: new UntypedFormControl('cbsas')
  });
  isLoading = true;
  states: FipsObject[] = [];

  @ViewChild(MatButtonToggleGroup)
  formSelector: MatButtonToggleGroup;

  private _isEmitDisabled = false;

  constructor(private fipsService: FipsService) {
    super();
  }

  ngAfterViewInit(): void {
    this.loadShapes();
  }

  //Merging each form's valueChanges observables wasn't working for some reason, so we're using (change) instead...
  emitSelectionChange(): void {
    if (!this._isEmitDisabled) {
      const formValue = this.form.getRawValue();
      const selectedCbsas = _.keys(_.pickBy(formValue.cbsas));
      const selectedStates = _.keys(_.pickBy(formValue.states));

      const selections: FipsObject[] = [];
      this.cbsas.forEach((cbsa: FipsObject) => {
        if (_.includes(selectedCbsas, cbsa.fips)) {
          selections.push(cbsa);
        }
      });
      this.states.forEach((state: FipsObject) => {
        if (_.includes(selectedStates, state.fips)) {
          selections.push(state);
        }
      });

      this.onSelectionChange.emit(selections);
    }
  }

  selectAll(): void {
    this._isEmitDisabled = true;
    const selectionsForm = <UntypedFormGroup>this.form.get(this.form.get('selectedForm').value);
    Object.keys(selectionsForm.controls).forEach((key: string) => selectionsForm.get(key).setValue(true, {emitEvent: false}));
    this._isEmitDisabled = false;
    this.emitSelectionChange();
  }

  private initForm(): void {
    const cbsaForm = new UntypedFormGroup({});
    this.cbsas.forEach((cbsa: FipsObject) => {
      const isAlreadySelected = _.includes(this.initialCbsas, cbsa.fips);
      const control = new UntypedFormControl(isAlreadySelected);
      if (isAlreadySelected) {
        control.disable();
      }
      cbsaForm.addControl(cbsa.fips, control);
    });

    const stateForm = new UntypedFormGroup({});
    this.states.forEach((state: FipsObject) => {
      const isAlreadySelected = _.includes(this.initialStates, state.fips);
      const control = new UntypedFormControl(isAlreadySelected);
      if (isAlreadySelected) {
        control.disable();
      }
      stateForm.addControl(state.fips, control);
    });

    this.form = new UntypedFormGroup({
      cbsas: cbsaForm,
      filter: new UntypedFormControl(),
      selectedForm: new UntypedFormControl('cbsas'),
      states: stateForm
    });

    this.isLoading = false;
  }

  private loadShapes(): void {
    this.isLoading = true;

    const tasks: Observable<any>[] = [];
    tasks.push(this.fipsService.findAllCbsas()
      .pipe(tap((results: FipsObject[]) => this.cbsas = _.orderBy(results, 'name'))));
    tasks.push(this.fipsService.findAllStates()
      .pipe(tap((results: FipsObject[]) => this.states = _.orderBy(results, 'name'))));

    forkJoin(tasks)
      .subscribe(() => {
        this.initForm();
      });
  }
}

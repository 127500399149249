<div class="company-add-on-list-container container default-padding mat-elevation-z1">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">Add-Ons</h2>
    <mtn-what-is-this text="Add-Ons may be added to your license by contacting sales@retailstat.com"></mtn-what-is-this>
  </div>
  <hr/>
  <div fxLayout="row wrap" fxLayoutGap="15px">
    <div class="add-on-container container" *ngFor="let addOn of addOns | isAddOnVisible : hasCustomExtractionAddOn"
         fxLayout="column" fxLayoutAlign="start center"
         fxLayoutGap="5px" [ngClass]="{'purchased': hasAddOn(addOn.type), 'clickable': hasInternalLicense}"
         [matTooltip]="hasInternalLicense ? 'Click to Toggle' : 'Contact sales@retailstat.com for Details'"
         (click)="openToggleAddOnWizard(addOn)">
      <div class="purchased-container" fxLayout="center center">
        <span class="text-center"><span *ngIf="!hasAddOn(addOn.type)">NOT&nbsp;</span>PURCHASED</span>
      </div>
      <div class="image-container" fxLayoutAlign="center center" [ngSwitch]="addOn.type">
        <img alt="Retailstat Logo" width="50px"
             src="https://insights.mtnra.com/assets/images/retailstat-icon.png"
             *ngSwitchCase="AddOnType.CUSTOM_EXTRACTION"/>
        <img alt="Retailstat Logo" width="50px"
             src="https://insights.mtnra.com/assets/images/retailstat-icon.png"
             *ngSwitchCase="AddOnType.MTN_MODEL_DATA"/>
        <img alt="Retailstat Logo" width="50px"
             src="https://insights.mtnra.com/assets/images/retailstat-icon.png"
             *ngSwitchCase="AddOnType.PLANNED_GROCERY"/>
      </div>
      <strong class="text-center">{{addOn.typeDefinition.displayName}}</strong>
    </div>
  </div>
  <br/>
  <div *ngIf="hasCustomExtractionAddOn">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <h3 class="mat-h3 color-primary text-small-caps no-bottom-margin">Custom Extraction</h3>
      <mtn-what-is-this
        text="A CSV data extraction crafted specifically to your company's requirements. This may take a short while!"></mtn-what-is-this>
    </div>
    <hr/>
    <button mat-raised-button color="accent" (click)="downloadCustomExtraction()" style="width: 100%;">
      Download Extraction Data
      <mtn-spinner [on]="isExtracting" [size]="SpinnerSize.EXTRA_SMALL"></mtn-spinner>
    </button>
  </div>
</div>

export enum FilterType {
  BANNER = 'BANNER',
  COLLECTION = 'COLLECTION',
  FIT_CATEGORY = 'FIT_CATEGORY',
  FIPS = 'FIPS',
  GEOJSON = 'GEOJSON',
  GROSS_LEASABLE_AREA = 'GROSS_LEASABLE_AREA',
  IS_FLOAT = 'IS_FLOAT',
  IS_VACANT = 'IS_VACANT',
  OWNER = 'OWNER',
  POSITION_IN_CENTER = 'POSITION_IN_CENTER',
  SALES_AREA = 'SALES_AREA',
  SALES_SQFT = 'SALES_SQFT',
  SALES_VOLUME = 'SALES_VOLUME',
  SHOPPING_CENTER_TYPE = 'SHOPPING_CENTER_TYPE',
  SHOPPING_CENTER_UUID = 'SHOPPING_CENTER_UUID',
  SPACE_UUID = 'SPACE_UUID',
  STATUS = 'STATUS',
  STORE_UUID = 'STORE_UUID',
  TOTAL_AREA = 'TOTAL_AREA'
}

<mat-form-field class="default-field-width" [formGroup]="form">
  <input type="number" matInput [formControlName]="controlName" placeholder="Minutes"/>
  <mat-error
    *ngIf="form.get(controlName).hasError('min') || form.get(controlName).hasError('max')">
    <i>Must be between 1 - 20 minutes</i>
  </mat-error>
  <mat-error *ngIf="form.get(controlName).hasError('required')">
    <i>Must be provided</i>
  </mat-error>
</mat-form-field>

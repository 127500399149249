import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '../company';
import { AuthorizationAwareComponent } from '../../authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { CompanyService } from '../company.service';
import { ToastService } from '../../toast/toast.service';
import { catchError } from 'rxjs/operators';
import { GENERIC_ERROR_MESSAGE } from '../../util/string-utils';
import { WizardRunnerService } from '../../wizard/wizard-runner.service';
import { ConfirmActionWizard } from '../../confirm-action-wizard/confirm-action-wizard';
import { ClipboardUtil } from '../../util/clipboard-util';

@Component({
  selector: 'mtn-company-api-key',
  templateUrl: './company-api-key.component.html',
  styleUrls: ['./company-api-key.component.scss']
})
export class CompanyApiKeyComponent extends AuthorizationAwareComponent {

  @Output()
  onUpdate = new EventEmitter<any>();

  @Input()
  company: Company;

  hasRefreshPermission = false;

  constructor(private companyService: CompanyService,
              protected ngrxStore: NgrxStore<AppState>,
              private toaster: ToastService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.hasRefreshPermission = this.hasInternalLicense || this.isCompanyAdministrator();
  }

  copyApiKeyToClipboard(): void {
    ClipboardUtil.copyValueToClipboard(this.company.apiKey);
    this.toaster.info("Copied API Key to Clipboard");
  }

  refresh(): void {
    if (this.hasRefreshPermission) {
      const wizard = new ConfirmActionWizard();
      wizard.model = {
        title: 'Refresh API Key',
        text: 'WARNING! Refreshing the API key will break any existing integrations with the Insights API until those integrations are updated with the new key! Are you sure you want to do this?',
        onConfirm: this.companyService.refreshOnesApiKey(this.company.uuid)
          .pipe(catchError((err) => {
            this.toaster.error(GENERIC_ERROR_MESSAGE);
            throw err;
          }))
      };

      this.wizardRunner.run(wizard)
        .afterClosed().subscribe((result: ConfirmActionWizard) => {
        if (result?.model?.result) {
          this.onUpdate.emit(true);
        }
      });
    }
  }

}

<div class="store-subpanel-container container">
  <div class="content-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
       *ngIf="isExpanded; else centeredIcon"
       [matTooltip]="breakpoint === Breakpoints.XLarge || breakpoint === Breakpoints.Large ? 'Click for Details' : ''">
    <div class="logo-container">
      <mtn-banner-image [banner]="store.banner"
                        *ngIf="store.banner?.logoCloudinaryFilename; else noBanner"></mtn-banner-image>
      <ng-template #noBanner>
        <fa-icon icon="store-alt" size="2x"></fa-icon>
      </ng-template>
    </div>
    <div class="store-info-container" *ngIf="isExpanded && store">
      <h2 class="mat-h2 text-small-caps no-bottom-margin">{{store.name}} <span class="color-muted" *ngIf="store.number">
        ({{store.number}})</span></h2>
      <div class="color-muted" fxLayout="row" fxLayoutGap="5px">
        <span>{{store.banner?.name || 'No Associated Banner'}}</span>
        <span *ngIf="hasLicense(LicenseType.STANDARD)">-</span>
        <mtn-definition-view [definitionType]="DefinitionType.FIT" [systemName]="store.fit"
                             *ngIf="hasLicense(LicenseType.STANDARD) && store.fit; else noFit"></mtn-definition-view>
        <ng-template #noFit>
          <span *ngIf="hasLicense(LicenseType.STANDARD)">No FIT Category</span>
        </ng-template>
      </div>
    </div>
    <div fxFlex></div>
    <div mtnClickTrap>
      <mtn-icon-button color="accent" *ngIf="breakpoint !== Breakpoints.XLarge && breakpoint !== Breakpoints.Large"
                       (onClick)="openDetailsWizard()" matTooltip="Click for Details">
        <fa-icon icon="ellipsis-h"></fa-icon>
      </mtn-icon-button>
    </div>
  </div>
  <ng-template #centeredIcon>
    <div class="content-container" fxLayout="row" fxLayoutAlign="center center">
      <fa-icon icon="store-alt" size="2x"></fa-icon>
    </div>
  </ng-template>
</div>

<div class="partner-attribution-container container" [ngSwitch]="partnerType">
  <span *ngSwitchCase="PartnerType.SYNERGOS">Demographic data provided by <a href="https://www.synergos-tech.com"
                                                                             target="_blank">Synergos Technologies, Inc</a>.</span>
  <span *ngSwitchCase="PartnerType.CENSUS">Demographic polygons provided by <a href="https://www.census.gov"
                                                                               target="_blank">Census.gov</a>.</span>
  <span *ngSwitchCase="PartnerType.TRAVEL_TIME">Drive Time polygons provided by <a href="https://traveltime.com/"
                                                                                   target="_blank">TravelTime</a>.</span>
  <span *ngSwitchCase="PartnerType.DSR_MARKETING">FIT Category provided by <a href="https://dsrmarketing.com"
                                                                              target="_blank">DSR Marketing</a>.</span>
</div>

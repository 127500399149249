import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsEventType } from '../../../google-analytics/analytics-event-type';
import { GoogleSearchWizard } from '../google-search-wizard/google-search-wizard';
import { MtnMap } from '../../mtn-map';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';

@Component({
  selector: 'mtn-google-search-assembly',
  templateUrl: './google-search-assembly.component.html',
  styleUrls: ['./google-search-assembly.component.scss']
})
export class GoogleSearchAssemblyComponent implements OnInit {

  @Input()
  map: MtnMap;

  AnalyticsEventType = AnalyticsEventType;

  constructor(private wizardRunner: WizardRunnerService) {
  }

  ngOnInit(): void {
  }

  openGoogleSearchWizard(): void {
    const wizard = new GoogleSearchWizard();
    wizard.model = {
      map: this.map
    };

    this.wizardRunner.run(wizard);
  }

}

<div class="wizard-stepper-container container">
  <div class="bar-background"></div>
  <div class="step-container container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
    <div *ngFor="let page of pages">
      <div class="wizard-step container" fxLayout="column"
           fxLayoutAlign="start center" fxLayoutGap="15px"
           [ngClass]="{
         completed: wizard.currentPage.key !== page.key && page.isVisited && page.form.valid,
         visited: wizard.currentPage.key !== page.key && page.isVisited,
         current: wizard.currentPage.key === page.key
         }" *ngIf="page.isVisible">
        <div class="icon-outer-container" fxLayoutAlign="center center">
          <div class="icon-inner-container" fxLayoutAlign="center center">
            <fa-icon icon="check" *ngIf="page.isVisited && page.form.valid"></fa-icon>
          </div>
        </div>
        <span class="step-label">{{page.stepperText}}</span>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { StoreInsightRequest } from './store-insight-request';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultingService extends InsightsRestService {

  protected path = '/consulting';

  constructor(private http: HttpClient) {
    super();
  }

  requestStoreInsight(request: StoreInsightRequest): Observable<any> {
    const url = this.buildUrl(`${this.path}/store-insight`);

    return this.http.post(url, request);
  }
}

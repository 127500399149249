import { Component, Input, OnChanges } from '@angular/core';
import { BaseComponent } from '../../../../core/base-component';
import { AnalystRatingResponse } from '../../../../core/store/rating/analyst-rating-response';
import { InsightsDatePipe } from '../../../../core/date/insights-date.pipe';
import { DatePipe } from '@angular/common';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import { StoreAnalystRatingWizard } from './store-analyst-rating-wizard/store-analyst-rating-wizard';

@Component({
  selector: 'mtn-analyst-ratings-card',
  templateUrl: './analyst-ratings-card.component.html',
  styleUrls: ['../additional-insights-card.scss', './analyst-ratings-card.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class AnalystRatingsCardComponent extends BaseComponent implements OnChanges {

  @Input()
  isRestrictedLicenseOverlayEnabled = false;
  @Input()
  ratings: AnalystRatingResponse;

  description: string;
  title: string;

  constructor(private insightsDatePipe: InsightsDatePipe,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnChanges() {
    this.description = this.ratings?.hasMinimumRequiredRatingCount() ? `As of ${this.insightsDatePipe.transform(this.ratings.ratingDate)}` : 'Not yet evaluated';
    this.title = this.ratings?.hasMinimumRequiredRatingCount() ? 'Analyst Ratings Available' : 'Analyst Ratings Unavailable';
  }

  handleClick(): void {
    if (this.ratings?.hasMinimumRequiredRatingCount() && !this.isRestrictedLicenseOverlayEnabled) {
      const wizard = new StoreAnalystRatingWizard();
      wizard.model = {
        rating: this.ratings
      };

      this.wizardRunner.run(wizard);
    }
  }

}

<div class="associated-company-card-container container default-padding mat-elevation-z1" fxLayout="column"
     fxLayoutGap="15px">
  <div *ngIf="company; else noCompany" fxLayout="column" fxLayoutGap="15px">
    <div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">{{company.name}}</h2>
        <div fxFlex></div>
        <a [href]="'/administration/company/' + company.uuid" target="_blank">
          <fa-icon class="color-help clickable" icon="link" matTooltip="View Company Details"></fa-icon>
        </a>
      </div>
      <hr/>
    </div>
    <span>{{userProfile.getDisplayName()}} is {{userProfile.isCompanyAdministrator ? 'an Administrator' : 'a Member'}}
      of this company.</span>
    <div fxLayout="column">
      <mtn-key-value key="Type">
        <span>{{company.license.definition?.displayName}}</span>
      </mtn-key-value>
      <mtn-key-value key="Expiration Date">
          <span
            *ngIf="company.license.expirationDate; else nonExpiring">{{company.license.expirationDate | insightsDate}}</span>
        <ng-template #nonExpiring>
          <span class="color-muted">Non-Expiring</span>
        </ng-template>
      </mtn-key-value>
      <mtn-key-value key="Status">
        <span class="color-success" *ngIf="company.license.isValid()">Active</span>
        <span class="color-error" *ngIf="company.license.isExpired()">Expired</span>
      </mtn-key-value>
    </div>
  </div>
  <ng-template #noCompany>
    <div class="no-company-container container" fxLayout="column" fxLayoutAlign="center center"
         *ngIf="!userProfile?.company?.uuid">
      <span class="color-muted">User does not belong to any Company</span>
    </div>
  </ng-template>
  <mtn-spinner [on]="!company" *ngIf="userProfile?.company?.uuid"></mtn-spinner>
</div>

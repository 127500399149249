import * as jwtDecode from 'jwt-decode';
import { UserProfile } from '../../core/user-profile/user-profile';
import { TokenResponse } from './token-response';
import * as _ from 'lodash';

export class MtnAccessToken {

  private static readonly MAXIMUM_LEEWAY = 1000 * 60 * 60 * 2; //2 hours

  token: string;

  private readonly payload: MtnAccessTokenPayload;

  constructor(token: TokenResponse) {
    if (token) {
      this.token = token.token;

      this.payload = new MtnAccessTokenPayload(this.token);
    }
  }

  public getCurrentUser(): UserProfile {
    let user: UserProfile = null;
    if (this.payload && this.payload.user) {
      user = _.cloneDeep(this.payload && this.payload.user);
    }
    return user;
  }

  public getExpirationDate(): Date {
    return this.payload && this.payload.exp;
  }

  public isExpired(): boolean {
    return this.payload && this.payload.exp < new Date();
  }

  public isRenewable(): boolean {
    return this.isExpired() && this.payload && this.payload.exp && (new Date().getTime() - this.payload.exp.getTime()) < MtnAccessToken.MAXIMUM_LEEWAY;
  }

  public isValid(): boolean {
    return !this.isExpired();
  }
}

export class MtnAccessTokenPayload {

  sub: string;
  iss: string;
  exp: Date;
  user: UserProfile;

  constructor(raw: string) {
    if (raw) {
      const decodedPayload: any = jwtDecode(raw);
      Object.assign(this, decodedPayload);

      if (decodedPayload.exp) {
        this.exp = new Date(decodedPayload.exp * 1000);
      }
      if (decodedPayload.user) {
        this.user = new UserProfile(JSON.parse(decodedPayload.user));
      }
    }
  }
}

<div class="store-critical-insights-panel-container container">
  <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Critical Insights</h1>
  <hr style="margin-bottom: 30px;"/>
  <div fxLayout="row" fxLayoutGap="15px">
    <div class="info-cards-container" fxLayout="column" fxLayoutGap="15px">
      <mtn-critical-insights-card icon="dollar-sign" [title]="cardTitleVolume"
                                  [description]="cardDescriptionVolume"
                                  [volume]="volume"
                                  [isRestrictedLicenseOverlayEnabled]="!hasStandardLicense"
                                  mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                  [analyticsEventParameters]="{'target': 'store-details-sales-volume-button'}"
                                  (click)="openSalesVolumeHistoryWizard()"></mtn-critical-insights-card>
      <mtn-critical-insights-card icon="dollar-sign" secondaryIcon="draw-polygon" [title]="cardTitleSalesSqft"
                                  [description]="cardDescriptionSalesSqft"
                                  [volume]="volume"
                                  [isRestrictedLicenseOverlayEnabled]="!hasStandardLicense"
                                  mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                  [analyticsEventParameters]="{'target': 'store-details-sales-sqft-button'}"
                                  (click)="openSalesVolumeHistoryWizard()"></mtn-critical-insights-card>
      <mtn-critical-insights-card icon="draw-polygon" [title]="cardTitleArea"
                                  [description]="cardDescriptionArea"
                                  [isRestrictedLicenseOverlayEnabled]="!hasStandardLicense"
                                  mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                  [analyticsEventParameters]="{'target': 'store-details-area-button'}"
                                  (click)="openSalesAreaWizard()"></mtn-critical-insights-card>
      <mtn-critical-insights-card icon="circle" iconColor="limegreen" [title]="cardTitleStatus"
                                  [description]="cardDescriptionStatus"
                                  [iconColor]="statusColor"
                                  [isRestrictedLicenseOverlayEnabled]="!hasStandardLicense"
                                  mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                  [analyticsEventParameters]="{'target': 'store-details-status-button'}"
                                  (click)="openStatusHistoryWizard()"></mtn-critical-insights-card>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <mtn-store-highlights-panel #highlightsPanel [maxCount]="maxHighlightCount" fxFlex></mtn-store-highlights-panel>
  </div>
  <div fxLayout="row" fxLayoutAlign="end start" *ngIf="highlightsPanel.highlights.length > 4"
       style="width: 100%; margin-top: 5px;">
    <a href="javascript:void(0)"
       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
       [analyticsEventParameters]="{'target': 'store-details-view-all-highlights-link'}"
       (click)="toggleMaxCount()">{{maxHighlightCount === 4 ? 'View All' : 'View Less'}}</a>
  </div>
</div>

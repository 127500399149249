import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../../core/wizard/wizard-page-component';
import { SpaceDetailsWizard } from '../space-details-wizard';
import { Space } from '../../../../../../../core/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'mtn-space-details-page',
  templateUrl: './space-details-page.component.html',
  styleUrls: ['./space-details-page.component.scss']
})
export class SpaceDetailsPageComponent extends WizardPageComponent<SpaceDetailsWizard> {

  key = 'space-details';

  space: Space;

  constructor() {
    super();
    this.title = 'Space Details';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.space = this.wizard.model.space;
    return super.onLoad();
  }

}

<div class="space-subpanel-container container">
  <div class="content-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
       *ngIf="isExpanded; else centeredIcon"
       [matTooltip]="breakpoint === Breakpoints.XLarge || breakpoint === Breakpoints.Large ? 'Click for Details' : ''">
    <fa-icon icon="map-marker-alt" size="2x"></fa-icon>
    <mtn-address-view [location]="space.location" *ngIf="isExpanded && space.location"></mtn-address-view>
    <div fxFlex></div>
    <div mtnClickTrap>
      <mtn-icon-button color="accent" *ngIf="breakpoint !== Breakpoints.XLarge && breakpoint !== Breakpoints.Large"
                       matTooltip="Click for Details" (onClick)="openDetailsWizard()">
        <fa-icon icon="ellipsis-h"></fa-icon>
      </mtn-icon-button>
    </div>
  </div>
  <ng-template #centeredIcon>
    <div class="content-container" fxLayout="row" fxLayoutAlign="center center">
      <fa-icon icon="map-marker-alt" size="2x"></fa-icon>
    </div>
  </ng-template>

</div>

<div class="wizard-container container"
     [ngStyle]="{width: wizard.width + 'px', 'min-width': wizard.width + 'px', 'max-width': wizard.width + 'px'}">

  <div class="header-container" fxLayout="row" fxLayoutAlign="start center" *ngIf="isInitialized">
    <div>
      <h2 class="mat-h2 text-small-caps" mat-dialog-title>{{wizard.currentPage?.title || 'Loading Wizard...'}}</h2>
      <div *ngIf="!!wizard.currentPage?.subTitle"><small>{{wizard.currentPage?.subTitle}}</small></div>
    </div>
    <div fxFlex></div>
    <button mat-icon-button (click)="close()" [hidden]="!wizard.currentPage?.closeButtonText">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="loader-container" *ngIf="!isInitialized" fxLayoutAlign="center center">
    <img src="assets/images/cube-preloader.svg" height="90px" width="90px;" alt="Loading Indicator"/>
  </div>

  <div class="content-container container scrollable"
       fxLayout="column" fxLayoutGap="15px" [hidden]="!isInitialized">
    <mtn-wizard-stepper [wizard]="wizard" *ngIf="isInitialized && wizard.pageCount > 1"></mtn-wizard-stepper>

    <div class="content-header-container" *ngIf="wizard.currentPage?.headerText || wizard.currentPage?.subheaderText">
      <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin"
          *ngIf="wizard.currentPage?.headerText">{{wizard.currentPage?.headerText}}</h2>
      <div *ngIf="wizard.currentPage?.subheaderText" [innerHTML]="wizard.currentPage?.subheaderText"></div>
    </div>

    <mtn-simple-panel color="purple" *ngIf="wizard.currentPage?.alert1Text">
      <div [innerHTML]="wizard.currentPage?.alert1Text"></div>
    </mtn-simple-panel>

    <form (ngSubmit)="next()">
      <div #page></div>
    </form>

    <mtn-simple-panel color="purple" *ngIf="wizard.currentPage?.alert2Text">
      <div [innerHTML]="wizard.currentPage?.alert2Text"></div>
    </mtn-simple-panel>

  </div>

  <div class="footer-container" fxLayout="row" *ngIf="isInitialized">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="footer" *ngIf="wizard.currentPage?.footerText" [innerHTML]="wizard.currentPage?.footerText"></div>
    </div>

    <div fxFlex></div>

    <div class="wizard-toolbar" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
      <!-- Footer Checkbox -->
      <div *ngIf="wizard.currentPage?.footerCheckboxText">
        <mat-checkbox color="primary"
                      [(ngModel)]="wizard.isFooterCheckboxChecked">{{wizard.currentPage?.footerCheckboxText}}</mat-checkbox>
      </div>

      <!-- Action Button -->
      <div [hidden]="!wizard.currentPage?.actionButtonText" mtnClickTrap>
        <button mat-raised-button [color]="wizard.currentPage?.actionButtonColor" (click)="action()"
                [disabled]="!wizard.currentPage || !wizard.currentPage.isActionButtonEnabled">
          <mtn-spinner [color]="wizard.currentPage?.actionButtonColor" [size]="SpinnerSize.EXTRA_SMALL"
                       #actionSpinner></mtn-spinner>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <fa-icon [icon]="wizard.currentPage?.actionButtonIcon"
                     *ngIf="wizard.currentPage?.actionButtonIcon"
                     [ngStyle]="{'font-size': wizard.currentPage?.actionButtonIconSize + 'px'}"></fa-icon>
            <span>{{wizard.currentPage?.actionButtonText}}</span>
          </div>
        </button>
      </div>

      <!-- Back Button -->
      <div [hidden]="!wizard.currentPage?.backButtonText">
        <button mat-raised-button (click)="back()">
          <mtn-spinner [size]="SpinnerSize.EXTRA_SMALL" #backSpinner></mtn-spinner>
          <span>{{wizard.currentPage?.backButtonText}}</span>
        </button>
      </div>

      <!-- Next Button -->
      <div [mtnFormErrorHighlighter]="wizard.currentPage?.form" [hidden]="!wizard.currentPage?.nextButtonText">
        <button [color]="wizard.currentPage?.nextButtonColor" mat-raised-button (click)="next()"
                [disabled]="!wizard.currentPage?.form?.valid">
          <mtn-spinner [color]="wizard.currentPage?.nextButtonColor" [size]="SpinnerSize.EXTRA_SMALL"
                       #nextSpinner></mtn-spinner>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <fa-icon [icon]="wizard.currentPage?.nextButtonIcon"
                     *ngIf="wizard.currentPage?.nextButtonIcon"
                     [ngStyle]="{'font-size': wizard.currentPage?.nextButtonIconSize + 'px'}"></fa-icon>
            <span>{{wizard.currentPage?.nextButtonText}}</span>
          </div>
        </button>
      </div>

      <!-- Close Button -->
      <div [hidden]="!wizard.currentPage?.closeButtonText">
        <button mat-raised-button (click)="close()">
          <mtn-spinner [size]="SpinnerSize.EXTRA_SMALL" #closeSpinner></mtn-spinner>
          <span>{{wizard.currentPage?.closeButtonText}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

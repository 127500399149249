import { Wizard } from '../../../core/wizard/wizard';
import { SavedSearch } from '../../../core/federation/filter/saved-search';
import { MtnMap } from '../../mtn-map';
import { AddOrEditSavedSearchPageComponent } from './add-or-edit-saved-search-page/add-or-edit-saved-search-page.component';

export class AddOrEditSavedSearchWizard extends Wizard<AddOrEditSavedSearchWizardModel> {
  key = 'add-or-edit-saved-search';
  pageTypeMap = {
    'add-or-edit-saved-search': AddOrEditSavedSearchPageComponent
  };
  startingPageKey = 'add-or-edit-saved-search';
}

export interface AddOrEditSavedSearchWizardModel {
  map: MtnMap;
  result?: SavedSearch;
  savedSearch?: SavedSearch;
}

import { Injectable } from '@angular/core';
import { CrudService } from '../../service/crud-service';
import { buildFilter, Filter } from './filter';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class FilterService extends CrudService<Filter<any>> {

  protected path = '/filter';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): Filter<any> {
    return buildFilter(raw);
  }

  // @ts-ignore
  protected buildPageableInstance(raw: any): Pageable<Filter<any>> {
    throw new Error('UnsupportedOperationException');
  }
}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { SalesAreaWizard } from '../sales-area-wizard';
import { Observable } from 'rxjs';
import { Store } from '../../../../../core/models';
import { round } from '../../../../../core/util/math-utils';

@Component({
  selector: 'mtn-sales-area-page',
  templateUrl: './sales-area-page.component.html',
  styleUrls: ['./sales-area-page.component.scss']
})
export class SalesAreaPageComponent extends WizardPageComponent<SalesAreaWizard> {

  key = 'sales-area';

  salesAreaPercentage: number;
  store: Store;

  constructor() {
    super();
    this.title = 'Sales / Total Area';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.store = this.wizard.model.store;

    if (this.store.grocerySalesArea && this.store.totalArea) {
      this.salesAreaPercentage = round(this.store.grocerySalesArea / this.store.totalArea * 100);
    }
    return super.onLoad();
  }

}

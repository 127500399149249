import { Wizard } from '../../wizard/wizard';
import { UserProfile } from '../../user-profile/user-profile';
import { ConfirmRemoveAccessPageComponent } from './confirm-remove-access-page/confirm-remove-access-page.component';

export class ConfirmRemoveAccessWizard extends Wizard<ConfirmRemoveAccessWizardModel> {
  key = 'confirm-remove-access';
  pageTypeMap = {
    'confirm-remove-access': ConfirmRemoveAccessPageComponent
  };
  startingPageKey = 'confirm-remove-access';
}

export interface ConfirmRemoveAccessWizardModel {
  isDeleted?: boolean;
  user: UserProfile;
}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { UpdateLicenseWizard } from '../update-license-wizard';
import { LicenseService } from '../../../../../../core/license/license.service';
import { ToastService } from '../../../../../../core/toast/toast.service';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { compareByUuid } from '../../../../../../core/util/collection-util';
import { Company } from '../../../../../../core/company/company';
import { License } from '../../../../../../core/license/license';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-update-license-page',
  templateUrl: './update-license-page.component.html',
  styleUrls: ['./update-license-page.component.scss']
})
export class UpdateLicensePageComponent extends WizardPageComponent<UpdateLicenseWizard> {

  company: Company;
  comparator = compareByUuid;
  key = 'update-license';
  license: License;

  constructor(private licenseService: LicenseService,
              private toaster: ToastService) {
    super();
  }

  onLoad(): Observable<any> {
    this.company = this.wizard.model.company;

    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
    this.headerText = 'Update License';
    this.subheaderText = `You are updating the License for <strong>${this.company.name}</strong>.`
    this.title = 'Update License';

    this.form = new UntypedFormGroup({
      expirationDate: new UntypedFormControl(),
      startDate: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl(null, [Validators.required])
    });

    return this.licenseService.findOne(this.company.license.uuid)
      .pipe(map((license: License) => {
        this.license = license;
        this.initForm();
      }));
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();

    return this.licenseService.updateOne(request)
      .pipe(map((result: License) => {
        this.toaster.info("Successfully updated License");
        this.wizard.model.license = result;
        this.wizard.model.isUpdated = true;
        return null;
      }));
  }

  private buildRequest(): License {
    const request = _.cloneDeep(this.license);
    const formValue = this.form.getRawValue();

    request.startDate = formValue.startDate;
    request.expirationDate = formValue.expirationDate;
    request.type = formValue.type;

    return request;
  }

  private initForm(): void {
    this.form.get('type').setValue(this.license.type, {emitEvent: false});
    this.form.get('startDate').setValue(this.license.startDate, {emitEvent: false});
    this.form.get('expirationDate').setValue(this.license.expirationDate, {emitEvent: false});
  }

}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { HistoricalStoresWizard } from '../historical-stores-wizard';
import { Store } from '../../../../../core/models';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../../core/date/insights-date.pipe';

@Component({
  selector: 'mtn-historical-stores-page',
  templateUrl: './historical-stores-page.component.html',
  styleUrls: ['./historical-stores-page.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class HistoricalStoresPageComponent extends WizardPageComponent<HistoricalStoresWizard> {

  key = 'historical-stores';

  otherStores: Store[] = [];

  constructor() {
    super();
    this.title = 'Historical Store(s)';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.otherStores = this.wizard.model.otherStores;
    return super.onLoad();
  }
}

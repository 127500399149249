<div class="breadcrumbs-container container" fxLayout="row" fxLayoutGap="10px"
     *ngIf="breadcrumbs.length">
  <div *ngFor="let breadcrumb of breadcrumbs; let isLast = last" fxLayout="row" fxLayoutGap="10px">
    <a class="color-accent" href="javascript:void(0)" [routerLink]="breadcrumb.routerLink"
       *ngIf="breadcrumb.routerLink; else noLink">{{breadcrumb.displayValue}}</a>
    <ng-template #noLink>
      <span>{{breadcrumb.displayValue}}</span>
    </ng-template>
    <div *ngIf="!isLast">&gt;</div>
  </div>
</div>

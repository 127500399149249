<div class="add-to-collection-page-container container" fxLayout="column" fxLayoutGap="15px">
  <p>Select a collection to which you'd like to add
    these {{wizard.model.storeUuids.length}} {{wizard.model.storeUuids.length === 1 ? 'store' : 'stores'}}.</p>
  <div fxLayout="row" fxLayoutAlign="end start">
    <mtn-text-field [form]="form" controlName="filter" placeholder="Filter" [noErrorPadding]="true"></mtn-text-field>
  </div>
  <div class="container">
    <div class="collection-list-container container scrollable">
      <mat-selection-list [multiple]="false" (selectionChange)="handleSelection($event)">
        <mat-list-option *ngFor="let collection of collections" [value]="collection">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="default-icon-container" fxLayoutAlign="center center">
              <fa-icon icon="folder"></fa-icon>
            </div>
            <div fxLayout="column" style="max-width: 320px;">
              <small class="text-ellipsis"><strong>{{collection.name}}</strong></small>
              <small class="color-muted text-ellipsis" *ngIf="collection.description">{{collection.description}}</small>
            </div>

          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <mtn-spinner [on]="isLoading"></mtn-spinner>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { MtnMaterialModule } from '../../modules/mtn-material.module';

const componentDeclarations: any[] = [
  SpinnerComponent
]

@NgModule({
  declarations: componentDeclarations,
  exports: componentDeclarations,
  imports: [
    CommonModule,
    MtnMaterialModule
  ]
})
export class MtnSpinnerModule {
}

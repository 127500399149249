<div class="navigation-button interactive-button container" [ngClass]="{disabled: isDisabled}" fxLayout="row"
     fxLayoutAlign="start center"
     fxLayoutGap="10px" [routerLink]="[path]" routerLinkActive="active" [matTooltip]="condensedMode ? label : null"
     matTooltipPosition="right">
  <div class="active-indicator"></div>
  <div class="icon-container" fxLayoutAlign="center center">
    <fa-icon [icon]="icon"></fa-icon>
  </div>
  <div class="interactive-label" *ngIf="!condensedMode">{{label}}</div>
</div>

import { Component, Input, OnChanges } from '@angular/core';
import { Company } from '../../../../core/company/company';
import { BaseComponent } from '../../../../core/base-component';
import { LicenseService } from '../../../../core/license/license.service';
import { finalize, map, switchMap } from 'rxjs/operators';
import { License } from '../../../../core/license/license';
import * as _ from 'lodash';
import { UserProfile } from '../../../../core/user-profile/user-profile';
import { forkJoin, Observable } from 'rxjs';
import { UserProfileService } from '../../../../core/user-profile/user-profile.service';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../core/date/insights-date.pipe';
import { getLicenseDisplayName } from '../../../../auth/authorization/license-type.enum';

@Component({
  selector: 'mtn-license-history-panel',
  templateUrl: './license-history-panel.component.html',
  styleUrls: ['./license-history-panel.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class LicenseHistoryPanelComponent extends BaseComponent implements OnChanges {

  private readonly OMITTED_FIELDS: string[] = ['id', 'uuid', 'createdBy', 'createdDate', 'updatedBy', 'deletedBy', 'deletedDate', 'updatedDate', 'version'];

  @Input()
  company: Company;

  isLoading = false;
  maxCount = 5;
  sort: 'asc' | 'desc' = 'desc';
  versions: LicenseHistory[] = [];

  constructor(private insightsDatePipe: InsightsDatePipe,
              private licenseService: LicenseService,
              private userService: UserProfileService) {
    super();
  }

  ngOnChanges(): void {
    this.loadLicenseHistory();
  }

  toggleSort(): void {
    if (this.sort === 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.sortVersions();
  }

  private loadLicenseHistory(): void {
    this.isLoading = true;
    this.licenseService.findAllVersions(this.company.license.uuid)
      .pipe(
        switchMap((results: License[]) => {
          const allLicenses: License[] = [];
          allLicenses.push(this.company.license);
          allLicenses.push.apply(allLicenses, results);

          const userProfileTasks: Observable<UserProfile>[] = allLicenses.map((license: License) => this.userService.findOneCached(license.updatedBy));

          return forkJoin(userProfileTasks)
            .pipe(map((userProfiles: UserProfile[]) => {
              this.versions = allLicenses.map((license: License) => {
                const version: LicenseHistory = {
                  license: license,
                  userProfile: _.find(userProfiles, (userProfile: UserProfile) => userProfile.uuid === license.updatedBy)
                };
                return version;
              });
              this.sortVersions();
            }))
        }),
        finalize(() => this.isLoading = false)
      )
      .subscribe();
  }

  private sortVersions(): void {
    //Sort the records
    this.versions = _.orderBy(this.versions, (version: LicenseHistory) => version.license.updatedDate, this.sort);

    //Diff the records if they haven't already been diffed
    this.versions.forEach((version: LicenseHistory, index: number) => {
      if (!version.diffs?.length) {

        const previousIndex = this.sort === 'asc' ? index - 1 : index + 1;
        const previousVersion = this.versions[previousIndex];

        const currentPairs = _.toPairs(_.omit(version.license, this.OMITTED_FIELDS));
        const previousPairs = previousVersion ? _.toPairs(_.omit(previousVersion.license, this.OMITTED_FIELDS)) : [];

        const diffs: LicenseDiff[] = [];
        const fields: string[] = [];

        const rawDiffs = _.differenceWith(currentPairs, previousPairs, _.isEqual);
        rawDiffs.push.apply(rawDiffs, _.differenceWith(previousPairs, currentPairs, _.isEqual));
        rawDiffs.forEach((raw: any) => {
          const rawField = raw[0];
          const field = this.translateFieldName(rawField);
          fields.push(field);

          // @ts-ignore
          const from = previousVersion?.license[rawField];
          // @ts-ignore
          const to = version.license[rawField];

          diffs.push({
            field: field,
            from: this.translateFieldValue(rawField, from),
            to: this.translateFieldValue(rawField, to)
          })
        });

        version.diffs = _.uniqWith(diffs, _.isEqual);
        version.fieldString = _.join(fields, ', ');
      }
    });
  }

  private translateFieldName(value: string): string {
    switch (value) {
      case 'expirationDate':
        return 'Expiration Date';
      case 'startDate':
        return 'Start Date';
      case 'suspendedDate':
        return 'Suspended Date';
      case 'suspendedComment':
        return 'Comment';
      case 'suspendedReason':
        return 'Reason';
      case 'type':
        return 'Type';
      default:
        return value;
    }
  }

  private translateFieldValue(key: string, value: any): string {
    if (value) {
      switch (key) {
        case 'expirationDate':
        case 'startDate':
        case 'suspendedDate':
          return this.insightsDatePipe.transform(value);
        case 'type':
          return getLicenseDisplayName(value);
        default:
          return value;
      }
    }
  }
}

export interface LicenseDiff {
  field: string;
  from: any
  to: any;
}

export interface LicenseHistory {
  diffs?: any[];
  fieldString?: string;
  license: License;
  userProfile: UserProfile;
}

<div class="collections-list-page-container container" fxLayout="column" fxLayoutGap="15px">
  <div class="page-header" fxLayout="row" fxLayoutGap="15px">
    <div fxLayout="column">
      <h1 class="mat-h1 color-accent text-small-caps no-bottom-margin">My Collections</h1>
      <span class="tab-description color-muted">Collections are simply a way of organizing items, such as stores (or shopping centers, soon!), into a group that you can analyze and compare against each other or against other collections.</span>
    </div>
  </div>
  <div class="table-toolbar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [formGroup]="filterForm">
    <button class="default-button" mat-raised-button [matMenuTriggerFor]="actionsMenu" [disabled]="!selectionCount"
            mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
            [analyticsEventParameters]="{'target': 'collections-list-actions-button'}">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <span>Actions ({{selectionCount || 0}})</span>
        <fa-icon icon="caret-down"></fa-icon>
      </div>
    </button>
    <div fxFlex></div>
    <mat-checkbox color="primary" formControlName="includeShared"
                  mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                  [analyticsEventParameters]="{'target': 'collections-list-include-shared-button'}">Include Shared with
      Me
    </mat-checkbox>
    <!-- TODO re-enable this after we have validation on this page to ensure that a collection is ready for use
    and we've removed federation's validation requiring at least one includedFeatures entry or a filter on create. We
    want to add an icon to this page for any collection that is missing minimum requirements for use, and need to
    filter the same out of the collection filter drop-down somehow. Until that time, only allow creation of collections
    from the map.-->
    <mtn-icon-button color="accent" (onClick)="openCreateCollectionWizard()" [matTooltip]="CREATE_COLLECTION_TOOLTIP"
                     matTooltipClass="multiline-tooltip" [disabled]="true">
      <fa-icon icon="plus"></fa-icon>
    </mtn-icon-button>
    <mtn-text-field [form]="filterForm" controlName="filter" label="Filter" [enableClear]="true"
                    placeholder="by Name, Description, or Tag"
                    [noErrorPadding]="true"></mtn-text-field>
  </div>

  <div class="table-container container">
    <mtn-spinner [size]="SpinnerSize.LARGE" [on]="isLoading"></mtn-spinner>
    <div class="no-data-container" fxLayout="row" fxLayoutAlign="center center" *ngIf="!dataSource?.data?.length">No
      Results Available
    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="favorite" matSortDirection="desc"
           [hidden]="!dataSource?.data?.length" (matSortChange)="loadCollections()">

      <!-- Selection Column -->
      <ng-container matColumnDef="selection">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let selectable" mtnClickTrap [preventDefault]="false">
          <mat-checkbox color="primary" [(ngModel)]="selectable.isSelected" (change)="countSelections()"></mat-checkbox>
        </td>
      </ng-container>

      <!-- Favorite Column -->
      <ng-container matColumnDef="favorite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let selectable" mtnClickTrap>
          <fa-icon class="favorite-icon clickable"
                   [icon]="selectable.content.isFavoritedBy(currentUser.uuid) ? 'star' : ['far', 'star']"
                   [ngClass]="{'color-accent': selectable.content.isFavoritedBy(currentUser.uuid)}"
                   (click)="toggleFavorite(selectable.content)"
                   matTooltip="Favorite"></fa-icon>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let selectable">
          <div fxLayout="column">
            <strong>{{selectable.content.name}}</strong>
            <small class="color-muted">{{selectable.content.description}}</small>
          </div>
        </td>
      </ng-container>

      <!-- Date column -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let selectable">
          <div>{{selectable.content.createdDate | insightsDate}}</div>
        </td>
      </ng-container>

      <!-- Owner column -->
      <ng-container matColumnDef="ownerDisplayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner</th>
        <td mat-cell *matCellDef="let selectable">
          <mtn-user-inline-view [userUuid]="selectable.content.owner.uuid"
                                *ngIf="selectable.content.owner"></mtn-user-inline-view>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="clickable" *matRowDef="let row; columns: displayedColumns;"
          (click)="navigateToCollection(row.content)"></tr>
    </table>
    <mat-paginator [pageSize]="25" [length]="totalResults" [pageIndex]="currentPage"
                   [showFirstLastButtons]="true" [hidePageSize]="true" [hidden]="totalResults <= 25"
                   (page)="loadCollections($event.pageIndex)"></mat-paginator>
  </div>
</div>

<mat-menu #actionsMenu="matMenu">
  <button mat-menu-item (click)="openConfirmDeleteDialog()">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      Delete Collection(s)
    </div>
  </button>
</mat-menu>

import { BasicEntity } from '../../basic-entity';
import { FilterGroupRelationshipType } from './filter-group-relationship-type.enum';
import { buildFilter, Filter } from './filter';
import { FilterType } from './filter-type.enum';
import * as _ from 'lodash';

export class FilterGroup extends BasicEntity {

  parent: FilterGroup;
  relationshipType = FilterGroupRelationshipType.AND;

  children: FilterGroup[] = [];
  filters: Filter<any>[] = [];

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.children = [];
      this.filters = [];

      if (raw.parent) {
        this.parent = new FilterGroup(raw.parent);
      }
      if (raw.relationshipType) {
        // @ts-ignore
        this.relationshipType = FilterGroupRelationshipType[<string>raw.relationshipType];
      }
      if (raw.children?.length) {
        this.children = raw.children.map((child: any) => new FilterGroup(child));
      }
      if (raw.filters?.length) {
        this.filters = raw.filters.map((filter: any) => buildFilter(filter));
      }
    }
  }

  /**
   * Remove all ids and uuids so we can effectively make a clone in the back-end. Recommended to clone the FilterGroup
   * before calling this function.
   */
  anonymize(): void {
    this.id = null;
    this.uuid = null;

    this.children.forEach((child: FilterGroup) => child.anonymize());
    this.filters.forEach((filter: Filter<any>) => {
      filter.id = null;
      filter.uuid = null;
    });
  }

  clearFilter(type: FilterType, clearInChildren = true): void {
    _.remove(this.filters, (filter: Filter<any>) => filter.type === type);

    if (clearInChildren) {
      this.children.forEach((child: FilterGroup) => child.clearFilter(type, clearInChildren));
    }
  }

  getFilterByUuid(uuid: string): Filter<any> {
    return _.find(this.filters, (filter: Filter<any>) => filter.uuid === uuid);
  }

  /**
   * This method looks in this FilterGroup's filters array, as well as any child FilterGroups' filters arrays.
   */
  getFiltersOfType(type: FilterType): Filter<any>[] {
    const results: Filter<any>[] = _.filter(this.filters, (filter: Filter<any>) => filter.type === type);

    this.children.forEach((child: FilterGroup) => {
      const childResults = child.getFiltersOfType(type);
      if (childResults.length) {
        results.push.apply(results, childResults);
      }
    });

    return results;
  }

  /**
   * This method only looks for the given filter in this FilterGroup's filters array, and does NOT check any child
   * FilterGroups. It also assumes there will only be one of the given filter type. This function was intended for use
   * with the maps, where there should only be one of each filter, and not for any advanced Collection or SavedSearch
   * handling.
   */
  getOwnFilter(type: FilterType): Filter<any> {
    return _.find(this.filters, (filter: Filter<any>) => filter.type === type);
  }

  /**
   * This method looks in this FilterGroup's filters array, as well as any child FilterGroups' filters arrays.
   */
  hasFilter(type: FilterType): boolean {
    const hasFilter = !!_.find(this.filters, (filter: Filter<any>) => filter.type === type);
    const childHasFilter = !!_.find(this.children, (child: FilterGroup) => child.hasFilter(type));

    return hasFilter || childHasFilter;
  }

  /**
   * This method only looks for the given filter in this FilterGroup's filters array, and does NOT check any child
   * FilterGroups. It also assumes there will only be one of the given filter type. This function was intended for use
   * with the maps, where there should only be one of each filter, and not for any advanced Collection or SavedSearch
   * handling.
   */
  hasOwnFilter(type: FilterType): boolean {
    return !!_.find(this.filters, (filter: Filter<any>) => filter.type === type);
  }
}

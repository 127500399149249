import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '../../core/models';
import { AppState } from '../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { DetailResetAction } from '../detail-actions';
import { AuthorizationAwareComponent } from '../../core/authorization-aware-component';
import { StoreDetailService } from '../../core/store/store-detail.service';
import { DetailState, selectDetailState } from '../detail-state';
import { finalize } from 'rxjs/operators';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import { ClipboardUtil } from '../../core/util/clipboard-util';
import { ToastService } from '../../core/toast/toast.service';

@Component({
  selector: 'mtn-store-detail-page',
  templateUrl: './store-detail-page.component.html',
  styleUrls: ['./store-detail-page.component.scss']
})
export class StoreDetailPageComponent extends AuthorizationAwareComponent implements OnInit {

  isMarketAreaLoaded = false;
  licenseLevel: LicenseType;
  store: Store;

  private isLoading = false;
  private uuid: string;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private route: ActivatedRoute,
              private storeDetailService: StoreDetailService,
              private toaster: ToastService) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onAuthorizationInit(): void {
    this.licenseLevel = this.currentUser.licenseType;
    this.subscribeToRouteChanges();
    this.subscribeToDetailState();
  }

  onAuthorizationChange(): void {

  }

  copyUrlToClipboard(): void {
    ClipboardUtil.copyValueToClipboard(window.location.href);
    this.toaster.info("Copied URL to Clipboard");
  }

  private loadStore(uuid: string): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.resetPageState();
      this.storeDetailService.loadOne(uuid)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe();
    }
  }

  private resetPageState(): void {
    this.store = null;
    this.ngrxStore.dispatch(DetailResetAction());
  }

  private subscribeToDetailState(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .subscribe((state: DetailState) => {
          this.store = state.store;
          this.isMarketAreaLoaded = !!state.marketArea;
        })
    );
  }

  private subscribeToRouteChanges(): void {
    this.addSubscription(
      this.route.params
        .subscribe((value: Params) => {
          if (this.uuid !== value['uuid']) {
            this.loadStore(value['uuid']);
          }
        })
    );
  }

}

import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { AreaSubscription } from './area-subscription';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class AreaSubscriptionService extends CrudService<AreaSubscription> {

  protected path = '/area-subscription';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): AreaSubscription {
    return new AreaSubscription(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<AreaSubscription> {
    return new Pageable<AreaSubscription>(raw, AreaSubscription);
  }
}

import { Wizard } from '../../../../core/wizard/wizard';
import { Store } from '../../../../core/models';
import { UserPreferences } from '../../../../core/user-preferences/user-preferences';
import { SalesVolumeHistoryPageComponent } from './sales-volume-history-page/sales-volume-history-page.component';

export class SalesVolumeHistoryWizard extends Wizard<SalesVolumeHistoryWizardModel> {
  key = 'sales-volume-history';
  pageTypeMap = {
    'sales-volume-history': SalesVolumeHistoryPageComponent
  };
  startingPageKey = 'sales-volume-history';
}

export interface SalesVolumeHistoryWizardModel {
  store: Store;
  userPreferences: UserPreferences;
}

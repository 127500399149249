export enum ShoppingCenterType {
  AIRPORT_RETAIL = 'AIRPORT_RETAIL',
  CENTRAL_BUSINESS_DISTRICT = 'CENTRAL_BUSINESS_DISTRICT',
  COMMUNITY_CENTER = 'COMMUNITY_CENTER',
  FACTORY_OUTLET = 'FACTORY_OUTLET',
  FREE_STANDING_ALONE = 'FREE_STANDING_ALONE',
  FREE_STANDING_CENTER = 'FREE_STANDING_CENTER',
  LIFESTYLE = 'LIFESTYLE',
  NEIGHBORHOOD_CENTER = 'NEIGHBORHOOD_CENTER',
  POWER_CENTER = 'POWER_CENTER',
  REGIONAL_MALL = 'REGIONAL_MALL',
  STRIP_CONVENIENCE = 'STRIP_CONVENIENCE',
  SUPER_REGIONAL_MALL = 'SUPER_REGIONAL_MALL',
  THEME_FESTIVAL = 'THEME_FESTIVAL'
}

export namespace ShoppingCenterType {

  export function values(): ShoppingCenterType[] {
    // @ts-ignore
    return Object.keys(ShoppingCenterType).filter((key: any) => isNaN(key) && key !== 'values').map((key: string) => ShoppingCenterType[key]);
  }
}

<button class="store-actions-menu-button default-button" mat-raised-button [matMenuTriggerFor]="actionsMenu"
        style="height: 36px; line-height: 30px;" (menuOpened)="loadCollections()">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span>Actions</span>
    <fa-icon icon="caret-down"></fa-icon>
  </div>
</button>

<mat-menu #actionsMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="collectionsMenu"
          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
          [analyticsEventParameters]="{'target': 'store-actions-menu-add-to-collection-button'}">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="default-icon-container" fxLayoutAlign="center center">
        <fa-icon icon="folder-plus"></fa-icon>
      </div>
      <span>Add to Collection</span>
    </div>
  </button>
  <button mat-menu-item (click)="openDownloadWizard()" [disabled]="!hasStandardLicense"
          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
          [analyticsEventParameters]="{'target': 'store-actions-menu-download-data-button'}">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="default-icon-container" fxLayoutAlign="center center">
        <fa-icon icon="cloud-download-alt"></fa-icon>
      </div>
      <span>Download Data</span>
    </div>
  </button>
  <button mat-menu-item (click)="copyUrlToClipboard()" *ngIf="storeUuids?.length === 1"
          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
          [analyticsEventParameters]="{'target': 'store-actions-menu-share-button'}">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="default-icon-container" fxLayoutAlign="center center">
        <fa-icon icon="share-alt"></fa-icon>
      </div>
      <span>Share</span>
    </div>
  </button>
</mat-menu>

<mat-menu #collectionsMenu="matMenu">
  <button mat-menu-item mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
          [analyticsEventParameters]="{'target': 'store-actions-menu-create-collection-button'}">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" (click)="openCreateNewCollectionWizard()">
      <div class="default-icon-container" fxLayoutAlign="center center">
        <fa-icon icon="plus"></fa-icon>
      </div>
      <span>New Collection</span>
    </div>
  </button>
  <div>
    <mat-divider></mat-divider>
    <div *ngIf="collections.length; else noFavorites">
      <div class="color-muted" style="padding: 10px;">Favorite Collections</div>
      <div>
        <button mat-menu-item *ngFor="let collection of collections" (click)="addToCollection(collection)"
                mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                [analyticsEventParameters]="{'target': 'store-actions-menu-add-to-favorite-collection-button'}">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="default-icon-container" fxLayoutAlign="center center">
              <fa-icon icon="folder"></fa-icon>
            </div>
            <div fxLayout="column" style="max-width:200px; line-height: 16px;">
              <small class="text-ellipsis"><strong>{{collection.name}}</strong></small>
              <small class="text-ellipsis color-muted" *ngIf="collection.description">{{collection.description}}</small>
            </div>
          </div>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  <button mat-menu-item (click)="openAddToCollectionWizard()" mtnAnalyticsEventTrigger
          [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
          [analyticsEventParameters]="{'target': 'store-actions-menu-add-to-other-collection-button'}">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="default-icon-container" fxLayoutAlign="center center">
        <fa-icon icon="ellipsis-h"></fa-icon>
      </div>
      <span>Other</span>
    </div>
  </button>
</mat-menu>

<ng-template #noFavorites>
  <div class="color-muted" style="padding: 10px;">No Favorite Collections Found</div>
</ng-template>

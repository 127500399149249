<div class="maintenance-container" fxLayoutAlign="center center" fxFlexFill>
  <div class="background-container-1" fxLayoutAlign="center center">
    <div class="background-container-2" fxLayoutAlign="center center">
      <div class="background-container-3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div class="header-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <h1
            class="mat-h1 header">{{isMaintenanceEnded ? 'Insights is back up!' : 'Insights is down for scheduled maintenance'}}</h1>
          <span
            class="subheader color-accent">{{isMaintenanceEnded ? 'Click below to sign in' : 'We\'ll be up and running soon'}}</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px"
             *ngIf="!isMaintenanceEnded; else signInButton">
          <img src="assets/images/hamster-spinner.gif" height="193" width="193" alt="Hamster Spinner"/>
          <div class="postscript" fxLayout="column" fxLayoutAlign="start center">
            <span>Thank you for your patience.</span>
            <span>No, really, you're the best!</span>
            <br/>
            <span>We'll let you know on this page when the maintenance has ended.</span>
          </div>
        </div>
        <ng-template #signInButton>
          <button mat-raised-button color="accent" (click)="signIn()">
            Sign In
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div [formGroup]="form">
  <mat-form-field appearance="outline" [ngClass]="{'no-error-padding': noErrorPadding}">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [formControlName]="controlName" [matAutocomplete]="auto"/>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="getDisplayText"
                      (optionSelected)="emitSelection($event)">
      <mat-option *ngFor="let option of search$ | async; let index = index"
                  [value]="option">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <img width="38px" [src]="getLogoUrl(option)" *ngIf="getLogoUrl(option)" alt="Banner Logo"/>
          <span>{{option.name}}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('requiresMatch')">
      <i>Please select a valid option</i>
    </mat-error>
  </mat-form-field>
</div>

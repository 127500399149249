<mat-card class="current-user-chip clickable" [matMenuTriggerFor]="userMenu" mtnAnalyticsEventTrigger
          [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU" [analyticsEventParameters]="{'target': 'user-menu'}">
  <mat-card-header fxLayout="row" fxLayoutAlign="start center">
    <div mat-card-avatar class="avatar-image" *ngIf="currentUser?.avatarLink; else noAvatar"></div>
    <ng-template #noAvatar>
      <mtn-user-initials-avatar [user]="currentUser"></mtn-user-initials-avatar>
    </ng-template>
    <mat-card-title class="no-bottom-margin">{{currentUser?.getDisplayName()}}</mat-card-title>
    <fa-icon icon="caret-down"></fa-icon>
  </mat-card-header>
</mat-card>
<mat-menu #userMenu="matMenu">
  <button mat-menu-item [routerLink]="'/profile'">
    <mat-icon>
      <fa-icon [icon]="['far', 'user-circle']"></fa-icon>
    </mat-icon>
    <span>My Profile & Settings</span>
  </button>
  <a href="mailto:support@retailstat.com?subject=Insights%20Support" target="_blank" mat-menu-item
     >
    <mat-icon>
      <fa-icon [icon]="['far', 'envelope']"></fa-icon>
    </mat-icon>
    <span>Contact Support</span>
  </a>
  <button mat-menu-item>
    <mat-icon>
      <fa-icon icon="balance-scale"></fa-icon>
    </mat-icon>
    <span>Terms of Use</span>
  </button>
  <button mat-menu-item (click)="openAboutWizard()">
    <mat-icon>
      <fa-icon icon="info-circle"></fa-icon>
    </mat-icon>
    <span>About</span>
  </button>
  <button mat-menu-item (click)="logOut()" mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.LOG_OUT">
    <mat-icon>
      <fa-icon icon="sign-out-alt"></fa-icon>
    </mat-icon>
    <span>Sign Out</span>
  </button>
</mat-menu>

<div class="status-history-page-container container" fxLayout="column" fxLayoutGap="15px">
  <p>MTN frequently visits stores throughout the country, both physically and virtually, in order to keep our
    information as up-to-date as possible.</p>
  <div>
    <mtn-key-value key="Store Opening Date">
      <span *ngIf="store.actualOpenedDate; else notAvailable">{{store.actualOpenedDate | insightsDate}}</span>
    </mtn-key-value>
    <mtn-key-value key="Store Closed Date" *ngIf="store.actualClosedDate">
      <span>{{store.actualClosedDate | insightsDate}}</span>
    </mtn-key-value>
  </div>
  <mtn-simple-panel color="blue">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <fa-icon icon="info-circle" size="2x"></fa-icon>
      <span>As MTN visits stores, the status is only updated if it has changed since the last visit. Therefore the dates below are the first dates MTN observed the status, and do not necessarily reflect the actual date the store entered that status nor the frequency with which MTN has visited this store.</span>
    </div>
  </mtn-simple-panel>
  <hr/>
  <div class="table-container container condensed-table">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Observed Date</th>
        <td mat-cell *matCellDef="let status">
          <span>{{status.statusDate | insightsDate}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let status">
          <mtn-store-status-view [type]="status.getStatusSystemName()"></mtn-store-status-view>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSize]="5" [showFirstLastButtons]="true" [length]="dataSource.data?.length"
                   [hidePageSize]="true" [hidden]="dataSource.data?.length <= 5"></mat-paginator>
  </div>
</div>

<ng-template #notAvailable>
  <span class="color-muted">Not Available</span>
</ng-template>

<div class="user-profile-form" fxLayout="column">
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-text-field [form]="form" controlName="firstName" placeholder="First Name"></mtn-text-field>
    <mtn-text-field [form]="form" controlName="lastName" placeholder="Last Name"></mtn-text-field>
  </div>
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-text-field [form]="form" controlName="title" placeholder="Title"></mtn-text-field>
    <mtn-phone-number-field [form]="form" controlName="phoneNumber"></mtn-phone-number-field>
  </div>
</div>

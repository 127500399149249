import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../core/base-component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { AuthenticationService } from '../authentication.service';
import { selectUserState, UserState } from '../../user-state';
import { Subscription, timer } from 'rxjs';
import { MtnAccessToken } from '../mtn-access-token';

@Component({
  selector: 'mtn-token-monitoring',
  templateUrl: './token-monitoring.component.html',
  styleUrls: ['./token-monitoring.component.scss']
})
export class TokenMonitoringComponent extends BaseComponent implements OnInit {

  private timerSubscription: Subscription;
  private token: MtnAccessToken;

  constructor(private authenticationService: AuthenticationService,
              private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAuthState();
  }

  private resetTokenRenewalTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    const timerMs = this.token.getExpirationDate().getTime() - new Date().getTime();
    if (timerMs > 0) {
      this.timerSubscription = timer(timerMs)
        .subscribe(() => {
          this.authenticationService.renewMtnAccessToken(this.token.token).subscribe();
        });
    } else {
      this.authenticationService.renewMtnAccessToken(this.token.token).subscribe();
    }
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.store)
        .subscribe((state: UserState) => {
          if (state.mtnAuthToken && (!state.mtnAuthToken.isExpired() || state.mtnAuthToken.isRenewable())) {
            const newExpirationDate = state.mtnAuthToken.getExpirationDate();
            if (!this.token || this.token.getExpirationDate() !== newExpirationDate) {
              this.token = state.mtnAuthToken;
              this.resetTokenRenewalTimer();
            }
          }
        })
    );
  }

}

<div class="map-controls-legend-assembly-container container">
  <mtn-map-toolbar-button icon="question" color="blue" matTooltip="Map Legend" matTooltipPosition="right"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'map-legend-button'}"
                          (onClick)="toggleSlide()"></mtn-map-toolbar-button>
  <div class="legend-container" [ngClass]="{hidden: isHidden, hiding: isHiding, showing: isShowing}" *ngIf="!isHidden">
    <div class="panel-connector"></div>
    <fa-icon class="clickable close-button color-muted" icon="times" size="xs" (click)="closeSlide()"></fa-icon>
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <img alt="Historical Marker" class="marker-image"
             src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/c_scale,h_50/e_replace_color:5c7b8a:100:00ff00/e_outline:3,co_white/l_Map%20Markers:circle-solid,g_north,c_limit,h_26,y_9,e_replace_color:white:100:00ff00,fl_layer_apply/l_insights:preloader-icon,g_north,c_limit,h_16,y_14/v1571263789/Map%20Markers/marker.png"/>
        <strong>Historical Store</strong>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <img alt="Active Store" class="marker-image"
             src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/c_scale,h_50/e_replace_color:263238:100:00ff00/e_outline:3,co_ffb300/l_Map%20Markers:circle-solid,g_north,c_limit,h_26,y_9,e_replace_color:white:100:00ff00,fl_layer_apply/l_insights:preloader-icon,g_north,c_limit,h_16,y_14/v1571263789/Map%20Markers/marker.png"/>
        <strong>Active Store</strong>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <img alt="Future Store" class="marker-image"
             src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/c_scale,h_50/e_replace_color:005a87:100:00ff00/e_outline:3,co_4dc3ff/l_Map%20Markers:circle-solid,g_north,c_limit,h_26,y_9,e_replace_color:white:100:00ff00,fl_layer_apply/l_insights:preloader-icon,g_north,c_limit,h_16,y_14/v1571263789/Map%20Markers/marker.png"/>
        <strong>Future Store</strong>
      </div>
    </div>
    <hr/>
    <mat-checkbox class="preference-control" [formControl]="preferenceControl" color="primary"><small>Show legend on map
      load</small>
    </mat-checkbox>
  </div>
</div>

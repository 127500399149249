import { Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { Space } from '../../../../../core/models';
import { WizardRunnerService } from '../../../../../core/wizard/wizard-runner.service';
import { SpaceDetailsWizard } from './space-details-wizard/space-details-wizard';
import { AppState } from '../../../../../app-state';
import { AuthorizationAwareComponent } from '../../../../../core/authorization-aware-component';
import { Store as NgrxStore } from '@ngrx/store';

@Component({
  selector: 'mtn-space-subpanel',
  templateUrl: './space-subpanel.component.html',
  styleUrls: ['./space-subpanel.component.scss']
})
export class SpaceSubpanelComponent extends AuthorizationAwareComponent {

  @Input()
  breakpoint: string;
  @Input()
  isExpanded = true;
  @Input()
  space: Space;

  Breakpoints = Breakpoints;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
  }

  openDetailsWizard(): void {
    const wizard = new SpaceDetailsWizard();
    wizard.model = {
      licenseType: this.licenseType,
      space: this.space
    };

    this.wizardRunner.run(wizard);
  }

}

<div class="banner-comparison-page-container container" fxLayout="column" fxLayoutGap="15px">
  <div class="form-container" fxLayout="row" fxLayoutGap="15px">
    <mtn-banner-comparison-mode-select [form]="form"></mtn-banner-comparison-mode-select>
    <div fxFlex></div>
  </div>
  <div class="table-container condensed-table container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="rank" matSortDirection="asc">
      <ng-container matColumnDef="rank">
        <th mat-header-cell *matHeaderCellDef>Rank</th>
        <td mat-cell *matCellDef="let ranking">{{ranking.rank}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Banner</th>
        <td mat-cell *matCellDef="let ranking">{{ranking.banner.name || 'Other'}}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th class="column-right" mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Value</th>
        <td class="column-right" mat-cell *matCellDef="let ranking">
          <span
            *ngIf="comparison.keyIndicator === KeyIndicatorType.STORE_COUNT; else percentageValue">{{ranking.value}}</span>
          <ng-template #percentageValue>
            <span>{{ranking.getValueAsPercentage()}}</span>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
          [ngClass]="{'subject-banner': (!subjectBanner && !row.banner.uuid) || row.banner.uuid === subjectBanner?.uuid}"
          *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [length]="dataSource.data?.length" [showFirstLastButtons]="true"
                   [hidePageSize]="true" [hidden]="dataSource.data?.length <= 10"></mat-paginator>
  </div>
  <mtn-spinner [size]="SpinnerSize.MEDIUM" [on]="isLoading"></mtn-spinner>
</div>

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FipsObject } from './fips-object';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FipsService extends InsightsRestService {

  constructor(private http: HttpClient) {
    super();
  }

  findAllCbsas(): Observable<FipsObject[]> {
    const url = this.buildUrl("/fips/cbsa");
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new FipsObject(result))));
  }

  findAllStates(): Observable<FipsObject[]> {
    const url = this.buildUrl("/fips/state");
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new FipsObject(result))));
  }

}

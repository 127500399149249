import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CoverageService } from './coverage.service';
import { FeatureCollection } from 'geojson';
import { Chart } from 'chart.js';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from '../../../core/base-component';

@Component({
  selector: 'mtn-coverage-map',
  templateUrl: './coverage-map.component.html',
  styleUrls: ['./coverage-map.component.scss']
})
export class CoverageMapComponent extends BaseComponent implements AfterViewInit {

  // Map
  @ViewChild('mapContainer')
  mapElementRef: ElementRef;
  map: google.maps.Map;

  // Chart
  chart: Chart;

  // Data
  coverageData: FeatureCollection;

  currentToolTip = 'Data collected personally by an MTN Analyst within the past two years.';
  historicalToolTip = 'Data collected personally by an MTN Analyst more than 2 years ago.';
  isInitialized = false;
  isInitializing = false;
  generatedToolTip = 'Data generated by MTN\'s proprietary machine learning model.';

  counts = [0, 0, 0];

  constructor(private coverageService: CoverageService) {
    super();
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.initPie();
  }

  initialize(): void {
    if (!this.isInitializing) {
      this.isInitializing = true;

      this.coverageService.findAll()
        .pipe(finalize(() => this.isInitializing = false))
        .subscribe(coveragePoints => {
          // Coverage Data = Points on the map
          this.coverageData = coveragePoints;
          this.map.data.addGeoJson(this.coverageData);

          // Pie shows percent in categories
          this.updatePieData();
          this.isInitialized = true;
        });
    }
  }

  private initPie() {
    this.chart = new Chart('myChart', {
      type: 'pie',
      data: {
        labels: ['Primary', 'Generated', 'Coming Soon'],
        datasets: [
          {
            label: 'Coverage',
            data: [100, 0, 0],
            backgroundColor: ['#6cca98', '#3d7cc9', '#863399']
          }
        ]
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
        layout: {
          padding: {
            right: 40
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return `${data.datasets[0].data[tooltipItem.index]}% (${this.counts[tooltipItem.index].toLocaleString()})`;
            }
          }
        }
      }
    });
  }

  private updatePieData() {
    const dataSet = this.chart.data.datasets[0];
    let current = 0;
    let historical = 0;
    let generated = 0;
    this.map.data.forEach(feature => {
      const type = feature.getProperty(`type`);
      if (type === 0) { current++; }
      else if (type === 1) { historical++; }
      else if (type === 2) { generated++; }
    });
    this.counts = [current, historical, generated];
    const total = current + historical + generated;
    current = Math.round(100 * (current / total));
    historical = Math.round(100 * (historical / total));
    generated = Math.round(100 * (generated / total));
    dataSet.data = [current, historical, generated];
    this.chart.update();
  }

  private initMap(): void {
    this.map = new google.maps.Map(this.mapElementRef.nativeElement, {
      center: {lat: 39.82827172489851, lng: -98.57949043082073},
      zoom: 4,
      mapId: '4bbe431ce0178faa',
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      scaleControl: true,
      zoomControl: false,
      gestureHandling: 'cooperative'
    });
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('filterButton'));
    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('pieChart'));

    this.setMapDataStyle();

  }

  private setMapDataStyle(): void {
    this.map.data.setStyle((feature) => {
      const type = feature.getProperty(`type`);
      let color = 'gray';
      let size = 2;

      let zIndex = 0;

      if (type === 0) {
        color = '6cca98';
        zIndex = 10;
      } else if (type === 1) {
        color = '3d7cc9';
        zIndex = 9;
        size = 2;
      } else if (type === 2) {
        color = '863399';
        zIndex = 5;
        size = 2;
      } else if (type === 3) {
        color = '808080';
        size = 2;
        zIndex = 1;
      }

      // @ts-ignore
      return {
        icon: {
          url: `https://res.cloudinary.com/mtn-retail-advisors/image/upload/c_scale,e_replace_color:${color},h_100/v1571676698/Map%20Markers/circle-solid.png`,
          scaledSize: new google.maps.Size(size, size)
        },
        zIndex: zIndex
      };
    });
  }

}

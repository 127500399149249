import { Component } from '@angular/core';
import { CreateOrEditCollectionWizard } from '../create-or-edit-collection-wizard';
import { WizardPageComponent } from '../../../core/wizard/wizard-page-component';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../core/toast/toast.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CollectionService } from '../../../core/federation/collection/collection.service';
import { Collection } from '../../../core/federation/collection/collection';
import { FeatureType } from '../../../core/federation/feature/feature-type.enum';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-create-or-edit-collection-page',
  templateUrl: './create-or-edit-collection-page.component.html',
  styleUrls: ['./create-or-edit-collection-page.component.scss']
})
export class CreateOrEditCollectionPageComponent extends WizardPageComponent<CreateOrEditCollectionWizard> {

  key = 'create-or-edit-collection';

  constructor(private collectionService: CollectionService,
              private toaster: ToastService) {
    super();
    this.title = 'Create Collection';
    this.nextButtonText = 'Submit';
    this.closeButtonText = 'Cancel';
  }

  onLoad(): Observable<any> {
    if (this.wizard.model.collection?.uuid) {
      this.title = 'Edit Collection';
    }
    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    if (this.wizard.model.collection?.uuid) {
      return this.updateCollection();
    } else {
      return this.createCollection();
    }
  }

  private createCollection(): Observable<string> {
    const formValue = this.form.getRawValue();

    const request = new Collection();
    request.featureType = FeatureType.STORE; //TODO this will have to be enhanced when shopping centers are supported
    request.name = formValue.name;
    request.description = formValue.description;

    if (this.wizard.model.storeUuids?.length) {
      request.includedFeatures = this.wizard.model.storeUuids;
    }

    return this.collectionService.addOne(request)
      .pipe(
        catchError((error: any) => {
          this.toaster.error('Failed to create collection');
          throw error;
        }),
        switchMap((result: Collection) => {
          this.wizard.model.result = result;
          this.toaster.info('Successfully created collection');

          if (formValue.favorite) {
            return this.collectionService.addOneFavorite(result.uuid)
              .pipe(map(() => null));
          } else {
            return of(null);
          }
        })
      );
  }

  private initForm(): void {
    const isFavorite = this.wizard.model.collection ? this.wizard.model.collection.isFavoritedBy(this.wizard.model.userProfileUuid) : false;

    this.form = new UntypedFormGroup({
      description: new UntypedFormControl(this.wizard.model.collection?.description, [Validators.maxLength(255)]),
      name: new UntypedFormControl(this.wizard.model.collection?.name, [Validators.maxLength(64), Validators.required]),
      favorite: new UntypedFormControl(isFavorite)
    });
  }

  private updateCollection(): Observable<string> {
    const formValue = this.form.getRawValue();

    const request = _.cloneDeep(this.wizard.model.collection);
    request.name = formValue.name;
    request.description = formValue.description;

    return this.collectionService.updateOne(request)
      .pipe(
        catchError((error: any) => {
          this.toaster.error('Failed to update collection');
          throw error;
        }),
        map((result: Collection) => {
          this.wizard.model.result = result;
          this.toaster.info('Successfully updated collection');
          return null;
        })
      );
  }
}

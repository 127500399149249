import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyInvitation } from './company';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyInvitationService extends InsightsRestService {

  protected path = '/invitation';

  constructor(private http: HttpClient) {
    super();
  }

  expireOne(uuid: string): Observable<any> {
    const url = this.buildUrl(`${this.path}/${uuid}`);
    return this.http.delete(url);
  }

  findOne(uuid: string): Observable<CompanyInvitation> {
    const url = this.buildUrl(`${this.path}/${uuid}`);
    return this.http.get(url)
      .pipe(map((raw: any) => new CompanyInvitation(raw)));
  }

  updateOne(request: CompanyInvitation): Observable<CompanyInvitation> {
    const url = this.buildUrl(`${this.path}/${request.uuid}`);
    return this.http.put(url, request)
      .pipe(map((raw: any) => new CompanyInvitation(raw)));
  }
}

import { round } from "../util/math-utils";

export class DemographicDataView {
  age65Plus: number;
  ageAverage: number;
  ageAverageForecast10Year: number;
  ageAverageForecast5Year: number;
  ageFemale0005: number;
  ageFemale0514: number;
  ageFemale1418: number;
  ageFemale1822: number;
  ageFemale2225: number;
  ageFemale2530: number;
  ageFemale3035: number;
  ageFemale3540: number;
  ageFemale4045: number;
  ageFemale4550: number;
  ageFemale5055: number;
  ageFemale5560: number;
  ageFemale6065: number;
  ageFemale6570: number;
  ageFemale7075: number;
  ageFemale7580: number;
  ageFemale8085: number;
  ageFemale85Plus: number;
  ageMale0005: number;
  ageMale0514: number;
  ageMale1418: number;
  ageMale1822: number;
  ageMale2225: number;
  ageMale2530: number;
  ageMale3035: number;
  ageMale3540: number;
  ageMale4045: number;
  ageMale4550: number;
  ageMale5055: number;
  ageMale5560: number;
  ageMale6065: number;
  ageMale6570: number;
  ageMale7075: number;
  ageMale7580: number;
  ageMale8085: number;
  ageMale85Plus: number;
  ageMedian: number;
  ageMedianForecast10Year: number;
  ageMedianForecast5Year: number;
  ancestryAmerican: number;
  ancestryBritish: number;
  ancestryCentralAmerican: number;
  ancestryChinese: number;
  ancestryCuban: number;
  ancestryDominican: number;
  ancestryDutch: number;
  ancestryFrench: number;
  ancestryGerman: number;
  ancestryHawaiian: number;
  ancestryItalian: number;
  ancestryJapanese: number;
  ancestryKorean: number;
  ancestryMexican: number;
  ancestryMiddleEastern: number;
  ancestryNativeAmerican: number;
  ancestryOther: number;
  ancestryOtherAsian: number;
  ancestryOtherEuropean: number;
  ancestryOtherHispanic: number;
  ancestryPolish: number;
  ancestryPuertoRican: number;
  ancestryScandinavian: number;
  ancestryScotchIrish: number;
  ancestrySouthAmerican: number;
  ancestrySouthCentralAsian: number;
  ancestrySouthEasternAsian: number;
  ancestryUnclassified: number;
  areaLand: number;
  areaWater: number;
  births: number;
  blueCollar: number;
  daytimePopulation: number;
  deaths: number;
  disposableIncomeAverage: number;
  disposableIncomeMedian: number;
  diversityIndicator: number;
  education912: number;
  educationCollegeAssociates: number;
  educationCollegeBachelors: number;
  educationCollegeDoctorate: number;
  educationCollegeMasters: number;
  educationCollegeOrDegree: number;
  educationCollegeProfessional: number;
  educationHighSchool: number;
  educationSomeCollege1990: number;
  educationUnder9: number;
  employed16Plus1990: number;
  ethnicDominanceIndicator: number;
  ethnicityAsian: number;
  ethnicityAsianForecast5Year: number;
  ethnicityBlack: number;
  ethnicityBlackForecast5Year: number;
  ethnicityHispanic: number;
  ethnicityHispanicForecast5Year: number;
  ethnicityOther: number;
  ethnicityOtherForecast5Year: number;
  ethnicityWhite: number;
  ethnicityWhiteForecast5Year: number;
  expectedValueIndicator: number;
  groupQuartersCollege: number;
  groupQuartersForecast10Year: number;
  groupQuartersForecast5Year: number;
  groupQuartersInstitutionalized: number;
  groupQuartersMilitary: number;
  groupQuartersNonInstitutionalized: number;
  growthStabilityIndicator: number;
  hhInc000010k: number;
  hhInc010015k: number;
  hhInc015020k: number;
  hhInc020025k: number;
  hhInc025030k: number;
  hhInc030035k: number;
  hhInc035040k: number;
  hhInc040045k: number;
  hhInc045050k: number;
  hhInc050055k: number;
  hhInc055060k: number;
  hhInc060065k: number;
  hhInc065070k: number;
  hhInc070075k: number;
  hhInc075080k: number;
  hhInc080085k: number;
  hhInc085090k: number;
  hhInc090095k: number;
  hhInc095100k: number;
  hhInc100125k: number;
  hhInc125150k: number;
  hhInc150175k: number;
  hhInc175200k: number;
  hhInc200250k: number;
  hhInc250500k: number;
  hhInc500kPlus: number;
  homeValueMedian: number;
  households1Person: number;
  households2Person: number;
  households3Person: number;
  households4Person: number;
  households5Person: number;
  households6Person: number;
  households7PersonPlus: number;
  householdsAverageIncome: number;
  householdsAverageIncomeForecast5Year: number;
  householdsEstimate: number;
  householdsEstimateQMinus1: number;
  householdsEstimateQMinus2: number;
  householdsEstimateQMinus3: number;
  householdsEstimateQMinus4: number;
  householdsEstimateQMinus5: number;
  householdsEstimateQMinus6: number;
  householdsEstimateQMinus7: number;
  householdsEstimateQMinus8: number;
  householdsForecast10Year: number;
  householdsForecast5Year: number;
  householdsHistoricalGrowth2Year: number;
  householdsInPoverty: number;
  householdsMedianIncome: number;
  householdsMedianIncomeForecast5Year: number;
  householdsPersonsPer: number;
  householdsPersonsPerForecast10Year: number;
  householdsPersonsPerForecast5Year: number;
  householdsPopulationInForecast10Year: number;
  householdsProjectedGrowth5Year: number;
  housingAverageValue2000: number;
  landscapeHouseholdPopulationInCategoryA: number;
  landscapeHouseholdPopulationInCategoryB: number;
  landscapeHouseholdPopulationInCategoryC: number;
  landscapeHouseholdPopulationInCategoryD: number;
  landscapeHouseholdPopulationInCategoryE: number;
  landscapeHouseholdPopulationInCategoryF: number;
  landscapeHouseholdPopulationInCategoryG: number;
  landscapeHouseholdPopulationInCategoryH: number;
  landscapeHouseholdPopulationInCategoryI: number;
  landscapeHouseholdPopulationInCategoryJ: number;
  landscapeHouseholdPopulationInCategoryK: number;
  landscapeHouseholdPopulationInCategoryL: number;
  landscapeHouseholdPopulationInCategoryM: number;
  landscapeHouseholdPopulationInCategoryN: number;
  landscapeHouseholdPopulationInCategoryO: number;
  languageChinese: number;
  languageEnglishOnly: number;
  languageKorean: number;
  languageOtherAsian: number;
  languageSpanish: number;
  languageTagalogOtherPacific: number;
  languageVietnamese: number;
  migration: number;
  mortgageRiskAverage: number;
  ownerOccupied: number;
  perCapitaAverageIncome: number;
  populationDensityPerKm: number;
  populationDensityPerMi: number;
  populationEstimate: number;
  populationEstimateQMinus1: number;
  populationEstimateQMinus2: number;
  populationEstimateQMinus3: number;
  populationEstimateQMinus4: number;
  populationEstimateQMinus5: number;
  populationEstimateQMinus6: number;
  populationEstimateQMinus7: number;
  populationEstimateQMinus8: number;
  populationForecast10Year: number;
  populationForecast5Year: number;
  populationForecast5YearHigh: number;
  populationForecast5YearLow: number;
  populationHistoricalGrowth2Year: number;
  populationProjectedGrowth5Year: number;
  populationSeasonalEstimate: number;
  populationSeasonalEstimateQMinus1: number;
  populationSeasonalEstimateQMinus2: number;
  populationSeasonalEstimateQMinus3: number;
  populationSeasonalEstimateQMinus4: number;
  populationSeasonalEstimateQMinus5: number;
  populationSeasonalEstimateQMinus6: number;
  populationSeasonalEstimateQMinus7: number;
  populationSeasonalEstimateQMinus8: number;
  populationTransientEstimate: number;
  populationTransientEstimateQMinus1: number;
  populationTransientEstimateQMinus2: number;
  populationTransientEstimateQMinus3: number;
  populationTransientEstimateQMinus4: number;
  populationTransientEstimateQMinus5: number;
  populationTransientEstimateQMinus6: number;
  populationTransientEstimateQMinus7: number;
  populationTransientEstimateQMinus8: number;
  sexFemale: number;
  sexFemaleForecast10Year: number;
  sexFemaleForecast5Year: number;
  sexMale: number;
  sexMaleForecast10Year: number;
  sexMaleForecast5Year: number;
  unemploymentRate: number;
  wealthAverage: number;
  wealthMedian: number;
  whiteCollar: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
    }
  }

  getFormattedPercent(value: number): number {
    return round(value * 100, 1);
  }

  getPercentOfPopulation(value: number): number {
    return (value / this.populationEstimate) * 100;
  }
}

import { FeatureRequest } from './feature-request';
import { SalesVolumeDisplayType } from '../../user-preferences/sales-volume-display-type.enum';
import { SalesSqftDisplayType } from '../../user-preferences/sales-sqft-display-type.enum';

export class StoreFeatureRequest extends FeatureRequest {

  salesSqftDisplayType: SalesSqftDisplayType;
  salesVolumeDisplayType: SalesVolumeDisplayType;

}

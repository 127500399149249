<div class="space-details-page-container container">
  <mtn-key-value key="Intersection">
    <mtn-intersection-view [location]="space.location"
                           *ngIf="space.hasIntersection(); else notAvailable"></mtn-intersection-view>
  </mtn-key-value>
  <mtn-key-value key="Address">
    <mtn-address-view [location]="space.location"></mtn-address-view>
  </mtn-key-value>
  <hr/>
  <div class="container">
    <mtn-key-value-with-placeholder key="Position in Center" [value]="space.positionInCenter"
                                    [whatIsThisText]="Dictionary.tooltipPositionInCenter"></mtn-key-value-with-placeholder>
    <br/>
    <mtn-restricted-license-overlay *ngIf="!isLicenseOfLevel(this.wizard.model.licenseType, LicenseType.STANDARD)"
                                    [bannerSize]="Size.EXTRA_SMALL"
                                    [buttonSize]="Size.EXTRA_SMALL"></mtn-restricted-license-overlay>
  </div>
</div>

<ng-template #notAvailable>
  <span class="color-muted">Not Available</span>
</ng-template>

import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastService } from '../../toast/toast.service';
import { Wizard } from '../wizard';
import { BaseComponent } from '../../base-component';
import * as _ from 'lodash';
import { WizardPageComponent } from '../wizard-page-component';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SpinnerSize } from '../../util/spinner/spinner-size.enum';
import { SpinnerComponent } from '../../util/spinner/spinner.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'mtn-wizard-container',
  templateUrl: './wizard-container.component.html',
  styleUrls: ['./wizard-container.component.scss']
})
export class WizardContainerComponent extends BaseComponent implements AfterViewInit {

  isInitialized = false;
  SpinnerSize = SpinnerSize;
  totalPageCount = 0;
  wizard: Wizard<any>;

  @ViewChild('actionSpinner')
  actionSpinner: SpinnerComponent;

  @ViewChild('backSpinner')
  backSpinner: SpinnerComponent;

  @ViewChild('closeSpinner')
  closeSpinner: SpinnerComponent;

  @ViewChild('nextSpinner')
  nextSpinner: SpinnerComponent;

  @ViewChild('page', {
    read: ViewContainerRef
  })
  viewContainerRef: ViewContainerRef;

  constructor(@Inject(MAT_DIALOG_DATA) private data: Wizard<any>,
              private dialogRef: MatDialogRef<any>,
              private factoryResolver: ComponentFactoryResolver,
              private toaster: ToastService) {
    super();
    this.wizard = this.data;
  }

  ngAfterViewInit(): void {
    //We can't actually use the ViewContainerRef yet, so setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      try {
        this.initPageReferences();
      } catch (e) {
        console.error(e);
        this.toaster.info('An unexpected error occurred while trying to start the Wizard.');
        this.dialogRef.close();
      }
    });
  }

  action(): void {
    if (this.wizard.currentPage.isActionButtonEnabled) {
      this.actionSpinner.start();
      this.addSubscription(
        this.wizard.currentPage.onAction()
          .pipe(
            catchError((err) => {
              console.error(err);
              this.toaster.error();
              return of('filter-sets');
            }),
            finalize(() => this.actionSpinner.stop()),
            tap((nextPageKey: string) => {
              this.wizard.updatePageCount();
              if (nextPageKey !== this.wizard.currentPage.key) {
                this.wizard.recordNavigation(this.wizard.currentPage.key, nextPageKey);
              }
            })
          )
          .subscribe((nextPageKey: string) => {
            this.displayPage(nextPageKey);
          })
      );
    }
  }

  back(): void {
    const previousPageKey = this.wizard.getPreviousPageKey();
    if (previousPageKey) {
      this.backSpinner.start();
      this.addSubscription(
        this.wizard.currentPage.onBack()
          .pipe(finalize(() => {
            this.backSpinner.stop();
            this.wizard.updatePageCount();
          }))
          .subscribe(() => {
            this.displayPage(previousPageKey);
          })
      );
    }
  }

  close(): void {
    this.closeSpinner.start();
    this.addSubscription(
      this.wizard.currentPage.onClose()
        .pipe(finalize(() => this.backSpinner.stop()))
        .subscribe(() => {
          this.dialogRef.close(this.wizard);
        })
    );
  }

  next(): void {
    if (this.wizard.currentPage.form.valid) {
      this.nextSpinner.start();
      this.addSubscription(
        this.wizard.currentPage.onNext()
          .pipe(
            catchError((err) => {
              const errorMessage = err?.error?.message || 'An unexpected error occurred';
              this.toaster.error(errorMessage);
              return of(this.wizard.currentPage.key);
            }),
            tap((nextPageKey: string) => {
              this.wizard.recordNavigation(this.wizard.currentPage.key, nextPageKey);
            }),
            finalize(() => {
              this.nextSpinner.stop();
              this.wizard.updatePageCount();
            })
          ).subscribe((nextPageKey: string) => {
          this.displayPage(nextPageKey);
        })
      );
    }
  }

  private displayPage(key: string): void {
    //Only load a page if we don't have a current page, or that current page is not the same as the provided key
    if (key) {
      if (!this.wizard.currentPage || this.wizard.currentPage.key !== key) {
        this.viewContainerRef.detach();
        const componentRef = this.wizard.pageReferenceMap[key];
        if (!componentRef) {
          throw `No componentRef found for key '${key}'`;
        }

        componentRef.instance.isVisited = true;

        let loadTask: Observable<any>;
        if (!componentRef.instance.isLoaded || componentRef.instance.reloadOnNavigation) {
          loadTask = componentRef.instance.onLoad()
            .pipe(tap(() => {
              /*
              After we load the page, we'll subscribe to form changes under the covers and set flags we can safely use
              instead of form.valid or form.invalid, which can cause ExpressionChangedAfterItHasBeenCheckedErrors.
               */
              if (componentRef.instance.formSubscription) {
                componentRef.instance.formSubscription.unsubscribe();
              }

              componentRef.instance.form.updateValueAndValidity();
            }));
        } else {
          loadTask = of(null);
        }

        this.addSubscription(
          loadTask
            .subscribe(() => {
              this.isInitialized = true;
              componentRef.instance.isLoaded = true;
              this.wizard.currentPage = componentRef.instance;
              this.viewContainerRef.insert(componentRef.hostView);
            }, (error: any) => {
              console.error(error);
              this.toaster.info('An unexpected error occurred while loading the next page.');
            })
        );
      }
    } else {
      this.dialogRef.close(this.wizard);
    }
  }

  private initPageReferences(): void {
    const pageKeys = _.keys(this.wizard.pageTypeMap);
    if (pageKeys.length) {
      this.totalPageCount = pageKeys.length;
    } else {
      throw 'No pages configured in the wizard\'s pageTypeMap. Did you forget to configure the wizard\'s pages?';
    }

    pageKeys.forEach((key: string) => {
      this.wizard.pageReferenceMap[key] = this.initPageReference(key);
    });

    if (!this.wizard.currentPage) {
      this.displayPage(this.wizard.startingPageKey);
    }

    this.wizard.updatePageCount();
  }

  private initPageReference(key: string): ComponentRef<WizardPageComponent<any>> {
    const componentType = this.wizard.pageTypeMap[key];

    let componentRef = this.factoryResolver.resolveComponentFactory(componentType).create(this.viewContainerRef.injector);
    componentRef.instance.wizard = this.wizard;
    componentRef.instance.close = () => this.close();

    return componentRef;
  }
}

<div class="demographic-details-page-container container" fxLayout="column" fxLayoutGap="15px">
  <form [formGroup]="form" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
    <mtn-region-field [form]="form" [isMarketAreaAvailable]="!!wizard.model.marketArea"></mtn-region-field>
    <mtn-drive-time-minutes-field [form]="form"
                                  *ngIf="form.get('region').value === RegionType.DRIVE_TIME"></mtn-drive-time-minutes-field>
    <mtn-ring-miles-field [form]="form" *ngIf="form.get('region').value === RegionType.RING"></mtn-ring-miles-field>
  </form>
  <div class="content-container container scrollable">
    <div fxLayoutAlign="center center" *ngIf="isLoading && isLargeArea">
      <mtn-simple-panel color="blue" style="max-width: 340px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <fa-icon icon="info-circle" size="2x"></fa-icon>
          <div>Larger areas contain a lot data, and so it may take us just a bit to process it all for you. Please
            wait...
          </div>
        </div>
      </mtn-simple-panel>
    </div>
    <div fxLayout="column" fxLayoutGap="30px" *ngIf="demographics">

      <!-- Education -->
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <h3 class="mat-h3 color-primary no-bottom-margin">Education</h3>
          <mtn-what-is-this [text]="EDUCATION_DESCRIPTION"></mtn-what-is-this>
        </div>
        <div>
          <mtn-key-value-with-placeholder key="Associates" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.educationCollegeAssociates) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Bachelors" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.educationCollegeBachelors) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Masters" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.educationCollegeMasters) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Professional" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.educationCollegeProfessional) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Doctorate" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.educationCollegeDoctorate) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <hr/>
          <mtn-key-value-with-placeholder key="Any College" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.educationCollegeOrDegree) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
        </div>
      </div>

      <!-- Ethnicity -->
      <div fxLayout="column" fxLayoutGap="15px">
        <h3 class="mat-h3 color-primary no-bottom-margin">Ethnicity</h3>
        <div>
          <mtn-key-value-with-placeholder key="Asian" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.ethnicityAsian) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Black" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.ethnicityBlack) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Hispanic" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.ethnicityHispanic) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Other" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.ethnicityOther) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="White" keyWidthPercent="70"
                                          [value]="demographics.getFormattedPercent(demographics.ethnicityWhite) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
        </div>
      </div>

      <!-- Income -->
      <div fxLayout="column" fxLayoutGap="15px">
        <h3 class="mat-h3 color-primary no-bottom-margin">Income</h3>
        <div>
          <mtn-key-value-with-placeholder key="Average Household" keyWidthPercent="70"
                                          [value]="formatAsCurrency(demographics.householdsAverageIncome, false)"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Median Household" keyWidthPercent="70"
                                          [value]="formatAsCurrency(demographics.householdsMedianIncome, false)"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Average Per-Capita" keyWidthPercent="70"
                                          [value]="formatAsCurrency(demographics.perCapitaAverageIncome, false)"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
        </div>
      </div>

      <!-- Language -->
      <div fxLayout="column" fxLayoutGap="15px">
        <h3 class="mat-h3 color-primary no-bottom-margin">Language</h3>
        <div>
          <mtn-key-value-with-placeholder key="Chinese" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.languageChinese).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="English-Only" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.languageEnglishOnly).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Korean" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.languageKorean).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Other Asian" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.languageOtherAsian).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Spanish" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.languageSpanish).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Pacific Island" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.languageTagalogOtherPacific).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Vietnamese" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.languageVietnamese).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
        </div>
      </div>

      <!-- People -->
      <div fxLayout="column" fxLayoutGap="15px">
        <h3 class="mat-h3 color-primary no-bottom-margin">People</h3>
        <div>
          <mtn-key-value-with-placeholder key="Average Age" keyWidthPercent="70"
                                          [value]="round(demographics.ageAverage, 1)"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Gender - Female" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.sexFemale).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Gender - Male" keyWidthPercent="70"
                                          [value]="demographics.getPercentOfPopulation(demographics.sexMale).toFixed(1) + '%'"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Household Size" keyWidthPercent="70"
                                          [value]="round(demographics.householdsPersonsPer, 1)"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Households" keyWidthPercent="70"
                                          [value]="demographics.householdsEstimate"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
        </div>
      </div>

      <!-- Population -->
      <div fxLayout="column" fxLayoutGap="15px">
        <h3 class="mat-h3 color-primary no-bottom-margin">Population</h3>
        <div>
          <mtn-key-value-with-placeholder key="Historical 2yr Growth" keyWidthPercent="70"
                                          [value]="pop2yrChange"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Current Population" keyWidthPercent="70"
                                          [value]="demographics.populationEstimate.toLocaleString()"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
          <mtn-key-value-with-placeholder key="Projected 5yr Growth" keyWidthPercent="70"
                                          [value]="pop5yrProjection"
                                          alignment="space-between"></mtn-key-value-with-placeholder>
        </div>
      </div>

    </div>
  </div>
  <mtn-spinner [size]="SpinnerSize.MEDIUM" [on]="isLoading"></mtn-spinner>
</div>

<div class="demographic-card-container container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
  <div class="card-container container default-padding" [ngStyle]="{'background-color': color}" fxLayout="column"
       fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="icon-container">
      <fa-icon class="primary-icon" size="2x" [icon]="icon"></fa-icon>
      <fa-icon class="secondary-icon" [icon]="secondaryIcon" *ngIf="secondaryIcon"></fa-icon>
    </div>
    <div class="label-container text-center" fxLayoutAlign="center center"><strong>{{label}}</strong></div>
  </div>
  <div class="value-container text-center" fxLayout="column" fxLayoutAlign="end center"><strong class="text-center"
                                                                                                [innerHTML]="value"></strong>
  </div>
</div>

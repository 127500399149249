<div class="add-user-page-container container" fxLayout="column">
  <div fxLayout="column" *ngIf="wizard.model.isSearchEnabled">
    <h3 class="mat-h3 color-primary no-bottom-margin">Search for an Existing User</h3>
    <mtn-user-autocomplete-field class="default-field-width" [form]="form" controlName="existingUser"
                                 [excludeUuids]="excludedUuids"></mtn-user-autocomplete-field>
    <mtn-simple-panel color="purple" *ngIf="isAssociatedToOtherCompany">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <fa-icon icon="exclamation-triangle" size="2x"></fa-icon>
        <span><strong>Warning!</strong> This user is currently associated to another company! Continuing will remove all associations to their current company.</span>
      </div>
    </mtn-simple-panel>
    <mtn-simple-panel color="purple" *ngIf="wizard.model.isDowngraded">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <fa-icon icon="exclamation-triangle" size="2x"></fa-icon>
        <span><strong>Warning!</strong> This user is currently an administrator of this company. Continuing will downgrade them to a member and revoke their administrative privileges.</span>
      </div>
    </mtn-simple-panel>
    <div style="margin-bottom:15px">or</div>
  </div>
  <h3 class="mat-h3 color-primary no-bottom-margin">Create a New User</h3>
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-text-field class="default-field-width" [form]="newUserForm" controlName="firstName"
                    placeholder="First Name"></mtn-text-field>
    <mtn-text-field class="default-field-width" [form]="newUserForm" controlName="lastName"
                    placeholder="Last Name"></mtn-text-field>
  </div>
  <div fxLayout="row" fxLayoutGap="15px" [formGroup]="newUserForm">
    <mtn-email-address-field class="default-field-width" [form]="newUserForm"
                             controlName="email"></mtn-email-address-field>

    <mat-form-field class="default-field-width" appearance="outline">
      <mat-select formControlName="type" placeholder="User Type">
        <mat-option [value]="CompanyInvitationType.ADMINISTRATOR">Administrator</mat-option>
        <mat-option [value]="CompanyInvitationType.MEMBER">Member</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from './analytics.service';
import { AnalyticsEventTriggerDirective } from './analytics-event-trigger.directive';


@NgModule({
  declarations: [AnalyticsEventTriggerDirective],
  imports: [
    CommonModule
  ],
  exports: [
    AnalyticsEventTriggerDirective
  ],
  providers: [
    AnalyticsService
  ]
})
export class MtnGoogleAnalyticsModule {
}

import { StoreComparison } from '../../../../core/store/ranking/store-comparison';
import { RegionType } from '../../../../core/location/region-type.enum';
import { ComparisonType } from '../../../../core/store/ranking/comparison-type.enum';
import { Rankable } from '../../../../core/store/ranking/rankable';

export function buildRankingDescriptor(ranking: Rankable): string {
  if (ranking.percentile > 80) {
    return 'Leading';
  } else if (ranking.percentile > 60 && ranking.percentile <= 80) {
    return 'Strong';
  } else if (ranking.percentile > 40 && ranking.percentile <= 60) {
    return 'Average';
  } else if (ranking.percentile > 20 && ranking.percentile <= 40) {
    return 'Weak';
  } else {
    return 'Lagging';
  }
}

export function buildStoreRankingGroupDescriptor(comparison: StoreComparison): string {
  if (comparison.comparisonType === ComparisonType.BANNER) {
    return 'Banner';
  } else if (comparison.comparisonType === ComparisonType.FIT_CATEGORY) {
    return 'FIT';
  } else if (comparison.region === RegionType.MARKET_AREA) {
    return 'Market';
  } else {
    return 'Local';
  }
}

export function buildStoreRankingTypeDescriptor(comparison: StoreComparison): string {
  if (comparison.comparisonType === ComparisonType.BANNER) {
    return 'Contributor';
  } else {
    return 'Competitor';
  }
}

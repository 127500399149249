import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Banner } from '../../../models';
import { BannerService } from '../../../banner/banner.service';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Pageable } from '../../../service/pageable';
import { LogoUtil } from '../../../util/logo-util';
import { HttpParams } from '@angular/common/http';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { BannerSearchRequest } from '../../../banner/banner-search-request';

@Component({
  selector: 'mtn-banner-autocomplete-field',
  templateUrl: './banner-autocomplete-field.component.html',
  styleUrls: ['./banner-autocomplete-field.component.scss']
})
export class BannerAutocompleteFieldComponent implements OnInit {

  @Output()
  onSelection = new EventEmitter<Banner>();

  @Input()
  form: UntypedFormGroup;
  @Input()
  controlName: string = 'banner';
  @Input()
  isRequired = false;
  @Input()
  noErrorPadding = false;
  @Input()
  placeholder: string = 'Banner';

  search$: Observable<Banner[]>;

  constructor(private bannerService: BannerService) {
  }

  ngOnInit(): void {
    this.search$ = this.form.get(this.controlName).valueChanges
        .pipe(startWith(''),
        debounceTime(300),
        switchMap((value: any) => {
          if (value && !(value instanceof Banner)) {
            return this.search(value);
          } else {
            return of(null);
          }
        }));
  }

  emitSelection(event: MatAutocompleteSelectedEvent): void {
    this.onSelection.emit(event.option?.value);
  }

  getDisplayText(banner: Banner): string {
    return banner && banner.name;
  }

  getLogoUrl(banner: Banner): string {
    return (banner && banner.logoCloudinaryFilename) ? LogoUtil.buildLogoUrl(banner.logoCloudinaryFilename) : null;
  }

  private search(q: string): Observable<Banner[]> {
    const params = new HttpParams()
      .set('page', '0')
      .set('size', '25');

    const request = new BannerSearchRequest();
    request.query = q;

    return this.bannerService.search(request, params)
      .pipe(map((page: Pageable<Banner>) => page.content));
  }

}

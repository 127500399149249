export class KeyListener {

  private _ctrl = false;

  constructor() {
    this.initListeners();
  }

  get ctrl(): boolean {
    return this._ctrl;
  }

  private handleKeyboardEvent(event: KeyboardEvent): void {
    this._ctrl = event.ctrlKey;
  }

  private initListeners(): void {
    document.addEventListener("keydown", (event: KeyboardEvent) => this.handleKeyboardEvent(event));
    document.addEventListener("keyup", (event: KeyboardEvent) => this.handleKeyboardEvent(event));
  }
}

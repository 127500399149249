import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppState } from '../../app-state';
import { Store } from '@ngrx/store';
import { selectUserState, UserState } from '../user-state';
import { switchMap, take } from 'rxjs/operators';
import { HttpHeader } from '../../core/service/http-header.enum';

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isInterceptorRegisteredForPath(request.url)) {
      return selectUserState(this.store)
        .pipe(take(1))
        .pipe(switchMap((state: UserState) => {
          let config: any = {
            setHeaders: {}
          };

          if (state && state.mtnAuthToken && !request.headers.has(HttpHeader.AUTHORIZATION)) {
            config.setHeaders[HttpHeader.AUTHORIZATION] = `Bearer ${state.mtnAuthToken.token}`;

            return next.handle(request.clone(config));
          } else {
            return next.handle(request);
          }
        }));
    } else {
      return next.handle(request);
    }
  }

  private isInterceptorRegisteredForPath(url: string): boolean {
    return url.includes('/api') && !url.includes('/api/insights/auth/authenticate');
  }
}

<div class="critical-insights-card-container container default-padding clickable mat-elevation-z1" matRipple>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div class="icon-container container" fxLayoutAlign="center center">
      <fa-icon class="primary-icon" [icon]="icon" [ngStyle]="{color: iconColor}"></fa-icon>
      <fa-icon class="color-muted secondary-icon" [icon]="secondaryIcon" *ngIf="secondaryIcon"></fa-icon>
    </div>
    <div class="info-container container" fxFlex fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <strong>{{title}}</strong>
        <mtn-sales-volume-tag *ngIf="volume" [volume]="volume"></mtn-sales-volume-tag>
      </div>
      <small class="color-muted">{{description}}</small>
    </div>
  </div>
  <mtn-restricted-license-overlay *ngIf="isRestrictedLicenseOverlayEnabled" [buttonSize]="Size.EXTRA_SMALL"
                                  [bannerSize]="Size.EXTRA_SMALL"></mtn-restricted-license-overlay>
</div>

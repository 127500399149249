import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../../../core/base-component';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { AdminState, selectAdminState } from '../../administration-state';

@Component({
  selector: 'mtn-administration-breadcrumbs',
  templateUrl: './administration-breadcrumbs.component.html',
  styleUrls: ['./administration-breadcrumbs.component.scss']
})
export class AdministrationBreadcrumbsComponent extends BaseComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = [];

  constructor(private ngrxStore: NgrxStore<AppState>,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToRouteChanges();
    this.subscribeToAdministrationState();
    this.parseUrl(this.router.url);
  }

  private buildBreadcrumbs(paths: string[]): void {
    const breadcrumbs: Breadcrumb[] = [];

    const rootPath = paths[0];
    const childPath = paths.length > 1 ? paths[1] : null;
    const uuidPath = paths.length === 3 ? paths[2] : null;

    breadcrumbs.push({
      displayValue: this.buildDisplayValueForPath(rootPath),
      path: rootPath,
      routerLink: childPath ? [`/${rootPath}`] : null
    });

    if (childPath) {
      breadcrumbs.push({
        displayValue: this.buildDisplayValueForPath(childPath),
        path: childPath,
        routerLink: uuidPath ? [`/${rootPath}`, childPath] : null
      });
    }

    if (uuidPath) {
      breadcrumbs.push({
        displayValue: this.buildDisplayValueForPath(uuidPath),
        path: uuidPath,
        routerLink: null
      });
    }

    this.breadcrumbs = breadcrumbs;
  }

  private buildDisplayValueForPath(path: string): string {
    switch (path) {
      case 'administration':
        return 'Administration';
      case 'company':
        return 'Companies';
      case 'user':
        return 'Users';
      default:
        return path;
    }
  }

  private parseUrl(url: string): void {
    const parts = url.split('/');
    const filteredParts = _.filter(parts, (part: string) => !!part);
    this.buildBreadcrumbs(filteredParts);
  }

  private subscribeToAdministrationState(): void {
    this.addSubscription(
      selectAdminState(this.ngrxStore)
        .subscribe((state: AdminState) => {
          if (this.breadcrumbs.length === 3 && state.namedSubject) {
            this.breadcrumbs[2].displayValue = state.namedSubject.name;
          }
        })
    );
  }

  private subscribeToRouteChanges(): void {
    this.addSubscription(
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.parseUrl(event.url);
        })
    );
  }

}

export interface Breadcrumb {
  path: string;
  displayValue: string;
  routerLink: string[];
}

import { Injectable } from '@angular/core';
import { CrudService } from '../../service/crud-service';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../../service/pageable';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Collection } from './collection';
import { CompanyCollection } from './company-collection';

@Injectable({
  providedIn: 'root'
})
export class CollectionService extends CrudService<Collection> {

  protected path = '/collection';

  private collectionCache = new Map<string, Collection>();

  constructor(protected http: HttpClient) {
    super(http);
  }

  addAllExcludedFeatures(collectionUuid: string, featureUuids: string[]): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/excluded-feature`);
    return this.http.post(url, featureUuids);
  }

  addAllIncludedFeatures(collectionUuid: string, featureUuids: string[]): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/included-feature`);
    return this.http.post(url, featureUuids);
  }

  addOneExcludedFeature(collectionUuid: string, featureUuid: string): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/excluded-feature/${featureUuid}`);
    return this.http.post(url, null);
  }

  addOneFavorite(collectionUuid: string): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/favorite`);
    return this.http.post(url, null);
  }

  addOneIncludedFeature(collectionUuid: string, featureUuid: string): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/included-feature/${featureUuid}`);
    return this.http.post(url, null);
  }

  cacheAll(collections: Collection[]): void {
    collections.forEach((collection: Collection) => {
      this.collectionCache.set(collection.uuid, collection);
    });
  }

  deleteAllExcludedFeatures(collectionUuid: string, featureUuids: string[]): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/excluded-feature`);
    return this.http.post(url, featureUuids);
  }

  deleteAllIncludedFeatures(collectionUuid: string, featureUuids: string[]): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/included-feature`);
    return this.http.post(url, featureUuids);
  }

  deleteOneShare(collectionUuid: string, companyCollectionUuid: string): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/company-collection/${companyCollectionUuid}`);
    return this.http.delete(url);
  }

  deleteOneExcludedFeature(collectionUuid: string, featureUuid: string): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/excluded-feature/${featureUuid}`);
    return this.http.delete(url);
  }

  deleteOneFavorite(collectionUuid: string): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/favorite`);
    return this.http.delete(url);
  }

  deleteOneIncludedFeature(collectionUuid: string, featureUuid: string): Observable<any> {
    const url = this.buildUrl(`/${collectionUuid}/included-feature/${featureUuid}`);
    return this.http.delete(url);
  }

  findOne(uuid: string): Observable<Collection> {
    if (this.collectionCache.has(uuid)) {
      return of(this.collectionCache.get(uuid));
    } else {
      return super.findOne(uuid)
        .pipe(tap((result: Collection) => this.collectionCache.set(result.uuid, result)));
    }
  }

  shareOne(uuid: string, request: CompanyCollection): Observable<CompanyCollection> {
    const url = this.buildUrl(`/${uuid}/share`);
    return this.http.post(url, request)
      .pipe(map((raw: any) => new CompanyCollection(raw)));
  }

  updateOneShare(collectionUuid: string, request: CompanyCollection): Observable<CompanyCollection> {
    const url = this.buildUrl(`/${collectionUuid}/share/${request.uuid}`);
    return this.http.put(url, request)
      .pipe(map((raw: any) => new CompanyCollection(raw)));
  }

  protected buildInstance(raw: any): Collection {
    return new Collection(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Collection> {
    return new Pageable<Collection>(raw, Collection);
  }

}

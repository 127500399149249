import { Subject } from 'rxjs';
import { MtnMapOptions } from './mtn-map-options';
import {
  MapBoundsChangeEvent,
  MapClickEvent,
  MapDrawEvent,
  MapEvent,
  MapFilterChangeEvent,
  MapOptionChangeEvent,
  MapSelectionEvent,
  MapSelectionModeChangeEvent,
  MapTypeChangeEvent
} from './map-events';
import { Layer, LayerType } from './layer';
import { MapSnapshot } from './map-snapshot';
import { KeyListener } from '../core/util/key-listener';
import { GeometryUtil } from '../core/util/geometry-util';
import { MapStyleService } from './style/map-style.service';
import * as _ from 'lodash';
import { MarkerUtil } from './marker/marker-util';
import { MarkerZIndexUtil } from './marker/marker-z-index-util';
import { MarkerIconUtil } from './marker/marker-icon-util';
import { MapSelectionMode } from './map-selection-mode.enum';
import { AbstractControl } from '@angular/forms';
import { milesToMeters } from '../core/util/math-utils';
import { FeatureType } from '../core/federation/feature/feature-type.enum';
import { Feature, FeatureCollection } from '../core/models';
import Polygon = google.maps.Polygon;
import MapMouseEvent = google.maps.MapMouseEvent;
import PlacesService = google.maps.places.PlacesService;
import Marker = google.maps.Marker;
import DrawingManager = google.maps.drawing.DrawingManager;
import PolygonOptions = google.maps.PolygonOptions;
import OverlayType = google.maps.drawing.OverlayType;
import Polyline = google.maps.Polyline;
import LatLng = google.maps.LatLng;
import Circle = google.maps.Circle;
import MapsEventListener = google.maps.MapsEventListener;
import LatLngLiteral = google.maps.LatLngLiteral;

export class MtnMap extends google.maps.Map {

  private _events$ = new Subject<MapEvent<any>>();

  private _drawingManager: DrawingManager;
  private _keyListener = new KeyListener();
  private _mousemoveEventListener: MapsEventListener;
  private _options: MtnMapOptions;
  private _placesService: PlacesService;
  private _ringCircle: Circle;
  private _selectionMode: MapSelectionMode;
  private _styleService: MapStyleService;

  private _featureHistory = new Map<string, LatLngLiteral>();
  private _layers: Layer[] = [];
  private _selectedFeatures = new Map<string, Feature>();
  private _selections = new Set<string>();

  constructor(element: HTMLElement, snapshot: MapSnapshot, styleService: MapStyleService) {
    super(element, snapshot.options);
    this._styleService = styleService;
    this.initMap(snapshot);
  }

  get bounds(): FeatureCollection {
    const bounds = this.getBounds()?.toJSON();
    if (bounds) {
      const polygon = new Polygon({
        paths: [
          {lat: bounds.north, lng: bounds.east},
          {lat: bounds.north, lng: bounds.west},
          {lat: bounds.south, lng: bounds.west},
          {lat: bounds.south, lng: bounds.east}
        ]
      });

      const feature = GeometryUtil.googlePolygonToGeoJsonFeature(polygon);

      const featureCollection = new FeatureCollection();
      featureCollection.features.push(feature);
      return featureCollection;
    } else {
      return null;
    }
  }

  get events(): Subject<MapEvent<any>> {
    return this._events$;
  }

  get featureHistory(): Map<string, LatLngLiteral> {
    return this._featureHistory;
  }

  get layers(): Layer[] {
    return this._layers;
  }

  get markers(): Marker[] {
    return (<Marker[]>this.findLayer(LayerType.MARKER)?.objects) || [];
  }

  get options(): MtnMapOptions {
    return this._options;
  }

  get selectionMode(): MapSelectionMode {
    return this._selectionMode;
  }

  get selectedFeatures(): Feature[] {
    return Array.from(this._selectedFeatures.values());
  }

  get selectedFeatureUuids(): string[] {
    return this.selectedFeatures.map((feature: Feature) => feature.id);
  }

  get selections(): Set<string> {
    return this._selections;
  }

  get snapshot(): MapSnapshot {
    return new MapSnapshot({
      bounds: this.getBounds()?.toJSON(),
      center: this.getCenter()?.toJSON(),
      options: _.cloneDeep(this.options),
      selections: this._selections,
      zoom: this.getZoom()
    });
  }

  clearSelections(): void {
    this._selections.clear();
    this._selectedFeatures.clear();
  }

  disableDrawing(): void {
    this._drawingManager.setDrawingMode(null);
  }

  drawMarkers(features: Feature[]): Marker[] {
    const relevantFeatures = MarkerUtil.getRelevantFeatures(features);
    //Keep a history of all features that we've generated markers for, so we can find selections after they leave the map
    relevantFeatures.forEach((feature: Feature) => {
      this._featureHistory.set(feature.id, feature.getGeometryAsLatLng());
    });

    const selectedFeatures = _.filter(relevantFeatures, (feature: Feature) => this._selections.has(feature.id));
    const remainingFeatures = _.reject(relevantFeatures, (feature: Feature) => this._selections.has(feature.id));

    const markers = MarkerUtil.buildMarkers(remainingFeatures, false, this._options);
    const selectedMarkers = MarkerUtil.buildMarkers(selectedFeatures, true, this._options);

    this.findLayer(LayerType.MARKER).addAll(markers);
    this.findLayer(LayerType.MARKER).addAll(selectedMarkers);

    const allMarkers = [...markers, ...selectedMarkers];

    this._events$.next(new MapDrawEvent(allMarkers));

    return allMarkers;
  }

  enableDrawing(overlayType: OverlayType): void {
    this._drawingManager.setDrawingMode(overlayType);
  }

  /**
   * At the time of this writing, only markers can have ids set, so this only searches the marker layers.
   */
  findFeature(id: string): Feature {
    const features = this.findLayer(LayerType.MARKER).objects.map((marker: Marker) => marker.get('feature'));
    return _.find(features, (feature: Feature) => feature.id === id);
  }

  findFeatures(ids: string[]): Feature[] {
    const features = this.findLayer(LayerType.MARKER).objects.map((marker: Marker) => marker.get('feature'));
    return _.filter(features, (feature: Feature) => _.includes(ids, feature.id));
  }

  findLayer(type: LayerType): Layer {
    return _.find(this.layers, (layer: Layer) => layer.type === type);
  }

  /**
   * This doesn't return the full features, just a list of points that represent the selected features, so the map can
   * be recentered (and thus redrawn with full features).
   */
  findSelectedPoints(): LatLngLiteral[] {
    const points: LatLngLiteral[] = [];

    this._selections.forEach((selection: string) => {
      const point = this._featureHistory.get(selection);
      if (point) {
        points.push(point);
      }
    });

    return points;
  }

  isFeatureType(type: FeatureType): boolean {
    return this._options.featureType === type;
  }

  isSelected(id: string): boolean {
    return this._selections.has(id);
  }

  removeSelection(id: string, triggerEvent = false) {
    this._selections.delete(id);
    this._selectedFeatures.delete(id);

    if (triggerEvent) {
      this.updateMarkers();
      this._events$.next(new MapSelectionEvent(this._selections));
    }
  }

  select(id: string): void {
    const isCtrlSelection = !!this._keyListener.ctrl;

    if (!isCtrlSelection && this._selectionMode !== MapSelectionMode.MULTI_SELECT) {
      this.clearSelections();
    }

    const isExistingSelection = this._selections.has(id);
    this.toggleSelection(id, isCtrlSelection, isExistingSelection);

    //Set multi-select if ctrlSelection and we've selected now more than one
    if (isCtrlSelection && this._selections.size > 1 && !this._selectionMode) {
      this.setSelectionMode(MapSelectionMode.MULTI_SELECT);
    }

    this.updateMarkers();
    this._events$.next(new MapSelectionEvent(this._selections));
  }

  selectAll(ids: string[]): void {
    const isCtrlSelection = !!this._keyListener.ctrl;

    if (!isCtrlSelection) {
      this.clearSelections();
    }

    let isBatchExisting = this._selections.has(ids[0]);
    ids.forEach((id: string) => {
      this.toggleSelection(id, isCtrlSelection, isBatchExisting);
    });

    this.updateMarkers();
    this._events$.next(new MapSelectionEvent(this._selections));
  }

  /**
   * Applies, not sets, the given options to the existing map options
   * @param options
   */
  setOptions(options: MtnMapOptions) {
    /*
    Check for specific changes so we can emit appropriate events later
     */
    const isFiltersChanged = !!options.filterGroup || !!options.staticFeatures;
    const isMapTypeChanged = this._options.mapTypeId !== options.mapTypeId;

    /*
    Apply changes
     */

    //Translate our control configurations (that apply to the google maps controls) to the google options
    if (options.controlConfiguration?.isStreetViewEnabled !== undefined) {
      options.streetViewControl = options.controlConfiguration.isStreetViewEnabled;
    }
    if (options.controlConfiguration?.isTiltAndRotateEnabled !== undefined) {
      options.rotateControl = options.controlConfiguration.isTiltAndRotateEnabled;
    }

    Object.assign(this._options, options);
    this._options.styles = this._styleService.buildStyles(this._options);
    super.setOptions(this._options);

    /*
    Emit change events
     */
    this._events$.next(new MapOptionChangeEvent(this.options));

    if (isFiltersChanged) {
      this._events$.next(new MapFilterChangeEvent(this._options.filterGroup));
    }

    if (isMapTypeChanged) {
      this._events$.next(new MapTypeChangeEvent(this._options.mapTypeId));
    }
  }

  setSelectionMode(mode: MapSelectionMode, radiusMilesFormControl?: AbstractControl): void {
    this._selectionMode = mode;

    this.resetDrawingOptions();

    switch (mode) {
      case MapSelectionMode.LASSO:
        this.handleLassoSelection();
        break;
      case MapSelectionMode.MULTI_SELECT:
        //No special handling needed here, but we also don't want to call the default logic
        break;
      case MapSelectionMode.RING:
        this.handleRingSelection(radiusMilesFormControl);
        break;
      default:
        break;
    }

    this._events$.next(new MapSelectionModeChangeEvent(mode));
  }

  private addSelection(id: string): void {
    this._selections.add(id);

    const selectedFeature = this.findFeature(id);
    if (selectedFeature) {
      this._selectedFeatures.set(id, selectedFeature);
    }
  }

  clearMarkers(): void {
    this.findLayer(LayerType.MARKER).clear();
  }

  private disableMapDragAndScroll(): void {
    this.setOptions({
      draggable: false,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      clickableIcons: false
    });
  }

  private disableMarkerClick(): void {
    this.setOptions({
      draggableCursor: 'crosshair',
      clickableIcons: false
    });

    this.findLayer(LayerType.MARKER).objects.forEach((marker: Marker) => {
      marker.setClickable(false);
      marker.setCursor('crosshair');
    });
  }

  private enableMapDragAndScroll(): void {
    this.setOptions({
      draggable: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      clickableIcons: true
    });
  }

  private enableMarkerClick(): void {
    this.setOptions({
      draggableCursor: '',
      clickableIcons: true
    });

    this.findLayer(LayerType.MARKER).objects.forEach((marker: Marker) => {
      marker.setClickable(true);
      marker.setCursor('');
    });
  }

  private handleLassoSelection(): void {
    this.enableDrawing(OverlayType.POLYLINE);

    this.disableMapDragAndScroll();

    const polyline = new Polyline({
      map: this,
      clickable: false,
      strokeColor: 'limegreen'
    });

    this._mousemoveEventListener = google.maps.event.addListener(this, 'mousemove', (event: MapMouseEvent) => {
      polyline.getPath().push(event.latLng);
    });

    google.maps.event.addListenerOnce(this, 'mouseup', () => {
      this.enableMapDragAndScroll();
      this.disableDrawing();

      polyline.setMap(null);

      const polygon = new Polygon({paths: polyline.getPath()});

      const newSelections: string[] = [];

      this.findLayer(LayerType.MARKER).objects.forEach((marker: Marker) => {
        const feature = marker.get('feature');
        const latLng = new LatLng(feature.getGeometryAsLatLng());

        const containsLocation = google.maps.geometry.poly.containsLocation(latLng, polygon);
        if (containsLocation) {
          newSelections.push(marker.get('id'));
        }
      });

      this.selectAll(newSelections);
      this.setSelectionMode(null);
    });
  }

  private handleRingSelection(radiusMilesFormControl: AbstractControl): void {
    this.disableMarkerClick();

    //Draw a ring centered on the cursor until the user clicks
    this._mousemoveEventListener = google.maps.event.addListener(this, 'mousemove', (event: MapMouseEvent) => {
      const radiusMiles = radiusMilesFormControl.value;
      const radiusMeters = milesToMeters(radiusMiles);

      if (this._ringCircle) {
        this._ringCircle.setOptions({
          center: event.latLng,
          radius: radiusMeters,
        });
      } else {
        this._ringCircle = new Circle({
          center: event.latLng,
          radius: radiusMeters,
          map: this,
          clickable: false,
          strokeColor: 'limegreen',
          fillColor: 'limegreen',
          fillOpacity: 0.5
        });
      }
    });

    google.maps.event.addListenerOnce(this, 'mouseup', (event: MapMouseEvent) => {
      this.enableMarkerClick();

      const radiusMiles = radiusMilesFormControl.value;
      const radiusMeters = milesToMeters(radiusMiles);

      const circle = new Circle({
        center: event.latLng,
        radius: radiusMeters
      });

      const polygon = GeometryUtil.googleCircleToGooglePolygon(circle);

      const newSelections: string[] = [];

      this.findLayer(LayerType.MARKER).objects.forEach((marker: Marker) => {
        const feature = marker.get('feature');
        const latLng = new LatLng(feature.getGeometryAsLatLng());

        const containsLocation = google.maps.geometry.poly.containsLocation(latLng, polygon);
        if (containsLocation) {
          newSelections.push(marker.get('id'));
        }
      });

      this.selectAll(newSelections);
      setTimeout(() => this.setSelectionMode(null), 50);
    });
  }

  private initDrawingManager(): void {
    this._drawingManager = new DrawingManager({
      drawingControl: false,
      map: this
    });
  }

  private initEventListeners(): void {
    this.addListener('bounds_changed', () => {
      this._events$.next(new MapBoundsChangeEvent(this.bounds));
    });

    this.addListener('click', (event: MapMouseEvent) => {
      if (this._selectionMode === MapSelectionMode.MULTI_SELECT) {
        this.setSelectionMode(null);
      }
      this._events$.next(new MapClickEvent({
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }));
    });
  }

  private initLayers(): void {
    this.layers.push(new Layer(LayerType.THEMATIC, this));
    this.layers.push(new Layer(LayerType.MARKER, this));
  }

  private initMap(snapshot: MapSnapshot): void {
    this.validateKeyProvided(snapshot.options);
    this.initLayers();

    this._options = snapshot.options;
    this.setCenter(snapshot.center);
    this.setZoom(snapshot.zoom);
    this.getStreetView().setOptions({imageDateControl: true});
    this.setOptions({
      draggableCursor: ''
    });

    this.initEventListeners();
    this.initPlacesService();
    this.initDrawingManager();
    this.initSelections(snapshot);
  }

  private initPlacesService(): void {
    if (this._options.controlConfiguration.isGoogleSearchEnabled && !this._placesService) {
      this._placesService = new PlacesService(this);
    }
  }

  private initSelections(snapshot: MapSnapshot): void {
    if (snapshot.selections?.length) {
      this.selectAll(snapshot.selections);
    }
  }

  private resetDrawingOptions(): void {
    this.enableMarkerClick();
    this.enableMapDragAndScroll();
    this.disableDrawing();

    if (this._mousemoveEventListener) {
      google.maps.event.removeListener(this._mousemoveEventListener);
      this._mousemoveEventListener = null;
    }

    if (this._ringCircle) {
      this._ringCircle.setMap(null);
      this._ringCircle = null;
    }
  }

  // @ts-ignore
  private setPolygonDrawingOptions(options: PolygonOptions): void {
    this._drawingManager.setOptions({
      polygonOptions: options
    });
  }

  private toggleSelection(id: string, isCtrlSelection: boolean, isExisting: boolean): void {
    if (isCtrlSelection) {
      if (isExisting) {
        this.removeSelection(id);
      } else if (id) {
        this.addSelection(id);
      }
    } else if (id) {
      this.addSelection(id);
    }
  }

  private updateMarkers(): void {
    this.findLayer(LayerType.MARKER).objects.forEach((marker: Marker) => {
      const isSelected = this.isSelected(marker.get('id'));
      marker.setZIndex(MarkerZIndexUtil.getMarkerZIndex(marker.get('feature'), isSelected));
      marker.setIcon(MarkerIconUtil.buildIcon(marker.get('feature'), isSelected));
    });
  }

  private validateKeyProvided(options: MtnMapOptions): void {
    if (!options.key) {
      console.warn("Map initialized without a key! Generating a key for you, but fix me!");
      options.key = Date.now().toString();
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { StoreVolume } from '../volume/store-volume';
import { VolumeType } from '../volume/volume-type.enum';

@Component({
  selector: 'mtn-sales-volume-tag',
  templateUrl: './sales-volume-tag.component.html',
  styleUrls: ['./sales-volume-tag.component.scss']
})
export class SalesVolumeTagComponent implements OnInit {

  @Input()
  volume: StoreVolume;

  readonly VOLUME_TYPE = VolumeType

  readonly GENERATED_TOOLTIP = "A sales volume generated by MTN's proprietary Machine Learning model";
  readonly ESTIMATE_TOOLTIP = "A sales volume estimate from one of MTN's experienced Analysts, usually after visiting the store";

  constructor() {
  }

  ngOnInit(): void {
  }

}

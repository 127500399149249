<div class="sales-filter-assembly container">
  <mtn-map-toolbar-button icon="dollar-sign" [badgeCount]="toolbarState.badgeCountSales"
                          [color]="toolbarState.badgeCountSales ? 'green' : 'grey'"
                          [iconColor]="toolbarState.badgeCountSales ? 'green' : 'default'"
                          [matMenuTriggerFor]="salesMenu" [disabled]="!hasStandardLicense"
                          (menuOpened)="showSliders()" (menuClosed)="hideSliders()"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                          [analyticsEventParameters]="{'target': 'map-sales-filters-menu'}"
                          [matTooltip]="hasStandardLicense ? 'Sales Filters' : 'Sales Filters (Upgrade to unlock!)'"></mtn-map-toolbar-button>
</div>

<mat-menu class="wide-menu" #salesMenu="matMenu">
  <div class="sales-menu-container toolbar-menu container" mtnClickTrap [preventDefault]="false" fxLayout="column"
       fxLayoutGap="30px" style="width: 300px">
    <div class="filter-container" [formGroup]="salesVolumeForm">
      <div fxLayout="row">
        <div fxLayout="row" fxLayoutGap="5px">
          <strong>Sales Volume</strong>
          <span class="color-muted">({{salesVolumeSuffix}})</span>
        </div>
        <div fxFlex></div>
        <a href="javascript:void(0)" (click)="clearSalesVolume()"
           [ngClass]="{'color-accent': toolbarState?.filterSalesVolume, 'color-muted': !toolbarState?.filterSalesVolume}">Clear</a>
      </div>
      <hr/>
      <div class="container" style="height: 54px">
        <ngx-slider class="mtn-slider" formControlName="salesVolume" [options]="salesVolumeConfig"
                    *ngIf="isMenuReady; else sliderLoader"></ngx-slider>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-checkbox color="accent" formControlName="isExclusive">Exclusive</mat-checkbox>
        <mtn-what-is-this [text]="EXCLUSIVE_FILTER_DEFINITION"></mtn-what-is-this>
      </div>
    </div>
    <div class="filter-container" [formGroup]="salesSqftForm">
      <div fxLayout="row">
        <div fxLayout="row" fxLayoutGap="5px">
          <strong>Sales/Sqft</strong>
          <span class="color-muted">({{salesSqftSuffix}})</span>
        </div>
        <div fxFlex></div>
        <a href="javascript:void(0)" (click)="clearSalesSqft()"
           [ngClass]="{'color-accent': toolbarState?.filterSalesSqft, 'color-muted': !toolbarState?.filterSalesSqft}">Clear</a>
      </div>
      <hr/>
      <div class="container" style="height: 54px">
        <ngx-slider class="mtn-slider" formControlName="salesSqft" [options]="salesSqftConfig"
                    *ngIf="isMenuReady; else sliderLoader"></ngx-slider>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-checkbox color="accent" formControlName="isExclusive">Exclusive</mat-checkbox>
        <mtn-what-is-this [text]="EXCLUSIVE_FILTER_DEFINITION"></mtn-what-is-this>
      </div>
    </div>
    <div>
      <small><strong>Important!</strong> Use of these filters will restrict results to only those stores for which
        volume data has been
        purchased.</small>
    </div>
  </div>
</mat-menu>

<ng-template #sliderLoader>
  <mtn-spinner [size]="SpinnerSize.EXTRA_SMALL" [on]="true"></mtn-spinner>
</ng-template>

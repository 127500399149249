import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { EditCompanyWizard } from '../edit-company-wizard';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CompanyService } from '../../../../../core/company/company.service';
import { ToastService } from '../../../../../core/toast/toast.service';
import { Company } from '../../../../../core/company/company';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-edit-company-page',
  templateUrl: './edit-company-page.component.html',
  styleUrls: ['./edit-company-page.component.scss']
})
export class EditCompanyPageComponent extends WizardPageComponent<EditCompanyWizard> {

  key = 'edit-company';

  constructor(private companyService: CompanyService,
              private toaster: ToastService) {
    super();
  }

  onLoad(): Observable<any> {
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
    this.headerText = 'Edit Company';
    this.title = 'Edit Company';

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.wizard.model.company.name, [Validators.required, Validators.maxLength(64)]),
      description: new UntypedFormControl(this.wizard.model.company.description, [Validators.maxLength(255)])
    });

    return of(null);
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();

    return this.companyService.updateOne(request)
      .pipe(map((result: Company) => {
        this.toaster.info("Successfully updated Company");
        this.wizard.model.company = result;
        this.wizard.model.isEdited = true;
        return null;
      }));
  }

  private buildRequest(): Company {
    const request = _.cloneDeep(this.wizard.model.company);
    const formValue = this.form.getRawValue();

    request.name = formValue.name;
    request.description = formValue.description;

    return request;
  }

}

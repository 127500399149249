import { CompanyInvitation } from './company';
import { UserProfile } from '../user-profile/user-profile';

export class CompleteInvitation {

  invitation: CompanyInvitation;
  invitee: UserProfile;
  inviter: UserProfile;

  constructor(raw?: any) {
    if (raw) {
      if (raw.invitation) {
        this.invitation = new CompanyInvitation(raw.invitation);
      }
      if (raw.invitee) {
        this.invitee = new UserProfile(raw.invitee);
      }
      if (raw.inviter) {
        this.inviter = new UserProfile(raw.inviter);
      }
    }
  }
}

import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'mtn-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnChanges {

  @Input()
  count = 0;
  @Input()
  max = 5;
  @Input()
  size: number = 18;

  constructor() {
  }

  fullStars = 2;
  halfStars = 1;
  emptyStars = 2;

  tooltip: string;

  ngOnChanges(): void {
    this.fullStars = Math.floor(this.count);
    this.halfStars = Math.ceil(this.count - this.fullStars);
    this.emptyStars = this.max - (this.fullStars + this.halfStars);
    this.buildTooltip();
  }

  countArray(count: number) {
    return Array(count);
  }

  private buildTooltip(): void {
    if (this.count === 0) {
      this.tooltip = 'Not Rated';
    } else if (this.count <= 1) {
      this.tooltip = 'Poor';
    } else if (this.count <= 2) {
      this.tooltip = 'Fair';
    } else if (this.count <= 3) {
      this.tooltip = 'Average';
    } else if (this.count <= 4) {
      this.tooltip = 'Good';
    } else if (this.count <= 5) {
      this.tooltip = 'Excellent';
    }
  }
}

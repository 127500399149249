import { ComparisonType } from './comparison-type.enum';
import { KeyIndicatorType } from '../../user-preferences/key-indicator-type.enum';
import { SalesSqftDisplayType } from '../../user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from '../../user-preferences/sales-volume-display-type.enum';
import { FitType } from '../fit/fit-type.enum';
import { FeatureCollection } from '../../models';

export class StoreRankingRequest {
  bannerUuid: string;
  comparisonType: ComparisonType;
  fitCategory: FitType;
  geoJson: FeatureCollection;
  keyIndicator: KeyIndicatorType;
  salesSqftDisplayType: SalesSqftDisplayType;
  salesVolumeDisplayType: SalesVolumeDisplayType;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.comparisonType) {
        // @ts-ignore
        this.comparisonType = ComparisonType[<string>raw.comparisonType];
      }
      if (raw.fitCategory) {
        // @ts-ignore
        this.fitCategory = FitType[<string>raw.fitCategory];
      }
      if (raw.geoJson) {
        this.geoJson = new FeatureCollection(raw.geoJson);
      }
      if (raw.keyIndicator) {
        // @ts-ignore
        this.keyIndicator = KeyIndicatorType[<string>raw.keyIndicator];
      }
      if (raw.salesSqftDisplayType) {
        // @ts-ignore
        this.salesSqftDisplayType = SalesSqftDisplayType[<string>raw.salesSqftDisplayType];
      }
      if (raw.salesVolumeDisplayType) {
        // @ts-ignore
        this.salesVolumeDisplayType = SalesVolumeDisplayType[<string>raw.salesVolumeDisplayType];
      }
    }
  }
}

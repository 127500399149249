import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../../core/service/pageable';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InsightsRestService } from '../../core/service/insights-rest-service';

@Injectable({
  providedIn: 'root'
})
export class PingService extends InsightsRestService {

  path = '/ping';

  constructor(protected http: HttpClient) {
    super();
  }

  maintenancePing(): Observable<boolean> {
    const url = this.buildUrl('/maintenance');
    return this.http.get(url)
      .pipe(
        map(() => true),
        catchError(() => {
          return of(false);
        })
      );
  }

  ping(): Observable<any> {
    const url = this.buildUrl();
    return this.http.get(url);
  }

  protected buildInstance(): any {
    return null;
  }

  protected buildPageableInstance(): Pageable<any> {
    return null;
  }


}

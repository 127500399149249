<div class="company-api-key-container container default-padding mat-elevation-z1" fxLayout="column" fxLayoutGap="15px">
  <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">API Key</h2>
  <hr/>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <span>{{company.apiKey}}</span>
    <div fxFlex></div>
    <mtn-icon-button (onClick)="copyApiKeyToClipboard()" matTooltip="Copy to Clipboard">
      <fa-icon icon="copy"></fa-icon>
    </mtn-icon-button>
    <mtn-icon-button color="accent" *ngIf="hasRefreshPermission" (onClick)="refresh()" matTooltip="Refresh API Key">
      <fa-icon icon="sync"></fa-icon>
    </mtn-icon-button>
  </div>
</div>

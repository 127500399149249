import { Wizard } from '../../../../core/wizard/wizard';
import { AboutWizardPageComponent } from './about-wizard-page/about-wizard-page.component';

export class AboutWizard extends Wizard<any> {
  key = 'about';
  pageTypeMap = {
    'about': AboutWizardPageComponent
  };
  startingPageKey = 'about';

  constructor() {
    super();
    this.width = 520;
  }
}

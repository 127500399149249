import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../wizard/wizard-page-component';
import { CreateUserWizard } from '../create-user-wizard';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MtnValidators } from '../../../form-controls/mtn-validators';
import { UserProfile } from '../../../user-profile/user-profile';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { ToastService } from '../../../toast/toast.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-create-user-page',
  templateUrl: './create-user-page.component.html',
  styleUrls: ['./create-user-page.component.scss']
})
export class CreateUserPageComponent extends WizardPageComponent<CreateUserWizard> {

  key = 'create-user';

  constructor(private userService: UserProfileService,
              private toaster: ToastService) {
    super();
  }

  onLoad(): Observable<any> {
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
    this.title = 'Create User';
    this.headerText = 'Create User';

    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.email, Validators.required, Validators.maxLength(255)]),
      firstName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
      lastName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
      title: new UntypedFormControl(null, [Validators.maxLength(64)]),
      phoneNumber: new UntypedFormControl(null, [MtnValidators.lengthEquals(10)])
    });

    return of(null);
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();

    return this.userService.addOne(request)
      .pipe(map((result: UserProfile) => {
        this.toaster.info("Successfully created User");
        this.wizard.model.user = result;
        return null;
      }));
  }

  private buildRequest(): UserProfile {
    const request = new UserProfile();
    Object.assign(request, this.form.getRawValue());
    return request;
  }

}

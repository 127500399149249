import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../core/base-component';
import { formatNumericalShorthand } from '../../../../core/util/string-utils';
import { MtnMap } from '../../../../map/mtn-map';
import { Feature } from '../../../../core/models';

@Component({
  selector: 'mtn-store-feature-card',
  templateUrl: './store-feature-card.component.html',
  styleUrls: ['./store-feature-card.component.scss']
})
export class StoreFeatureCardComponent extends BaseComponent {

  @Input()
  feature: Feature;
  @Input()
  map: MtnMap;

  formatNumericalShorthand = formatNumericalShorthand;

  constructor() {
    super();
  }

  removeSelection(id: string): void {
    this.map.removeSelection(id, true);
  }
}

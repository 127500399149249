import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { BaseComponent } from '../core/base-component';
import { debounceTime, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { StorageService } from '../core/util/storage.service';
import { MapStyleService } from './style/map-style.service';
import { MtnMapOptions } from './mtn-map-options';
import { selectUserState, UserState } from '../auth/user-state';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../app-state';
import { buildDefaultMapSnapshot, MapSnapshot } from './map-snapshot';
import { FeatureService } from '../core/federation/feature/feature.service';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { MtnMap } from './mtn-map';
import { MapEvent, MapEventType } from './map-events';
import { MapSelectionMode } from './map-selection-mode.enum';
import { FilterGroup } from '../core/federation/filter/filter-group';
import { FeatureRequest } from '../core/federation/feature/feature-request';
import { GeoJsonFilter } from '../core/federation/filter/filter';
import { Pageable } from '../core/service/pageable';
import { FeatureType } from '../core/federation/feature/feature-type.enum';
import { StoreFeatureRequest } from '../core/federation/feature/store-feature-request';
import { Feature } from '../core/models';
import { FilterType } from '../core/federation/filter/filter-type.enum';
import Marker = google.maps.Marker;
import LatLngBounds = google.maps.LatLngBounds;

@Component({
  selector: 'mtn-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends BaseComponent implements AfterViewInit, OnDestroy {

  private readonly DEFAULT_CLUSTER_ZOOM = 9;

  @Input()
  options: MtnMapOptions;

  @Output()
  ready = new EventEmitter<MtnMap>(true);

  @ViewChild('mapContainer')
  mapElementRef: ElementRef;

  isFeatureToolbarShown = false;
  isLoadingFeatures = false;
  isReady = false;
  map: MtnMap;
  MapSelectionMode = MapSelectionMode;
  markerClusterer: MarkerClusterer;
  runningFeatureRequest: Subscription;
  showSelectionAdvice = false;

  private _featureRefresh$ = new Subject<any>();
  private snapshot: MapSnapshot;

  constructor(private featureService: FeatureService,
              private mapStyleService: MapStyleService,
              private storageService: StorageService,
              private ngrxStore: NgrxStore<AppState>,
              private zone: NgZone) {
    super();
  }

  ngAfterViewInit(): void {
    this.initMap()
      .subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    google.maps.event.clearListeners(this.map, 'bounds_changed');
    google.maps.event.clearListeners(this.map, 'click');
    google.maps.event.clearListeners(this.map, 'maptypeid_changed');
    google.maps.event.clearListeners(this.map, 'overlaycomplete');
    this.map = null;
  }

  focusOnMarkers(): void {
    const bounds = new LatLngBounds();

    this.markerClusterer.getMarkers().forEach((marker: Marker) => bounds.extend(marker.getPosition()));

    this.map.fitBounds(bounds);
  }

  private createMap(): Observable<MtnMap> {
    return this.loadSettingsFromAutoSave()
      .pipe(switchMap((snapshot: MapSnapshot) => this.applyDefaultOptions(snapshot)))
      .pipe(map(() => {
        return new MtnMap(this.mapElementRef.nativeElement, this.snapshot, this.mapStyleService);
      }));
  }

  private getSnapshotStorageKey(): string {
    return `map-snapshot-${this.options.key}`;
  }

  private initMap(): Observable<MtnMap> {
    return this.createMap()
      .pipe(map((result: MtnMap) => {
        this.map = result;
        this.autoSave();

        this.ready.emit(this.map);
        this.isReady = true;

        this.buildMarkerClusterer();
        this.subscribeToMapEvents();
        this.subscribeToFeatureRefreshEvents();

        return result;
      }));
  }

  private loadSettingsFromAutoSave(): Observable<MapSnapshot> {
    const storageKey = this.getSnapshotStorageKey();
    return this.storageService.get(storageKey)
      .pipe(map((snapshot: MapSnapshot) => {
        return snapshot ? new MapSnapshot(snapshot) : null;
      }));
  }

  private applyDefaultOptions(preloadedSnapshot: MapSnapshot): Observable<any> {
    //First, load preferences
    return selectUserState(this.ngrxStore)
      .pipe(filter((state: UserState) => !!state.userPreferences))
      .pipe(
        take(1),
        tap((state: UserState) => {
          const defaultMode = state.userPreferences?.mapDefaultMode;
          const defaultType = state.userPreferences?.mapDefaultType;

          //Build a default snapshot
          const snapshot = buildDefaultMapSnapshot(defaultMode, defaultType);

          //If we have a snapshot loaded, apply those to the default
          if (preloadedSnapshot) {
            Object.assign(snapshot, preloadedSnapshot);
          }

          //Finally, if we have options supplied, apply those to the default
          if (this.options) {
            Object.assign(snapshot.options, this.options);
          }

          //Re-apply mode and type settings to ensure consistent behavior
          if (this.options?.mode) {
            snapshot.options.mode = this.options.mode;
          } else {
            snapshot.options.mode = defaultMode;
          }
          if (this.options?.mapTypeId) {
            snapshot.options.mapTypeId = this.options.mapTypeId;
          } else {
            snapshot.options.mapTypeId = defaultType;
          }

          //Apply styles
          snapshot.options.styles = this.mapStyleService.buildStyles(snapshot.options);

          //Apply controls settings to proper map settings
          snapshot.options.streetViewControl = snapshot.options.controlConfiguration.isStreetViewEnabled;
          snapshot.options.rotateControl = snapshot.options.controlConfiguration.isTiltAndRotateEnabled;

          this.snapshot = snapshot;
          this.options = snapshot.options;
        })
      );
  }

  private autoSave(): void {
    if (this.map.options.isAutoSaveEnabled && this.isReady) {
      const storageKey = this.getSnapshotStorageKey();

      const snapshot = this.map.snapshot;
      snapshot.options.onMarkerClick = null;

      this.storageService.set(storageKey, snapshot).subscribe();
    }
  }

  private buildMarkerClusterer(): void {
    this.markerClusterer = new MarkerClusterer(this.map, [], {
      averageCenter: true,
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      maxZoom: this.DEFAULT_CLUSTER_ZOOM
    });
  }

  private buildMarkersForFeatures(features: Feature[]): void {
    if (features?.length) {
      const drawnMarkers = this.map.drawMarkers(features);
      this.markerClusterer.addMarkers(drawnMarkers);
    }
  }

  private clearMarkers(): void {
    this.map.clearMarkers();
    this.markerClusterer.clearMarkers();
  }

  private loadFeatures(): Observable<any> {
    if (this.map.options.staticFeatures?.length) {
      this.clearMarkers();
      this.zone.run(() => this.buildMarkersForFeatures(this.map.options.staticFeatures));
      return of([]);
    } else if (this.map.options.isFeatureMap && this.map.getZoom() >= 8) {
      this.isLoadingFeatures = true;

      this.clearMarkers();
      return this.loadFeaturesForFilterGroup(this.map.options.filterGroup)
        .pipe(
          finalize(() => this.isLoadingFeatures = false),
          tap((featureResponse: Pageable<Feature>) => {
            if (featureResponse?.content) {
              this.zone.run(() => this.buildMarkersForFeatures(featureResponse.content));
            }
          })
        );
    } else {
      this.markerClusterer.clearMarkers();
      return of([]);
    }
  }

  private loadFeaturesForFilterGroup(filterGroup: FilterGroup): Observable<Pageable<Feature>> {
    filterGroup.clearFilter(FilterType.GEOJSON);

    const geoJsonFilter = new GeoJsonFilter();
    geoJsonFilter.value = this.map.bounds.getFeature().geometry;
    filterGroup.filters.push(geoJsonFilter);

    let task: Observable<Pageable<Feature>>;
    if (this.map.isFeatureType(FeatureType.SHOPPING_CENTER)) {
      const request = new FeatureRequest();
      request.filterGroup = filterGroup;
      task = this.featureService.findAllShoppingCenters(request);
    } else if (this.map.isFeatureType(FeatureType.STORE)) {
      const request = new StoreFeatureRequest();
      request.filterGroup = filterGroup;
      task = this.featureService.findAllStores(request);
    } else {
      task = of(null);
    }

    return task;
  }

  private subscribeToMapEvents(): void {
    this.addSubscription(
      this.map.events
        .subscribe((event: MapEvent<any>) => {
          switch (event.type) {
            case MapEventType.BOUNDS_CHANGE:
              if (this.map.options.isAutoUpdateEnabled) {
                this._featureRefresh$.next();
              }
              this.autoSave();
              console.log('Map Zoom Level: ' + this.map.getZoom());
              break;
            case MapEventType.CLICK:
              if (this.map.selectionMode !== MapSelectionMode.RING) {
                this.map.select(null);
              }
              break;
            case MapEventType.FILTER_CHANGE:
              this._featureRefresh$.next();
              break;
            case MapEventType.OPTION_CHANGE:
              const options = event.payload;
              this.isFeatureToolbarShown = options?.controlConfiguration.isGoogleSearchEnabled
                || options?.controlConfiguration.isFiltersEnabled;
              this.autoSave();
              // this._featureRefresh$.next();
              break;
            case MapEventType.SELECTION_CHANGE:
              this.autoSave();
              break;
            case MapEventType.SELECTION_MODE_CHANGE:
              const selectionMode = event.payload;
              this.showSelectionAdvice = !!selectionMode;
              break;
            default:
              break;
          }
        })
    );
  }

  private subscribeToFeatureRefreshEvents(): void {
    this.addSubscription(
      this._featureRefresh$
        .pipe(debounceTime(500))
        .subscribe(() => {
          if (this.runningFeatureRequest) {
            this.runningFeatureRequest.unsubscribe();
          }

          this.runningFeatureRequest = this.loadFeatures()
            .pipe(finalize(() => {
              this.runningFeatureRequest = null;
            }))
            .subscribe();
        })
    );
  }

}

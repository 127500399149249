import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfile } from '../../user-profile/user-profile';
import { MtnValidators } from '../mtn-validators';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() {
  }

  buildUserProfileForm(userProfile?: UserProfile): UntypedFormGroup {
    return new UntypedFormGroup({
      firstName: new UntypedFormControl(userProfile?.firstName, [Validators.maxLength(64), Validators.required]),
      lastName: new UntypedFormControl(userProfile?.lastName, [Validators.maxLength(64), Validators.required]),
      title: new UntypedFormControl(userProfile?.title, [Validators.maxLength(64)]),
      phoneNumber: new UntypedFormControl(userProfile?.phoneNumber?.trim(), [MtnValidators.lengthEquals(10)])
    });
  }
}

<div class="restricted-license-overlay-container" fxLayoutAlign="center center"
     [ngClass]="{'no-border': !isBorderEnabled}" mtnClickTrap>
  <div class="upgrade-badge-container default-padding"
       [ngClass]="{medium: bannerSize === Size.MEDIUM, small: bannerSize === Size.SMALL, 'extra-small': bannerSize === Size.EXTRA_SMALL}"
       *ngIf="isSampleDataEnabled">
    <div fxLayoutAlign="center center" fxLayoutGap="15px">
      <h2 class="mat-h2 no-bottom-margin">{{bannerSize === Size.EXTRA_SMALL ? 'Sample' : 'Sample Data'}}</h2>
    </div>
  </div>
  <div class="upgrade-button-container">
    <button class="info-button"
            [ngClass]="{medium: buttonSize === Size.MEDIUM, small: buttonSize === Size.SMALL, 'extra-small': buttonSize === Size.EXTRA_SMALL}"
            mat-raised-button
            (click)="openUpgradeWizard()">
      <div class="color-primary" fxLayout="row" fxLayoutAlign="start center" [fxLayoutGap]="buttonGap">
        <fa-icon icon="unlock"></fa-icon>
        <div>Upgrade to Unlock</div>
      </div>
    </button>
  </div>
</div>

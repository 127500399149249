import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { AddUserToCompanyWizard } from '../add-user-to-company-wizard';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MtnValidators } from '../../../../../../core/form-controls/mtn-validators';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserProfile } from '../../../../../../core/user-profile/user-profile';
import { UserProfileService } from '../../../../../../core/user-profile/user-profile.service';
import { ToastService } from '../../../../../../core/toast/toast.service';
import { CompanyService } from '../../../../../../core/company/company.service';
import { CompanyInvitationType } from '../../../../../../core/company/company';

@Component({
  selector: 'mtn-add-user-to-company-page',
  templateUrl: './add-user-to-company-page.component.html',
  styleUrls: ['./add-user-to-company-page.component.scss']
})
export class AddUserToCompanyPageComponent extends WizardPageComponent<AddUserToCompanyWizard> {

  newUserForm = new UntypedFormGroup({
    firstName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    lastName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    email: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255), Validators.email]),
    type: new UntypedFormControl(CompanyInvitationType.MEMBER, [Validators.required])
  });
  form = new UntypedFormGroup({
    existingUser: new UntypedFormControl(null, [MtnValidators.requiresMatch, Validators.required]),
    newUserForm: this.newUserForm
  });

  CompanyInvitationType = CompanyInvitationType;
  excludedUuids: string[] = [];
  isAssociatedToOtherCompany = false;
  key = 'add-user-to-company';

  constructor(private companyService: CompanyService,
              private toaster: ToastService,
              private userProfileService: UserProfileService) {
    super();
    this.title = 'Add User';
    this.nextButtonText = 'Submit';
    this.closeButtonText = 'Cancel';
  }

  onLoad(): Observable<any> {
    //Exclude all existing admins/members, so they aren't being added twice
    this.excludedUuids = [...this.wizard.model.company.administrators, ...this.wizard.model.company.members];

    this.watchForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();

    let preliminaryTask: Observable<UserProfile>;

    if (formValue.existingUser) {
      preliminaryTask = of(formValue.existingUser);
    } else {
      const userRequest = new UserProfile();
      userRequest.email = formValue.newUserForm.email;
      userRequest.firstName = formValue.newUserForm.firstName;
      userRequest.lastName = formValue.newUserForm.lastName;

      preliminaryTask = this.userProfileService.addOne(userRequest)
        .pipe(tap(() => {
          this.toaster.info('Successfully created user. Have the user check their email for instructions on setting their password.', 4000);
        }));
    }

    return preliminaryTask
      .pipe(
        catchError((err) => {
          console.error(err);
          this.toaster.error('Failed to add user!');
          throw err;
        }),
        switchMap((user: UserProfile) => {
          let addTask: Observable<any>;
          if (formValue.newUserForm.type === CompanyInvitationType.ADMINISTRATOR) {
            addTask = this.companyService.addOneAdministrator(this.wizard.model.company.uuid, user.uuid);
          } else {
            addTask = this.companyService.addOneMember(this.wizard.model.company.uuid, user.uuid);
          }
          return addTask
            .pipe(map(() => {
              this.wizard.model.userProfile = user;
              return null;
            }));
        }),
      );
  }

  private watchForm(): void {
    this.addSubscription(
      this.form.valueChanges
        .subscribe((value: any) => {
          if (value.existingUser) {
            this.newUserForm.disable({emitEvent: false});
          } else if (this.newUserForm.valid) {
            this.form.get('existingUser').disable({emitEvent: false});
          } else {
            this.form.enable({emitEvent: false});
          }
        })
    );

    this.addSubscription(
      this.form.get('existingUser').valueChanges
        .subscribe((value: UserProfile) => {
          this.isAssociatedToOtherCompany = false;
          this.wizard.model.isDowngraded = false;
          this.wizard.model.isUpgraded = false;
          if (value && value instanceof UserProfile) {
            this.userProfileService.findOne(value.uuid)
              .subscribe((detailedView: UserProfile) => {
                this.form.get('existingUser').setValue(detailedView, {emitEvent: false});
                this.isAssociatedToOtherCompany = !!detailedView.company?.uuid && detailedView.company.uuid !== this.wizard.model.company.uuid;
              });
          }
        })
    );
  }

}

import { Component, Input } from '@angular/core';
import { ShoppingCenter } from '../../../../../core/models';
import { Breakpoints } from '@angular/cdk/layout';
import { ShoppingCenterDetailsWizard } from './shopping-center-details-wizard/shopping-center-details-wizard';
import { WizardRunnerService } from '../../../../../core/wizard/wizard-runner.service';
import { AuthorizationAwareComponent } from '../../../../../core/authorization-aware-component';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../../../app-state';

@Component({
  selector: 'mtn-shopping-center-subpanel',
  templateUrl: './shopping-center-subpanel.component.html',
  styleUrls: ['./shopping-center-subpanel.component.scss']
})
export class ShoppingCenterSubpanelComponent extends AuthorizationAwareComponent {

  @Input()
  breakpoint: string;
  @Input()
  isExpanded = true;
  @Input()
  shoppingCenter: ShoppingCenter;

  Breakpoints = Breakpoints;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
  }

  openDetailsWizard(): void {
    if (this.hasStandardLicense) {
      const wizard = new ShoppingCenterDetailsWizard();
      wizard.model = {
        shoppingCenter: this.shoppingCenter
      };

      this.wizardRunner.run(wizard);
    }
  }

}

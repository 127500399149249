import { Injectable } from '@angular/core';
import { CrudService } from '../../service/crud-service';
import { FormatType } from './format-type.enum';
import { HttpClient } from '@angular/common/http';
import { Definition } from '../../definition/definition';
import { Pageable } from '../../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class FormatService extends CrudService<Definition<FormatType>> {

  protected path = '/format';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): Definition<FormatType> {
    return new Definition<FormatType>(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Definition<FormatType>> {
    return new Pageable<Definition<FormatType>>(raw, Definition);
  }
}

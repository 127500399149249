<div class="request-more-store-insight-form-page-container container" [formGroup]="form">
  <p>We're here to help! Our expert analysts can provide a number of reports and services to suit your needs. Submit a
    request here, and one of our analysts will be in touch with you within the next business day.</p>
  <p>Which of these are you interested in?</p>
  <div class="checkboxes-container container">
    <div class="checkbox-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-checkbox color="primary" formControlName="groceryPotentialStudy">Grocery Potential Study</mat-checkbox>
      <mtn-what-is-this
        text="Find out whether this store is the best fit for the trade area, given the local demographic and grocery demand, or find a stronger grocery format that might effectively backfill this store."></mtn-what-is-this>
    </div>
    <div class="checkbox-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-checkbox color="primary" formControlName="assetReview">Asset Review</mat-checkbox>
      <mtn-what-is-this
        text="Assess the performance and long-term stability of the store and the greatest contributing factors to its current sales, including trade and market area sales, real estate characteristics, demographics, and competitive vulnerability."></mtn-what-is-this>
    </div>
    <div class="checkbox-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-checkbox color="primary" formControlName="salesForecastReport">Sales Forecast Report</mat-checkbox>
      <mtn-what-is-this
        text="View a sophisticated forecast of this store's sales, taking into account the current competitive landscape, future changes to that landscape, and even sister store impacts so that you can make major decisions about this store with confidence and peace of mind."></mtn-what-is-this>
    </div>
    <div class="checkbox-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-checkbox color="primary" formControlName="other">Other</mat-checkbox>
    </div>
  </div>
  <br/>
  <br/>
  <div>
    <div fxLayout="row" fxLayoutGap="15px">
      <mtn-email-address-field [form]="form" placeholder="Your Email Address"></mtn-email-address-field>
      <mtn-phone-number-field [form]="form" placeholder="Your Phone Number"></mtn-phone-number-field>
    </div>
    <mtn-text-area-field [form]="form" controlName="message" placeholder="What can we do for you?"
                         maxLength="500"></mtn-text-area-field>
  </div>
</div>

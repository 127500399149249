import { AuditableEntity } from '../auditable-entity';
import { License } from '../license/license';
import { Named } from '../util/named';
import { BasicEntity } from '../basic-entity';
import { DateUtil } from '../util/date-util';
import { AddOn } from './add-on';
import { AddOnType } from './add-on-type.enum';

/**
 * These models combined here in this file only in order to avoid circular dependencies...
 */
export class Company extends AuditableEntity implements Named {

  apiKey: string;
  apiUserProfileUuid: string;
  description: string;
  hubspotId: string;
  license: License;
  name: string;
  uniqueIdentifier: string;

  addOns: AddOn[] = [];
  administrators: string[] = [];
  banners: string[] = [];
  invitations: CompanyInvitation[] = [];
  members: string[] = [];

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);

      if (raw.license) {
        this.license = new License(raw.license);
      }
      if (raw.addOns) {
        this.addOns = raw.addOns.map((rawAddOn: any) => new AddOn(rawAddOn));
      }
      if (raw.invitations) {
        this.invitations = raw.invitations.map((rawInvitation: any) => new CompanyInvitation(rawInvitation));
      }
    }
  }

  hasAddOn(type: AddOnType): boolean {
    return !!this.addOns.find((addOn: AddOn) => addOn.type === type);
  }
}

export class CompanyInvitation extends BasicEntity {

  acceptedDate: Date;
  company: Company;
  emailAddress: string;
  expirationDate: Date;
  firstName: string;
  lastName: string;
  type: CompanyInvitationType;
  userProfileUuid: string;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);

      if (raw.acceptedDate) {
        this.acceptedDate = DateUtil.buildFromTimestamp(raw.acceptedDate);
      }
      if (raw.company) {
        this.company = new Company(raw.company);
      }
      if (raw.expirationDate) {
        this.expirationDate = DateUtil.buildFromTimestamp(raw.expirationDate);
      }
      if (raw.type) {
        // @ts-ignore
        this.type = CompanyInvitationType[<string>raw.type];
      }
    }
  }
}

export enum CompanyInvitationType {
  ADMINISTRATOR = 'ADMINISTRATOR',
  MEMBER = 'MEMBER'
}

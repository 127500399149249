import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mtn-drive-time-minutes-field',
  templateUrl: './drive-time-minutes-field.component.html',
  styleUrls: ['./drive-time-minutes-field.component.scss']
})
export class DriveTimeMinutesFieldComponent {

  @Input()
  form: UntypedFormGroup;
  @Input()
  controlName: string = 'driveTimeMinutes';
  @Input()
  placeholder: string = 'Minutes';

  constructor() {
  }

}

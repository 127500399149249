import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mtnClickTrap]'
})
export class ClickTrapDirective {

  @Input()
  preventDefault: boolean = true;

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    event.stopPropagation();
    if (this.preventDefault) {
      event.preventDefault();
    }
  }

}

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../../service/insights-rest-service';
import { Observable, of } from 'rxjs';
import { StoreComparison } from './store-comparison';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { DetailState, selectDetailState } from '../../../detail/detail-state';
import { map, switchMap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { RegionType } from '../../location/region-type.enum';
import { LocationService } from '../../location/location.service';
import { FeatureCollection, Store } from '../../models';
import { GeometryUtil } from '../../util/geometry-util';
import { StoreRankingService } from './store-ranking.service';
import { StoreRankingRequest } from './store-ranking-request';
import { ComparisonType } from './comparison-type.enum';
import { StoreRanking } from './store-ranking';
import { KeyIndicatorType } from '../../user-preferences/key-indicator-type.enum';
import { METERS_IN_A_MILE } from '../../util/math-utils';
import { UserPreferences } from '../../user-preferences/user-preferences';
import { StoreVolume } from '../volume/store-volume';

@Injectable({
  providedIn: 'root'
})
export class StoreComparisonService extends InsightsRestService {

  constructor(private locationService: LocationService,
              private ngrxStore: NgrxStore<AppState>,
              private storeRankingService: StoreRankingService) {
    super();
  }

  appendGeoJson(request: StoreComparison): Observable<any> {
    let task: Observable<FeatureCollection>;

    switch (request.region) {
      case RegionType.DRIVE_TIME:
        if (request.driveTimeMinutes === 7) {
          task = selectDetailState(this.ngrxStore)
            .pipe(take(1),
              map((state: DetailState) => {
                return state.defaultDriveTime;
              }));
        } else {
          task = this.locationService.findOnesIsochrone(request.store.space.location.uuid, request.driveTimeMinutes);
        }
        break;
      case RegionType.RING:
        const circle = new google.maps.Circle({
          center: request.store.space.location.getPointAsLatLng(),
          radius: request.ringMiles * METERS_IN_A_MILE
        });
        task = of(GeometryUtil.googleCircleToGeoJsonFeatureCollection(circle));
        break;
      case RegionType.MARKET_AREA:
      case RegionType.TRADE_AREA:
        //By the time we get to this point, we should have already loaded the trade and market areas into the state
        task = selectDetailState(this.ngrxStore)
          .pipe(take(1))
          .pipe(map((state: DetailState) => {
            if (request.region === RegionType.MARKET_AREA) {
              return state.marketArea;
            } else if (request.region === RegionType.TRADE_AREA) {
              return state.tradeArea;
            }
          }));
        break;
      default:
        throw new Error(`Unexpected region type: ${request.region}`);
    }

    return task
      .pipe(
        take(1),
        map((result: FeatureCollection) => {
          if (result) {
            request.geoJson = result;
            return request;
          } else {
            const error = 'Failed to load geoJson for StoreComparison!';
            console.error(error, request);
            throw error;
          }
        })
      );
  }

  buildDefaultComparisons(store: Store, volume: StoreVolume, marketArea: FeatureCollection, userPreferences: UserPreferences): StoreComparison[] {
    const comparisons: StoreComparison[] = [];

    let config: any = {
      store: store,
      volume: volume,
      comparisonType: ComparisonType.ALL,
      driveTimeMinutes: 7,
      region: RegionType.DRIVE_TIME,
      keyIndicator: KeyIndicatorType.SALES_VOLUME,
      salesSqftDisplayType: userPreferences.salesSqftDisplayMode,
      salesVolumeDisplayType: userPreferences.salesVolumeDisplayMode
    }

    comparisons.push(new StoreComparison(config));

    config.keyIndicator = KeyIndicatorType.SALES_SQFT;
    comparisons.push(new StoreComparison(config));

    config = {
      store: store,
      volume: volume,
      comparisonType: ComparisonType.BANNER,
      region: RegionType.MARKET_AREA,
      keyIndicator: KeyIndicatorType.SALES_VOLUME,
      geoJson: marketArea,
      salesSqftDisplayType: userPreferences.salesSqftDisplayMode,
      salesVolumeDisplayType: userPreferences.salesVolumeDisplayMode
    }

    comparisons.push(new StoreComparison(config));

    config.keyIndicator = KeyIndicatorType.SALES_SQFT;
    comparisons.push(new StoreComparison(config));

    config = {
      store: store,
      volume: volume,
      comparisonType: ComparisonType.ALL,
      region: RegionType.MARKET_AREA,
      keyIndicator: KeyIndicatorType.SALES_VOLUME,
      geoJson: marketArea,
      salesSqftDisplayType: userPreferences.salesSqftDisplayMode,
      salesVolumeDisplayType: userPreferences.salesVolumeDisplayMode
    }

    comparisons.push(new StoreComparison(config));

    config.keyIndicator = KeyIndicatorType.SALES_SQFT;
    comparisons.push(new StoreComparison(config));

    return comparisons;
  }

  /**
   * Searches the current state for a matching comparison, in order to reduce the volume of calls to the server
   * to get a given comparison, especially considering that these comparisons are often reused during a given session.
   * @param request
   */
  findOne(request: StoreComparison): Observable<StoreComparison> {
    return this.findOneFromState(request)
      .pipe(switchMap((cachedComparison: StoreComparison) => {
        if (cachedComparison) {
          return of(cachedComparison);
        } else {
          return this.findOneFromServer(request)
        }
      }));
  }

  findOneFromServer(request: StoreComparison): Observable<StoreComparison> {
    const requestClone = new StoreComparison(request);
    return this.appendGeoJson(requestClone)
      .pipe(switchMap(() => this.loadAndAppendRanking(requestClone)))
      .pipe(map((result: StoreComparison) => {
        return result;
      }));
  }

  private findOneFromState(request: StoreComparison): Observable<StoreComparison> {
    return selectDetailState(this.ngrxStore)
      .pipe(take(1))
      .pipe(map((state: DetailState) => {
        let match: StoreComparison = null;

        const comparisons = [...state.defaultComparisons, ...state.customComparisons];
        if (comparisons?.length) {
          match = _.find(comparisons, (comparison: StoreComparison) => {
            return comparison.equals(request, true);
          });
        }

        return match;
      }));
  }

  private loadAndAppendRanking(request: StoreComparison): Observable<StoreComparison> {
    if (this.validateRankingPossible(request)) {
      const rankingRequest = new StoreRankingRequest();
      rankingRequest.geoJson = request.geoJson;
      rankingRequest.comparisonType = request.comparisonType;
      rankingRequest.keyIndicator = request.keyIndicator;
      rankingRequest.salesSqftDisplayType = request.salesSqftDisplayType;
      rankingRequest.salesVolumeDisplayType = request.salesVolumeDisplayType;

      if (request.comparisonType === ComparisonType.BANNER) {
        rankingRequest.bannerUuid = request.store.banner.uuid;
      } else if (request.comparisonType === ComparisonType.FIT_CATEGORY) {
        rankingRequest.fitCategory = request.store.fit;
      }

      return this.storeRankingService.findAllRankings(request.store.uuid, rankingRequest)
        .pipe(map((results: StoreRanking[]) => {
          request.rankings = results;
          return request;
        }));
    } else {
      return of(request)
        .pipe(take(1));
    }
  }

  private validateRankingPossible(request: StoreComparison): boolean {
    request.message = null;

    if (request.region === RegionType.MARKET_AREA && !request.geoJson?.getFeature()) {
      request.message = 'Store is outside standard Market Areas';
    } else if (!(request.store.grocerySalesArea || request.store.totalArea)) {
      request.message = 'Store is missing required data';
    } else if (request.comparisonType === ComparisonType.BANNER && !request.store.banner) {
      request.message = 'Store does not belong to a Banner';
    } else if (request.comparisonType === ComparisonType.FIT_CATEGORY && !request.store.fit) {
      request.message = 'Store does not have a FIT Category';
    }

    return !request.message;
  }
}

<div class="store-detail-page-container container" fxFlexFill fxLayout="column" fxLayoutAlign="start center"
     fxLayoutGap="30px">
  <img alt="Header"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/v1668200992/sample-store/01_header.png"/>
  <img alt="Need More Insights"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/v1668201382/sample-store/02_Need_more_insight.png"/>
  <img alt="Critical Insights"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/l_text:arial_180:SAMPLE%20DATA,o_5,co_rgb:ffffff/v1668201382/sample-store/03_Critical_Insights.png"/>
  <img alt="Sales Performance Insights"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/l_text:arial_180:SAMPLE%20DATA,o_5,co_rgb:ffffff/v1668201382/sample-store/04_Sales_Performance_Insights.png"/>
  <img alt="Header"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/l_text:arial_150:SAMPLE%20DATA,o_5,co_rgb:ffffff/v1668201383/sample-store/05_Additional_Insights.png"/>
  <img alt="Header"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/l_text:arial_180:SAMPLE%20DATA,o_5,co_rgb:ffffff/v1668201383/sample-store/06_Demographic_Profile.png"/>
  <img alt="Header"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/l_text:arial_180:SAMPLE%20DATA,o_5,co_rgb:ffffff/v1668201382/sample-store/07_Store_Competitive_Analysis.png"/>
  <img alt="Header"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/l_text:arial_150:SAMPLE%20DATA,o_5,co_rgb:ffffff/v1668201382/sample-store/08_Banner_Analysis.png"/>
  <img alt="Header"
       src="https://res.cloudinary.com/mtn-retail-advisors/image/upload/v1668201382/sample-store/09_Footer.png"/>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mtn-key-value',
  templateUrl: './key-value.component.html',
  styleUrls: ['./key-value.component.scss']
})
export class KeyValueComponent implements OnInit {

  @Input()
  key: string;
  @Input()
  keyWidthPercent: number = 40;
  @Input()
  alignment: string = 'start start';
  @Input()
  whatIsThisText: string;

  constructor() {
  }

  ngOnInit() {
  }

}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { StoreAnalystRatingWizard } from '../store-analyst-rating-wizard';
import { AnalystRatingResponse } from '../../../../../../core/store/rating/analyst-rating-response';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { SimpleRating } from '../../../../../../core/store/rating/simple-rating';
import { SimpleRatingType } from '../../../../../../core/store/rating/simple-rating-type.enum';

@Component({
  selector: 'mtn-store-analyst-rating-page',
  templateUrl: './store-analyst-rating-page.component.html',
  styleUrls: ['./store-analyst-rating-page.component.scss'],
  providers: [DatePipe]
})
export class StoreAnalystRatingPageComponent extends WizardPageComponent<StoreAnalystRatingWizard> {

  key = 'store-analyst-rating';

  averageRating: number;
  rating: AnalystRatingResponse;
  ratingCotenantExterior: SimpleRating;
  ratingCotenantSynergy: SimpleRating;
  ratingParkingCondition: SimpleRating;
  ratingParkingFlow: SimpleRating;
  ratingParkingIngressEgress: SimpleRating;
  ratingParkingLighting: SimpleRating;
  ratingStoreCeiling: SimpleRating;
  ratingStoreCheckstand: SimpleRating;
  ratingStoreExterior: SimpleRating;
  ratingStoreFloor: SimpleRating;
  ratingStoreRefrigeration: SimpleRating;
  ratingStoreShelving: SimpleRating;
  ratingStoreVisibility: SimpleRating;
  ratingStoreWall: SimpleRating;

  constructor() {
    super();
    this.title = 'MTN Analyst Rating';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.rating = this.wizard.model.rating;
    this.averageRating = this.rating.getAverageRating();
    this.ratingCotenantExterior = this.rating.getRatingOfType(SimpleRatingType.COTENANT_EXTERIOR_CONDITION);
    this.ratingCotenantSynergy = this.rating.getRatingOfType(SimpleRatingType.COTENANT_SYNERGY);
    this.ratingParkingCondition = this.rating.getRatingOfType(SimpleRatingType.PARKING_LOT_CONDITION);
    this.ratingParkingFlow = this.rating.getRatingOfType(SimpleRatingType.PARKING_LOT_FLOW);
    this.ratingParkingIngressEgress = this.rating.getRatingOfType(SimpleRatingType.PARKING_LOT_INGRESS_EGRESS);
    this.ratingParkingLighting = this.rating.getRatingOfType(SimpleRatingType.PARKING_LOT_LIGHTING);
    this.ratingStoreCeiling = this.rating.getRatingOfType(SimpleRatingType.STORE_CEILING_CONDITION);
    this.ratingStoreCheckstand = this.rating.getRatingOfType(SimpleRatingType.STORE_CHECKSTAND_CONDITION);
    this.ratingStoreExterior = this.rating.getRatingOfType(SimpleRatingType.STORE_EXTERIOR_CONDITION);
    this.ratingStoreFloor = this.rating.getRatingOfType(SimpleRatingType.STORE_FLOOR_CONDITION);
    this.ratingStoreRefrigeration = this.rating.getRatingOfType(SimpleRatingType.STORE_REFRIGERATION_CONDITION);
    this.ratingStoreShelving = this.rating.getRatingOfType(SimpleRatingType.STORE_SHELVING_CONDITION);
    this.ratingStoreVisibility = this.rating.getRatingOfType(SimpleRatingType.STORE_VISIBILITY);
    this.ratingStoreWall = this.rating.getRatingOfType(SimpleRatingType.STORE_WALL_CONDITION);

    return super.onLoad();
  }
}

<div class="full-partner-attribution-container container" fxLayout="column" fxLayoutAlign="start center"
     fxLayoutGap="5px">
  <hr/>
  <h2 class="mat-h2 color-accent text-small-caps">Thank You to our Partners!</h2>
  <hr/>
  <mtn-partner-attribution [partnerType]="PartnerType.CENSUS"></mtn-partner-attribution>
  <mtn-partner-attribution [partnerType]="PartnerType.DSR_MARKETING"></mtn-partner-attribution>
  <mtn-partner-attribution [partnerType]="PartnerType.TRAVEL_TIME"></mtn-partner-attribution>
  <mtn-partner-attribution [partnerType]="PartnerType.SYNERGOS"></mtn-partner-attribution>
</div>

import { BaseComponent } from '../core/base-component';
import { Component, HostListener, OnInit } from '@angular/core';

/**
 * This should be used by any component implementing the grid system for the purpose of creating
 * a dashboard. It sets a standard grid size and handles resizing the column count dynamically.
 */
@Component({
  selector: 'mtn-grid-enabled-dashboard',
  template: '<div></div>'
})
export abstract class GridEnabledDashboard extends BaseComponent implements OnInit {

  private readonly MIN_COLUMN_COUNT: number = 3;
  private readonly MAX_COLUMN_COUNT: number = 6;
  private readonly COLUMN_WIDTH: number = 350;

  columnCount: number = this.MAX_COLUMN_COUNT;

  ngOnInit(): void {
    this.calculateColumnCount(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.calculateColumnCount(event.target.innerWidth);
  }

  private calculateColumnCount(windowWidth: number): void {
    const rawColumnCount = windowWidth / this.COLUMN_WIDTH;
    let columnCount = Math.round(rawColumnCount);

    columnCount = Math.min(this.MAX_COLUMN_COUNT, columnCount);
    columnCount = Math.max(this.MIN_COLUMN_COUNT, columnCount);

    this.columnCount = columnCount;
  }
}

// @ts-ignore
type Constructor<T> = new (...args: any[]) => T;

export class Pageable<T> {

  content: T[];
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: string;
  first: boolean;
  numberOfElements: number;

  private type: new (raw: any) => T;

  constructor(raw: any, type?: new (raw: any) => T) {
    Object.assign(this, raw);
    this.content = [];
    this.type = type;

    if (raw.content) {
      raw.content.forEach((element: any) => this.content.push(this.buildContentInstance(element)));
    }
  }

  private buildContentInstance(raw: any): T {
    return new this.type(raw);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { UpgradeLicenseWizard } from '../upgrade-license-wizard/upgrade-license-wizard';
import { WizardRunnerService } from '../../wizard/wizard-runner.service';
import { BaseComponent } from '../../base-component';
import { Size } from '../../util/size.enum';

@Component({
  selector: 'mtn-restricted-license-overlay',
  templateUrl: './restricted-license-overlay.component.html',
  styleUrls: ['./restricted-license-overlay.component.scss']
})
export class RestrictedLicenseOverlayComponent extends BaseComponent implements OnInit {

  @Input()
  isBorderEnabled = true;
  @Input()
  isSampleDataEnabled = true;
  @Input()
  bannerSize = Size.LARGE;
  @Input()
  buttonSize = Size.LARGE;

  buttonGap = '30px';

  constructor(private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit() {
    this.setButtonGap();
  }

  openUpgradeWizard(): void {
    const wizard = new UpgradeLicenseWizard();

    this.wizardRunner.run(wizard);
  }

  private setButtonGap(): void {
    if (this.buttonSize === Size.EXTRA_SMALL) {
      this.buttonGap = '10px';
    }
  }

}

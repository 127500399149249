import { Component, Input, OnChanges } from '@angular/core';
import { Location } from '../../models';
import { DefinitionService } from '../../definition/definition.service';
import { Definition } from '../../definition/definition';
import { QuadrantType } from '../constant/quadrant-type.enum';

@Component({
  selector: 'mtn-intersection-view',
  templateUrl: './intersection-view.component.html',
  styleUrls: ['./intersection-view.component.scss']
})
export class IntersectionViewComponent implements OnChanges {

  @Input()
  location: Location;

  quadrantDefinition: Definition<QuadrantType>;

  constructor(private definitionService: DefinitionService) {
  }

  ngOnChanges(): void {
    this.loadQuadrantDefinition();
  }

  private loadQuadrantDefinition(): void {
    if (this.location.quadrant) {
      this.definitionService.findOneQuadrantType(this.location.quadrant)
        .subscribe((result: Definition<QuadrantType>) => this.quadrantDefinition = result);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base-component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InvitationService } from '../../core/company/invitation.service';
import { CompleteInvitation } from '../../core/company/complete-invitation';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../auth/authentication/authentication.service';
import { ToastService } from '../../core/toast/toast.service';
import { MtnValidators } from '../../core/form-controls/mtn-validators';
import { UserProfile } from '../../core/user-profile/user-profile';
import { catchError } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'mtn-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent extends BaseComponent implements OnInit {

  private readonly OTHER_COMPANY_ALERT_TEXT_TEMPLATE = 'I acknowledge that by accepting this invitation, I will be reassigned to <strong>$$NEWCOMPANY$$</strong>, and will lose access to <strong>$$OLDCOMPANY$$</strong> and all items associated with it.';

  readonly OTHER_COMPANY_ALERT_TITLE = 'You currently belong to another Company!';

  invitation: CompleteInvitation;
  isAccepting = false;
  isExpired = false;
  isMemberOfOtherCompany = false;
  isPasswordShown = false;
  otherCompanyAcknowledgement: UntypedFormControl;
  otherCompanyAlertText: string;
  userForm: UntypedFormGroup;

  constructor(private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private invitationService: InvitationService,
              private toaster: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.signOutExistingUser();
    this.loadInvitation();
  }

  acceptInvitation(): void {
    if (!this.isAccepting) {
      this.isAccepting = true;

      this.signOutExistingUser();

      const request = this.buildRequest();

      this.invitationService.acceptOne(this.invitation.invitation.uuid, request)
        .pipe(
          catchError((err) => {
            const errorMessage = err?.error?.message || 'An unexpected error occurred';
            this.toaster.error(errorMessage);
            throw err;
          }),
          finalize(() => this.isAccepting = false))
        .subscribe(() => {
          this.toaster.info("Success! You'll be redirected to the login page in a moment.");
          setTimeout(() => {
            this.authenticationService.openAuth0AuthenticationDialog();
          }, 3000);
        });
    }
  }

  private buildRequest(): UserProfile {
    if (this.isMemberOfOtherCompany) {
      return null;
    } else {
      const formValue = this.userForm.getRawValue();

      const userProfile = new UserProfile();
      userProfile.email = this.invitation.invitation.emailAddress;
      userProfile.firstName = formValue.firstName;
      userProfile.lastName = formValue.lastName;
      userProfile.password = formValue.password;

      return userProfile;
    }
  }

  private checkPasswordsMatch(): void {
    const password = this.userForm.get('password').value;
    const confirmPassword = this.userForm.get('confirmPassword').value;

    if (password && confirmPassword && password !== confirmPassword) {
      this.userForm.get('confirmPassword').setErrors({noPasswordMatch: true});
    }
  }

  private getUuidFromUrl(): Observable<string> {
    return this.activatedRoute.paramMap
      .pipe(
        take(1),
        map((paramMap: ParamMap) => paramMap.get('uuid'))
      );
  }

  private initForm(invitation: CompleteInvitation): void {
    this.userForm = new UntypedFormGroup({
      authorization: new UntypedFormControl(false, [Validators.requiredTrue]),
      firstName: new UntypedFormControl(invitation.invitation.firstName, [Validators.required, Validators.maxLength(64)]),
      lastName: new UntypedFormControl(invitation.invitation.lastName, [Validators.required, Validators.maxLength(64)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(64),
        MtnValidators.matchesPattern(/\d/, {missingNumber: true}),
        MtnValidators.matchesPattern(/[A-Z]/, {missingUpperCase: true}),
        MtnValidators.matchesPattern(/[a-z]/, {missingLowerCase: true}),
        MtnValidators.matchesPattern(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, {missingSpecialCharacter: true})
      ]),
      confirmPassword: new UntypedFormControl(null, [Validators.required])
    });

    this.watchPasswordFields();
  }

  private initOtherCompanyAlert(invitation: CompleteInvitation): void {
    this.otherCompanyAlertText = this.OTHER_COMPANY_ALERT_TEXT_TEMPLATE
      .replace("$$OLDCOMPANY$$", invitation.invitee.company.name)
      .replace("$$NEWCOMPANY$$", invitation.inviter.company.name);

    this.otherCompanyAcknowledgement = new UntypedFormControl(false, [Validators.requiredTrue]);
  }

  private loadInvitation(): void {
    this.getUuidFromUrl()
      .pipe(
        switchMap((uuid: string) => {
          return this.invitationService.findOneComplete(uuid)
            .pipe(tap((result: CompleteInvitation) => {
              this.invitation = result;

              this.isExpired = moment().isAfter(result.invitation.expirationDate)
              if (!this.isExpired) {
                this.isMemberOfOtherCompany = result.invitee?.company?.uuid && result.invitee.company.uuid !== this.invitation.invitation.company.uuid;

                if (this.isMemberOfOtherCompany) {
                  this.initOtherCompanyAlert(result);
                } else {
                  this.initForm(result);
                }
              }
            }));
        })
      )
      .subscribe();
  }

  private signOutExistingUser(): void {
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.clearAuthentication();
    }
  }

  private watchPasswordFields(): void {
    //Check for password match when confirmPassword changed
    this.addSubscription(
      this.userForm.get('confirmPassword').valueChanges
        .subscribe(() => this.checkPasswordsMatch())
    );
    //Reset confirmPassword field if password changed
    this.addSubscription(
      this.userForm.get('password').valueChanges
        .subscribe(() => {
          this.userForm.get('confirmPassword').reset();
        })
    );
  }

}

<div class="banner-filter-assembly container">
  <mtn-map-toolbar-button icon="bookmark" [badgeCount]="toolbarState.badgeCountBanners"
                          [color]="toolbarState.badgeCountBanners ? 'green' : 'grey'"
                          [iconColor]="toolbarState.badgeCountBanners ? 'green' : 'default'"
                          [matMenuTriggerFor]="bannerMenu" [disabled]="!hasStandardLicense"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                          [analyticsEventParameters]="{'target': 'map-banner-filter-menu'}"
                          [matTooltip]="hasStandardLicense ? 'Banner Filter' : 'Banner Filter (Upgrade to unlock!)'"></mtn-map-toolbar-button>
</div>

<mat-menu class="wide-menu" #bannerMenu="matMenu">
  <div class="banner-menu-container toolbar-menu container" mtnClickTrap [preventDefault]="false" fxLayout="column"
       style="width: 300px" [formGroup]="form">
    <div class="header-container">
      <div fxLayout="row">
        <strong>Banners</strong>
        <div fxFlex></div>
        <a href="javascript:void(0)" (click)="clear()"
           [ngClass]="{'color-accent': toolbarState?.filterBanners, 'color-muted': !toolbarState?.filterBanners}">Clear</a>
      </div>
      <hr/>
    </div>
    <mtn-banner-autocomplete-field [form]="form" controlName="bannerSearch" placeholder="Search..."
                                   (onSelection)="addBanner($event)"
                                   [noErrorPadding]="true"></mtn-banner-autocomplete-field>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-checkbox color="accent" formControlName="isExclusive">Exclusive</mat-checkbox>
      <mtn-what-is-this [text]="EXCLUSIVE_FILTER_DEFINITION"></mtn-what-is-this>
    </div>
    <br/>
    <div *ngIf="uuids?.length" fxLayout="column">
      <small>Applied Filters</small>
      <hr/>
      <mat-checkbox color="accent" [value]="uuid" [checked]="true" (change)="removeBanner(uuid)"
                    *ngFor="let uuid of uuids">
        <mtn-banner-view [uuid]="uuid"></mtn-banner-view>
      </mat-checkbox>
    </div>
  </div>
</mat-menu>

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../core/base-component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { selectUserState, UserState } from '../../../auth/user-state';
import { filter } from 'rxjs/operators';
import { UserProfile } from '../../../core/user-profile/user-profile';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { EditProfileWizard } from './edit-profile-wizard/edit-profile-wizard';
import { MtnAccessToken } from '../../../auth/authentication/mtn-access-token';
import { AuthenticationService } from '../../../auth/authentication/authentication.service';

@Component({
  selector: 'mtn-profile-sidebar',
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.scss']
})
export class ProfileSidebarComponent extends BaseComponent implements OnInit {

  profile: UserProfile;

  private token: MtnAccessToken;

  constructor(private authenticationService: AuthenticationService,
              private store: Store<AppState>,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAuthState();
  }

  openEditProfileWizard(): void {
    const wizard = new EditProfileWizard();
    wizard.model = {
      uuid: this.profile.uuid
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: EditProfileWizard) => {
        if (result?.model.profile) {
          this.authenticationService.renewMtnAccessToken(this.token.token).subscribe();
        }
      })
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.store)
        .pipe(filter((state: UserState) => state && !!state.currentUser))
        .subscribe((state: UserState) => {
          this.profile = state.currentUser;
          this.token = state.mtnAuthToken;
        })
    );
  }

}

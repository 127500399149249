<div class="add-user-page-container container" fxLayout="column">
  <p>An invitation email will be sent to the email address you enter below. The invitee will need to open that email and
    follow the instructions inside to accept the invitation.</p>
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-text-field class="default-field-width" [form]="form" controlName="firstName"
                    placeholder="First Name"></mtn-text-field>
    <mtn-text-field class="default-field-width" [form]="form" controlName="lastName"
                    placeholder="Last Name"></mtn-text-field>
  </div>
  <div fxLayout="row" fxLayoutGap="15px" [formGroup]="form">
    <mtn-email-address-field class="default-field-width" [form]="form"
                             controlName="email"></mtn-email-address-field>
    <mat-form-field class="default-field-width" appearance="outline">
      <mat-select formControlName="type" placeholder="User Type">
        <mat-option [value]="CompanyInvitationType.ADMINISTRATOR">Administrator</mat-option>
        <mat-option [value]="CompanyInvitationType.MEMBER">Member</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>

import { Component, ViewChild } from '@angular/core';
import { DefinitionViewComponent } from '../../../../../../../core/definition/definition-display/definition-view.component';
import { WizardPageComponent } from '../../../../../../../core/wizard/wizard-page-component';
import { ShoppingCenterDetailsWizard } from '../shopping-center-details-wizard';
import { Observable } from 'rxjs';
import { ShoppingCenter } from '../../../../../../../core/models';

@Component({
  selector: 'mtn-shopping-center-details-page',
  templateUrl: './shopping-center-details-page.component.html',
  styleUrls: ['./shopping-center-details-page.component.scss']
})
export class ShoppingCenterDetailsPageComponent extends WizardPageComponent<ShoppingCenterDetailsWizard> {

  key = 'shopping-center-details';

  @ViewChild('shoppingCenterType')
  definitionShoppingCenterType: DefinitionViewComponent;

  shoppingCenter: ShoppingCenter;

  constructor() {
    super();
    this.title = 'Shopping Center Details';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.shoppingCenter = this.wizard.model.shoppingCenter;
    return super.onLoad();
  }

}

<div class="sign-up-sign-in-panel-container container page-panel mat-elevation-z1" fxLayout="row"
     fxLayout.lt-md="column"
     fxLayoutAlign="space-between" fxLayoutGap="30px">
  <div class="intro-container" fxLayout="column" fxLayoutGap="30px">
    <div class="carousel-container">
      <mat-tab-group class="question-carousel" [(selectedIndex)]="tabIndex">
        <mat-tab *ngFor="let question of questions">
          <mtn-question-view [question]="question"></mtn-question-view>
        </mat-tab>
      </mat-tab-group>
    </div>
    <h2 class="mat-h2 color-muted">You're only a few clicks away from finding out. Retailstat Insights
      specializes in collecting and analyzing over 100
      data points for tens of thousands of grocery
      stores and grocery-anchored shopping centers
      across the nation.</h2>
    <h2 class="mat-h2 color-muted no-bottom-margin">Insights puts all of that data and analytics
      power into your hands, and helps you make
      well-informed business decisions.</h2>
  </div>
  <div class="sign-up-sign-in-container" fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px">
      <div class="sign-up-container">
        <h1 class="mat-h1 color-primary">Get Started (It's free to try!)</h1>
        <p class="section-description" [class.columnized]="isSmallScreen ? 'columnized' : ''">Sign up quickly and easily below to try out
          Insights for free. Don't worry, we hate
          spam as much as you do, so we aren't going to flood your inbox.</p>
        <button mat-raised-button color="accent" [class.columnized]="isSmallScreen ? 'columnized' : ''" [routerLink]="['/registration']">
          Sign Up
        </button>
      </div>
      <div class="or-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" fxHide.lt-lg>
        <div class="divider color-primary"></div>
        <strong>OR</strong>
        <div class="divider color-primary"></div>
      </div>
      <div class="sign-in-container">
        <h1 class="mat-h1 color-primary">Existing Users</h1>
        <p class="section-description" [class.columnized]="isSmallScreen ? 'columnized' : ''">Welcome back! Let's go to work.</p>
        <button mat-raised-button color="accent" (click)="handleSignIn()" mtnAnalyticsEventTrigger
                [analyticsEventName]="AnalyticsEventType.LOG_IN" [class.columnized]="isSmallScreen ? 'columnized' : ''">
          Sign In
        </button>
      </div>
    </div>
    <div class="text-center">
      Have questions, or would like a demo? Contact <a href="mailto:sales@retailstat.com?subject=Insights%20Sales%20Question"
                                                       target="_blank">sales&#64;retailstat.com</a>!
    </div>
  </div>
</div>

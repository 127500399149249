import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../base-component';
import { PartnerType } from './partner-type.enum';

@Component({
  selector: 'mtn-partner-attribution',
  templateUrl: './partner-attribution.component.html',
  styleUrls: ['./partner-attribution.component.scss']
})
export class PartnerAttributionComponent extends BaseComponent {

  @Input()
  partnerType: PartnerType;

  PartnerType = PartnerType;

  constructor() {
    super();
  }

}

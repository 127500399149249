import { Component, Input, OnInit } from '@angular/core';
import { UserProfile } from '../user-profile';

@Component({
  selector: 'mtn-user-initials-avatar',
  templateUrl: './user-initials-avatar.component.html',
  styleUrls: ['./user-initials-avatar.component.scss']
})
export class UserInitialsAvatarComponent implements OnInit {

  @Input()
  color: string;
  @Input()
  user: UserProfile;

  initials: string;

  constructor() {
  }

  ngOnInit(): void {
    this.generateInitials();
  }

  private generateInitials(): void {
    if (this.user && this.user.firstName) {
      this.initials = `${this.user.firstName.charAt(0).toUpperCase()}${this.user.lastName ? this.user.lastName.charAt(0).toUpperCase() : ''}`;
    } else if (this.user) {
      this.initials = this.user.email.charAt(0).toUpperCase();
    }
  }

}

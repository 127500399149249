import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'mtn-phone-number-view',
  templateUrl: './phone-number-view.component.html',
  styleUrls: ['./phone-number-view.component.scss']
})
export class PhoneNumberViewComponent implements OnChanges {

  @Input()
  phoneNumber: string;

  formattedPhoneNumber: string;

  constructor() {
  }

  ngOnChanges(): void {
    this.formatPhoneNumber();
  }

  private formatPhoneNumber(): void {
    if (this.phoneNumber) {
      const areaCode = this.phoneNumber.substring(0, 3);
      const part1 = this.phoneNumber.substring(3, 6);
      const part2 = this.phoneNumber.substring(6);
      this.formattedPhoneNumber = `(${areaCode}) ${part1}-${part2}`;
    } else {
      this.formattedPhoneNumber = null;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnMaterialModule } from '../modules/mtn-material.module';
import { EmailAddressFieldComponent } from './controls/email-address-field/email-address-field.component';
import { TextFieldComponent } from './controls/text-field/text-field.component';
import { TextAreaFieldComponent } from './controls/text-area-field/text-area-field.component';
import { PhoneNumberFieldComponent } from './controls/phone-number-field/phone-number-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LicenseTypesSelectComponent } from './controls/license-types-select/license-types-select.component';
import { DatepickerFieldComponent } from './controls/datepicker-field/datepicker-field.component';
import { FormErrorHighlighterDirective } from './form-error-highlighter.directive';
import { UserAutocompleteFieldComponent } from './controls/user-autocomplete-field/user-autocomplete-field.component';
import { NumericFormatEnforcerDirective } from './format-enforcers/numeric-format-enforcer.directive';
import { ShoppingCenterTypesSelectComponent } from './controls/shopping-center-types-select/shopping-center-types-select.component';
import { AddressFormComponent } from './forms/address-form/address-form.component';
import { StateFieldComponent } from './controls/state-field/state-field.component';
import { IntersectionFormComponent } from './forms/intersection-form/intersection-form.component';
import { IntersectionTypesSelectComponent } from './controls/intersection-types-select/intersection-types-select.component';
import { QuadrantTypesSelectComponent } from './controls/quadrant-types-select/quadrant-types-select.component';
import { NumberFieldComponent } from './controls/number-field/number-field.component';
import { FitsSelectComponent } from './controls/fits-select/fits-select.component';
import { FormatsSelectComponent } from './controls/formats-select/formats-select.component';
import { StoreStatusSelectComponent } from './controls/store-status-select/store-status-select.component';
import { BannerAutocompleteFieldComponent } from './controls/banner-autocomplete-field/banner-autocomplete-field.component';
import { IdentityCompanyAutocompleteFieldComponent } from './controls/identity-company-autocomplete-field/identity-company-autocomplete-field.component';
import { UserProfileFormComponent } from './forms/user-profile-form/user-profile-form.component';
import { StoreStatusMultiselectComponent } from './controls/store-status-multiselect/store-status-multiselect.component';
import { BannerChipsFieldComponent } from './controls/banner-chips-field/banner-chips-field.component';
import { MtnFontModule } from '../modules/mtn-font.module';
import { FitCategoriesMultiselectComponent } from './controls/fit-categories-multiselect/fit-categories-multiselect.component';
import { DriveTimeMinutesFieldComponent } from './controls/drive-time-minutes-field/drive-time-minutes-field.component';
import { RingMilesFieldComponent } from './controls/ring-miles-field/ring-miles-field.component';
import { RegionFieldComponent } from './controls/region-field/region-field.component';
import { KeyIndicatorFieldComponent } from './controls/key-indicator-field/key-indicator-field.component';
import { ComparisonTypeFieldComponent } from './controls/comparison-type-field/comparison-type-field.component';
import { BannerComparisonModeSelectComponent } from './controls/banner-comparison-mode-select/banner-comparison-mode-select.component';
import { MtnSpinnerModule } from '../util/spinner/mtn-spinner.module';
import { StoreStatusCheckboxMenuComponent } from './controls/store-status-checkbox-menu/store-status-checkbox-menu.component';
import { FitCheckboxMenuComponent } from './controls/fit-checkbox-menu/fit-checkbox-menu.component';
import { CollectionAutocompleteFieldComponent } from './controls/collection-autocomplete-field/collection-autocomplete-field.component';


@NgModule({
  declarations: [
    TextFieldComponent,
    TextAreaFieldComponent,
    EmailAddressFieldComponent,
    PhoneNumberFieldComponent,
    LicenseTypesSelectComponent,
    DatepickerFieldComponent,
    FormErrorHighlighterDirective,
    UserAutocompleteFieldComponent,
    NumericFormatEnforcerDirective,
    ShoppingCenterTypesSelectComponent,
    AddressFormComponent,
    StateFieldComponent,
    IntersectionFormComponent,
    IntersectionTypesSelectComponent,
    QuadrantTypesSelectComponent,
    NumberFieldComponent,
    FitsSelectComponent,
    FormatsSelectComponent,
    StoreStatusSelectComponent,
    BannerAutocompleteFieldComponent,
    IdentityCompanyAutocompleteFieldComponent,
    UserProfileFormComponent,
    StoreStatusMultiselectComponent,
    BannerChipsFieldComponent,
    FitCategoriesMultiselectComponent,
    DriveTimeMinutesFieldComponent,
    RingMilesFieldComponent,
    RegionFieldComponent,
    KeyIndicatorFieldComponent,
    ComparisonTypeFieldComponent,
    BannerComparisonModeSelectComponent,
    StoreStatusCheckboxMenuComponent,
    FitCheckboxMenuComponent,
    CollectionAutocompleteFieldComponent
  ],
  exports: [
    TextFieldComponent,
    TextAreaFieldComponent,
    EmailAddressFieldComponent,
    PhoneNumberFieldComponent,
    LicenseTypesSelectComponent,
    DatepickerFieldComponent,
    UserAutocompleteFieldComponent,
    FormErrorHighlighterDirective,
    ShoppingCenterTypesSelectComponent,
    AddressFormComponent,
    IntersectionFormComponent,
    NumberFieldComponent,
    FitsSelectComponent,
    FormatsSelectComponent,
    StoreStatusSelectComponent,
    BannerAutocompleteFieldComponent,
    IdentityCompanyAutocompleteFieldComponent,
    UserProfileFormComponent,
    StoreStatusMultiselectComponent,
    BannerChipsFieldComponent,
    FitCategoriesMultiselectComponent,
    DriveTimeMinutesFieldComponent,
    RingMilesFieldComponent,
    RegionFieldComponent,
    KeyIndicatorFieldComponent,
    ComparisonTypeFieldComponent,
    BannerComparisonModeSelectComponent,
    StoreStatusCheckboxMenuComponent,
    FitCheckboxMenuComponent,
    CollectionAutocompleteFieldComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MtnMaterialModule,
    MtnFontModule,
    MtnSpinnerModule
  ]
})
export class MtnFormControlsModule {
}

<div class="accordion-subpanel-container container mat-elevation-z1"
     [ngClass]="{clickable: isAccordionEnabled, expanded: isExpanded}"
     [ngClass.gt-md]="{clickable: mode !== 'shopping-center' || hasStandardLicense, expanded: isExpanded}"
     (click)="handleClick()" matRipple>
  <div class="content-container" [ngSwitch]="mode" *ngIf="store">
    <mtn-store-subpanel *ngSwitchCase="'store'" [store]="store" [breakpoint]="breakpoint"
                        [isExpanded]="isExpanded"></mtn-store-subpanel>
    <mtn-space-subpanel *ngSwitchCase="'space'" [space]="store.space" [breakpoint]="breakpoint"
                        [isExpanded]="isExpanded"></mtn-space-subpanel>
    <mtn-shopping-center-subpanel *ngSwitchCase="'shopping-center'" [shoppingCenter]="store.space.shoppingCenter"
                                  [breakpoint]="breakpoint" [isExpanded]="isExpanded"></mtn-shopping-center-subpanel>
    <mtn-restricted-license-overlay [bannerSize]="Size.EXTRA_SMALL" [buttonSize]="Size.SMALL"
                                    *ngIf="mode === 'shopping-center' && !hasStandardLicense"></mtn-restricted-license-overlay>
  </div>
</div>

import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { UserPreferences } from './user-preferences';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService extends CrudService<UserPreferences> {

  protected path = '/user-preferences';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): UserPreferences {
    return new UserPreferences(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<UserPreferences> {
    return new Pageable<UserPreferences>(raw, UserPreferences);
  }

}

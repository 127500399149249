import { BasicEntity } from '../../basic-entity';
import { FilterType } from './filter-type.enum';
import { FitType } from '../../store/fit/fit-type.enum';
import { Geometry } from 'geojson';
import { NumberRange } from '../number-range';
import { ShoppingCenterType } from '../../identity/constant/shopping-center-type.enum';
import { StoreStatusType } from '../../identity/constant/store-status-type.enum';

export function buildFilter(raw: any): Filter<any> {
  // @ts-ignore
  const filterType: FilterType = FilterType[<string>raw.type];

  switch (filterType) {
    case FilterType.BANNER:
      return new BannerFilter(raw);
    case FilterType.COLLECTION:
      return new CollectionFilter(raw);
    case FilterType.FIT_CATEGORY:
      return new FitCategoryFilter(raw);
    case FilterType.FIPS:
      return new FipsFilter(raw);
    case FilterType.GEOJSON:
      return new GeoJsonFilter(raw);
    case FilterType.GROSS_LEASABLE_AREA:
      return new GrossLeasableAreaFilter(raw);
    case FilterType.IS_FLOAT:
      return new IsFloatFilter(raw);
    case FilterType.IS_VACANT:
      return new IsVacantFilter(raw);
    case FilterType.OWNER:
      return new OwnerFilter(raw);
    case FilterType.POSITION_IN_CENTER:
      return new PositionInCenterFilter(raw);
    case FilterType.SALES_AREA:
      return new SalesAreaFilter(raw);
    case FilterType.SALES_SQFT:
      return new SalesSqftFilter(raw);
    case FilterType.SALES_VOLUME:
      return new SalesVolumeFilter(raw);
    case FilterType.SHOPPING_CENTER_TYPE:
      return new ShoppingCenterTypeFilter(raw);
    case FilterType.SHOPPING_CENTER_UUID:
      return new ShoppingCenterUuidFilter(raw);
    case FilterType.SPACE_UUID:
      return new SpaceUuidFilter(raw);
    case FilterType.STATUS:
      return new StatusFilter(raw);
    case FilterType.STORE_UUID:
      return new StoreUuidFilter(raw);
    case FilterType.TOTAL_AREA:
      return new TotalAreaFilter(raw);
    default:
      throw new Error(`Unsupported FilterType '${filterType}'`);
  }
}

export abstract class Filter<T> extends BasicEntity {

  isExclusive = false;
  type: FilterType;
  value: T;

  abstract isValid(): boolean;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);

      if (raw.type) {
        // @ts-ignore
        this.type = FilterType[<string>raw.type];
      }
    }
  }
}

export class BannerFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.BANNER;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class CollectionFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.COLLECTION;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class FitCategoryFilter extends Filter<FitType[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.FIT_CATEGORY;
    if (raw?.value?.length) {
      // @ts-ignore
      this.value = raw.value.map((fit: any) => FitType[<string>fit]);
    }
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class FipsFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.FIPS;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class GeoJsonFilter extends Filter<Geometry> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.GEOJSON;
  }

  isValid(): boolean {
    return !!this.value;
  }
}

export class GrossLeasableAreaFilter extends Filter<NumberRange> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.GROSS_LEASABLE_AREA;
    if (raw?.value) {
      this.value = new NumberRange(raw.value);
    }
  }

  isValid(): boolean {
    return !!this.value?.max || !!this.value?.min;
  }
}

export class IsFloatFilter extends Filter<boolean> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.IS_FLOAT;
  }

  isValid(): boolean {
    return this.value === true || this.value === false;
  }
}

export class IsVacantFilter extends Filter<boolean> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.IS_VACANT;
  }

  isValid(): boolean {
    return this.value === true || this.value === false;
  }
}

export class OwnerFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.OWNER;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class PositionInCenterFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.POSITION_IN_CENTER;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class SalesAreaFilter extends Filter<NumberRange> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.SALES_AREA;
    if (raw?.value) {
      this.value = new NumberRange(raw.value);
    }
  }

  isValid(): boolean {
    return !!this.value?.max || !!this.value?.min;
  }
}

export class SalesSqftFilter extends Filter<NumberRange> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.SALES_SQFT;
    if (raw?.value) {
      this.value = new NumberRange(raw.value);
    }
  }

  isValid(): boolean {
    return !!this.value?.max || !!this.value?.min;
  }
}

export class SalesVolumeFilter extends Filter<NumberRange> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.SALES_VOLUME;
    if (raw?.value) {
      this.value = new NumberRange(raw.value);
    }
  }

  isValid(): boolean {
    return !!this.value?.max || !!this.value?.min;
  }
}

export class ShoppingCenterTypeFilter extends Filter<ShoppingCenterType[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.SHOPPING_CENTER_TYPE;
    if (raw?.value?.length) {
      // @ts-ignore
      this.value = raw.value.map((type: any) => ShoppingCenterType[<string>type]);
    }
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class ShoppingCenterUuidFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.SHOPPING_CENTER_UUID;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class SpaceUuidFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.SPACE_UUID;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class StatusFilter extends Filter<StoreStatusType[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.STATUS;
    if (raw?.value?.length) {
      // @ts-ignore
      this.value = raw.value.map((type: any) => StoreStatusType[<string>type]);
    }
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class StoreUuidFilter extends Filter<string[]> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.STORE_UUID;
  }

  isValid(): boolean {
    return !!this.value?.length;
  }
}

export class TotalAreaFilter extends Filter<NumberRange> {

  constructor(raw?: any) {
    super(raw);
    this.type = FilterType.TOTAL_AREA;
    if (raw?.value) {
      this.value = new NumberRange(raw.value);
    }
  }

  isValid(): boolean {
    return !!this.value?.max || !!this.value?.min;
  }
}



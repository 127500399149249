<div class="sales-area-page-container container">
  <p>{{Dictionary.tooltipSalesArea}}</p>
  <p>{{Dictionary.tooltipTotalArea}}</p>
  <p>The ratio of Sales Area to Total Area can be used to help answer questions about the store's overhead.</p>
  <hr/>
  <mtn-key-value key="Total Area">
    <span *ngIf="store.totalArea; else notAvailable">{{store.totalArea.toLocaleString()}} sqft</span>
  </mtn-key-value>
  <mtn-key-value key="Sales Area">
          <span *ngIf="store.grocerySalesArea; else notAvailable">{{store.grocerySalesArea.toLocaleString()}} sqft <span
            *ngIf="salesAreaPercentage">({{salesAreaPercentage}}% of Total Area)</span></span>
  </mtn-key-value>
</div>

<ng-template #notAvailable>
  <span class="color-muted">Not Available</span>
</ng-template>

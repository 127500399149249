<div class="company-administration-container container" fxLayout="column" fxLayoutGap="30px">
  <mtn-company-alerts-panel [company]="company" (onUpdate)="loadCompany()"></mtn-company-alerts-panel>
  <div class="page-header container" fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutGap="15px">
      <mtn-company-initials-avatar *ngIf="company" [company]="company"></mtn-company-initials-avatar>
      <div class="company-description" fxLayout="column" fxFlex="40">
        <h1 class="mat-h1 color-primary text-small-caps" style="margin-bottom:0">{{company?.name}} <span
          class="color-muted" [matTooltip]="identifierTooltip">({{company?.uniqueIdentifier}})</span></h1>
        <span class="color-muted">{{company?.description || 'Edit Company to add a Description'}}</span>
      </div>
      <div fxFlex></div>
      <!-- <mtn-hubspot-object-link [company]="company"></mtn-hubspot-object-link> -->
      <mtn-icon-button (click)="openEditCompanyWizard()" matTooltip="Edit Company">
        <fa-icon icon="pencil-alt"></fa-icon>
      </mtn-icon-button>
      <mtn-icon-button color="warn" (click)="openConfirmDeleteCompanyWizard()" matTooltip="Delete Company">
        <fa-icon icon="trash-alt"></fa-icon>
      </mtn-icon-button>
    </div>
    <hr/>
  </div>
  <div fxLayout="row" fxLayoutGap="15px" *ngIf="company">
    <div fxLayout="column" fxLayoutGap="30px">
      <mtn-company-api-key [company]="company" (onUpdate)="loadCompany()"></mtn-company-api-key>
      <mtn-company-api-user [company]="company" (onUpdate)="loadCompany()"></mtn-company-api-user>
      <mtn-company-add-on-list [company]="company"></mtn-company-add-on-list>
      <mtn-license-card [company]="company" (onUpdate)="loadCompany()"></mtn-license-card>
      <mtn-license-history-panel [company]="company"></mtn-license-history-panel>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="30px">
      <mtn-company-users-list [company]="company" (onUpdate)="loadCompany()"></mtn-company-users-list>
      <mtn-area-subscriptions-list [company]="company"></mtn-area-subscriptions-list>
<!--      <mtn-associated-banners-list [company]="company"></mtn-associated-banners-list>-->
      <!-- TODO documents here -->
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public readonly DEFAULT_ERROR = 'Uh-oh! That didn\'t work... If the problem persists, please contact an administrator for assistance.';

  constructor(private snackBar: MatSnackBar) {
  }

  error(message: string = this.DEFAULT_ERROR, duration: number = 4000, action?: string): MatSnackBarRef<any> {
    let newmsg=message;
    if(message.includes("message")){
      let i=message.lastIndexOf(":")+2;
      newmsg=message.substring(i,message.length-2);
    }
    return this.snackBar.open(newmsg, action, {
      duration: duration,
      horizontalPosition: 'right',
      panelClass: 'mat-snackbar-error'
    });
  }

  info(message: string, duration: number = 2000, action?: string): MatSnackBarRef<any> {
    return this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: 'right',
      panelClass: 'mat-snackbar'
    });
  }
}

import { Wizard } from '../../../../core/wizard/wizard';
import { InactivitySignedOutPageComponent } from './inactivity-signed-out-page/inactivity-signed-out-page.component';

export class InactivitySignedOutWizard extends Wizard<null> {
  key = 'inactivity-signed-out';
  pageTypeMap = {
    'inactivity-signed-out': InactivitySignedOutPageComponent
  };
  startingPageKey = 'inactivity-signed-out';
}

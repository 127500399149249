import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Breakpoints } from '@angular/cdk/layout';
import * as _ from 'lodash';
import { Store } from '../../../../core/models';
import { AuthorizationAwareComponent } from '../../../../core/authorization-aware-component';
import { AppState } from '../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';

@Component({
  selector: 'mtn-accordion-subpanel',
  templateUrl: './accordion-subpanel.component.html',
  styleUrls: ['./accordion-subpanel.component.scss']
})
export class AccordionSubpanelComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Output()
  onClick = new EventEmitter<'store' | 'space' | 'shopping-center'>();
  @Input()
  breakpoint: string;
  @Input()
  mode: 'store' | 'space' | 'shopping-center';
  @Input()
  store: Store;

  isAccordionEnabled = false;
  isExpanded = true;

  constructor(protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
  }

  ngOnChanges() {
    setTimeout(() => this.handleBreakpointChange());
  }

  ngOnInit() {
    super.ngOnInit();
  }

  handleClick(): void {
    this.onClick.emit(this.mode);
  }

  private handleBreakpointChange(): void {
    //If we're gt-md, all of the subpanels should be expanded, and not accordion-enabled
    if (_.includes([Breakpoints.XLarge, Breakpoints.Large], this.breakpoint)) {
      this.isExpanded = true;
      this.isAccordionEnabled = false;
    }
    //Else, expanded and accordion-enabled depends on current mode and breakpoint
    else {
      switch (this.mode) {
        case 'store':
          //store should always be expanded by default, but only accordion-enabled for small or xsmall
          this.isExpanded = true;
          this.isAccordionEnabled = _.includes([Breakpoints.Small, Breakpoints.XSmall], this.breakpoint);
          break;
        case 'space':
          //space should always be collapsed by default and accordion-enabled
          this.isExpanded = false;
          this.isAccordionEnabled = true;
          break;
        case 'shopping-center':
          //shopping-center should be expanded for md/lg, collapsed for lt-md, and always accordion-enabled
          this.isExpanded = this.breakpoint === Breakpoints.Medium;
          this.isAccordionEnabled = true;
          break;
      }
    }
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Company } from '../../../../core/company/company';
import { BaseComponent } from '../../../../core/base-component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../core/date/insights-date.pipe';
import { UpdateLicenseWizard } from '../license-card/update-license-wizard/update-license-wizard';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';

@Component({
  selector: 'mtn-company-alerts-panel',
  templateUrl: './company-alerts-panel.component.html',
  styleUrls: ['./company-alerts-panel.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class CompanyAlertsPanelComponent extends BaseComponent implements OnChanges {

  @Output()
  onUpdate = new EventEmitter<any>();

  @Input()
  company: Company;

  expirationDateString: string;
  suspendedDateString: string;
  isExpired = false;
  isExpiring = false;
  isSuspended = false;

  constructor(private insightsDatePipe: InsightsDatePipe,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnChanges(): void {
    if (this.company) {
      this.initAlerts();
    }
  }

  openUpdateLicenseWizard(): void {
    const wizard = new UpdateLicenseWizard();
    wizard.model = {
      company: this.company
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: UpdateLicenseWizard) => {
      if (result.model.isUpdated) {
        this.onUpdate.emit();
      }
    });
  }

  private initAlerts(): void {
    this.isSuspended = !!this.company.license.suspendedDate;
    this.isExpired = this.company.license.isExpired();
    this.isExpiring = false;

    if (this.company.license.suspendedDate) {
      this.suspendedDateString = this.insightsDatePipe.transform(this.company.license.suspendedDate);
    }

    if (this.company.license.expirationDate) {
      this.expirationDateString = this.insightsDatePipe.transform(this.company.license.expirationDate);

      if (!this.isExpired) {
        const now = moment();
        const expirationDate = moment(this.company.license.expirationDate);

        this.isExpiring = expirationDate.diff(now, 'days') < 30;
      }
    }
  }

}

export enum QuadrantType {
  NONE = 'NONE',
  NC = 'NC',
  NWC = 'NWC',
  NEC = 'NEC',
  N_OF_NC = 'N_OF_NC',
  N_OF_NEC = 'N_OF_NEC',
  N_OF_NWC = 'N_OF_NWC',
  EC = 'EC',
  E_OF_NEC = 'E_OF_NEC',
  E_OF_EC = 'E_OF_EC',
  E_OF_SEC = 'E_OF_SEC',
  WC = 'WC',
  W_OF_NWC = 'W_OF_NWC',
  W_OF_WC = 'W_OF_WC',
  W_OF_SWC = 'W_OF_SWC',
  SC = 'SC',
  S_OF_SWC = 'S_OF_SWC',
  S_OF_SC = 'S_OF_SC',
  S_OF_SEC = 'S_OF_SEC',
  SWC = 'SWC',
  SEC = 'SEC'
}

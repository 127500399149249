<div [formGroup]="form">
  <mat-form-field class="default-field-width" appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [matDatepicker]="picker" [formControlName]="controlName" [required]="isRequired">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>
</div>

import { BaseComponent } from './base-component';
import { UserProfile } from './user-profile/user-profile';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { Component, OnInit } from '@angular/core';
import { selectUserState, UserState } from '../auth/user-state';
import { isLicenseOfLevel, LicenseType } from '../auth/authorization/license-type.enum';
import { filter } from 'rxjs/operators';
import { UserPreferences } from './user-preferences/user-preferences';
import { Company } from './company/company';
import { AddOnType } from './company/add-on-type.enum';

/**
 * IMPORTANT: If the subclass does not call super.ngOnInit() in its own ngOnInit, no authorization logic will be available!
 */

@Component({
  selector: 'mtn-authorization-aware-component',
  template: '<div></div>'
})
export abstract class AuthorizationAwareComponent extends BaseComponent implements OnInit {

  /**
   * Override this to serve as a sort of OnChanges when the AuthState is updated.
   */
  abstract onAuthorizationChange(): void;

  /**
   * Override this to serve as an OnInit, but only after the currentUser has been initialized.
   */
  abstract onAuthorizationInit(): void;

  currentCompany: Company;
  currentUser: UserProfile;
  hasStandardLicense = false;
  hasInternalLicense = false;
  isFreeLicense = false;
  isInitialized = false;
  licenseType: LicenseType;
  userPreferences: UserPreferences;

  protected constructor(protected ngrxStore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.subscribeToAuthState();
  }

  hasAddOn(type: AddOnType): boolean {
    return this.hasInternalLicense || this.currentCompany?.hasAddOn(type);
  }

  /**
   * The back-end already checks for license suspension or expiration and downgrades the user's license in the auth
   * token, so all we need to do here is check that the license is of the appropriate level.
   */
  hasLicense(type: LicenseType): boolean {
    return isLicenseOfLevel(this.currentUser.licenseType, type);
  }

  isCompanyAdministrator(): boolean {
    return this.currentUser.isCompanyAdministrator;
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.ngrxStore)
        .pipe(filter((state: UserState) => !!state.currentUser && !!state.userPreferences))
        .subscribe((state: UserState) => {
          this.currentCompany = state.currentCompany;
          this.currentUser = state.currentUser;
          this.licenseType = state.currentUser.licenseType;
          this.userPreferences = state.userPreferences;
          this.hasStandardLicense = this.hasLicense(LicenseType.STANDARD);
          this.hasInternalLicense = this.hasLicense(LicenseType.INTERNAL);
          this.isFreeLicense = this.licenseType === LicenseType.FREE;

          if (!this.isInitialized) {
            this.onAuthorizationInit();
            this.isInitialized = true;
          }

          this.onAuthorizationChange();
        })
    );
  }
}

import { Wizard } from '../../../../../core/wizard/wizard';
import { StoreComparison } from '../../../../../core/store/ranking/store-comparison';
import { CompetitiveAnalysisDetailsPageComponent } from './competitive-analysis-details-page/competitive-analysis-details-page.component';
import { FeatureCollection } from '../../../../../core/models';

export class CompetitiveAnalysisDetailsWizard extends Wizard<ComparisonDetailWizardModel> {
  key = 'competitive-analysis-details';
  pageTypeMap = {
    'competitive-analysis-details': CompetitiveAnalysisDetailsPageComponent
  };
  startingPageKey = 'competitive-analysis-details';

  constructor() {
    super();
    this.width = 850;
  }
}

export interface ComparisonDetailWizardModel {
  comparison: StoreComparison;
  isRestrictedLicenseOverlayEnabled: boolean;
  marketArea?: FeatureCollection;
}

import { Feature, FeatureCollection, Store } from '../core/models';
import { Action, createReducer, on, select, Store as NgrxStore } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import {
  DetailAddStoreComparisonAction,
  DetailAddStoreCustomComparisonRequestAction,
  DetailResetAction,
  DetailSetAnalystRatingsAction,
  DetailSetCompetitionAction,
  DetailSetDefaultDriveTimeAction,
  DetailSetDemographicsAction,
  DetailSetMarketAreaAction,
  DetailSetSalesSqftBannerComparisonAction,
  DetailSetSalesVolumeBannerComparisonAction,
  DetailSetStoreAction,
  DetailSetStoreCountBannerComparisonAction,
  DetailSetStoreCustomComparisonRequestsAction,
  DetailSetStoreCustomComparisonsAction,
  DetailSetStoreDefaultComparisonRequestsAction,
  DetailSetStoreDefaultComparisonsAction,
  DetailSetStoreServicesAction,
  DetailSetTradeAreaAction,
  DetailSetVolumeAction,
  DetailSetVolumeHistoryAction
} from './detail-actions';
import { StoreComparison } from '../core/store/ranking/store-comparison';
import * as _ from 'lodash';
import { AnalystRatingResponse } from '../core/store/rating/analyst-rating-response';
import { BannerComparison } from '../core/banner/banner-comparison';
import { StoreServices } from '../core/store/store-services';
import { StoreVolume } from '../core/store/volume/store-volume';
import { DemographicDataView } from '../core/depot/demographic-data-view';

export const DETAIL_STATE_KEY = 'detailState';

export interface DetailState {
  analystRating: AnalystRatingResponse;
  bannerComparisonSalesSqft: BannerComparison;
  bannerComparisonSalesVolume: BannerComparison;
  bannerComparisonStoreCount: BannerComparison;
  competition: Feature[];
  customComparisonRequests: StoreComparison[];
  customComparisons: StoreComparison[];
  defaultComparisonRequests: StoreComparison[];
  defaultComparisons: StoreComparison[];
  defaultDriveTime: FeatureCollection;
  demographics: DemographicDataView;
  isDemographicsSampleData: boolean;
  marketArea: FeatureCollection;
  store: Store;
  services: StoreServices;
  tradeArea: FeatureCollection;
  volume: StoreVolume;
  volumeHistory: StoreVolume[];
}

const initialState: DetailState = {
  analystRating: null,
  bannerComparisonSalesSqft: null,
  bannerComparisonSalesVolume: null,
  bannerComparisonStoreCount: null,
  competition: [],
  customComparisonRequests: [],
  customComparisons: [],
  defaultComparisonRequests: [],
  defaultComparisons: [],
  defaultDriveTime: null,
  demographics: null,
  isDemographicsSampleData: false,
  marketArea: null,
  services: null,
  store: null,
  tradeArea: null,
  volume: null,
  volumeHistory: []
};

export const selectDetailState = (store: NgrxStore<any>) => store.pipe(select((state: any) => state.detailState), filter(state => state !== undefined));

const _detailReducer = createReducer(
  initialState,
  on(DetailAddStoreComparisonAction, (state, {comparison}) => {
    //This event is used for both custom and default comparisons, so find the appropriate list(s) to modify
    const isDefaultComparison = !!_.find(state.defaultComparisonRequests, (request: StoreComparison) => request.equals(comparison));
    const isCustomComparison = !!_.find(state.customComparisonRequests, (request: StoreComparison) => request.equals(comparison));

    let defaultComparisons: StoreComparison[] = state.defaultComparisons;
    if (isDefaultComparison) {
      //Replace any existing matched comparison with the new version
      defaultComparisons = _.reject(state.defaultComparisons, (existingComparison: StoreComparison) => existingComparison.equals(comparison, true));
      defaultComparisons.push(comparison);
    }

    let customComparisons: StoreComparison[] = state.customComparisons;
    if (isCustomComparison) {
      //Replace any existing matched comparison with the new version
      customComparisons = _.reject(state.customComparisons, (existingComparison: StoreComparison) => existingComparison.equals(comparison, true));
      customComparisons.push(comparison);
    }

    return ({
      ...state,
      defaultComparisons: defaultComparisons,
      customComparisons: customComparisons
    });
  }),
  on(DetailAddStoreCustomComparisonRequestAction, (state, {comparison}) => {
    const filteredComparisons = _.reject(state.customComparisonRequests, (existingComparison: StoreComparison) => existingComparison.equals(comparison));
    filteredComparisons.push(comparison);

    return ({
      ...state,
      customComparisonRequests: filteredComparisons
    });
  }),
  on(DetailResetAction, () => ({...initialState})),
  on(DetailSetAnalystRatingsAction, (state, {rating}) => ({...state, analystRating: rating})),
  on(DetailSetSalesSqftBannerComparisonAction, (state, {comparison}) => ({
    ...state,
    bannerComparisonSalesSqft: comparison
  })),
  on(DetailSetSalesVolumeBannerComparisonAction, (state, {comparison}) => ({
    ...state,
    bannerComparisonSalesVolume: comparison
  })),
  on(DetailSetStoreCountBannerComparisonAction, (state, {comparison}) => ({
    ...state,
    bannerComparisonStoreCount: comparison
  })),
  on(DetailSetCompetitionAction, (state, {competition}) => ({...state, competition: competition})),
  on(DetailSetDefaultDriveTimeAction, (state, {driveTime}) => ({...state, defaultDriveTime: driveTime})),
  on(DetailSetDemographicsAction, (state, {demographics, isDemographicsSampleData}) => ({
    ...state,
    demographics: demographics,
    isDemographicsSampleData: isDemographicsSampleData
  })),
  on(DetailSetMarketAreaAction, (state, {area}) => ({...state, marketArea: area})),
  on(DetailSetStoreAction, (state, {store}) => ({...state, store: store})),
  on(DetailSetStoreServicesAction, (state, {services}) => ({...state, services: services})),
  on(DetailSetStoreDefaultComparisonRequestsAction, (state, {comparisons}) => ({
    ...state,
    defaultComparisonRequests: comparisons
  })),
  on(DetailSetStoreDefaultComparisonsAction, (state, {comparisons}) => ({...state, defaultComparisons: comparisons})),
  on(DetailSetStoreCustomComparisonsAction, (state, {comparisons}) => ({...state, customComparisons: comparisons})),
  on(DetailSetStoreCustomComparisonRequestsAction, (state, {comparisons}) => ({
    ...state,
    customComparisonRequests: comparisons
  })),
  on(DetailSetTradeAreaAction, (state, {area}) => ({...state, tradeArea: area})),
  on(DetailSetVolumeAction, (state, {volume}) => ({...state, volume: volume})),
  on(DetailSetVolumeHistoryAction, (state, {volumes}) => ({...state, volumeHistory: volumes}))
);

export function detailReducer(state: DetailState | undefined, action: Action) {
  return _detailReducer(state, action);
}

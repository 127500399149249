<div [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [formControlName]="controlName" [required]="isRequired"/>
    <mat-error *ngIf="form.get(controlName).hasError('email')">
      <i>Must be a valid email address</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('maxlength')">
      <i>Cannot exceed {{form.get(controlName).getError('maxlength').requiredLength}} characters</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>
</div>

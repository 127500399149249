import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthorizationAwareComponent } from '../../core/authorization-aware-component';
import { AppState } from '../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { CollectionService } from '../../core/federation/collection/collection.service';
import { debounceTime, finalize, tap } from 'rxjs/operators';
import { Pageable } from '../../core/service/pageable';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../core/date/insights-date.pipe';
import { WizardRunnerService } from '../../core/wizard/wizard-runner.service';
import { CreateOrEditCollectionWizard } from '../create-or-edit-collection-wizard/create-or-edit-collection-wizard';
import { forkJoin, Observable } from 'rxjs';
import { Selectable } from '../../core/util/selectable';
import * as _ from 'lodash';
import { ConfirmActionWizard } from '../../core/confirm-action-wizard/confirm-action-wizard';
import { ToastService } from '../../core/toast/toast.service';
import { Collection } from '../../core/federation/collection/collection';
import { UserProfileService } from '../../core/user-profile/user-profile.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'mtn-collections-list-page',
  templateUrl: './collections-list-page.component.html',
  styleUrls: ['./collections-list-page.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class CollectionsListPageComponent extends AuthorizationAwareComponent {

  public readonly CREATE_COLLECTION_TOOLTIP = `Create Collection

Powerful collection tools coming soon! Until then, you can create more simple collections of stores from the map.`;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  currentPage = 0;
  dataSource = new MatTableDataSource<Selectable<Collection>>();
  displayedColumns: string[] = ['selection', 'favorite', 'name', 'createdDate', 'ownerDisplayName'];
  filterForm = new UntypedFormGroup({
    filter: new UntypedFormControl(),
    includeShared: new UntypedFormControl(false)
  });
  isLoading = false;
  selectionCount = 0;
  totalResults = 0;

  constructor(private activatedRoute: ActivatedRoute,
              private collectionService: CollectionService,
              protected ngrxStore: NgrxStore<AppState>,
              private router: Router,
              private toaster: ToastService,
              private userProfileService: UserProfileService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initTable();
    this.loadCollections();
    this.subscribeToFilterChanges();
  }

  countSelections(): void {
    this.selectionCount = _.filter(this.dataSource.data, (selectable: Selectable<Collection>) => selectable.isSelected).length;
  }

  loadCollections(page = 0): void {
    if (!this.isLoading) {
      this.isLoading = true;

      const formValue = this.filterForm.getRawValue();

      let sortDirection = this.sort?.direction;
      let sortValue = this.sort?.active;
      if (!sortValue || sortValue === 'favorite') {
        sortValue = 'name';
        sortDirection = 'asc';
      }

      const params = new HttpParams()
        .set('sort', `${sortValue},${sortDirection}`)
        .set('page', `${page.toString()}`);

      this.addSubscription(
        this.userProfileService.findOnesCollections(this.currentUser.uuid, formValue.filter, formValue.includeShared, params)
          .pipe(finalize(() => this.isLoading = false))
          .subscribe((result: Pageable<Collection>) => {
            this.dataSource.data = result.content.map((collection: Collection) => {
              return {isSelected: false, content: collection};
            });
            this.currentPage = result.number;
            this.totalResults = result.totalElements;
            this.countSelections();
          })
      );
    }
  }

  navigateToCollection(collection: Collection): void {
    this.router.navigate([collection.uuid], {relativeTo: this.activatedRoute});
  }

  openConfirmDeleteDialog(): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: this.deleteCollections(),
      text: `Are you sure you want to delete ${this.selectionCount} collection(s)? They will no longer be visible to anyone they were shared with, and this cannot be undone!`,
      title: 'Delete Collections'
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe(() => {
        this.loadCollections();
      });
  }

  openCreateCollectionWizard(): void {
    const wizard = new CreateOrEditCollectionWizard();
    wizard.model = {
      companyUuid: this.currentUser.company.uuid,
      userProfileUuid: this.currentUser.uuid
    };

    this.addSubscription(this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: CreateOrEditCollectionWizard) => {
        if (result.model.result) {
          this.navigateToCollection(result.model.result);
        }
      })
    );
  }

  setFilter(value: string): void {
    this.filterForm.get('filter').setValue(value);
  }

  toggleFavorite(collection: Collection): void {
    let task: Observable<Collection>;
    if (collection.isFavoritedBy(this.currentUser.uuid)) {
      task = this.collectionService.deleteOneFavorite(collection.uuid)
        .pipe(tap(() => {
          const index = _.findIndex(collection.favoritedBy, this.currentUser.uuid);
          collection.favoritedBy.splice(index, 1);
        }));
    } else {
      task = this.collectionService.addOneFavorite(collection.uuid)
        .pipe(tap(() => {
          collection.favoritedBy.push(this.currentUser.uuid);
        }));
    }

    task.subscribe();
  }

  private deleteCollections(): Observable<any> {
    const tasks: Observable<any>[] = [];

    this.dataSource.data.forEach((selectable: Selectable<Collection>) => {
      if (selectable.isSelected) {
        tasks.push(this.collectionService.deleteOne(selectable.content.uuid));
      }
    });

    return forkJoin(tasks)
      .pipe(tap(() => {
        this.toaster.info('Successfully deleted collections');
      }));
  }

  private initTable(): void {
    setTimeout(() => {
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 50);

    this.dataSource.sortingDataAccessor = (data: Selectable<Collection>, header: string): any => {
      switch (header) {
        case 'favorite':
          return data.content.isFavoritedBy(this.currentUser.uuid);
        case 'ownerDisplayName':
          return data.content.owner?.getDisplayName();
        default:
          // @ts-ignore
          return data.content[header];
      }
    };
  }

  private subscribeToFilterChanges(): void {
    this.addSubscription(
      this.filterForm.valueChanges
        .pipe(debounceTime(300))
        .subscribe(() => this.loadCollections())
    );
  }
}

import {
  ActiveStoreStatuses,
  FutureStoreStatuses,
  HistoricalStoreStatuses
} from '../../core/identity/constant/store-status-type.enum';
import * as _ from 'lodash';
import { Feature } from '../../core/models';

export class MarkerZIndexUtil {

  // private static Z_INDEX_THEMATIC = 10;
  // private static Z_INDEX_THEMATIC_PRIORITY = 20;
  private static Z_INDEX_MARKER = 30;
  private static Z_INDEX_MARKER_PRIORITY = 40;

  static getMarkerZIndex(feature: Feature, isSelected: boolean): number {
    let zIndex = isSelected ? this.Z_INDEX_MARKER_PRIORITY : this.Z_INDEX_MARKER;

    if (feature.isStore()) {
      zIndex += this.getStoreMarkerZIndexOffset(feature);
    }

    return zIndex;
  }

  /**
   * We add this offset to the base zIndex in order to overlay markers as best we can by relevancy.
   * We want Active > Future > Closed.
   */
  private static getStoreMarkerZIndexOffset(feature: Feature): number {
    let offset = 0;

    const storeStatus = feature.getStore().getCurrentStatus()?.getStatusSystemName();
    if (storeStatus) {
      if (_.includes(ActiveStoreStatuses, storeStatus)) {
        offset = 3;
      } else if (_.includes(FutureStoreStatuses, storeStatus)) {
        offset = 2;
      } else if (_.includes(HistoricalStoreStatuses, storeStatus)) {
        offset = 1;
      }
    }

    return offset;
  }
}

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaintenanceNotification } from './maintenance-notification';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceNotificationService extends InsightsRestService {

  path = '/maintenance-notification';

  constructor(private http: HttpClient) {
    super()
  }

  findAllUpcoming(): Observable<MaintenanceNotification[]> {
    const url = this.buildUrl(`${this.path}/upcoming`);
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new MaintenanceNotification(result))));
  }
}

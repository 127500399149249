import { MtnMap } from '../mtn-map';
import {
  BannerFilter,
  CollectionFilter,
  Filter,
  FitCategoryFilter,
  SalesAreaFilter,
  SalesSqftFilter,
  SalesVolumeFilter,
  StatusFilter,
  TotalAreaFilter
} from '../../core/federation/filter/filter';
import { FilterGroup } from '../../core/federation/filter/filter-group';
import { FeatureType } from '../../core/federation/feature/feature-type.enum';
import { FilterType } from '../../core/federation/filter/filter-type.enum';
import * as _ from 'lodash';

/**
 * This is used as a convenience and a way to abstract away the logic of determining how the filter toolbar should
 * appear and behave, in an effort to keep the toolbar component itself as clean as possible. We transform the
 * FilterGroup into a structure that's more useful and concise for the toolbar's purposes.
 */
export class FilterToolbarState {

  badgeCountArea = 0;
  badgeCountBanners = 0;
  badgeCountCollections = 0;
  badgeCountFits = 0;
  badgeCountLayers = 0;
  badgeCountSales = 0;
  badgeCountStatuses = 0;

  /*
  This is used as a convenience for enabling the Clear All button, and therefore only cares about how many filters we
  actually have, not how many filter values (as we show in the badges).
   */
  filterCount = 0;

  filterBanners: BannerFilter;
  filterCollections: CollectionFilter;
  filterFitCategories: FitCategoryFilter;
  filterSalesArea: SalesAreaFilter;
  filterSalesSqft: SalesSqftFilter;
  filterSalesVolume: SalesVolumeFilter;
  filterStatuses: StatusFilter;
  filterTotalArea: TotalAreaFilter;

  isPointOfInterestLayerEnabled = false;
  isShoppingCenterLayerEnabled = false;
  isStoreLayerEnabled = false;

  constructor(map: MtnMap) {
    this.handleLayers(map);
    this.handleFilters(map.options.filterGroup);
  }

  private handleLayers(map: MtnMap): void {
    if (map.options.isPointsOfInterestEnabled) {
      this.badgeCountLayers++;
      this.isPointOfInterestLayerEnabled = true;
    }
    if (map.isFeatureType(FeatureType.SHOPPING_CENTER)) {
      this.badgeCountLayers++;
      this.isShoppingCenterLayerEnabled = true;
    }
    if (map.isFeatureType(FeatureType.STORE)) {
      this.badgeCountLayers++;
      this.isStoreLayerEnabled = true;
    }
  }

  private handleFilters(filterGroup: FilterGroup): void {
    if (filterGroup?.filters?.length) {
      if (this.isShoppingCenterLayerEnabled) {
        this.handleShoppingCenterFilters(filterGroup);
      } else {
        this.handleStoreFilters(filterGroup);
      }
    }
  }

  // @ts-ignore
  private handleShoppingCenterFilters(filterGroup: FilterGroup): void {
    throw new Error('NotYetImplementedException');
  }

  private handleStoreFilters(filterGroup: FilterGroup): void {
    this.filterCount = _.filter(filterGroup.filters, (filter: Filter<any>) => filter.type !== FilterType.GEOJSON).length;

    if (filterGroup.hasFilter(FilterType.BANNER)) {
      this.filterBanners = filterGroup.getOwnFilter(FilterType.BANNER);
      this.badgeCountBanners = this.filterBanners.value?.length || 0;
      this.filterCount++;
    }
    if (filterGroup.hasFilter(FilterType.COLLECTION)) {
      this.filterCollections = filterGroup.getOwnFilter(FilterType.COLLECTION);
      this.badgeCountCollections = this.filterCollections.value?.length || 0;
    }
    if (filterGroup.hasFilter(FilterType.FIT_CATEGORY)) {
      this.filterFitCategories = filterGroup.getOwnFilter(FilterType.FIT_CATEGORY);
      this.badgeCountFits = this.filterFitCategories.value?.length || 0;
    }
    if (filterGroup.hasFilter(FilterType.SALES_AREA)) {
      this.badgeCountArea++;
      this.filterSalesArea = filterGroup.getOwnFilter(FilterType.SALES_AREA);
    }
    if (filterGroup.hasFilter(FilterType.SALES_SQFT)) {
      this.badgeCountSales++;
      this.filterSalesSqft = filterGroup.getOwnFilter(FilterType.SALES_SQFT);
    }
    if (filterGroup.hasFilter(FilterType.SALES_VOLUME)) {
      this.badgeCountSales++;
      this.filterSalesVolume = filterGroup.getOwnFilter(FilterType.SALES_VOLUME);
    }
    if (filterGroup.hasFilter(FilterType.STATUS)) {
      this.filterStatuses = filterGroup.getOwnFilter(FilterType.STATUS);
      this.badgeCountStatuses = this.filterStatuses.value?.length;
    }
    if (filterGroup.hasFilter(FilterType.TOTAL_AREA)) {
      this.badgeCountArea++;
      this.filterTotalArea = filterGroup.getOwnFilter(FilterType.TOTAL_AREA);
    }
  }

}

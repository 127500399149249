<div class="store-details-page-container container">
  <mtn-key-value-with-placeholder key="Name" [value]="store.name"></mtn-key-value-with-placeholder>
  <mtn-key-value-with-placeholder key="Store Number" [value]="store.number"></mtn-key-value-with-placeholder>
  <mtn-key-value-with-placeholder key="Banner"
                                  [value]="store.banner?.name || 'No Associated Banner'"></mtn-key-value-with-placeholder>
  <div class="container">
    <mtn-key-value key="FIT Category" [whatIsThisText]="Dictionary.tooltipFitCategory">
      <mtn-definition-view [definitionType]="DefinitionType.FIT" [systemName]="store.fit"
                           *ngIf="store.fit; else noFit"></mtn-definition-view>
      <ng-template #noFit>
        <span class="color-muted">No Associated FIT Category</span>
      </ng-template>
    </mtn-key-value>
    <mtn-key-value key="Status" [whatIsThisText]="Dictionary.tooltipStoreStatus">
      <mtn-store-status-view [type]="store.currentStatus.getStatusSystemName()"></mtn-store-status-view>
    </mtn-key-value>
    <mtn-restricted-license-overlay *ngIf="!isLicenseOfLevel(this.wizard.model.licenseType, LicenseType.STANDARD)"
                                    [bannerSize]="Size.EXTRA_SMALL"
                                    [buttonSize]="Size.EXTRA_SMALL"></mtn-restricted-license-overlay>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../core/util/storage.service';
import { AnalyticsEventType } from '../../../google-analytics/analytics-event-type';

@Component({
  selector: 'mtn-administration-landing-page',
  templateUrl: './administration-landing-page.component.html',
  styleUrls: ['./administration-landing-page.component.scss']
})
export class AdministrationLandingPageComponent implements OnInit {

  AnalyticsEventType = AnalyticsEventType;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService) {
  }

  ngOnInit(): void {
  }

  navigateToTab(tab: string): void {
    this.storageService.set(StorageService.ADMIN_LAST_USED_TAB, tab).subscribe();
    this.router.navigate([tab], {relativeTo: this.activatedRoute});
  }

}

<div class="select-hubspot-company-page-container container" fxLayout="column" fxLayoutGap="15px" [formGroup]="form">
  <h3 class="mat-h3 color-primary text-small-caps">Existing HubSpot Records Found</h3>
  <p>We found more than one matching record in HubSpot. Which existing HubSpot Company record should we associate to
    this new Company?</p>
  <mat-radio-group color="primary" formControlName="hubspotId" fxLayout="column" fxLayoutGap="5px">
    <mat-radio-button [value]="null">
      <div fxLayout="column">
        <strong>None</strong>
        <span class="color-muted">(Create a new HubSpot Company)</span>
      </div>
    </mat-radio-button>
    <mat-radio-button *ngFor="let option of options" [value]="option.id">
      <div fxLayout="column">
        <strong>{{option.properties.name}}</strong>
        <span class="color-muted">{{option.getContactsAsString()}}</span>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>

import { Wizard } from '../../wizard/wizard';
import { UserProfile } from '../../user-profile/user-profile';
import { EditUserPageComponent } from './edit-user-page/edit-user-page.component';

export class EditUserWizard extends Wizard<EditUserWizardModel> {
  key = 'edit-user';
  pageTypeMap = {
    'edit-user': EditUserPageComponent
  };
  startingPageKey = 'edit-user';

  constructor() {
    super();
    this.width = 450;
  }
}

export interface EditUserWizardModel {
  isEdited?: boolean;
  user: UserProfile;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapSearchComponent } from '../../map-search/map-search.component';
import { HelpDashboardComponent } from '../../help/help-dashboard/help-dashboard.component';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { ProfileComponent } from '../../profile/profile/profile.component';
import { AuthenticatingComponent } from '../../auth/authentication/authenticating/authenticating.component';
import { PublicLandingPageComponent } from '../../public/public-landing-page/public-landing-page.component';
import { IsAuthenticatedGuard } from '../../auth/authentication/is-authenticated.guard';
import { IsNotAuthenticatedGuard } from '../../auth/authentication/is-not-authenticated.guard';
import { UserNotRegisteredComponent } from '../../public/user-not-registered/user-not-registered.component';
import { AdministrationComponent } from '../../administration/administration/administration.component';
import { IsAdministratorGuard } from '../../administration/is-administrator.guard';
import {
  CompaniesAdministrationComponent
} from '../../administration/administration/companies-administration/companies-administration.component';
import {
  UsersAdministrationComponent
} from '../../administration/administration/users-administration/users-administration.component';
import {
  AdministrationLandingPageComponent
} from '../../administration/administration/administration-landing-page/administration-landing-page.component';
import {
  CompanyAdministrationComponent
} from '../../administration/administration/company-administration/company-administration.component';
import {
  UserAdministrationComponent
} from '../../administration/administration/user-administration/user-administration.component';
import { StoreDetailPageComponent } from '../../detail/store-detail-page/store-detail-page.component';
import { RegistrationComponent } from '../../public/registration/registration.component';
import { NotAvailableComponent } from '../../public/not-available/not-available.component';
import {
  SelfServiceAdministrationComponent
} from '../../administration/administration/self-service-administration/self-service-administration.component';
import { InvitationComponent } from '../../public/invitation/invitation.component';
import { CollectionsPageComponent } from '../../collections/collections-page/collections-page.component';
import { CollectionPageComponent } from '../../collections/collection-page/collection-page.component';
import { CollectionsListPageComponent } from '../../collections/collections-list-page/collections-list-page.component';
import { SampleStorePageComponent } from '../../detail/sample-store-page/sample-store-page.component';
import { MaintenanceComponent } from '../../maintenance/maintenance/maintenance.component';


const routes: Routes = [
  {path: '', component: PublicLandingPageComponent, canActivate: [IsNotAuthenticatedGuard]},
  {
    path: 'administration',
    component: AdministrationComponent,
    canActivate: [IsAdministratorGuard],
    children: [
      {
        path: '',
        component: AdministrationLandingPageComponent
      },
      {
        path: 'company',
        component: CompaniesAdministrationComponent
      },
      {
        path: 'company/:uuid',
        component: CompanyAdministrationComponent
      },
      {
        path: 'user',
        component: UsersAdministrationComponent
      },
      {
        path: 'user/:uuid',
        component: UserAdministrationComponent
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {path: 'authenticating', component: AuthenticatingComponent, canActivate: [IsNotAuthenticatedGuard]},
  {
    path: 'collection',
    component: CollectionsPageComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: '',
        component: CollectionsListPageComponent
      },
      {
        path: ':uuid',
        component: CollectionPageComponent
      }
    ]
  },
  {path: 'dashboard', component: DashboardComponent, canActivate: [IsAuthenticatedGuard]},
  {path: 'detail/store/:uuid', component: StoreDetailPageComponent, canActivate: [IsAuthenticatedGuard]},
  {path: 'help', component: HelpDashboardComponent, canActivate: [IsAuthenticatedGuard]},
  {path: 'invitation/:uuid', component: InvitationComponent},
  {path: 'maintenance', component: MaintenanceComponent},
  {path: 'map-search', component: MapSearchComponent, canActivate: [IsAuthenticatedGuard]},
  {path: 'not-available', component: NotAvailableComponent},
  {path: 'sample-store', component: SampleStorePageComponent},
  {path: 'not-registered', component: UserNotRegisteredComponent, canActivate: [IsNotAuthenticatedGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [IsAuthenticatedGuard]},
  {path: 'registration', component: RegistrationComponent, canActivate: [IsNotAuthenticatedGuard]},
  {
    path: 'self-service-administration',
    component: SelfServiceAdministrationComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MtnRoutingModule {
}

<div class="area-subscriptions-list-container container" fxLayout="column" fxLayoutGap="15px">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">Area Subscriptions <span
        *ngIf="dataSource.data?.length">({{dataSource.data.length}})</span></h2>
      <mtn-what-is-this
        text="An Area Subscription represents a geographic area, such as a CBSA, for which this company receives automatic sales volume updates. These are negotiated into the company's contract by the Sales Team, and must not be changed without express instructions from the Sales Team to do so."></mtn-what-is-this>
      <div fxFlex></div>
      <mtn-text-field placeholder="Filter" [form]="filterForm" controlName="filter"
                      noErrorPadding="true"></mtn-text-field>
      <mtn-icon-button color="accent" (onClick)="openAddAreaSubscriptionWizard()" matTooltip="Add a Subscription">
        <fa-icon icon="plus"></fa-icon>
      </mtn-icon-button>
    </div>
    <hr/>
  </div>
  <mtn-simple-panel color="purple">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <fa-icon icon="exclamation-triangle" size="2x"></fa-icon>
      <span>Area Subscriptions are negotiated into the client's written contract, and must not be altered without express instruction from the Sales Team to do so.</span>
    </div>
  </mtn-simple-panel>
  <div class="table-container container">
    <div class="no-data-container" fxLayout="row" fxLayoutAlign="center center" *ngIf="!dataSource?.data?.length">No
      Results Available
    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
           [hidden]="!dataSource?.data?.length">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          <span>{{row.name}} <span *ngIf="row.abbreviation">({{row.abbreviation}})</span></span>
        </td>
      </ng-container>

      <!-- Shape Type Column -->
      <ng-container matColumnDef="shapeType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let row">
          <span>{{row.shapeType}}</span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="end start">
            <mtn-icon-button color="warn" matTooltip="Remove Subscription" (onClick)="handleRemoveSubscription(row)">
              <fa-icon icon="ban"></fa-icon>
            </mtn-icon-button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="clickable" *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3"><strong>0</strong> of <strong>{{dataSource?.data?.length}}</strong>
          Results Match Current Filter
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [showFirstLastButtons]="true" [hidePageSize]="true"
                   [hidden]="dataSource.data?.length <= 10"></mat-paginator>
  </div>
</div>

import { Wizard } from '../../../../core/wizard/wizard';
import { Store } from '../../../../core/models';
import { RequestMoreStoreInsightFormPageComponent } from './request-more-store-insight-form-page/request-more-store-insight-form-page.component';
import { UserProfile } from '../../../../core/user-profile/user-profile';

export class RequestMoreStoreInsightWizard extends Wizard<RequestMoreStoreInsightWizardModel> {
  key = 'request-more-store-insight';
  pageTypeMap = {
    'request-more-store-insight-form': RequestMoreStoreInsightFormPageComponent
  };
  startingPageKey = 'request-more-store-insight-form';
}

export interface RequestMoreStoreInsightWizardModel {
  store: Store;
  userProfile: UserProfile;
}

import { Action, createReducer, on, select, Store as NgrxStore } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { NavigationToggleCollapsed } from './navigation-actions';

export const NAVIGATION_STATE_KEY = 'navigationState';

export interface NavigationState {
  isCollapsed: boolean;
}

const initialState: NavigationState = {
  isCollapsed: false
};

export const selectNavigationState = (store: NgrxStore<any>) => store.pipe(select((state: any) => state.navigationState), filter(state => state !== undefined));

const _navigationReducer = createReducer(
  initialState,
  on(NavigationToggleCollapsed, (state, {isCollapsed}) => ({...state, isCollapsed: isCollapsed}))
);

export function navigationReducer(state: NavigationState | undefined, action: Action) {
  return _navigationReducer(state, action);
}

import { AuditableEntity } from '../auditable-entity';
import { SpatialShapeType } from '../depot/spatial-shape-type.enum';
import { FeatureCollection } from '../models';

export class AreaSubscription extends AuditableEntity {

  abbreviation: string;
  fips: string;
  geoJson: FeatureCollection;
  name: string;
  shapeType: SpatialShapeType;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);

      if (raw.geoJson) {
        this.geoJson = new FeatureCollection(raw.geoJson);
      }
      if (raw.shapeType) {
        // @ts-ignore
        this.shapeType = SpatialShapeType[<string>raw.shapeType];
      }
    }
  }
}

<div class="license-history-panel-container container">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">License History</h2>
      <div fxFlex></div>
      <div class="clickable" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
           (click)="toggleSort()">
        <fa-icon [icon]="sort === 'asc' ? 'sort-amount-up' : 'sort-amount-down'"></fa-icon>
        <span>Sort</span>
      </div>
    </div>
    <hr/>
  </div>
  <div class="versions-container container" fxLayout="column" fxLayoutGap="30px">
    <div class="version-container container" *ngFor="let version of versions | slice:0:maxCount; let isFirst = first;"
         fxLayout="row"
         fxLayoutGap="15px">
      <mtn-user-initials-avatar [user]="version.userProfile"></mtn-user-initials-avatar>
      <div class="diff-container" fxLayout="column" fxLayoutGap="3px">

        <span
          *ngIf="version.license.version === 1; else updateMessage"><strong>{{version.userProfile.getDisplayName()}}</strong> created the License </span>
        <ng-template #updateMessage>
          <span><strong>{{version.userProfile.getDisplayName()}}</strong> updated the <strong>{{version.fieldString}}</strong></span>
        </ng-template>

        <div fxLayout="row" fxLayoutGap="15px" *ngFor="let diff of version.diffs">
          <div
            class="key-container color-muted">{{diff.from || (version.license.version === 1 ? diff.field : 'None')}}</div>
          <fa-icon icon="long-arrow-alt-right"></fa-icon>
          <div [ngClass]="{'color-muted': !diff.to}" fxFlex>{{diff.to || 'None'}}</div>
        </div>
      </div>
      <div fxFlex></div>
      <div>{{version.license.updatedDate | insightsDate}}</div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end start">
    <a href="javascript:void(0)" class="color-help" (click)="maxCount = 1000" *ngIf="versions?.length > maxCount">View
      More</a>
  </div>
</div>

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../../service/insights-rest-service';
import { FileDownloadService } from '../../file/file-download.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomExtractionService extends InsightsRestService {

  protected path = '/extraction/custom';

  constructor(private fileDownloadService: FileDownloadService,
              private http: HttpClient) {
    super();
  }

  extract(): Observable<any> {
    const url = this.buildUrl(this.path);

    const headers = new HttpHeaders()
      .set('Accept', 'text/csv');

    return this.http.get(url, {headers: headers, responseType: 'blob'})
      .pipe(tap((data: Blob) => {
        this.fileDownloadService.downloadBlob(data, 'text/csv', 'custom-extraction.csv');
      }));
  }
}

<div class="sales-volume-history-page-container container" fxLayout="column" fxLayoutGap="15px">
  <p>The Est. Sales Volume records below are created either by the MTN Store Volume Estimation Engine, or by our Analyst
    Team when they visit and model a market. As such, available values may fall in inconsistent intervals, but may still
    be useful for constructing a sales trend over time.</p>
  <p class="color-muted">Per your preferences, you are viewing <strong
    style="color: white">{{volumeDisplayValue}}</strong> Est. Sales Volume, and are using <strong
    style="color: white">{{areaDisplayValue}}</strong> to calculate the Est. Sales/sqft.</p>
  <hr/>
  <div class="text-center color-muted" *ngIf="!dataSource?.data.length">No Volumes Estimates Available
  </div>
  <div class="table-container container condensed-table">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="volumeDate" matSortDirection="desc"
           *ngIf="dataSource?.data.length">
      <ng-container matColumnDef="volumeDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estimate Date</th>
        <td mat-cell *matCellDef="let volume">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span>{{volume.date | insightsDate}}</span>
            <mtn-sales-volume-tag [volume]="volume"></mtn-sales-volume-tag>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="salesVolume">
        <th class="column-right" mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Est. Sales
          Volume
        </th>
        <td class="column-right" mat-cell *matCellDef="let volume">
          <div fxLayout="row" fxLayoutAlign="end start">
            <mtn-sales-volume-view [volume]="volume" [includeSuffix]="false"
                                   *ngIf="volume.total; else notPurchased"></mtn-sales-volume-view>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="salesSqft">
        <th class="column-right" mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Est. Sales /
          Sqft
        </th>
        <td class="column-right" mat-cell *matCellDef="let volume">
          <div fxLayout="row" fxLayoutAlign="end start">
            <mtn-sales-sqft-view [volume]="volume" [includeSuffix]="false"
                                 *ngIf="volume.salesSqft; else doubleDash"></mtn-sales-sqft-view>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSize]="5" [showFirstLastButtons]="true" [length]="dataSource.data?.length"
                   [hidePageSize]="true" [hidden]="dataSource.data?.length <= 5"></mat-paginator>
  </div>
</div>

<ng-template #notPurchased>
  <span class="color-muted">Not Purchased</span>
</ng-template>

<ng-template #doubleDash>
  <span class="color-muted">--</span>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeader } from './http-header.enum';
import { MediaType } from './media-type.enum';

@Injectable()
export class ContentTypeHeaderInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has(HttpHeader.CONTENT_TYPE)) {
      let config: any = {
        setHeaders: {}
      };

      config.setHeaders[HttpHeader.CONTENT_TYPE] = MediaType.APPLICATION_JSON;

      return next.handle(request.clone(config));
    } else {
      return next.handle(request);
    }
  }
}

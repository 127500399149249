<div class="toggle-add-on-page-container container">
  <mtn-simple-panel color="purple">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <fa-icon icon="exclamation-triangle" size="2x"></fa-icon>
      <div>Add-Ons are negotiated into the client's written contract, and must not be altered without express
        instruction from the Sales Team to do so.
      </div>
    </div>
  </mtn-simple-panel>
  <p>You are about to {{isEnabling ? 'enable' : 'disable'}} the {{wizard.model.addOn.typeDefinition.displayName}} Add-On
    for this company. Are you sure?</p>
</div>

import { Component, Input, OnChanges } from '@angular/core';
import { BaseComponent } from '../../base-component';
import { SalesSqftDisplayType } from '../../user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from '../../user-preferences/sales-volume-display-type.enum';
import { StoreVolume } from '../volume/store-volume';
import { formatAsCurrency } from '../../util/string-utils';

@Component({
  selector: 'mtn-sales-sqft-view',
  templateUrl: './sales-sqft-view.component.html',
  styleUrls: ['./sales-sqft-view.component.scss']
})
export class SalesSqftViewComponent extends BaseComponent implements OnChanges {

  private readonly SUFFIX_ANNUALLY = 'Annually';
  private readonly SUFFIX_SALES_AREA = 'Sales Area';
  private readonly SUFFIX_TOTAL_AREA = 'Total Area';
  private readonly SUFFIX_WEEKLY = 'Weekly';

  @Input()
  includeSuffix = true;
  @Input()
  volume: StoreVolume;

  areaSuffix: string;
  volumeSuffix: string;
  value: string;

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this.initValue();
  }

  private initValue(): void {
    if (this.volume) {
      this.areaSuffix = this.volume.salesSqftDisplayType === SalesSqftDisplayType.TOTAL_AREA ? this.SUFFIX_TOTAL_AREA : this.SUFFIX_SALES_AREA;
      this.volumeSuffix = this.volume.salesVolumeDisplayType === SalesVolumeDisplayType.ANNUAL ? this.SUFFIX_ANNUALLY : this.SUFFIX_WEEKLY;
      this.value = formatAsCurrency(this.volume.salesSqft);
    } else {
      this.value = null;
    }
  }

}

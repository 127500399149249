export enum FitType {
  ALDI = 'ALDI',
  ASIAN = 'ASIAN',
  CLUB = 'CLUB',
  CONVENTIONAL = 'CONVENTIONAL',
  DISCOUNT = 'DISCOUNT',
  HISPANIC = 'HISPANIC',
  NATURAL_FOODS = 'NATURAL_FOODS',
  QUALITY_SERVICE = 'QUALITY_SERVICE',
  SAVE_A_LOT = 'SAVE_A_LOT',
  SPROUTS = 'SPROUTS',
  SUPERCENTER = 'SUPERCENTER',
  TRADER_JOES = 'TRADER_JOES',
  WAREHOUSE = 'WAREHOUSE',
  WHOLE_FOODS = 'WHOLE_FOODS'
}

export namespace FitType {

  export function values(): FitType[] {
    // @ts-ignore
    return Object.keys(FitType).filter((key: any) => isNaN(key) && key !== 'values').map((key: string) => FitType[key]);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WebAuth } from 'auth0-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store as NgrxStore } from '@ngrx/store';
import { UserState } from '../user-state';
import { UserSetCurrentUser, UserSetMtnAuthToken, UserSetRedirectUrl, UserSetUserPreferences } from '../user-actions';
import { Observable, Observer } from 'rxjs';
import { MtnAccessToken } from './mtn-access-token';
import { TokenResponse } from './token-response';
import { map } from 'rxjs/operators';
import { StorageService } from '../../core/util/storage.service';
import { UserProfileService } from '../../core/user-profile/user-profile.service';
import { UserPreferences } from '../../core/user-preferences/user-preferences';
import { WizardRunnerService } from '../../core/wizard/wizard-runner.service';
import { WelcomeWizard } from './welcome-wizard/welcome-wizard';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private auth0: WebAuth = new WebAuth({
    clientID: environment.AUTH_CONFIG.clientID,
    domain: environment.AUTH_CONFIG.domain,
    responseType: 'token id_token',
    audience: 'mtn-rest-api',
    redirectUri: environment.AUTH_CONFIG.callbackURL,
    scope: 'openid email profile'
  });

  constructor(private http: HttpClient,
              private router: Router,
              private ngrxStore: NgrxStore<UserState>,
              private storageService: StorageService,
              private userProfileService: UserProfileService,
              private wizardRunner: WizardRunnerService) {
  }

  clearAuthentication(): void {
    this.storageService.remove(StorageService.AUTH_CURRENT_USER).subscribe();
    this.storageService.remove(StorageService.AUTH_TOKEN).subscribe();
    this.ngrxStore.dispatch(UserSetMtnAuthToken({token: null}));
    this.ngrxStore.dispatch(UserSetCurrentUser({currentUser: null}));
  }

  public continueAuthentication(auth0Hash: string): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      this.auth0.parseHash({hash: auth0Hash}, ((error, result) => {
        if (result && result.accessToken) {
          this.authenticateWithMtn(result.accessToken)
            .subscribe((mtnAccessToken: MtnAccessToken) => {
              this.setAuthentication(mtnAccessToken);

              this.userProfileService.findOneUserPreferences(mtnAccessToken.getCurrentUser().uuid)
                .subscribe((userPreferences: UserPreferences) => {
                  this.ngrxStore.dispatch(UserSetUserPreferences({preferences: userPreferences}));

                  if (!userPreferences.isWelcomeWizardComplete) {
                    const wizard = new WelcomeWizard();
                    wizard.model = {
                      userProfile: mtnAccessToken.getCurrentUser(),
                      userPreferences: userPreferences
                    };
                    this.wizardRunner.run(wizard);
                  }

                  observer.next(!!mtnAccessToken);
                });
            }, (err) => {
              if (err.status === 401 || err.status === 403) {
                this.router.navigate(['not-registered']);
              } else {
                this.router.navigate(['not-available']);
              }
            });
        } else if (error) {
          observer.error(error);
          this.router.navigate(['']);
        } else {
          observer.next(false);
        }
      }));
    });
  }

  public isAuthenticated(): Observable<boolean> {
    return this.storageService.get(StorageService.AUTH_TOKEN)
      .pipe(map((cachedToken: MtnAccessToken) => {
        return cachedToken ? new MtnAccessToken(cachedToken).isValid() : false;
      }));
  }

  public loadAuthenticationFromStorage(): void {
    this.storageService.get(StorageService.AUTH_TOKEN)
      .subscribe((cachedToken: MtnAccessToken) => {
        if (cachedToken) {
          cachedToken = new MtnAccessToken(cachedToken);
          if (cachedToken.isValid()) {
            this.ngrxStore.dispatch(UserSetMtnAuthToken({token: cachedToken}));
            this.ngrxStore.dispatch(UserSetCurrentUser({currentUser: cachedToken.getCurrentUser()}));
            this.userProfileService.findOneUserPreferences(cachedToken.getCurrentUser().uuid)
              .subscribe((userPreferences: UserPreferences) => {
                this.ngrxStore.dispatch(UserSetUserPreferences({preferences: userPreferences}));
              });
          } else {
            this.clearAuthentication();
          }
        }
      });
  }

  public logOut(): void {
    this.clearAuthentication();
    this.router.navigate(['']).then(() => {
      setTimeout(() => window.scrollTo(0, 0), 50);
    });
  }

  public openAuth0AuthenticationDialog(): void {
    this.auth0.authorize();
  }

  public redirect(path: string): void {
    if (path && path !== '') {
      try {
        const parsedUri = decodeURI(path).split('?');
        const queryParams: any = {};
        if (parsedUri.length > 1) {
          const params = parsedUri[1].split('&');
          params.forEach(param => {
            const keyValue = param.split('=');
            queryParams[keyValue[0]] = keyValue[1];
          });
        }
        this.router.navigate([parsedUri[0]], {queryParams: queryParams})
          .then(() => this.storageService.remove(StorageService.AUTH_REDIRECT_URL).subscribe());
      } catch (err) {
        console.warn(err);
        this.router.navigate([''])
          .then(() => this.storageService.remove(StorageService.AUTH_REDIRECT_URL).subscribe());
      }
    } else {
      this.router.navigate([environment.defaultLandingPage])
        .then(() => this.storageService.remove(StorageService.AUTH_REDIRECT_URL).subscribe());
    }
  }

  public renewMtnAccessToken(token: string): Observable<boolean> {
    const url = `${environment.SERVICE_HOST}/api/insights/auth/renew`;
    const headers = this.buildAuthorizationHeader(token);

    return this.http.post<TokenResponse>(url, null, {headers: headers})
      .pipe(map((response: TokenResponse) => {
        if (response && response.token) {
          this.setAuthentication(new MtnAccessToken(response));
        }
        return response && !!response.token;
      }));
  }

  public setRedirectUrl(redirectUrl: string): void {
    if (redirectUrl && redirectUrl.indexOf('not-registered') === -1 && redirectUrl.indexOf('maintenance') !== -1) {
      this.storageService.set(StorageService.AUTH_REDIRECT_URL, redirectUrl).subscribe();
      this.ngrxStore.dispatch(UserSetRedirectUrl({url: redirectUrl}));
    }
  }

  private authenticateWithMtn(auth0AccessToken: string): Observable<MtnAccessToken> {
    const url = `${environment.SERVICE_HOST}/api/insights/auth/authenticate`;
    const headers = this.buildAuthorizationHeader(auth0AccessToken);

    return this.http.post<TokenResponse>(url, null, {headers: headers})
      .pipe(map((response: TokenResponse) => {
        if (response && response.token) {
          return new MtnAccessToken(response);
        }
        throw new Error('Unexpected response from authentication!');
      }));
  }

  private buildAuthorizationHeader(auth0AccessToken: string): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${auth0AccessToken}`
    });
  }

  private setAuthentication(mtnAccessToken: MtnAccessToken): void {
    this.storageService.set(StorageService.AUTH_TOKEN, mtnAccessToken).subscribe();
    this.ngrxStore.dispatch(UserSetMtnAuthToken({token: mtnAccessToken}));
    this.ngrxStore.dispatch(UserSetCurrentUser({currentUser: mtnAccessToken.getCurrentUser()}));
  }

}

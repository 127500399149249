import { Wizard } from '../wizard/wizard';
import { Observable } from 'rxjs';
import { ConfirmActionPageComponent } from './confirm-action-page/confirm-action-page.component';

export class ConfirmActionWizard extends Wizard<ConfirmActionWizardModel> {
  key = 'confirm-action';
  pageTypeMap = {
    'confirm-action': ConfirmActionPageComponent
  };
  startingPageKey = 'confirm-action';
}

export interface ConfirmActionWizardModel {
  onConfirm: Observable<any>;
  result?: boolean;
  text: string;
  title: string;
}

import * as _ from 'lodash';
import { KeyValuePair } from './key-value-pair';
import { round } from './math-utils';
import * as moment from 'moment';

export const GENERIC_ERROR_MESSAGE = 'Something went wrong! Please contact an Administrator to report the problem.';
export const EXCLUSIVE_FILTER_DEFINITION = 'An exclusive filter operates as a blacklist, excluding results found in the given group or range.';

export function buildKeyValueTooltipText(rows: KeyValuePair[], keyValueGap: number = 2, truncatedLength: number = 30) {
  const keys: string[] = rows.map(row => row.key);
  const keyPadLength = Math.max(...keys.map(key => key.length)) + keyValueGap;
  return rows.map(row => padLeft(row.key, keyPadLength) + truncate(row.value, truncatedLength)).join('\n');
}

export function convertBooleanToYesNo(bool: boolean): string {
  return bool ? 'Yes' : 'No';
}

export function convertToNth(value: number): string {
  const valueString = value.toString();
  if (valueString.endsWith('1') && !valueString.endsWith('11')) {
    return `${valueString}st`;
  } else if (valueString.endsWith('2') && !valueString.endsWith('12')) {
    return `${valueString}nd`;
  } else if (valueString.endsWith('3') && !valueString.endsWith('13')) {
    return `${valueString}rd`;
  } else {
    return `${valueString}th`;
  }
}

export function formatAsCurrency(value: number = 0, includeCents = true): string {
  const fixed = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: includeCents ? 2 : 0
  });
  return `${fixed}`;
}

export function formatSecondsAsDuration(seconds: number): string {
  const duration = moment.duration(seconds, 'seconds');
  let value = '';

  if (duration.days()) {
    value += `${duration.days()}d `;
  }
  if (duration.hours()) {
    value += `${duration.hours()}h `;
  }
  if (duration.minutes()) {
    value += `${duration.minutes()}m `;
  }
  if (duration.seconds()) {
    value += `${duration.seconds()}s`;
  }

  return value.trim();
}

export function formatNumericalShorthand(value: number = 0, precision: number = 2): string {
  if (value >= 1000000000) {
    return round(value / 1000000000, precision) + 'b';
  } else if (value >= 1000000) {
    return round(value / 1000000, precision) + 'm';
  } else if (value >= 1000) {
    return round(value / 1000, precision) + 'k';
  } else {
    return value?.toString() || '';
  }
}

export function padLeft(value: string, size: number): string {
  return _.padEnd(value, size);
}

export function truncate(value: string, size: number): string {
  return _.truncate(value, {length: size});
}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../wizard/wizard-page-component';
import { ConfirmActionWizard } from '../confirm-action-wizard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-confirm-action',
  templateUrl: './confirm-action-page.component.html',
  styleUrls: ['./confirm-action-page.component.scss']
})
export class ConfirmActionPageComponent extends WizardPageComponent<ConfirmActionWizard> {

  key = 'confirm-action';

  constructor() {
    super();
    this.nextButtonText = 'Yes';
    this.closeButtonText = 'No';
  }

  onLoad(): Observable<any> {
    this.title = this.wizard.model.title;
    this.subheaderText = this.wizard.model.text;

    this.onNext = () => {
      return this.wizard.model.onConfirm
        .pipe(map(() => {
          this.wizard.model.result = true;
          return null;
        }));
    }

    return super.onLoad();
  }

}

import { Wizard } from '../../../../../../core/wizard/wizard';
import { ShoppingCenter } from '../../../../../../core/models';
import { ShoppingCenterDetailsPageComponent } from './shopping-center-details-page/shopping-center-details-page.component';

export class ShoppingCenterDetailsWizard extends Wizard<ShoppingCenterDetailsWizardModel> {
  key = 'shopping-center-details';
  pageTypeMap = {
    'shopping-center-details': ShoppingCenterDetailsPageComponent
  };
  startingPageKey = 'shopping-center-details'
}

export interface ShoppingCenterDetailsWizardModel {
  shoppingCenter: ShoppingCenter;
}

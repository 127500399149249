import { Component, ViewChild } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { SalesVolumeHistoryWizard } from '../sales-volume-history-wizard';
import { Store } from '../../../../../core/models';
import { Observable } from 'rxjs';
import { SalesSqftDisplayType } from '../../../../../core/user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from '../../../../../core/user-preferences/sales-volume-display-type.enum';
import { StoreService } from '../../../../../core/store/store.service';
import { tap } from 'rxjs/operators';
import { StoreVolume } from '../../../../../core/store/volume/store-volume';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../../core/date/insights-date.pipe';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'mtn-sales-volume-history-page',
  templateUrl: './sales-volume-history-page.component.html',
  styleUrls: ['./sales-volume-history-page.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class SalesVolumeHistoryPageComponent extends WizardPageComponent<SalesVolumeHistoryWizard> {

  key = 'sales-volume-history';

  areaDisplayValue: string;
  dataSource = new MatTableDataSource<StoreVolume>();
  displayedColumns: string[] = ['volumeDate', 'salesVolume', 'salesSqft'];
  store: Store;
  volumeDisplayValue: string;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  constructor(private storeService: StoreService) {
    super();
    this.title = 'Sales Volume History';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.store = this.wizard.model.store;
    this.areaDisplayValue = this.wizard.model.userPreferences.salesSqftDisplayMode === SalesSqftDisplayType.SALES_AREA ? 'Sales Area' : 'Total Area';
    this.volumeDisplayValue = this.wizard.model.userPreferences.salesVolumeDisplayMode === SalesVolumeDisplayType.ANNUAL ? 'Annual' : 'Weekly';

    return this.loadVolumes();
  }

  private initTable(): void {
    setTimeout(() => {
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 50);

    this.dataSource.sortingDataAccessor = (data: StoreVolume, header: string): any => {
      if (header === 'volumeDate') {
        return data.date;
      } else if (header === 'salesVolume') {
        return data.total;
      } else if (header === 'salesSqft') {
        return data.salesSqft;
      }
      return null;
    };
  }

  private loadVolumes(): Observable<any> {
    return this.storeService.findOnesVolumes(this.store.uuid)
      .pipe(tap((results: StoreVolume[]) => {
        if (results.length) {
          this.initTable();
          this.dataSource.data = results;
        }
      }));
  }

}

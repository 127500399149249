<div class="shopping-center-subpanel-container container">
  <div class="content-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
       *ngIf="isExpanded; else centeredIcon"
       [matTooltip]="breakpoint === Breakpoints.XLarge || breakpoint === Breakpoints.Large ? 'Click for Details' : ''">
    <fa-icon icon="bags-shopping" size="2x"></fa-icon>
    <div class="shopping-center-info-container" *ngIf="isExpanded && shoppingCenter">
      <h2 class="mat-h2 text-small-caps no-bottom-margin">{{shoppingCenter.name || 'Unnamed Shopping Center'}}</h2>
      <div class="color-muted" fxLayout="row" fxLayoutGap="5px">
        <span>{{shoppingCenter.ownerCompany?.name || 'Unknown Owner'}}</span>
        <span>-</span>
        <mtn-definition-view [definitionType]="DefinitionType.SHOPPING_CENTER_TYPE" [systemName]="shoppingCenter.type"
                             *ngIf="shoppingCenter.type; else noType"></mtn-definition-view>
        <ng-template #noType>
          <span>Unknown Shopping Center Type</span>
        </ng-template>
      </div>
    </div>
    <div fxFlex></div>
    <div mtnClickTrap>
      <mtn-icon-button color="accent" *ngIf="breakpoint !== Breakpoints.XLarge && breakpoint !== Breakpoints.Large"
                       matTooltip="Click for Details" (onClick)="openDetailsWizard()">
        <fa-icon icon="ellipsis-h"></fa-icon>
      </mtn-icon-button>
    </div>
  </div>
  <ng-template #centeredIcon>
    <div class="content-container" fxLayout="row" fxLayoutAlign="center center">
      <fa-icon icon="bags-shopping" size="2x"></fa-icon>
    </div>
  </ng-template>
</div>

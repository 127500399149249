import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { UserProfile } from './user-profile';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Observable, of } from 'rxjs';
import { DefinitionState, selectDefinitionState } from '../definition/definition-state';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { DefinitionCacheUserProfileAction } from '../definition/definition-actions';
import { UserPreferences } from '../user-preferences/user-preferences';
import { Collection } from '../federation/collection/collection';
import * as _ from 'lodash';
import { SavedSearch } from '../federation/filter/saved-search';
import { CollectionService } from '../federation/collection/collection.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends CrudService<UserProfile> {

  protected path = '/user-profile';

  constructor(protected collectionService: CollectionService,
              protected http: HttpClient,
              protected store: Store<AppState>) {
    super(http);
  }

  findOneCached(uuid: string): Observable<UserProfile> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((definitionState: DefinitionState) => {
        const cachedResult = definitionState.userProfiles.get(uuid);
        if (cachedResult) {
          return of(cachedResult);
        } else {
          return this.findOne(uuid)
            .pipe(tap((result: UserProfile) => {
              this.store.dispatch(DefinitionCacheUserProfileAction({user: result}));
            }));
        }
      }));
  }

  findOneUserPreferences(uuid: string): Observable<UserPreferences> {
    const url = this.buildUrl(`/${uuid}/user-preferences`);
    return this.http.get(url)
      .pipe(map((result: any) => new UserPreferences(result)));
  }

  findOnesCollections(uuid: string, query: string, includeShared: boolean, params?: HttpParams): Observable<Pageable<Collection>> {
    const url = this.buildUrl(`/${uuid}/collection`);
    params = this.buildDefaultPaginationSupportedParams(params);
    if (includeShared) {
      params = params.set('include-shared', 'true');
    }
    if (query) {
      params = params.set('q', query);
    }

    return this.http.get(url, {params})
      .pipe(
        map((raw: any) => new Pageable<Collection>(raw, Collection)),
        tap((results: Pageable<Collection>) => {
          this.collectionService.cacheAll(results.content);
        })
      );
  }

  findOnesFavoriteCollections(uuid: string, params?: HttpParams): Observable<Pageable<Collection>> {
    return this.findOnesCollections(uuid, null, true, params)
      .pipe(map((results: Pageable<Collection>) => {
        results.content = _.filter(results.content, (collection: Collection) => _.includes(collection.favoritedBy, uuid));
        return results;
      }));
  }

  findOnesSavedSearches(uuid: string, params?: HttpParams): Observable<Pageable<SavedSearch>> {
    const url = this.buildUrl(`/${uuid}/saved-search`);
    params = this.buildDefaultPaginationSupportedParams(params);

    return this.http.get(url, {params})
      .pipe(map((raw: any) => new Pageable<SavedSearch>(raw, SavedSearch)));
  }

  protected buildInstance(raw: any): UserProfile {
    return new UserProfile(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<UserProfile> {
    return new Pageable<UserProfile>(raw, UserProfile);
  }
}

import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'mtn-demographic-card',
  templateUrl: './demographic-card.component.html',
  styleUrls: ['./demographic-card.component.scss']
})
export class DemographicCardComponent {

  @Input()
  color: string;
  @Input()
  icon: IconProp;
  @Input()
  label: string;
  @Input()
  secondaryIcon: IconProp;
  @Input()
  value: string;

  constructor() {
  }

}

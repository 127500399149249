import { HubspotObject } from './hubspot-object';

export class HubspotContact extends HubspotObject {
  properties: HubspotContactProperties;

  constructor(raw?: any) {
    super(raw);
    if (raw) {
      if (raw.properties) {
        this.properties = new HubspotContactProperties(raw.properties);
      }
    }
  }

  getDisplayName(): string {
    return `${this.properties.firstname} ${this.properties.lastname}`;
  }
}

export class HubspotContactProperties {
  company: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  website: string;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
    }
  }
}

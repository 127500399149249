import { Wizard } from '../../../core/wizard/wizard';
import { WelcomeWizardStartPageComponent } from './welcome-wizard-start-page/welcome-wizard-start-page.component';
import { WelcomeWizardKeyIndicatorPageComponent } from './welcome-wizard-key-indicator-page/welcome-wizard-key-indicator-page.component';
import { WelcomeWizardVolumePageComponent } from './welcome-wizard-volume-page/welcome-wizard-volume-page.component';
import { WelcomeWizardMapPageComponent } from './welcome-wizard-map-page/welcome-wizard-map-page.component';
import { WelcomeWizardEndPageComponent } from './welcome-wizard-end-page/welcome-wizard-end-page.component';
import { UserPreferences } from '../../../core/user-preferences/user-preferences';
import { UserProfile } from '../../../core/user-profile/user-profile';

export class WelcomeWizard extends Wizard<WelcomeWizardModel> {
  key = 'welcome';
  pageTypeMap = {
    'start': WelcomeWizardStartPageComponent,
    'volume': WelcomeWizardVolumePageComponent,
    'key-indicator': WelcomeWizardKeyIndicatorPageComponent,
    'map': WelcomeWizardMapPageComponent,
    'end': WelcomeWizardEndPageComponent
  };
  startingPageKey = 'start';

  constructor() {
    super();
    this.width = 800;
  }
}

export interface WelcomeWizardModel {
  request?: UserPreferences;
  userPreferences: UserPreferences;
  userProfile: UserProfile;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '../company';
import { CompanyService } from '../company.service';
import { ToastService } from '../../toast/toast.service';
import { catchError, finalize } from 'rxjs/operators';
import { GENERIC_ERROR_MESSAGE } from '../../util/string-utils';
import { BaseComponent } from '../../base-component';
import { UserProfile } from '../../user-profile/user-profile';

@Component({
  selector: 'mtn-company-api-user',
  templateUrl: './company-api-user.component.html',
  styleUrls: ['./company-api-user.component.scss']
})
export class CompanyApiUserComponent extends BaseComponent {

  @Output()
  onUpdate = new EventEmitter<any>();

  @Input()
  company: Company;

  isCreating = false;

  constructor(private companyService: CompanyService,
              private toaster: ToastService) {
    super();
  }

  createApiUser(): void {
    if (!this.isCreating) {
      this.isCreating = true;
      this.companyService.createOnesApiUser(this.company.uuid)
        .pipe(
          catchError((err) => {
            this.toaster.error(GENERIC_ERROR_MESSAGE);
            throw err;
          }),
          finalize(() => this.isCreating = false)
        ).subscribe((result: UserProfile) => {
        if (result) {
          this.toaster.info("Successfully created the API User. This Company may now access the Insights API with its API Key.", 5000);
          this.onUpdate.emit(true);
        }
      });
    }
  }

}

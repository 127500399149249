import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DistanceMatrixRequest } from './distance-matrix-request';
import { DistanceMatrixResponse } from './distance-matrix-response';

@Injectable({
  providedIn: 'root'
})
export class DistanceMatrixService extends InsightsRestService {

  constructor(protected http: HttpClient) {
    super();
  }

  findOne(request: DistanceMatrixRequest): Observable<DistanceMatrixResponse> {
    const url = this.buildUrl('/distance-matrix');

    return this.http.post(url, request)
      .pipe(map((result: any) => new DistanceMatrixResponse(result)));
  }
}

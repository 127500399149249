import { Component, Input, OnChanges } from '@angular/core';
import { ShoppingCenterType } from '../../identity/constant/shopping-center-type.enum';
import { Definition } from '../../definition/definition';
import { DefinitionService } from '../../definition/definition.service';

@Component({
  selector: 'mtn-shopping-center-type-view',
  templateUrl: './shopping-center-type-view.component.html',
  styleUrls: ['./shopping-center-type-view.component.scss']
})
export class ShoppingCenterTypeViewComponent implements OnChanges {

  @Input()
  type: ShoppingCenterType;

  definition: Definition<ShoppingCenterType>;

  constructor(private definitionService: DefinitionService) {
  }

  ngOnChanges(): void {
    this.loadDefinition();
  }

  private loadDefinition(): void {
    this.definitionService.findOneShoppingCenterType(this.type)
      .subscribe((definition: Definition<ShoppingCenterType>) => {
        this.definition = definition;
      });
  }
}

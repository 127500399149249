<div class="geographic-area-selection-list-container container" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" *ngIf="!isLoading">
    <div class="container" [formGroup]="form">
      <div fxLayout="row" fxLayoutGap="15px" mtnClickTrap>
        <mat-button-toggle-group formControlName="selectedForm">
          <mat-button-toggle value="cbsas">CBSA</mat-button-toggle>
          <mat-button-toggle value="states">State</mat-button-toggle>
        </mat-button-toggle-group>
        <button mat-raised-button color="accent" (click)="selectAll()">
          Select All
        </button>
      </div>
    </div>
    <div fxFlex></div>
    <mtn-text-field class="default-field-width" [form]="form" controlName="filter"
                    placeholder="Filter"></mtn-text-field>
  </div>
  <div class="control-container container scrollable" *ngIf="!isLoading">
    <div class="cbsas-container" fxLayout="row wrap" fxLayoutGap="15px" [formGroup]="form.get('cbsas')"
         *ngIf="form.get('selectedForm').value === 'cbsas'; else statesContainer">
      <mat-checkbox [formControlName]="cbsa.fips"
                    *ngFor="let cbsa of cbsas | fipsNameContains : form.get('filter').value"
                    (change)="emitSelectionChange()">{{cbsa.name}}</mat-checkbox>
    </div>
    <ng-template #statesContainer>
      <div class="states-container" fxLayout="row wrap" fxLayoutGap="15px" [formGroup]="form.get('states')">
        <mat-checkbox [formControlName]="state.fips"
                      *ngFor="let state of states | fipsNameContains : form.get('filter').value"
                      (change)="emitSelectionChange()">{{state.name}}</mat-checkbox>
      </div>
    </ng-template>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { Store } from '../../../../../core/models';
import { Breakpoints } from '@angular/cdk/layout';
import { WizardRunnerService } from '../../../../../core/wizard/wizard-runner.service';
import { StoreDetailsWizard } from './store-details-wizard/store-details-wizard';
import { AuthorizationAwareComponent } from '../../../../../core/authorization-aware-component';
import { AppState } from '../../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';

@Component({
  selector: 'mtn-store-subpanel',
  templateUrl: './store-subpanel.component.html',
  styleUrls: ['./store-subpanel.component.scss']
})
export class StoreSubpanelComponent extends AuthorizationAwareComponent {

  @Input()
  breakpoint: string;
  @Input()
  isExpanded = true;
  @Input()
  store: Store;

  Breakpoints = Breakpoints;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
  }

  openDetailsWizard(): void {
    const wizard = new StoreDetailsWizard();
    wizard.model = {
      licenseType: this.licenseType,
      store: this.store
    };

    this.wizardRunner.run(wizard);
  }

}

<div class="welcome-wizard-map-page-container container" fxLayout="row" fxLayoutGap="15px" [formGroup]="form">
  <div class="map-preferences-container container" fxFlex fxLayout="column">
    <p>{{Dictionary.labelMapDefaultModePreferenceDescription}}</p>
    <mat-radio-group formControlName="mapDefaultMode" fxLayout="column" fxLayoutGap="5px" color="primary">
      <mat-radio-button [value]="MapMode.CLASSIC">Classic</mat-radio-button>
      <mat-radio-button [value]="MapMode.DARK">Dark</mat-radio-button>
    </mat-radio-group>
    <br/>
    <p>{{Dictionary.labelMapDefaultTypePreferenceDescription}}</p>
    <mat-radio-group formControlName="mapDefaultType" fxLayout="column" fxLayoutGap="5px" color="primary">
      <mat-radio-button [value]="MapTypeId.ROADMAP">Road</mat-radio-button>
      <mat-radio-button [value]="MapTypeId.HYBRID">Satellite</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <p class="color-muted">Example</p>
    <div class="map-container container">
      <mtn-map [options]="mapOptions" *ngIf="mapOptions" (ready)="onMapReady($event)"></mtn-map>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserProfile } from '../user-profile';
import { SpinnerComponent } from '../../util/spinner/spinner.component';
import { Router } from '@angular/router';
import { UserProfileService } from '../user-profile.service';
import { AuthorizationAwareComponent } from '../../authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';

@Component({
  selector: 'mtn-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent extends AuthorizationAwareComponent implements OnInit {

  @Output()
  onDelete = new EventEmitter<UserProfile>();

  @Input()
  banner: string;
  @Input()
  isDeleteEnabled = false;
  @Input()
  isNavigationEnabled = false;
  @Input()
  user: UserProfile;
  @Input()
  userUuid: string;

  showDeleteButton = false;

  @ViewChild(SpinnerComponent)
  spinner: SpinnerComponent;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private router: Router,
              private userService: UserProfileService) {
    super(ngrxStore);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    if (this.userUuid) {
      this.userService.findOne(this.userUuid)
        .subscribe((result: UserProfile) => {
          this.user = result;
        });
    }
    //Cannot delete self
    if (this.isDeleteEnabled) {
      this.isDeleteEnabled = this.userUuid !== this.currentUser.uuid;
    }
    //Cannot navigate to users if not on the administration pages
    if (this.isNavigationEnabled) {
      this.isNavigationEnabled = window.location.href.indexOf('/administration') !== -1;
    }
  }

  handleDeleteClicked(): void {
    this.onDelete.emit(this.user);
  }

  navigateToUserDetails(): void {
    if (this.isNavigationEnabled) {
      this.router.navigate(['administration', 'user', this.user.uuid]);
    }
  }

}

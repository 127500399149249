import { Component } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { DetailState, selectDetailState } from '../../detail-state';
import { AnalystRatingResponse } from '../../../core/store/rating/analyst-rating-response';
import { StoreServices } from '../../../core/store/store-services';
import { RequestMoreStoreInsightWizard } from '../store-alerts-panel/request-more-insight-wizard/request-more-store-insight-wizard';
import { Store } from '../../../core/models';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';

@Component({
  selector: 'mtn-store-additional-insights-panel',
  templateUrl: './store-additional-insights-panel.component.html',
  styleUrls: ['./store-additional-insights-panel.component.scss']
})
export class StoreAdditionalInsightsPanelComponent extends AuthorizationAwareComponent {

  cardDescriptionRatings = 'Not yet evaluated';
  cardDescriptionServices = 'Not yet evaluated';
  cardTitleRatings = 'No Analyst Ratings Available';
  cardTitleServices = 'Unknown Services';

  ratings: AnalystRatingResponse;
  services: StoreServices;
  store: Store;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.subscribeToDetailState();
  }

  openRequestMoreInsightWizard(): void {
    const wizard = new RequestMoreStoreInsightWizard();
    wizard.model = {
      store: this.store,
      userProfile: this.currentUser
    };

    this.wizardRunner.run(wizard);
  }

  private subscribeToDetailState(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .subscribe((state: DetailState) => {
          this.ratings = state.analystRating;
          this.services = state.services;
          this.store = state.store;
        })
    );
  }

}

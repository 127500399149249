import { BasicEntity } from '../../basic-entity';

export class CompanyCollection extends BasicEntity {

  companyUuid: string;
  isEditable = false;
  isViewable = false;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
    }
  }
}

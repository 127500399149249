<div class="store-services-card additional-insights-card container default-padding"
     [ngClass]="{enabled: !!services?.surveyDate}" matRipple [matRippleDisabled]="!services?.surveyDate"
     (click)="handleClick()">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div class="icon-container container" fxLayoutAlign="center center">
      <fa-icon class="primary-icon" icon="bell"></fa-icon>
    </div>
    <div class="info-container container" fxFlex fxLayout="column">
      <strong>{{title}}</strong>
      <small class="color-muted">{{description}}</small>
    </div>
  </div>
  <mtn-restricted-license-overlay *ngIf="isRestrictedLicenseOverlayEnabled" [buttonSize]="Size.EXTRA_SMALL"
                                  [bannerSize]="Size.EXTRA_SMALL"></mtn-restricted-license-overlay>
</div>

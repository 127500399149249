import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserState, UserState } from '../../auth/user-state';
import { BaseComponent } from '../../core/base-component';
import { AuthenticationService } from '../../auth/authentication/authentication.service';
import { AppState } from '../../app-state';
import { StorageService } from '../../core/util/storage.service';
import { AnalyticsEventType } from '../../google-analytics/analytics-event-type';
import { License } from '../../core/license/license';
import { WizardRunnerService } from '../../core/wizard/wizard-runner.service';
import { LicenseSuspendedWizard } from './license-suspended-wizard/license-suspended-wizard';
import { LicenseExpiredWizard } from './license-expired-wizard/license-expired-wizard';

@Component({
  selector: 'mtn-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent extends BaseComponent implements OnInit {

  license: License;

  constructor(private authenticationService: AuthenticationService,
              private storageService: StorageService,
              private store: Store<AppState>,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  AnalyticsEventType = AnalyticsEventType;
  isAuthenticated = false;
  earlyAccessTooltip = 'Insights is still in development. You may see frequent and significant changes as we receive feedback from customers like you.';

  ngOnInit(): void {
    this.subscribeToAuthState();
  }

  clearAuthentication(): void {
    this.storageService.remove(StorageService.AUTH_CURRENT_USER).subscribe();
    this.storageService.remove(StorageService.AUTH_TOKEN).subscribe();
  }

  openAuth0SignInDialog(): void {
    this.authenticationService.openAuth0AuthenticationDialog();
  }

  openLicenseSuspendedWizard(): void {
    const wizard = new LicenseSuspendedWizard();
    wizard.model = {
      license: this.license
    };

    this.wizardRunner.run(wizard);
  }

  openLicenseExpiredWizard(): void {
    const wizard = new LicenseExpiredWizard();
    wizard.model = {
      license: this.license
    };

    this.wizardRunner.run(wizard);
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.store)
        .subscribe((state: UserState) => {
          this.isAuthenticated = !!state.currentUser;
          this.license = state.currentCompany?.license;
        })
    );
  }
}

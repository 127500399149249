import { VolumeType } from './volume-type.enum';
import { DateUtil } from '../../util/date-util';
import { SalesVolumeDisplayType } from '../../user-preferences/sales-volume-display-type.enum';
import { BasicEntity } from '../../basic-entity';
import { SalesSqftDisplayType } from '../../user-preferences/sales-sqft-display-type.enum';
import * as _ from 'lodash';

export class StoreVolume extends BasicEntity {

  date: Date;
  salesArea: number;
  salesSqftBySalesArea: number;
  salesSqftByTotalArea: number;
  salesSqftDisplayType: SalesSqftDisplayType;
  salesVolumeDisplayType: SalesVolumeDisplayType;
  total: number;
  totalArea: number;
  type: VolumeType;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);

      if (raw.date) {
        this.date = DateUtil.buildFromTimestamp(raw.date);
      }
      if (raw.type) {
        // @ts-ignore
        this.type = VolumeType[<string>raw.type];
      }
      if (raw.salesSqftDisplayType) {
        // @ts-ignore
        this.salesSqftDisplayType = SalesSqftDisplayType[<string>raw.salesSqftDisplayType];
      }
      if (raw.salesVolumeDisplayType) {
        // @ts-ignore
        this.salesVolumeDisplayType = SalesVolumeDisplayType[<string>raw.salesVolumeDisplayType];
      }
    }
  }

  /**
   * Returns the salesSqft value as determined by the volume's salesSqftDisplayType value, so you don't have to check
   * preferences everywhere the volume is displayed.
   */
  get salesSqft(): number {
    if (this.salesSqftDisplayType === SalesSqftDisplayType.SALES_AREA) {
      return this.salesSqftBySalesArea;
    } else {
      return this.salesSqftByTotalArea;
    }
  }

  /**
   * Returns a new instance of this volume view, reformatted with the given display preferences.
   */
  format(salesVolumeDisplayType: SalesVolumeDisplayType): StoreVolume {
    const clone = _.cloneDeep(this);

    if (clone.salesVolumeDisplayType !== salesVolumeDisplayType) {
      if (salesVolumeDisplayType === SalesVolumeDisplayType.ANNUAL) {
        clone.total *= 52;
        clone.salesSqftBySalesArea *= 52;
        clone.salesSqftByTotalArea *= 52;
      } else {
        clone.total /= 52;
        clone.salesSqftBySalesArea /= 52;
        clone.salesSqftByTotalArea /= 52;
      }
      clone.salesVolumeDisplayType = salesVolumeDisplayType;
    }

    return clone;
  }
}

import { Wizard } from '../../../../../../core/wizard/wizard';
import { Space } from '../../../../../../core/models';
import { SpaceDetailsPageComponent } from './space-details-page/space-details-page.component';
import { LicenseType } from '../../../../../../auth/authorization/license-type.enum';

export class SpaceDetailsWizard extends Wizard<SpaceDetailsWizardModel> {
  key = 'space-details';
  pageTypeMap = {
    'space-details': SpaceDetailsPageComponent
  };
  startingPageKey = 'space-details';
}

export interface SpaceDetailsWizardModel {
  licenseType: LicenseType;
  space: Space;
}

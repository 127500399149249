<div class="map-selection-tools-assembly container">
  <mtn-map-toolbar-button [icon]="getSelectionIcon()" [color]="map.selectionMode ? 'green' : 'grey'"
                          [iconColor]="map.selectionMode ? 'green' : 'default'" matTooltip="Toggle Selection Mode"
                          matTooltipPosition="right" (onClick)="toggleSelectionTools()"></mtn-map-toolbar-button>

  <div class="selection-tools-menu-container" [ngClass]="{hidden: isHidden, hiding: isHiding, showing: isShowing}"
       *ngIf="!isHidden">
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-divider [vertical]="true"></mat-divider>
        <mtn-map-toolbar-button icon="map-marked" matTooltip="Select All"
                                mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                [analyticsEventParameters]="{'target': 'map-select-all-button'}"
                                (onClick)="selectAll()"></mtn-map-toolbar-button>
        <mtn-map-toolbar-button icon="hand-pointer" matTooltip="Multi-Select"
                                (onClick)="setSelectionMode(MapSelectionMode.MULTI_SELECT)"
                                [color]="map.selectionMode === MapSelectionMode.MULTI_SELECT ? 'green' : 'grey'"
                                mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                [analyticsEventParameters]="{'target': 'map-multi-select-button'}"
                                [iconColor]="map.selectionMode === MapSelectionMode.MULTI_SELECT ? 'green' : 'default'"></mtn-map-toolbar-button>
        <mtn-map-toolbar-button icon="lasso" matTooltip="Lasso" (onClick)="setSelectionMode(MapSelectionMode.LASSO)"
                                [color]="map.selectionMode === MapSelectionMode.LASSO ? 'green' : 'grey'"
                                mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                [analyticsEventParameters]="{'target': 'map-lasso-select-button'}"
                                [iconColor]="map.selectionMode === MapSelectionMode.LASSO ? 'green' : 'default'"></mtn-map-toolbar-button>
        <mtn-map-toolbar-button [icon]="['far', 'circle']" matTooltip="Ring"
                                (onClick)="setSelectionMode(MapSelectionMode.RING)"
                                [color]="map.selectionMode === MapSelectionMode.RING ? 'green' : 'grey'"
                                mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                [analyticsEventParameters]="{'target': 'map-ring-select-button'}"
                                [iconColor]="map.selectionMode === MapSelectionMode.RING ? 'green' : 'default'"></mtn-map-toolbar-button>
        <mtn-ring-miles-field #radiusControl [form]="ringForm" *ngIf="map.selectionMode === MapSelectionMode.RING"
                              placeholder="Radius (Miles)" [maxRadius]="100"></mtn-ring-miles-field>
        <mtn-map-toolbar-button icon="times" matTooltip="Cancel Selection" (onClick)="setSelectionMode(null)"
                                color="red" iconColor="red"
                                mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                [analyticsEventParameters]="{'target': 'map-cancel-selection-button'}"
                                [disabled]="!map.selectionMode && !map.selections?.size"></mtn-map-toolbar-button>
      </div>
    </div>
  </div>
</div>

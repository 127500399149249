import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../core/base-component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { selectUserState, UserState } from '../../user-state';
import { Subscription, timer } from 'rxjs';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { InactivitySignOutWizard } from './inactivity-sign-out-wizard/inactivity-sign-out-wizard';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'mtn-activity-monitoring',
  templateUrl: './activity-monitoring.component.html',
  styleUrls: ['./activity-monitoring.component.scss']
})
export class ActivityMonitoringComponent extends BaseComponent implements OnInit {

  private lastActivityDate: Date;
  private timerSubscription: Subscription;

  constructor(private store: Store<AppState>,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAuthState();
  }

  private resetInactivityTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = timer(1000 * 60 * 59) //59 minutes
      .pipe(map(() => this.wizardRunner.run(new InactivitySignOutWizard())))
      .subscribe();
  }

  private subscribeToAuthState(): void {
    this.addSubscription(selectUserState(this.store)
      .pipe(debounceTime(300))
      .subscribe((state: UserState) => {
        //Only activate the timer if the user is signed in
        if (state.currentUser && state.lastActivityDate && state.lastActivityDate !== this.lastActivityDate) {
          this.lastActivityDate = state.lastActivityDate;
          this.resetInactivityTimer();
        }
        //Else kill the timer when the user signs out
        else if (!state.currentUser && this.timerSubscription) {
          this.timerSubscription.unsubscribe();
          this.timerSubscription = null;
        }
      })
    );
  }

}

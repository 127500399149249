import { Component } from '@angular/core';
import { GridEnabledDashboard } from './grid-enabled-dashboard';

@Component({
  selector: 'mtn-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends GridEnabledDashboard {

  constructor() {
    super();
  }

}

import { Component, Input } from '@angular/core';
import { StoreHighlight } from '../store-highlight';
import { BaseComponent } from '../../../../../core/base-component';

@Component({
  selector: 'mtn-store-highlight-card',
  templateUrl: './store-highlight-card.component.html',
  styleUrls: ['./store-highlight-card.component.scss']
})
export class StoreHighlightCardComponent extends BaseComponent {

  @Input()
  highlight: StoreHighlight;

  constructor() {
    super();
  }

}

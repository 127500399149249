import { Component } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { DetailState, selectDetailState } from '../../detail-state';
import { filter, take } from 'rxjs/operators';
import { Store } from '../../../core/models';
import { SalesVolumeDisplayType } from '../../../core/user-preferences/sales-volume-display-type.enum';
import { SalesSqftDisplayType } from '../../../core/user-preferences/sales-sqft-display-type.enum';
import { formatAsCurrency } from '../../../core/util/string-utils';
import { DefinitionService } from '../../../core/definition/definition.service';
import { Definition } from '../../../core/definition/definition';
import {
  ActiveStoreStatuses,
  FutureStoreStatuses,
  HistoricalStoreStatuses,
  StoreStatusType
} from '../../../core/identity/constant/store-status-type.enum';
import * as _ from 'lodash';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { SalesAreaWizard } from './sales-area-wizard/sales-area-wizard';
import { SalesVolumeHistoryWizard } from './sales-volume-history-wizard/sales-volume-history-wizard';
import { StatusHistoryWizard } from './status-history-wizard/status-history-wizard';
import { StoreVolume } from '../../../core/store/volume/store-volume';

@Component({
  selector: 'mtn-store-critical-insights-panel',
  templateUrl: './store-critical-insights-panel.component.html',
  styleUrls: ['./store-critical-insights-panel.component.scss']
})
export class StoreCriticalInsightsPanelComponent extends AuthorizationAwareComponent {

  cardDescriptionArea: string;
  cardDescriptionSalesSqft: string;
  cardDescriptionStatus: string;
  cardDescriptionVolume: string;
  cardTitleArea: string;
  cardTitleSalesSqft: string;
  cardTitleStatus: string;
  cardTitleVolume: string;
  isVolumeHighConfidence = false;
  maxHighlightCount = 4;

  statusColor: string;
  store: Store;
  volume: StoreVolume;
  volumeDate: string;

  private _isStoreCardsInitialized = false;
  private _isVolumeCardsInitialized = false;

  constructor(private definitionService: DefinitionService,
              protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.loadStore();
  }

  openSalesVolumeHistoryWizard(): void {
    const wizard = new SalesVolumeHistoryWizard();
    wizard.model = {
      store: this.store,
      userPreferences: this.userPreferences
    };

    this.wizardRunner.run(wizard);
  }

  openSalesAreaWizard(): void {
    const wizard = new SalesAreaWizard();
    wizard.model = {
      store: this.store
    };

    this.wizardRunner.run(wizard);
  }

  openStatusHistoryWizard(): void {
    const wizard = new StatusHistoryWizard();
    wizard.model = {
      store: this.store
    };

    this.wizardRunner.run(wizard);
  }

  toggleMaxCount(): void {
    if (this.maxHighlightCount === 4) {
      this.maxHighlightCount = 100;
    } else {
      this.maxHighlightCount = 4;
    }
  }

  private initAreaCard(): void {
    this.cardDescriptionArea = 'Sales / Total Area';
    this.cardTitleArea = 'Unknown';
    if (this.store.grocerySalesArea && this.store.totalArea) {
      this.cardTitleArea = `${this.store.grocerySalesArea.toLocaleString()} / ${this.store.totalArea.toLocaleString()} sqft`;
    }
  }

  private initSalesSqftCard(): void {
    const isVolumeAvailable = !this.volume && !this.volume.total; /*Available means MTN has a volume, but the value isn't purchased*/
    const isVolumePurchased = this.volume?.total;

    if (isVolumePurchased) {
      this.cardTitleSalesSqft = `${formatAsCurrency(this.volume.salesSqft)} /sqft`;
    } else if (isVolumeAvailable) {
      this.cardTitleSalesSqft = 'Not Purchased';
    } else {
      this.cardTitleSalesSqft = 'Unknown';
    }

    this.cardDescriptionSalesSqft = 'Est. Sales/sqft (';
    this.cardDescriptionSalesSqft += this.userPreferences.salesVolumeDisplayMode === SalesVolumeDisplayType.ANNUAL ? 'Annually' : 'Weekly';
    this.cardDescriptionSalesSqft += ', ';
    this.cardDescriptionSalesSqft += this.userPreferences.salesSqftDisplayMode === SalesSqftDisplayType.SALES_AREA ? 'Sales Area' : 'Total Area';
    this.cardDescriptionSalesSqft += ')';
  }

  private initStatusCard(): void {
    this.cardDescriptionStatus = 'Store Status';
    const status = this.store.getCurrentStatus();
    if (status) {

      this.definitionService.findOneStoreStatusType(status.getStatusSystemName())
        .pipe(take(1))
        .subscribe((definition: Definition<StoreStatusType>) => {
          this.cardTitleStatus = definition.displayName;
        });
    } else {
      this.cardTitleStatus = 'Unknown';
    }

    if (_.includes(FutureStoreStatuses, status.getStatusSystemName())) {
      this.statusColor = 'dodgerblue';
    } else if (_.includes(ActiveStoreStatuses, status.getStatusSystemName())) {
      this.statusColor = 'limegreen';
    } else if (_.includes(HistoricalStoreStatuses, status.getStatusSystemName())) {
      this.statusColor = 'red';
    }
  }

  private initVolumeCard(): void {
    const isVolumeAvailable = this.volume && !this.volume.total; /*Available means MTN has a volume, but the value isn't purchased*/
    const isVolumePurchased = this.volume?.total;

    if (isVolumePurchased) {
      this.cardTitleVolume = formatAsCurrency(this.volume.total, false);
    } else if (isVolumeAvailable) {
      this.cardTitleVolume = 'Not Purchased';
    } else {
      this.cardTitleVolume = 'Unknown';
    }
    this.getDate();
    this.cardDescriptionVolume = 'Est. Sales Volume';
    this.cardDescriptionVolume += ' (';
    this.cardDescriptionVolume += this.userPreferences.salesVolumeDisplayMode === SalesVolumeDisplayType.ANNUAL ? 'Annually' : 'Weekly';
    this.cardDescriptionVolume += ')';
    this.cardDescriptionVolume += ' ';
    this.cardDescriptionVolume += this.volumeDate;
  }

  getDate(){
    const volumeDate = this.volume.date;
    const newVolumeDate = new Date(volumeDate);
    const month = (newVolumeDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newVolumeDate.getFullYear();

    const formattedDate = `${month}/${year}`;
    this.volumeDate = formattedDate;
  }

  private initStoreCards(): void {
    if (this.store && !this._isStoreCardsInitialized) {
      this._isStoreCardsInitialized = true;
      this.initAreaCard();
      this.initStatusCard();
    }
  }

  private initVolumeCards(): void {
    if (this.volume && !this._isVolumeCardsInitialized) {
      this._isVolumeCardsInitialized = true;
      this.initVolumeCard();
      this.initSalesSqftCard();
    }
  }

  private loadStore(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .pipe(filter((state: DetailState) => !!state.store))
        .subscribe((state: DetailState) => {
          this.store = state.store;
          this.volume = state.volume;
          this.initStoreCards();
          this.initVolumeCards();
        })
    );
  }

}

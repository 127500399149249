import { Component, OnInit } from '@angular/core';
import { AuthorizationAwareComponent } from '../../core/authorization-aware-component';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { DetailState, selectDetailState } from '../../detail/detail-state';
import { NavigationState, selectNavigationState } from '../navigation-state';
import { NavigationToggleCollapsed } from '../navigation-actions';
import { StorageService } from '../../core/util/storage.service';
import { take } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { LicenseType } from '../../auth/authorization/license-type.enum';

@Component({
  selector: 'mtn-navigation-sidebar',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss']
})
export class NavigationSidebarComponent extends AuthorizationAwareComponent implements OnInit {

  detailsPath: string;
  isAdministrator = false;
  isCollapsed = true;

  constructor(private breakpointObserver: BreakpointObserver,
              protected ngrxStore: Store<AppState>,
              protected storageService: StorageService) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToBreakpointChanges();
    this.subscribeToDetailState();
    this.subscribeToNavigationState();
  }

  onAuthorizationChange() {
    this.isAdministrator = this.hasLicense(LicenseType.INTERNAL);
  }

  onAuthorizationInit(): void {
    //Do nothing
  }

  toggleIsCollapsed(): void {
    this.ngrxStore.dispatch(NavigationToggleCollapsed({isCollapsed: !this.isCollapsed}));
  }

  private subscribeToBreakpointChanges(): void {
    this.addSubscription(
      this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
        .subscribe((state: BreakpointState) => {
          if (state.matches && !this.isCollapsed) {
            this.toggleIsCollapsed();
          }
        })
    );
  }

  private subscribeToDetailState(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .subscribe((state: DetailState) => {
          const detailsPath = state.store ? `/detail/store/${state.store.uuid}` : null;
          setTimeout(() => this.detailsPath = detailsPath);
        })
    );
  }

  private subscribeToNavigationState(): void {
    //First, load the state from storage
    this.storageService.get(StorageService.NAVIGATION_STATE_TOKEN)
      .pipe(take(1))
      .subscribe((state: NavigationState) => {
        if (state) {
          this.ngrxStore.dispatch(NavigationToggleCollapsed({isCollapsed: state.isCollapsed}));
        }

        //Then subscribe to the state
        this.addSubscription(
          selectNavigationState(this.ngrxStore)
            .subscribe((state: NavigationState) => {
              this.isCollapsed = state.isCollapsed;
              this.writeStateToLocalStorage(state);
            })
        );
      });
  }

  private writeStateToLocalStorage(state: NavigationState): void {
    this.storageService.set(StorageService.NAVIGATION_STATE_TOKEN, state)
      .subscribe();
  }

}

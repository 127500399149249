import { Wizard } from '../../../../core/wizard/wizard';
import { EditProfilePageComponent } from './edit-profile-page/edit-profile-page.component';
import { UserProfile } from '../../../../core/user-profile/user-profile';

export class EditProfileWizard extends Wizard<EditProfileWizardModel> {
  key = 'edit-profile';
  pageTypeMap = {
    'edit-profile': EditProfilePageComponent
  };
  startingPageKey = 'edit-profile';
}

export interface EditProfileWizardModel {
  profile?: UserProfile;
  uuid: string;
}

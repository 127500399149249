import { DateUtil } from '../util/date-util';
import * as _ from 'lodash';

export class StoreServices {
  bakery: boolean;
  bank: boolean;
  beer: boolean;
  bulk: boolean;
  cheese: boolean;
  coffee: boolean;
  deli: boolean;
  expandedGM: boolean;
  extensivePreparedFoods: boolean;
  floral: boolean;
  fuel: boolean;
  inStoreRestaurant: boolean;
  liquor: boolean;
  meat: boolean;
  oliveBar: boolean;
  onlinePickup: boolean;
  pharmacy: boolean;
  saladBar: boolean;
  seafood: boolean;
  seating: boolean;
  surveyDate: Date;
  sushi: boolean;
  wine: boolean;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.surveyDate) {
        this.surveyDate = DateUtil.buildFromTimestamp(raw.surveyDate);
      }
    }
  }

  count(): number {
    let count = 0;

    _.forOwn(this, (value: any) => {
      if (value === true) {
        count++;
      }
    });

    return count;
  }
}

<div class="self-service-administration-container container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <div class="page-container container" fxLayout="column" fxLayoutGap="30px">
    <div class="alerts-panel-container container" *ngIf="!hasStandardLicense" fxLayout="row"
         fxLayoutAlign="center start" fxLayoutGap="15px">
      <mtn-simple-alert title="Upgrade Your License" text="Click here to get started" icon="arrow-circle-up"
                        color="blue" (onClick)="openUpgradeLicenseWizard()"></mtn-simple-alert>
    </div>
    <div class="page-header container" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="row" fxLayoutGap="15px">
        <mtn-company-initials-avatar *ngIf="company" [company]="company"></mtn-company-initials-avatar>
        <div class="company-description" fxLayout="column" fxFlex="40">
          <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin ">{{company?.name}}</h1>
          <span class="color-muted" *ngIf="company?.description">{{company.description}}</span>
        </div>
      </div>
      <hr/>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" *ngIf="company">
      <div fxLayout="column" fxLayoutGap="15px">
        <mtn-company-api-key [company]="company" (onUpdate)="loadCompany()"></mtn-company-api-key>
        <mtn-company-add-on-list [company]="company"></mtn-company-add-on-list>
        <mtn-license-card [company]="company" [isActionsEnabled]="false"></mtn-license-card>
      </div>
      <div fxFlex fxLayout="column" fxLayoutGap="30px">
        <mtn-company-users-list [company]="company" (onUpdate)="loadCompany()"></mtn-company-users-list>
      </div>
    </div>
  </div>
  <mtn-spinner [on]="isLoading" [size]="SpinnerSize.LARGE"></mtn-spinner>
</div>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MapComponent } from '../map/map.component';
import { BaseComponent } from '../core/base-component';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { SpinnerSize } from '../core/util/spinner/spinner-size.enum';
import { selectUserState, UserState } from '../auth/user-state';
import { filter } from 'rxjs/operators';
import { MapMode } from '../map/map-mode.enum';
import { StoreSpotlightPanelComponent } from './spotlight-panel/store-spotlight-panel/store-spotlight-panel.component';
import { MtnMap } from '../map/mtn-map';
import { MapEvent, MapEventType } from '../map/map-events';
import { MtnMapOptions } from '../map/mtn-map-options';
import { FeatureType } from '../core/federation/feature/feature-type.enum';
import MapTypeId = google.maps.MapTypeId;
import Marker = google.maps.Marker;

@Component({
  selector: 'mtn-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent extends BaseComponent implements OnDestroy, OnInit {

  @ViewChild(MapComponent)
  mapComponent: MapComponent;
  @ViewChild("storeSpotlightPanel")
  storeSpotlightPanel: StoreSpotlightPanelComponent;

  isFeatureLayerEnabled = false;
  map: MtnMap;
  options: MtnMapOptions;
  SpinnerSize = SpinnerSize;

  private previousMapMode: MapMode;
  private previousMapType: MapTypeId;

  constructor(private ngrxStore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.options = {
      key: 'map-search-2',
      featureType: FeatureType.STORE,
      onMarkerClick: (marker: Marker) => this.handleMarkerClick(marker)
    };
  }

  ngOnDestroy() {
    google.maps.event.clearListeners(this.map.getStreetView(), 'visible_changed');
  }

  onMapReady(map: MtnMap): void {
    this.map = map;
    if (this.map) {
      this.map.options.recenterOffsetX = 450; //Width of spotlight panel
      this.checkFeatureLayerEnabled();
      this.subscribeToMapOptionsChangeEvents();
      this.subscribeToAuthState();
      this.subscribeToStreetViewEvents();
    }
  }

  private handleMarkerClick(marker: Marker): void {
    this.map.select(marker.get('id'));
  }

  private checkFeatureLayerEnabled(): void {
    this.isFeatureLayerEnabled = !!this.map.options.featureType;
  }

  /**
   * Since the map search page is the default landing page, it'll be the page on which the user will complete the
   * welcome wizard. If they change preferences from the defaults in the wizard, we want to apply those changes
   * immediately to the map search map.
   */
  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.ngrxStore)
        .pipe(filter((state: UserState) => !!state.userPreferences))
        .subscribe((state: UserState) => {
          if (this.previousMapMode !== state.userPreferences.mapDefaultMode
            || this.previousMapType !== state.userPreferences.mapDefaultType) {
            this.previousMapMode = state.userPreferences.mapDefaultMode;
            this.previousMapType = state.userPreferences.mapDefaultType;
            this.map.setOptions({
              mode: state.userPreferences.mapDefaultMode,
              mapTypeId: state.userPreferences.mapDefaultType
            });
          }
        })
    );
  }

  private subscribeToMapOptionsChangeEvents(): void {
    this.addSubscription(
      this.map.events
        .pipe(filter((event: MapEvent<any>) => event.type === MapEventType.OPTION_CHANGE))
        .subscribe(() => this.checkFeatureLayerEnabled())
    );
  }

  private subscribeToStreetViewEvents(): void {
    this.map.getStreetView().addListener('visible_changed', () => {
      this.map.select(null);
    });
  }
}

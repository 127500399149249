import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { InactivitySignOutWizard } from '../inactivity-sign-out-wizard';
import { Observable, of, Subscription, timer } from 'rxjs';
import { AuthenticationService } from '../../../authentication.service';
import { PingService } from '../../../ping.service';
import { WizardRunnerService } from '../../../../../core/wizard/wizard-runner.service';
import { map } from 'rxjs/operators';
import { InactivitySignedOutWizard } from '../../inactivity-signed-out-wizard/inactivity-signed-out-wizard';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mtn-inactivity-sign-out-page',
  templateUrl: './inactivity-sign-out-page.component.html',
  styleUrls: ['./inactivity-sign-out-page.component.scss']
})
export class InactivitySignOutPageComponent extends WizardPageComponent<InactivitySignOutWizard> {

  key = 'inactivity-sign-out';
  secondsRemaining: number;

  private timerSubscription: Subscription;

  constructor(private authenticationService: AuthenticationService,
              private matDialog: MatDialog,
              private pingService: PingService,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  onLoad(): Observable<any> {
    this.closeButtonText = 'Sign Out';
    this.nextButtonText = 'Continue';
    this.title = 'Inactivity Sign Out';
    this.headerText = 'Are you still there?';
    this.subheaderText = 'You\'ve been inactive for some time now. Would you like to continue your work, or sign out?';

    this.startTimer();

    return of(null);
  }

  onNext(): Observable<string> {
    this.timerSubscription.unsubscribe();
    return this.pingService.ping()
      .pipe(map(() => null));
  }

  onClose(): Observable<null> {
    this.signOut();
    return of(null);
  }

  private signOut(isAutomatic: boolean = false): void {
    if (isAutomatic) {
      const redirectUrl = `${window.location.pathname}${window.location.search}`;
      this.authenticationService.setRedirectUrl(redirectUrl);
    }
    this.timerSubscription.unsubscribe();
    this.authenticationService.logOut();
    this.matDialog.closeAll();
    this.wizardRunner.run(new InactivitySignedOutWizard());
  }

  private startTimer(): void {
    this.timerSubscription = timer(0, 1000)
      .subscribe((secondsElapsed: number) => {
        this.secondsRemaining = 60 - secondsElapsed;
        this.closeButtonText = `Sign Out (${this.secondsRemaining})`;

        if (this.secondsRemaining === 0) {
          this.signOut(true);
        }
      });
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { Definition } from '../definition/definition';
import { IntersectionType } from '../identity/constant/intersection-type.enum';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class IntersectionTypeService extends CrudService<Definition<IntersectionType>> {

  protected path = '/intersection-type';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): Definition<IntersectionType> {
    return new Definition<IntersectionType>(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Definition<IntersectionType>> {
    return new Pageable<Definition<IntersectionType>>(raw, Definition);
  }
}

import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BaseComponent } from '../../../../core/base-component';
import { StoreVolume } from '../../../../core/store/volume/store-volume';

@Component({
  selector: 'mtn-critical-insights-card',
  templateUrl: './critical-insights-card.component.html',
  styleUrls: ['./critical-insights-card.component.scss']
})
export class CriticalInsightsCardComponent extends BaseComponent {

  @Input()
  description: string = 'No Description Set!';
  @Input()
  icon: IconProp = 'exclamation-triangle';
  @Input()
  iconColor: string = 'white';
  @Input()
  volume: StoreVolume;
  @Input()
  isRestrictedLicenseOverlayEnabled = false;
  @Input()
  secondaryIcon: IconProp;
  @Input()
  title: string = 'No Title Set!';

  constructor() {
    super();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule, WavesModule } from 'angular-bootstrap-md';


@NgModule({
  declarations: [],
  exports: [
    ChartsModule,
    WavesModule
  ],
  imports: [
    CommonModule,
    ChartsModule,
    WavesModule
  ]
})
export class MtnMdBootstrapModule {
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base-component';
import { interval, Subscription } from 'rxjs';
import { AuthenticationService } from '../../auth/authentication/authentication.service';
import { PingService } from '../../auth/authentication/ping.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'mtn-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent extends BaseComponent implements OnInit {

  isMaintenanceEnded = false;

  private _pingSubscription: Subscription;

  constructor(private authenticationService: AuthenticationService,
              private pingService: PingService) {
    super();
  }

  ngOnInit(): void {
    this.authenticationService.clearAuthentication();
    this.pingForMaintenanceEnd();
  }

  signIn(): void {
    this.authenticationService.openAuth0AuthenticationDialog();
  }

  private pingForMaintenanceEnd(): void {
    this._pingSubscription = interval(1000 * 30) //Every 30 seconds
      .pipe(switchMap(() => this.pingService.maintenancePing()))
      .subscribe((result: boolean) => {
        this.isMaintenanceEnded = result;

        if (this.isMaintenanceEnded) {
          this._pingSubscription.unsubscribe();
        }
      });
  }


}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mtn-what-is-this',
  templateUrl: './what-is-this.component.html',
  styleUrls: ['./what-is-this.component.scss']
})
export class WhatIsThisComponent implements OnInit {

  @Input()
  text: string;

  tooltip: string;

  constructor() {
  }

  ngOnInit(): void {
    this.initTooltip();
  }

  private initTooltip(): void {
    this.tooltip = `
What is this?

${this.text}
    `;
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'mtn-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent {

  @Output()
  onClick = new EventEmitter<any>();

  @Input()
  color: 'grey' | 'green' | 'blue' | 'red' = 'grey';
  @Input()
  disabled = false;
  @Input()
  icon: IconProp;

  constructor() {
  }

  handleClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }

}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Observable, of } from 'rxjs';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import { DefinitionState, selectDefinitionState } from './definition-state';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Definition } from './definition';
import { LicenseTypeService } from './license-type.service';
import { Pageable } from '../service/pageable';
import {
  DefinitionSetAddOnTypesAction,
  DefinitionSetFitsAction,
  DefinitionSetFormatsAction,
  DefinitionSetIntersectionTypesAction,
  DefinitionSetLicenseTypesAction,
  DefinitionSetQuadrantTypesAction,
  DefinitionSetShoppingCenterTypesAction,
  DefinitionSetStoreStatusTypesAction
} from './definition-actions';
import { ShoppingCenterService } from '../shopping-center/shopping-center.service';
import { ShoppingCenterType } from '../identity/constant/shopping-center-type.enum';
import * as _ from 'lodash';
import { QuadrantService } from '../identity/quadrant.service';
import { QuadrantType } from '../identity/constant/quadrant-type.enum';
import { StoreStatusType } from '../identity/constant/store-status-type.enum';
import { StoreService } from '../store/store.service';
import { IntersectionTypeService } from '../location/intersection-type.service';
import { IntersectionType } from '../identity/constant/intersection-type.enum';
import { FitService } from '../store/fit/fit.service';
import { FormatService } from '../store/format/format.service';
import { FitType } from '../store/fit/fit-type.enum';
import { FormatType } from '../store/format/format-type.enum';
import { AddOnService } from '../company/add-on.service';
import { AddOnType } from '../company/add-on-type.enum';

@Injectable({
  providedIn: 'root'
})
export class DefinitionService {

  constructor(private addOnService: AddOnService,
              private fitService: FitService,
              private formatService: FormatService,
              private intersectionTypeService: IntersectionTypeService,
              private licenseTypeService: LicenseTypeService,
              private quadrantService: QuadrantService,
              private shoppingCenterService: ShoppingCenterService,
              private store: Store<AppState>,
              private storeService: StoreService) {
  }

  findAllAddOnTypes(): Observable<Definition<AddOnType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.addOnTypes && state.addOnTypes.length) {
          return of(state.addOnTypes);
        } else {
          return this.addOnService.findAllTypes()
            .pipe(map((results: Definition<AddOnType>[]) => {
              this.store.dispatch(DefinitionSetAddOnTypesAction({definitions: results}))
              return results;
            }));
        }
      }));
  }

  findAllFits(): Observable<Definition<FitType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.fits && state.fits.length) {
          return of(state.fits);
        } else {
          return this.fitService.findAll()
            .pipe(map((results: Pageable<Definition<FitType>>) => {
              this.store.dispatch(DefinitionSetFitsAction({definitions: results.content}))
              return results.content;
            }));
        }
      }));
  }

  findAllFormats(): Observable<Definition<FormatType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.formats && state.formats.length) {
          return of(state.formats);
        } else {
          return this.formatService.findAll()
            .pipe(map((results: Pageable<Definition<FormatType>>) => {
              this.store.dispatch(DefinitionSetFormatsAction({definitions: results.content}))
              return results.content;
            }));
        }
      }));
  }

  findAllIntersectionTypes(): Observable<Definition<IntersectionType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.intersectionTypes && state.intersectionTypes.length) {
          return of(state.intersectionTypes);
        } else {
          return this.intersectionTypeService.findAll()
            .pipe(map((results: Pageable<Definition<IntersectionType>>) => {
              this.store.dispatch(DefinitionSetIntersectionTypesAction({definitions: results.content}))
              return results.content;
            }));
        }
      }));
  }

  findAllLicenseTypes(): Observable<Definition<LicenseType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.licenseTypes && state.licenseTypes.length) {
          return of(state.licenseTypes);
        } else {
          return this.licenseTypeService.findAll()
            .pipe(map((results: Pageable<Definition<LicenseType>>) => {
              this.store.dispatch(DefinitionSetLicenseTypesAction({definitions: results.content}))
              return results.content;
            }));
        }
      }));
  }

  findAllQuadrantTypes(): Observable<Definition<QuadrantType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.quadrantTypes && state.quadrantTypes.length) {
          return of(state.quadrantTypes);
        } else {
          return this.quadrantService.findAll()
            .pipe(map((results: Pageable<Definition<QuadrantType>>) => {
              this.store.dispatch(DefinitionSetQuadrantTypesAction({definitions: results.content}))
              return results.content;
            }));
        }
      }));
  }

  findAllShoppingCenterTypes(): Observable<Definition<ShoppingCenterType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.shoppingCenterTypes && state.shoppingCenterTypes.length) {
          return of(state.shoppingCenterTypes);
        } else {
          return this.shoppingCenterService.findAllTypes()
            .pipe(tap((results: Definition<ShoppingCenterType>[]) => {
              this.store.dispatch(DefinitionSetShoppingCenterTypesAction({definitions: results}))
            }));
        }
      }));
  }

  findAllStoreStatusTypes(): Observable<Definition<StoreStatusType>[]> {
    return selectDefinitionState(this.store)
      .pipe(take(1))
      .pipe(switchMap((state: DefinitionState) => {
        if (state.storeStatusTypes && state.storeStatusTypes.length) {
          return of(state.storeStatusTypes);
        } else {
          return this.storeService.findAllStatusTypes()
            .pipe(tap((results: Definition<StoreStatusType>[]) => {
              this.store.dispatch(DefinitionSetStoreStatusTypesAction({definitions: results}))
            }));
        }
      }));
  }

  findOneFit(type: FitType): Observable<Definition<FitType>> {
    return this.findAllFits()
      .pipe(map((definitions: Definition<FitType>[]) => _.find(definitions, (definition: Definition<FitType>) => definition.systemName === type)));
  }

  findOneFormat(type: FormatType): Observable<Definition<FormatType>> {
    return this.findAllFormats()
      .pipe(map((definitions: Definition<FormatType>[]) => _.find(definitions, (definition: Definition<FormatType>) => definition.systemName === type)));
  }

  findOneIntersectionType(type: IntersectionType): Observable<Definition<IntersectionType>> {
    return this.findAllIntersectionTypes()
      .pipe(map((definitions: Definition<IntersectionType>[]) => _.find(definitions, (definition: Definition<IntersectionType>) => definition.systemName === type)));
  }

  findOneQuadrantType(type: QuadrantType): Observable<Definition<QuadrantType>> {
    return this.findAllQuadrantTypes()
      .pipe(map((definitions: Definition<QuadrantType>[]) => _.find(definitions, (definition: Definition<QuadrantType>) => definition.systemName === type)));
  }

  findOneShoppingCenterType(type: ShoppingCenterType): Observable<Definition<ShoppingCenterType>> {
    return this.findAllShoppingCenterTypes()
      .pipe(map((definitions: Definition<ShoppingCenterType>[]) => _.find(definitions, (definition: Definition<ShoppingCenterType>) => definition.systemName === type)));
  }

  findOneStoreStatusType(type: StoreStatusType): Observable<Definition<StoreStatusType>> {
    return this.findAllStoreStatusTypes()
      .pipe(map((definitions: Definition<StoreStatusType>[]) => _.find(definitions, (definition: Definition<StoreStatusType>) => definition.systemName === type)));
  }
}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { ConfirmDeleteCompanyWizard } from '../confirm-delete-company-wizard';
import { CompanyService } from '../../../../../core/company/company.service';
import { ToastService } from '../../../../../core/toast/toast.service';
import { Observable, of } from 'rxjs';
import { Company } from '../../../../../core/company/company';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MtnValidators } from '../../../../../core/form-controls/mtn-validators';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-confirm-delete-company-page',
  templateUrl: './confirm-delete-company-page.component.html',
  styleUrls: ['./confirm-delete-company-page.component.scss']
})
export class ConfirmDeleteCompanyPageComponent extends WizardPageComponent<ConfirmDeleteCompanyWizard> {

  company: Company;
  key = 'confirm-delete-company';

  constructor(private companyService: CompanyService,
              private toaster: ToastService) {
    super();
  }

  onLoad(): Observable<any> {
    this.company = this.wizard.model.company;

    this.closeButtonText = 'No';
    this.footerText = 'Are you sure you want to do this?';
    this.nextButtonText = 'Yes';
    this.headerText = 'Delete Company';
    this.subheaderText = `You are about to delete <strong>${this.company.name}</strong> and all its Teams (including their Roles, Collections, and Items) with it. <strong>This cannot be undone!</strong><br/><br/>To confirm you want to do this, type "<i>${this.company.name}</i>" below and click "Yes".`;
    this.title = 'Delete Company';

    this.form = new UntypedFormGroup({
      confirmation: new UntypedFormControl(null, [Validators.required, MtnValidators.equals(this.company.name)])
    });

    return of(null);
  }

  onNext(): Observable<string> {
    return this.companyService.deleteOne(this.company.uuid)
      .pipe(map(() => {
        this.toaster.info("Successfully deleted Company");
        this.wizard.model.isDeleted = true;
        return null;
      }));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'mtn-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

  @Output()
  onClick = new EventEmitter<any>();
  @Input()
  color: ThemePalette;
  @Input()
  disabled = false;
  @Input()
  isMini = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  doClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }

}

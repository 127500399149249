import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../core/util/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationAwareComponent } from '../../core/authorization-aware-component';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { AnalyticsEventType } from '../../google-analytics/analytics-event-type';

@Component({
  selector: 'mtn-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent extends AuthorizationAwareComponent implements OnInit {

  AnalyticsEventType = AnalyticsEventType;
  noTabSelected = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              protected ngrxStore: Store<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadLastUsedTab();
  }

  onAuthorizationChange(): void {
    //Do nothing
  }

  onAuthorizationInit() {
    //Do nothing
  }

  setLastUsedTab(tab: string): void {
    this.storageService.set(StorageService.ADMIN_LAST_USED_TAB, tab).subscribe();
  }

  private loadLastUsedTab(): void {
    const isCompanyRouteActive = this.router.isActive('/administration/company', false);
    const isTeamRouteActive = this.router.isActive('/administration/team', false);
    const isUserRouteActive = this.router.isActive('/administration/user', false);

    if (!isTeamRouteActive && !isUserRouteActive && !isCompanyRouteActive) {
      this.storageService.get(StorageService.ADMIN_LAST_USED_TAB)
        .subscribe((tab: string) => {
          if (tab) {
            this.router.navigate([tab], {relativeTo: this.activatedRoute});
          } else {
            this.noTabSelected = true;
          }
        });
    }
  }

}

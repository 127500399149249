<mat-card class="user-card mat-elevation-z1 container" [ngClass]="{'clickable': isNavigationEnabled}"
          (mouseenter)="showDeleteButton = true"
          (mouseleave)="showDeleteButton = false" (click)="navigateToUserDetails()">
  <mtn-spinner></mtn-spinner>
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-card-header *ngIf="user">
      <div mat-card-avatar class="avatar-image" *ngIf="user.avatarLink; else noAvatar"></div>
      <ng-template #noAvatar>
        <mtn-user-initials-avatar [user]="user"></mtn-user-initials-avatar>
      </ng-template>
      <mat-card-title class="text-small-caps text-ellipsis">{{user.getDisplayName()}}</mat-card-title>
      <mat-card-subtitle class="text-ellipsis">{{user.title}}</mat-card-subtitle>
    </mat-card-header>
  </div>
  <div class="delete-button" [ngClass]="{active: isDeleteEnabled && showDeleteButton}" mtnClickTrap
       fxLayoutAlign="center center" matTooltip="Remove" (click)="handleDeleteClicked()">
    <fa-icon icon="ban"></fa-icon>
  </div>
  <div class="banner" *ngIf="banner">
    {{banner}}
  </div>
</mat-card>

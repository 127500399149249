import { Component, Input } from '@angular/core';

@Component({
  selector: 'mtn-simple-service-view',
  templateUrl: './simple-service-view.component.html',
  styleUrls: ['./simple-service-view.component.scss']
})
export class SimpleServiceViewComponent {

  @Input()
  label: string;
  @Input()
  value: boolean;

  constructor() {
  }

}

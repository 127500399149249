import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Feature, FeatureCollection, Store } from '../models';
import { AppState } from '../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { StoreService } from './store.service';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
  DetailAddStoreComparisonAction,
  DetailResetAction,
  DetailSetAnalystRatingsAction,
  DetailSetCompetitionAction,
  DetailSetDefaultDriveTimeAction,
  DetailSetMarketAreaAction,
  DetailSetSalesSqftBannerComparisonAction,
  DetailSetSalesVolumeBannerComparisonAction,
  DetailSetStoreAction,
  DetailSetStoreCountBannerComparisonAction,
  DetailSetStoreCustomComparisonRequestsAction,
  DetailSetStoreCustomComparisonsAction,
  DetailSetStoreDefaultComparisonRequestsAction,
  DetailSetStoreDefaultComparisonsAction,
  DetailSetStoreServicesAction,
  DetailSetVolumeAction,
  DetailSetVolumeHistoryAction
} from '../../detail/detail-actions';
import { SpatialShapeType } from '../depot/spatial-shape-type.enum';
import { selectUserState, UserState } from '../../auth/user-state';
import { StoreComparison } from './ranking/store-comparison';
import { StoreComparisonService } from './ranking/store-comparison.service';
import { AnalystRatingResponse } from './rating/analyst-rating-response';
import { isLicenseOfLevel, LicenseType } from '../../auth/authorization/license-type.enum';
import { StoreRanking } from './ranking/store-ranking';
import { KeyIndicatorType } from '../user-preferences/key-indicator-type.enum';
import { DateUtil } from '../util/date-util';
import { SimpleRatingType } from './rating/simple-rating-type.enum';
import { SimpleRating } from './rating/simple-rating';
import { LocationService } from '../location/location.service';
import { FeatureService } from '../federation/feature/feature.service';
import { ActiveStoreStatuses } from '../identity/constant/store-status-type.enum';
import { StoreServices } from './store-services';
import { BannerComparison } from '../banner/banner-comparison';
import { UserPreferences } from '../user-preferences/user-preferences';
import { BannerComparisonService } from '../banner/banner-comparison.service';
import { StorageService } from '../util/storage.service';
import * as _ from 'lodash';
import { BannerRankingRequest } from '../banner/banner-ranking-request';
import { SampleDataService } from './sample-data.service';
import {
  SAMPLE_RANKING_SALES_SQFT,
  SAMPLE_RANKING_SALES_VOLUME,
  SAMPLE_RANKING_STORE_COUNT
} from '../../detail/store-detail-page/store-banner-analysis/sample-banner-data';
import { GeoJsonFilter, StatusFilter } from '../federation/filter/filter';
import { StoreFeatureRequest } from '../federation/feature/store-feature-request';
import { Pageable } from '../service/pageable';
import { FilterGroup } from '../federation/filter/filter-group';
import { FilterGroupRelationshipType } from '../federation/filter/filter-group-relationship-type.enum';
import { StoreVolume } from './volume/store-volume';
import { VolumeType } from './volume/volume-type.enum';
import { round } from '../util/math-utils';
import { SearchIntersectingShapeRequest } from '../depot/search-intersecting-shape-request';
import { ShapeService } from '../depot/shape.service';

/**
 * This service is responsible for the workflow of loading the details of a given store and dispatching that data into
 * the DetailState. Other components should listen to the DetailState for the data.
 */
@Injectable({
  providedIn: 'root'
})
export class StoreDetailService {

  constructor(private bannerComparisonService: BannerComparisonService,
              private featureService: FeatureService,
              private locationService: LocationService,
              private ngrxStore: NgrxStore<AppState>,
              private sampleDataService: SampleDataService,
              private shapeService: ShapeService,
              private storageService: StorageService,
              private storeComparisonService: StoreComparisonService,
              private storeService: StoreService) {
  }

  buildDummyComparisons(store: Store, volume: StoreVolume, userPreferences: UserPreferences): StoreComparison[] {
    const comparisons: StoreComparison[] = this.buildDefaultComparisons(store, volume, null, userPreferences);
    this.sampleDataService.appendSampleStoreRankings(comparisons);

    comparisons.forEach((comparison: StoreComparison) => {
      const subjectIndex = _.findIndex(comparison.rankings, (ranking: StoreRanking) => !!ranking.store);
      if (subjectIndex !== -1) {
        comparison.rankings[subjectIndex].store.uuid = store.uuid;
      }
    });

    return comparisons;
  }

  loadOne(uuid: string): Observable<any> {
    this.ngrxStore.dispatch(DetailResetAction());

    //Synchronous requests are loaded together, and the details page should keep the spinner up until these are finished
    return this.loadAuthState()
      .pipe(
        mergeMap((authState: UserState) => {
          const hasStandardLicense = isLicenseOfLevel(authState.currentUser.licenseType, LicenseType.STANDARD);

          return this.loadStore(uuid, authState.currentUser.licenseType)
            .pipe(mergeMap((store: Store) => {
              if (hasStandardLicense) {
                const defaultAreaTasks: Observable<FeatureCollection>[] = [];

                defaultAreaTasks.push(this.loadMarketArea(store));
                defaultAreaTasks.push(this.load7MinuteDriveTime(store));

                return forkJoin(defaultAreaTasks)
                  .pipe(tap((areas: FeatureCollection[]) => {
                    let marketArea = areas[0];
                    if (!marketArea?.features?.length) {
                      marketArea = null;
                    }

                    //Secondary pieces can now be called asynchronously, and their components will simply display a spinner until ready

                    this.loadAnalystRatings(store).subscribe();
                    this.loadStoreServices(store).subscribe();
                    this.loadNearbyCompetition(areas[1]).subscribe();
                    this.loadBannerComparison(store, authState.userPreferences, marketArea);
                    this.loadVolumeHistoryAndFindBestVolume(store.uuid)
                      .subscribe((bestVolume: StoreVolume) => {
                        //We can't load comparisons for future stores
                        if (!store.isFuture()) {
                          const defaultComparisonRequests = this.prepareDefaultComparisonRequests(store, bestVolume, marketArea, authState.userPreferences);

                          this.prepareCustomComparisonRequests(store, defaultComparisonRequests, authState.userPreferences)
                            .subscribe((customComparisonRequests: StoreComparison[]) => {

                              if (store.isActive()) {
                                this.loadDefaultComparisons(defaultComparisonRequests)
                                  .subscribe();

                                this.loadCustomComparisons(customComparisonRequests, defaultComparisonRequests)
                                  .subscribe();
                              }
                            });
                        }
                      });
                  }))
              } else {
                this.loadDummyVolumes();
                this.loadDummyBannerComparison();
                this.loadDummyComparisons(store, authState.userPreferences).subscribe();
                this.loadDummyRatings().subscribe();
                this.loadDummyServices().subscribe();

                return of(null);
              }
            }));
        })
      );
  }

  private buildDefaultComparisons(store: Store, volume: StoreVolume, marketArea: FeatureCollection, userPreferences: UserPreferences): StoreComparison[] {
    return this.storeComparisonService.buildDefaultComparisons(store, volume, marketArea, userPreferences);
  }

  private loadAnalystRatings(store: Store): Observable<AnalystRatingResponse> {
    if (store.isFuture()) {
      return of(null);
    } else {
      return this.storeService.findOnesAnalystRatings(store.uuid)
        .pipe(tap((result: AnalystRatingResponse) => {
          this.ngrxStore.dispatch(DetailSetAnalystRatingsAction({rating: result}));
        }));
    }
  }

  private loadBannerComparison(store: Store, userPreferences: UserPreferences, marketArea: FeatureCollection): void {
    if (store.banner && marketArea) {
      const request = new BannerRankingRequest();
      request.uuid = store.banner.uuid;
      request.store = new Store(store);
      request.salesSqftDisplayType = userPreferences.salesSqftDisplayMode;

      //Do sales sqft request
      request.keyIndicator = KeyIndicatorType.SALES_SQFT;
      this.bannerComparisonService.findOne(request)
        .subscribe((result: BannerComparison) => {
          this.ngrxStore.dispatch(DetailSetSalesSqftBannerComparisonAction({comparison: result}));
        });

      //Do sales volume request
      request.keyIndicator = KeyIndicatorType.SALES_VOLUME;
      this.bannerComparisonService.findOne(request)
        .subscribe((result: BannerComparison) => {
          this.ngrxStore.dispatch(DetailSetSalesVolumeBannerComparisonAction({comparison: result}));
        });

      //Do store count request
      request.keyIndicator = KeyIndicatorType.STORE_COUNT;
      this.bannerComparisonService.findOne(request)
        .subscribe((result: BannerComparison) => {
          this.ngrxStore.dispatch(DetailSetStoreCountBannerComparisonAction({comparison: result}));
        });
    }
  }

  private loadCustomComparisons(comparisonRequests: StoreComparison[], defaultComparisonRequests: StoreComparison[]): Observable<StoreComparison[]> {
    //The only comparisons we actually need to request from the server here are the NON-DEFAULT comparisons
    comparisonRequests = _.reject(comparisonRequests, (comparisonRequest: StoreComparison) => {
      return !!_.find(defaultComparisonRequests, (defaultComparison: StoreComparison) => defaultComparison.equals(comparisonRequest));
    });

    if (comparisonRequests.length) {
      const tasks: Observable<StoreComparison>[] = comparisonRequests.map((request: StoreComparison) => this.storeComparisonService.findOneFromServer(request)
        .pipe(tap((comparison: StoreComparison) => {
          this.ngrxStore.dispatch(DetailAddStoreComparisonAction({comparison: comparison}));
        })));

      return forkJoin(tasks)
    } else {
      return of([]);
    }
  }

  private loadDefaultComparisons(comparisonRequests: StoreComparison[]): Observable<StoreComparison[]> {
    const tasks: Observable<StoreComparison>[] = comparisonRequests.map((request: StoreComparison) => {
      return this.storeComparisonService.findOneFromServer(request)
        .pipe(tap((comparison: StoreComparison) => {
          this.ngrxStore.dispatch(DetailAddStoreComparisonAction({comparison: comparison}));
        }));
    });

    return forkJoin(tasks);
  }

  private loadDummyBannerComparison(): void {
    this.ngrxStore.dispatch(DetailSetSalesSqftBannerComparisonAction({comparison: SAMPLE_RANKING_SALES_SQFT}));
    this.ngrxStore.dispatch(DetailSetSalesVolumeBannerComparisonAction({comparison: SAMPLE_RANKING_SALES_VOLUME}));
    this.ngrxStore.dispatch(DetailSetStoreCountBannerComparisonAction({comparison: SAMPLE_RANKING_STORE_COUNT}));
  }

  private loadDummyComparisons(store: Store, userPreferences: UserPreferences): Observable<StoreComparison[]> {
    const comparisonRequests = this.buildDummyComparisons(store, null, userPreferences);

    const customComparisonRequests = _.uniqWith(comparisonRequests, (a: StoreComparison, b: StoreComparison) => {
      return a.equals(b);
    });

    return of(comparisonRequests)
      .pipe(tap((results: StoreComparison[]) => {
        this.ngrxStore.dispatch(DetailSetStoreDefaultComparisonsAction({comparisons: results}));
        this.ngrxStore.dispatch(DetailSetStoreCustomComparisonsAction({comparisons: customComparisonRequests}));
        this.ngrxStore.dispatch(DetailSetStoreCustomComparisonRequestsAction({comparisons: customComparisonRequests}));
      }));
  }

  private loadDummyRatings(): Observable<AnalystRatingResponse> {
    const response = new AnalystRatingResponse();
    response.ratingDate = DateUtil.nDaysAgo(30);

    SimpleRatingType.values().forEach((type: SimpleRatingType) => {
      const rating = new SimpleRating();
      rating.type = type;
      rating.value = Math.floor(Math.random() * 5) + 1;
      response.ratings.push(rating);
    });

    return of(response)
      .pipe(tap((result: AnalystRatingResponse) => {
        this.ngrxStore.dispatch(DetailSetAnalystRatingsAction({rating: result}));
      }));
  }

  private loadDummyServices(): Observable<StoreServices> {
    const services = new StoreServices();
    services.surveyDate = new Date();

    services.deli = true;
    services.bakery = true;
    services.cheese = true;
    services.floral = true;
    services.meat = true;
    services.pharmacy = true;

    return of(services)
      .pipe(tap((result: StoreServices) => {
        this.ngrxStore.dispatch(DetailSetStoreServicesAction({services: result}));
      }));
  }

  private loadDummyVolumes(): void {
    const volume = new StoreVolume();
    volume.type = VolumeType.ESTIMATE;
    volume.salesArea = 12345;
    volume.total = 123456;
    volume.totalArea = 123456;

    volume.salesSqftBySalesArea = round(volume.total / volume.salesArea);
    volume.salesSqftByTotalArea = round(volume.total / volume.totalArea);

    volume.date = DateUtil.nDaysAgo(30);

    const volumes = [volume];

    this.ngrxStore.dispatch(DetailSetVolumeAction({volume}));
    this.ngrxStore.dispatch(DetailSetVolumeHistoryAction({volumes}));
  }

  private loadAuthState(): Observable<UserState> {
    return selectUserState(this.ngrxStore)
      .pipe(filter((state: UserState) => !!state.userPreferences))
      .pipe(take(1))
      .pipe(map((state: UserState) => {
        return state;
      }));
  }

  private loadMarketArea(store: Store): Observable<FeatureCollection> {
    const request: SearchIntersectingShapeRequest = {
      latitude: store.space.location.getPointLatitude(),
      longitude: store.space.location.getPointLongitude(),
      type: SpatialShapeType.CBSA
    };

    return this.shapeService.searchIntersectingShape(request)
      .pipe(tap((result: FeatureCollection) => {
        this.ngrxStore.dispatch(DetailSetMarketAreaAction({area: result}));
      }));
  }

  private loadNearbyCompetition(driveTime: FeatureCollection): Observable<Feature[]> {
    const geoJsonFilter = new GeoJsonFilter();
    geoJsonFilter.value = driveTime.getFeature().geometry;

    const statusFilter = new StatusFilter();
    statusFilter.value = [...ActiveStoreStatuses];

    const filterGroup = new FilterGroup();
    filterGroup.relationshipType = FilterGroupRelationshipType.AND;
    filterGroup.filters.push(geoJsonFilter)
    filterGroup.filters.push(statusFilter)

    const request = new StoreFeatureRequest();
    request.filterGroup = filterGroup;

    return this.featureService.findStores(request)
      .pipe(map((result: Pageable<Feature>) => {
        this.ngrxStore.dispatch(DetailSetCompetitionAction({competition: result.content}));
        return result.content;
      }));
  }

  private loadStore(uuid: string, licenseType: LicenseType): Observable<Store> {
    return this.storeService.findOne(uuid)
      .pipe(tap((result: Store) => {
        if (!isLicenseOfLevel(licenseType, LicenseType.INTERNAL)) {
          this.storeService.fillWithDummyData(result, licenseType);
        }
        this.ngrxStore.dispatch(DetailSetStoreAction({store: result}));
      }));
  }

  private loadStoreServices(store: Store): Observable<StoreServices> {
    return this.storeService.fineOnesServices(store.uuid)
      .pipe(tap((result: StoreServices) => {
        this.ngrxStore.dispatch(DetailSetStoreServicesAction({services: result}));
      }));
  }

  private loadVolumeHistoryAndFindBestVolume(uuid: string): Observable<StoreVolume> {
    return this.storeService.findOnesVolumes(uuid)
      .pipe(map((volumes: StoreVolume[]) => {
        this.ngrxStore.dispatch(DetailSetVolumeHistoryAction({volumes}));

        let volume: StoreVolume;
        if (volumes.length) {
          //Find best purchased volume, if any
          volume = _.find(volumes, (volume: StoreVolume) => !!volume.total);
          //If we don't have a purchased volume, take the newest volume and let components figure out what to do with it
          if (!volume) {
            volume = volumes[0];
          }

          if (volume) {
            this.ngrxStore.dispatch(DetailSetVolumeAction({volume}));
          }
        }

        return volume;
      }))
  }

  private load7MinuteDriveTime(store: Store): Observable<FeatureCollection> {
    return this.locationService.findOnesIsochrone(store.space.location.uuid, 7)
      .pipe(tap((result: FeatureCollection) => {
        this.ngrxStore.dispatch(DetailSetDefaultDriveTimeAction({driveTime: result}));
      }));
  }

  private prepareDefaultComparisonRequests(store: Store, volume: StoreVolume, marketArea: FeatureCollection, userPreferences: UserPreferences): StoreComparison[] {
    const comparisonRequests: StoreComparison[] = this.buildDefaultComparisons(store, volume, marketArea, userPreferences);
    this.ngrxStore.dispatch(DetailSetStoreDefaultComparisonRequestsAction({comparisons: comparisonRequests}));
    return comparisonRequests;
  }

  private prepareCustomComparisonRequests(store: Store, defaultComparisonRequests: StoreComparison[], userPreferences: UserPreferences): Observable<StoreComparison[]> {
    return this.storageService.get(StorageService.DETAIL_STORE_RANKING_COMPARISONS)
      .pipe(switchMap((values: any[]) => {
        let comparisonRequests: StoreComparison[];

        //Use stored comparisons, else default
        if (values?.length) {
          comparisonRequests = values.map((value: any) => new StoreComparison(value));
        } else {
          comparisonRequests = defaultComparisonRequests.map((comparison: StoreComparison) => new StoreComparison(comparison));
        }

        //Overwrite the store
        comparisonRequests.forEach((comparison: StoreComparison) => comparison.store = store);

        //The custom comparisons key off of the user's preferred key indicator, so make sure our requests respect that
        comparisonRequests.forEach((request: StoreComparison) => {
          request.keyIndicator = userPreferences.primaryKeyIndicator;
        });
        comparisonRequests = _.uniqWith(comparisonRequests, (a: StoreComparison, b: StoreComparison) => {
          return a.equals(b);
        });

        this.storageService.set(StorageService.DETAIL_STORE_RANKING_COMPARISONS, comparisonRequests).subscribe();

        this.ngrxStore.dispatch(DetailSetStoreCustomComparisonRequestsAction({comparisons: comparisonRequests}));

        return of(comparisonRequests);
      }));
  }
}

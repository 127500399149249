import { Action, createReducer, on, select, Store } from '@ngrx/store';
import { Definition } from './definition';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import { filter } from 'rxjs/operators';
import {
  DefinitionCacheUserProfileAction,
  DefinitionSetAddOnTypesAction,
  DefinitionSetIntersectionTypesAction,
  DefinitionSetLicenseTypesAction,
  DefinitionSetQuadrantTypesAction,
  DefinitionSetShoppingCenterTypesAction,
  DefinitionSetStoreStatusTypesAction
} from './definition-actions';
import { UserProfile } from '../user-profile/user-profile';
import { ShoppingCenterType } from '../identity/constant/shopping-center-type.enum';
import { QuadrantType } from '../identity/constant/quadrant-type.enum';
import { StoreStatusType } from '../identity/constant/store-status-type.enum';
import { IntersectionType } from '../identity/constant/intersection-type.enum';
import { FitType } from '../store/fit/fit-type.enum';
import { FormatType } from '../store/format/format-type.enum';
import { AddOnType } from '../company/add-on-type.enum';

export const DEFINITION_STATE_KEY = 'definitionState';

export interface DefinitionState {
  addOnTypes: Definition<AddOnType>[];
  fits: Definition<FitType>[];
  formats: Definition<FormatType>[];
  intersectionTypes: Definition<IntersectionType>[];
  licenseTypes: Definition<LicenseType>[];
  quadrantTypes: Definition<QuadrantType>[];
  shoppingCenterTypes: Definition<ShoppingCenterType>[];
  storeStatusTypes: Definition<StoreStatusType>[];
  userProfiles: Map<string, UserProfile>;
}

const initialState: DefinitionState = {
  addOnTypes: [],
  fits: [],
  formats: [],
  intersectionTypes: [],
  licenseTypes: [],
  quadrantTypes: [],
  shoppingCenterTypes: [],
  storeStatusTypes: [],
  userProfiles: new Map<string, UserProfile>()
};

export const selectDefinitionState = (store: Store<any>) => store.pipe(select((state: any) => state.definitionState), filter(state => state !== undefined));

const _definitionReducer = createReducer(
  initialState,
  on(DefinitionSetAddOnTypesAction, (state, {definitions}) => ({...state, addOnTypes: definitions})),
  on(DefinitionSetIntersectionTypesAction, (state, {definitions}) => ({...state, intersectionTypes: definitions})),
  on(DefinitionSetLicenseTypesAction, (state, {definitions}) => ({...state, licenseTypes: definitions})),
  on(DefinitionCacheUserProfileAction, (state, {user}) => {
    const newState = {...state};
    newState.userProfiles.set(user.uuid, user);
    return newState;
  }),
  on(DefinitionSetShoppingCenterTypesAction, (state, {definitions}) => ({...state, shoppingCenterTypes: definitions})),
  on(DefinitionSetStoreStatusTypesAction, (state, {definitions}) => ({...state, storeStatusTypes: definitions})),
  on(DefinitionSetQuadrantTypesAction, (state, {definitions}) => ({...state, quadrantTypes: definitions}))
);

export function definitionReducer(state: DefinitionState | undefined, action: Action) {
  return _definitionReducer(state, action);
}

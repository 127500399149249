<div class="area-filters-assembly container">
  <mtn-map-toolbar-button icon="draw-polygon" [badgeCount]="toolbarState.badgeCountArea"
                          [color]="toolbarState.badgeCountArea ? 'green' : 'grey'"
                          [iconColor]="toolbarState.badgeCountArea ? 'green' : 'default'"
                          [matMenuTriggerFor]="areaMenu" [disabled]="!hasStandardLicense"
                          (menuOpened)="showSliders()" (menuClosed)="hideSliders()"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                          [analyticsEventParameters]="{'target': 'map-area-filters-menu'}"
                          [matTooltip]="hasStandardLicense ? 'Area Filters' : 'Area Filters (Upgrade to unlock!)'"></mtn-map-toolbar-button>
</div>

<mat-menu class="wide-menu" #areaMenu="matMenu">
  <div class="sales-menu-container toolbar-menu container" mtnClickTrap [preventDefault]="false" fxLayout="column"
       fxLayoutGap="30px" style="width: 300px">
    <div class="filter-container" [formGroup]="salesAreaForm">
      <div fxLayout="row">
        <strong>Sales Area</strong>
        <div fxFlex></div>
        <a href="javascript:void(0)" (click)="clearSalesArea()"
           [ngClass]="{'color-accent': toolbarState?.filterSalesArea, 'color-muted': !toolbarState?.filterSalesArea}">Clear</a>
      </div>
      <hr/>
      <div class="container" style="height: 54px">
        <ngx-slider class="mtn-slider" formControlName="salesArea" [options]="salesAreaConfig"
                    *ngIf="isMenuReady; else sliderLoader"></ngx-slider>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-checkbox color="accent" formControlName="isExclusive">Exclusive</mat-checkbox>
        <mtn-what-is-this [text]="EXCLUSIVE_FILTER_DEFINITION"></mtn-what-is-this>
      </div>
    </div>
    <div class="filter-container" [formGroup]="totalAreaForm">
      <div fxLayout="row">
        <strong>Total Area</strong>
        <div fxFlex></div>
        <a href="javascript:void(0)" (click)="clearTotalArea()"
           [ngClass]="{'color-accent': toolbarState?.filterTotalArea, 'color-muted': !toolbarState?.filterTotalArea}">Clear</a>
      </div>
      <hr/>
      <div class="container" style="height: 54px">
        <ngx-slider class="mtn-slider" formControlName="totalArea" [options]="totalAreaConfig"
                    *ngIf="isMenuReady; else sliderLoader"></ngx-slider>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-checkbox color="accent" formControlName="isExclusive">Exclusive</mat-checkbox>
        <mtn-what-is-this [text]="EXCLUSIVE_FILTER_DEFINITION"></mtn-what-is-this>
      </div>
    </div>
  </div>
</mat-menu>

<ng-template #sliderLoader>
  <mtn-spinner [size]="SpinnerSize.EXTRA_SMALL" [on]="true"></mtn-spinner>
</ng-template>

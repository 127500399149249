import { Wizard } from './wizard';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '../base-component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ThemePalette } from '@angular/material/core';

export abstract class WizardPageComponent<T extends Wizard<any>> extends BaseComponent {

  /*
  A page key just needs to be unique for a given wizard, not globally unique. This key is what should be used when
  configuring the pageTypeMap in your Wizard implementation.
   */
  abstract key: string;

  //You should set these once in your implementation, usually in onLoad().
  form = new UntypedFormGroup({});

  /*
  Configure these on-the-fly and at any point (though usually in onLoad()) your page implementation to control page
  content and behavior.
   */
  actionButtonColor: ThemePalette = 'accent';
  actionButtonIcon: IconProp;
  actionButtonIconSize = 18;
  actionButtonText: string;
  alert1Text: string;
  alert2Text: string;
  backButtonText: string;
  closeButtonText: string;
  footerCheckboxText: string;
  footerText: string;
  headerText: string;
  isActionButtonEnabled = false;
  isVisible = true;
  nextButtonColor: ThemePalette = 'primary';
  nextButtonIcon: IconProp;
  nextButtonIconSize = 18;
  nextButtonText: string;
  reloadOnNavigation = false; //Whether onLoad should be called each time the user navigates to this page, usually false
  stepperText: string;
  subheaderText: string;
  title: string;
  subTitle: string;

  //These are mostly handled by the framework, and you shouldn't need to reference these at all.
  isComplete = false;
  isLoaded = false;
  isVisited = false;
  formSubscription: Subscription;

  //These are just for reference or convenience.
  close: () => void;
  wizard: T;

  /**
   * This method will rarely be used, and is only to provide a hook into the wizard toolbar for a custom action on a
   * given page. Generally, you will use onNext() to handle normal wizard workflow.
   *
   * This method should return the key of the page to be loaded after the action completes, in order to allow dynamic
   * wizard flow. To stay on the same page, return the current page key.
   *
   * If no key is provided, it instructs the wizard to close.
   */
  onAction(): Observable<string> {
    return of(null);
  }

  onBack(): Observable<null> {
    return of(null);
  }

  onClose(): Observable<null> {
    return of(null);
  }

  /**
   * Use this to perform observable-bound loading before the page is rendered.
   */
  onLoad(): Observable<any> {
    return of(null);
  }

  /**
   * This method should return the key of the page to be loaded next, in order to allow dynamic wizard flow.
   *
   * If no key is provided, it instructs the wizard to close.
   */
  onNext(): Observable<string> {
    return of(null);
  }
}

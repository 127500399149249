import MVCObject = google.maps.MVCObject;
import Marker = google.maps.Marker;
import Polygon = google.maps.Polygon;
import { MtnMap } from './mtn-map';

export enum LayerType {
  MARKER = 'MARKER',
  THEMATIC = 'THEMATIC'
}

export class Layer {
  objects: MVCObject[] = [];
  type: LayerType;

  private _map: MtnMap;

  constructor(type: LayerType, map: MtnMap) {
    this.type = type;
    this._map = map;
  }

  add(object: MVCObject): void {
    Layer.setMap(object, this._map);
    this.objects.push(object);
  }

  addAll(objects: MVCObject[]): void {
    objects.forEach((object: MVCObject) => Layer.setMap(object, this._map));
    this.objects.push.apply(this.objects, objects);
  }

  clear(): void {
    this.objects.forEach((object: MVCObject) => Layer.setMap(object, null));
    this.objects = [];
  }

  private static setMap(object: MVCObject, map: MtnMap): void {
    if (object instanceof Marker || object instanceof Polygon) {
      object.setMap(map);
    }
  }

}

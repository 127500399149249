<div [formGroup]="form">
  <mat-form-field class="default-field-width" appearance="outline">
    <mat-select [formControlName]="controlName" [required]="isRequired">
      <mat-option *ngFor="let option of options" [value]="option.systemName">{{option.displayName}}</mat-option>
    </mat-select>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>
</div>

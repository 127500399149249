import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnCoreModule } from '../core/mtn-core.module';
import { AuthenticationService } from './authentication/authentication.service';
import { StoreModule } from '@ngrx/store';
import { USER_STATE_KEY, userReducer } from './user-state';
import { AuthenticatingComponent } from './authentication/authenticating/authenticating.component';
import { ActivityMonitoringComponent } from './authentication/activity-monitoring/activity-monitoring.component';
import { TokenMonitoringComponent } from './authentication/token-monitoring/token-monitoring.component';
import { InactivitySignOutPageComponent } from './authentication/activity-monitoring/inactivity-sign-out-wizard/inactivity-sign-out-page/inactivity-sign-out-page.component';
import { InactivitySignedOutPageComponent } from './authentication/activity-monitoring/inactivity-signed-out-wizard/inactivity-signed-out-page/inactivity-signed-out-page.component';
import { WelcomeWizardStartPageComponent } from './authentication/welcome-wizard/welcome-wizard-start-page/welcome-wizard-start-page.component';
import { WelcomeWizardVolumePageComponent } from './authentication/welcome-wizard/welcome-wizard-volume-page/welcome-wizard-volume-page.component';
import { WelcomeWizardKeyIndicatorPageComponent } from './authentication/welcome-wizard/welcome-wizard-key-indicator-page/welcome-wizard-key-indicator-page.component';
import { WelcomeWizardMapPageComponent } from './authentication/welcome-wizard/welcome-wizard-map-page/welcome-wizard-map-page.component';
import { WelcomeWizardEndPageComponent } from './authentication/welcome-wizard/welcome-wizard-end-page/welcome-wizard-end-page.component';
import { MtnMapModule } from '../map/mtn-map.module';


@NgModule({
  declarations: [
    AuthenticatingComponent,
    ActivityMonitoringComponent,
    TokenMonitoringComponent,
    InactivitySignOutPageComponent,
    InactivitySignedOutPageComponent,
    WelcomeWizardStartPageComponent,
    WelcomeWizardVolumePageComponent,
    WelcomeWizardKeyIndicatorPageComponent,
    WelcomeWizardMapPageComponent,
    WelcomeWizardEndPageComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_STATE_KEY, userReducer),
    MtnCoreModule,
    MtnMapModule
  ],
  exports: [
    ActivityMonitoringComponent,
    TokenMonitoringComponent
  ],
  providers: [
    AuthenticationService
  ]
})
export class MtnAuthModule {
}

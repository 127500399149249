import * as _ from 'lodash';
import {
  ActiveStoreStatuses,
  FutureStoreStatuses,
  HistoricalStoreStatuses
} from '../../core/identity/constant/store-status-type.enum';
import { MarkerZIndexUtil } from './marker-z-index-util';
import { MarkerIconUtil } from './marker-icon-util';
import { MtnMapOptions } from '../mtn-map-options';
import { FeatureType } from '../../core/federation/feature/feature-type.enum';
import { Feature } from '../../core/models';
import Marker = google.maps.Marker;
import MarkerOptions = google.maps.MarkerOptions;

export class MarkerUtil {

  /**
   * Note that this builds the marker, but does NOT add it to the map! You'll need to make sure you call setMap() yourself
   * when you're ready to actually draw the marker.
   */
  static buildMarker(feature: Feature, isSelected: boolean, mapOptions: MtnMapOptions): Marker {
    const options = this.buildMarkerOptions(feature, isSelected);

    const marker = new Marker(options);
    marker.set('feature', feature);
    marker.set('id', feature.id);

    marker.addListener('click', () => {
      if (mapOptions.onMarkerClick && typeof mapOptions.onMarkerClick === 'function') {
        mapOptions.onMarkerClick(marker);
      }
    });

    return marker;
  }

  /**
   * Note that this builds the markers, but does NOT add them to the map! You'll need to make sure you call setMap() yourself
   * when you're ready to actually draw the markers.
   */
  static buildMarkers(features: Feature[], isSelected: boolean, mapOptions: MtnMapOptions): Marker[] {
    return features.map((feature: Feature) => this.buildMarker(feature, isSelected, mapOptions));
  }

  /**
   * Since it makes no sense to stack markers on top of each other, we filter the list of features down to only the most
   * relevant per location, so we can then draw only one marker per location using the most relevant feature.
   */
  static getRelevantFeatures(features: Feature[]): Feature[] {
    const featureIndex = this.indexFeatures(features);

    const relevantFeatures: Feature[] = [];
    featureIndex.forEach((features: Feature[]) => {
      relevantFeatures.push(features[0]);
    });

    return relevantFeatures;
  }

  private static buildMarkerOptions(feature: Feature, isSelected: boolean): MarkerOptions {
    const icon = MarkerIconUtil.buildIcon(feature, isSelected);
    const position = feature.getGeometryAsLatLng();
    const zIndex = MarkerZIndexUtil.getMarkerZIndex(feature, isSelected);

    return {
      icon: icon,
      position: position,
      zIndex: zIndex
    };
  }

  /*
   In order to draw only the "most relevant" marker, we index the features so we can pick only the best candidate at each
   location. We used to index features by spaceId, but that really only works for spaces and stores. In order to support
   all markers, we need to index more abstractly, and so we create a simple hash of the feature's location.

   Despite what the UI currently shows, I believe we've decided that we can only show Stores OR Shopping Centers at any
   given time, if for no other reason than the UX headache of managing filters for both stores and shopping centers at
   the same time. Therefore, we can assume here that all Features will be of the same type.
   */
  private static indexFeatures(features: Feature[]): Map<string, Feature[]> {
    const map = new Map<string, Feature[]>();

    let featureType: FeatureType;

    //Add all features to the map
    features.forEach((feature: Feature) => {
      //Figure out what type of features we're dealing with
      if (!featureType && feature.isStore()) {
        featureType = FeatureType.STORE;
      } else if (!featureType && feature.isShoppingCenter()) {
        featureType = FeatureType.SHOPPING_CENTER;
      }

      const hash = feature.getLocationHash();
      if (!map.has(hash)) {
        map.set(hash, []);
      }

      map.get(hash).push(feature);
    });

    //Sort each array in the map per featureType
    if (featureType === FeatureType.STORE) {
      this.sortStoreFeatures(map);
    }

    return map;
  }

  /**
   * Sorts each array in the map by Active > Future > Closed, so that we can pick the most relevant store to draw on the
   * map.
   */
  private static sortStoreFeatures(map: Map<string, Feature[]>): void {
    map.forEach((values: Feature[]) => {
      values.sort((a: Feature, b: Feature) => {
        if (a.isStore() && b.isStore()) {
          const aStatus = a.getStore().getCurrentStatus()?.getStatusSystemName();
          const aStatusDate = a.getStore().getCurrentStatus()?.statusDate;
          const bStatus = b.getStore().getCurrentStatus()?.getStatusSystemName();
          const bStatusDate = b.getStore().getCurrentStatus()?.statusDate;

          const isAHistorical = _.includes(HistoricalStoreStatuses, aStatus);
          const isAActive = _.includes(ActiveStoreStatuses, aStatus);
          const isAFuture = _.includes(FutureStoreStatuses, aStatus);

          const isBHistorical = _.includes(HistoricalStoreStatuses, bStatus);
          const isBActive = _.includes(ActiveStoreStatuses, bStatus);
          const isBFuture = _.includes(FutureStoreStatuses, bStatus);

          if ((isAActive && isBActive)
            || (isAHistorical && isBHistorical)
            || (isAFuture && isBFuture)) {
            if (aStatusDate && bStatusDate) {
              return bStatusDate.getTime() - aStatusDate.getTime();
            }
          } else if (isAActive) {
            return -1;
          } else if (isBActive) {
            return 1;
          } else if (isAFuture) {
            return -1;
          } else if (isBFuture) {
            return 1;
          }
        }

        return 0;
      });
    });
  }
}

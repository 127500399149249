import { Wizard } from '../../../../core/wizard/wizard';
import { InactivitySignOutPageComponent } from './inactivity-sign-out-page/inactivity-sign-out-page.component';

export class InactivitySignOutWizard extends Wizard<InactivitySignOutWizardModel> {
  key = 'inactivity-sign-out';
  pageTypeMap = {
    'inactivity-sign-out': InactivitySignOutPageComponent
  };
  startingPageKey = 'inactivity-sign-out';
}

export interface InactivitySignOutWizardModel {

}

import { Component, Input, OnInit } from '@angular/core';
import { BannerService } from '../banner.service';
import { Banner } from '../../models';

@Component({
  selector: 'mtn-banner-view',
  templateUrl: './banner-view.component.html',
  styleUrls: ['./banner-view.component.scss']
})
export class BannerViewComponent implements OnInit {

  @Input()
  uuid: string;

  banner: Banner;

  constructor(private bannerService: BannerService) {
  }

  ngOnInit(): void {
    this.loadBanner();
  }

  private loadBanner(): void {
    if (this.uuid) {
      this.bannerService.findOne(this.uuid)
        .subscribe((banner: Banner) => {
          this.banner = banner;
        });
    }
  }

}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { EditProfileWizard } from '../edit-profile-wizard';
import { UserProfile } from '../../../../../core/user-profile/user-profile';
import { UserProfileService } from '../../../../../core/user-profile/user-profile.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ToastService } from '../../../../../core/toast/toast.service';

@Component({
  selector: 'mtn-edit-profile-page',
  templateUrl: './edit-profile-page.component.html',
  styleUrls: ['./edit-profile-page.component.scss']
})
export class EditProfilePageComponent extends WizardPageComponent<EditProfileWizard> {

  key = 'edit-profile';

  userProfile: UserProfile;

  constructor(private toaster: ToastService,
              private userService: UserProfileService) {
    super();
    this.title = 'Edit Profile';
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
  }

  onLoad(): Observable<any> {
    return this.userService.findOne(this.wizard.model.uuid)
      .pipe(tap((result: UserProfile) => {
        this.userProfile = result;
      }));
  }

  onNext(): Observable<string> {
    const request = _.cloneDeep(this.userProfile);
    const formValue = this.form.getRawValue();
    Object.assign(request, formValue.userProfile);

    return this.userService.updateOne(request)
      .pipe(map((result: UserProfile) => {
        this.wizard.model.profile = result;
        this.toaster.info("Successfully updated profile");
        return null;
      }));
  }

}

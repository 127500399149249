import { createAction, props } from '@ngrx/store';
import { Feature, FeatureCollection, Store } from '../core/models';
import { StoreComparison } from '../core/store/ranking/store-comparison';
import { AnalystRatingResponse } from '../core/store/rating/analyst-rating-response';
import { BannerComparison } from '../core/banner/banner-comparison';
import { StoreServices } from '../core/store/store-services';
import { StoreVolume } from '../core/store/volume/store-volume';
import { DemographicDataView } from '../core/depot/demographic-data-view';

export const DetailAddStoreComparisonAction = createAction('detail-add-store-comparison', props<{ comparison: StoreComparison }>());
export const DetailAddStoreCustomComparisonRequestAction = createAction('detail-add-store-custom-comparison-request', props<{ comparison: StoreComparison }>());
export const DetailResetAction = createAction('detail-reset');
export const DetailSetAnalystRatingsAction = createAction('detail-set-analyst-ratings', props<{ rating: AnalystRatingResponse }>());
export const DetailSetStoreCountBannerComparisonAction = createAction('detail-set-store-count-banner-comparison', props<{ comparison: BannerComparison }>());
export const DetailSetSalesSqftBannerComparisonAction = createAction('detail-set-sales-sqft-banner-comparison', props<{ comparison: BannerComparison }>());
export const DetailSetSalesVolumeBannerComparisonAction = createAction('detail-set-sales-volume-banner-comparison', props<{ comparison: BannerComparison }>());
export const DetailSetCompetitionAction = createAction('detail-set-competition', props<{ competition: Feature[] }>());
export const DetailSetDefaultDriveTimeAction = createAction('detail-set-default-drive-time', props<{ driveTime: FeatureCollection }>());
export const DetailSetDemographicsAction = createAction('detail-set-demographics', props<{ demographics: DemographicDataView, isDemographicsSampleData?: boolean }>());
export const DetailSetStoreDefaultComparisonsAction = createAction('detail-set-store-default-comparisons', props<{ comparisons: StoreComparison[] }>());
export const DetailSetStoreDefaultComparisonRequestsAction = createAction('detail-set-store-default-comparison-requests', props<{ comparisons: StoreComparison[] }>());
export const DetailSetStoreCustomComparisonsAction = createAction('detail-set-store-custom-comparisons', props<{ comparisons: StoreComparison[] }>());
export const DetailSetStoreCustomComparisonRequestsAction = createAction('detail-set-store-custom-comparison-requests', props<{ comparisons: StoreComparison[] }>());
export const DetailSetMarketAreaAction = createAction('detail-set-market-area', props<{ area: FeatureCollection }>());
export const DetailSetStoreAction = createAction('detail-set-store', props<{ store: Store }>());
export const DetailSetStoreServicesAction = createAction('detail-set-store-services', props<{ services: StoreServices }>());
export const DetailSetTradeAreaAction = createAction('detail-set-trade-area', props<{ area: FeatureCollection }>());
export const DetailSetVolumeAction = createAction('detail-set-volume', props<{ volume: StoreVolume }>());
export const DetailSetVolumeHistoryAction = createAction('detail-set-volume-history', props<{ volumes: StoreVolume[] }>());

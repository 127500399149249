import { HttpParams } from '@angular/common/http';

export abstract class RestService {

  protected abstract host: string;
  protected abstract rootPath: string;

  protected buildDefaultPaginationSupportedParams(params: HttpParams): HttpParams {
    if (!params) {
      params = new HttpParams();
    }
    if (!params.has('page')) {
      params = params.set('page', '0');
    }
    if (!params.has('size')) {
      params = params.set('size', '100');
    }

    return params;
  }

  protected buildUrl(extendedPath: string = ''): string {
    //Ensure rootPath is relative
    if (!this.rootPath.startsWith('/')) {
      this.rootPath = `/${this.rootPath}`;
    }

    //Ensure extendedPath is relative
    if (extendedPath && !extendedPath.startsWith('/')) {
      extendedPath = `/${extendedPath}`;
    }

    return `${this.host}${this.rootPath}${extendedPath}`;
  }
}

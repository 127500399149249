import { AuditableEntity } from '../auditable-entity';
import { MapMode } from '../../map/map-mode.enum';
import { KeyIndicatorType } from './key-indicator-type.enum';
import { SalesSqftDisplayType } from './sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from './sales-volume-display-type.enum';
import MapTypeId = google.maps.MapTypeId;

export class UserPreferences extends AuditableEntity {

  isWelcomeWizardComplete = false;
  mapDefaultMode: MapMode;
  mapDefaultType: MapTypeId;
  primaryKeyIndicator: KeyIndicatorType;
  salesSqftDisplayMode: SalesSqftDisplayType;
  salesVolumeDisplayMode: SalesVolumeDisplayType;
  showMapLegendOnMapLoad = false;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);

      if (raw.mapDefaultMode) {
        // @ts-ignore
        this.mapDefaultMode = MapMode[<string>raw.mapDefaultMode];
      }
      if (raw.mapDefaultType) {
        // @ts-ignore
        this.mapDefaultType = MapTypeId[<string>raw.mapDefaultType.toUpperCase()];
      }
      if (raw.primaryKeyIndicator) {
        // @ts-ignore
        this.primaryKeyIndicator = KeyIndicatorType[<string>raw.primaryKeyIndicator];
      }
      if (raw.salesSqftDisplayMode) {
        // @ts-ignore
        this.salesSqftDisplayMode = SalesSqftDisplayType[<string>raw.salesSqftDisplayMode];
      }
      if (raw.salesVolumeDisplayMode) {
        // @ts-ignore
        this.salesVolumeDisplayMode = SalesVolumeDisplayType[<string>raw.salesVolumeDisplayMode];
      }
    }
  }

}

<div class="store-banner-analysis-panel-container container">
  <div class="header-container" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Banner
        Analysis: {{store?.banner?.name || 'No Banner'}}</h1>
      <mtn-what-is-this [text]="PANEL_DESCRIPTION"></mtn-what-is-this>
    </div>
    <div *ngIf="!!marketName">
      <small>In the {{marketName}} CBSA</small>
    </div>
  </div>
  <hr style="margin-bottom: 30px;"/>
  <div *ngIf="isLoading; else content" style="position: relative">
    <mtn-spinner [size]="SpinnerSize.LARGE" [on]="true"></mtn-spinner>
  </div>
  <ng-template #content>
    <div class="no-banner-container container default-padding" fxLayout="row" fxLayoutAlign="start center"
         fxLayoutGap="15px" *ngIf="!store?.banner?.uuid" style="margin-bottom: 15px">
      <fa-icon icon="exclamation-triangle"></fa-icon>
      <span><strong>Independent Store or Missing Banner</strong> - This store does not belong to a banner, or its banner is missing. It cannot therefore be usefully compared against competing banners in the market area. If you feel this is a mistake, please contact Retailstat to have it corrected.</span>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="15px">
      <mtn-banner-analysis-card [comparison]="comparisonSalesSqft" [store]="store"
                                *ngIf="comparisonSalesSqft"></mtn-banner-analysis-card>
      <mtn-banner-analysis-card [comparison]="comparisonSalesVolume" [store]="store"
                                *ngIf="comparisonSalesVolume"></mtn-banner-analysis-card>
      <mtn-banner-analysis-card [comparison]="comparisonStoreCount" [store]="store"
                                *ngIf="comparisonStoreCount"></mtn-banner-analysis-card>
    </div>
  </ng-template>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicEntity } from '../basic-entity';
import { Pageable } from './pageable';
import { InsightsRestService } from './insights-rest-service';

export abstract class CrudService<V extends BasicEntity> extends InsightsRestService {

  protected abstract path: string;

  protected constructor(protected http: HttpClient) {
    super();
  }

  protected abstract buildInstance(raw: any): V;

  protected abstract buildPageableInstance(raw: any): Pageable<V>;

  addOne(entity: V): Observable<V> {
    const url = this.buildUrl();

    return this.http.post<V>(url, entity)
      .pipe(map((result: V) => this.buildInstance(result)));
  }

  findAll(params?: HttpParams): Observable<Pageable<V>> {
    const url = this.buildUrl();
    params = this.buildDefaultPaginationSupportedParams(params);

    return this.http.get<Pageable<V>>(url, {params: params})
      .pipe(map((resultPage: Pageable<V>) => this.buildPageableInstance(resultPage)));
  }

  findOne(uuid: string): Observable<V> {
    const url = this.buildUrl(`/${uuid}`);

    return this.http.get<V>(url)
      .pipe(map((result: V) => this.buildInstance(result)));
  }

  updateOne(request: V): Observable<V> {
    const url = this.buildUrl(`/${request.uuid}`);

    return this.http.put<V>(url, request)
      .pipe(map((result: V) => this.buildInstance(result)));
  }

  deleteOne(uuid: string): Observable<any> {
    const url = this.buildUrl(`/${uuid}`);

    return this.http.delete<V>(url);
  }

  protected buildUrl(extendedPath: string = ''): string {
    //Ensure path is relative
    if (!this.path.startsWith('/')) {
      this.path = `/${this.path}`;
    }

    //Ensure extendedPath is relative
    if (extendedPath && !extendedPath.startsWith('/')) {
      extendedPath = `/${extendedPath}`;
    }

    return super.buildUrl(`${this.path}${extendedPath}`);
  }

}

import { Component, OnInit } from '@angular/core';
import { UserProfile } from '../../../core/user-profile/user-profile';
import { UserProfileService } from '../../../core/user-profile/user-profile.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { ConfirmRemoveAccessWizard } from '../../../core/auth/confirm-remove-access-wizard/confirm-remove-access-wizard';
import { EditUserWizard } from '../../../core/auth/edit-user-wizard/edit-user-wizard';
import { SetNamedSubject } from '../../administration-actions';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { of } from 'rxjs';
import { Company } from '../../../core/company/company';
import { CompanyService } from '../../../core/company/company.service';

@Component({
  selector: 'mtn-user-administration',
  templateUrl: './user-administration.component.html',
  styleUrls: ['./user-administration.component.scss']
})
export class UserAdministrationComponent implements OnInit {

  company: Company;
  user: UserProfile;

  constructor(private activatedRoute: ActivatedRoute,
              private companyService: CompanyService,
              private ngrxStore: NgrxStore<AppState>,
              private router: Router,
              private userService: UserProfileService,
              private wizardRunner: WizardRunnerService) {
  }

  ngOnInit(): void {
    this.loadUser();
  }

  openConfirmRemoveAccessWizard(): void {
    const wizard = new ConfirmRemoveAccessWizard();
    wizard.model = {
      user: this.user
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: ConfirmRemoveAccessWizard) => {
      if (result.model.isDeleted) {
        this.router.navigate(['administration', 'user']);
      }
    });
  }

  openEditUserWizard(): void {
    const wizard = new EditUserWizard();
    wizard.model = {
      user: this.user
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: EditUserWizard) => {
      if (result.model.isEdited) {
        this.user = result.model.user;
      }
    });
  }

  private loadUser(): void {
    this.activatedRoute.params
      .pipe(take(1))
      .subscribe((params: Params) => {
        this.userService.findOne(params.uuid)
          .pipe(switchMap((user: UserProfile) => {
            this.user = user;
            this.ngrxStore.dispatch(SetNamedSubject({subject: user}));

            if (user.company?.uuid) {
              return this.companyService.findOne(user.company.uuid)
                .pipe(tap((company: Company) => {
                  this.company = company;
                }))
            } else {
              return of(null);
            }
          }))
          .subscribe();
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base-component';

@Component({
  selector: 'mtn-collections-page',
  templateUrl: './collections-page.component.html',
  styleUrls: ['./collections-page.component.scss']
})
export class CollectionsPageComponent extends BaseComponent implements OnInit {

  breadcrumbLabels: Map<string, string>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initBreadcrumbs();
  }

  private initBreadcrumbs(): void {
    this.breadcrumbLabels = new Map<string, string>();
    this.breadcrumbLabels.set('collection', 'Collections');
  }

}

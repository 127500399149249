import { Component, Input, OnChanges } from '@angular/core';
import { Definition } from '../../definition/definition';
import { DefinitionService } from '../../definition/definition.service';
import { StoreStatusType } from '../../identity/constant/store-status-type.enum';

@Component({
  selector: 'mtn-store-status-view',
  templateUrl: './store-status-view.component.html',
  styleUrls: ['./store-status-view.component.scss']
})
export class StoreStatusViewComponent implements OnChanges {

  @Input()
  type: StoreStatusType;
  @Input()
  definition: Definition<StoreStatusType>;

  constructor(private definitionService: DefinitionService) {
  }

  ngOnChanges(): void {
    this.loadDefinition();
  }

  private loadDefinition(): void {
    if (!this.definition || this.definition.systemName !== this.type) {
      this.definitionService.findOneStoreStatusType(this.type)
        .subscribe((definition: Definition<StoreStatusType>) => {
          this.definition = definition;
        });
    }
  }
}

import { DateUtil } from './util/date-util';
import { BasicEntity } from './basic-entity';

export abstract class AuditableEntity extends BasicEntity {

  createdBy: string;
  createdDate: Date;
  deletedBy: string;
  deletedDate: Date;
  updatedBy: string;
  updatedDate: Date;
  version: number;

  protected mapAuditableEntityFields(raw: any): void {
    if (raw) {
      //This intentionally does NOT call Object.assign()
      this.version = raw.version;
      this.createdBy = raw.createdBy;
      this.createdDate = DateUtil.buildFromTimestamp(raw.createdDate);
      this.deletedBy = raw.deletedBy;
      this.deletedDate = DateUtil.buildFromTimestamp(raw.deletedDate);
      this.updatedBy = raw.updatedBy;
      this.updatedDate = DateUtil.buildFromTimestamp(raw.updatedDate);
    }
  }
}

export interface Identifiable {
  readonly id: number;
  readonly uuid: string;
}

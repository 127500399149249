<div class="store-spotlight-panel spotlight-panel container" fxFlexFill [hidden]="!isVisible">
  <button class="close-button" mat-icon-button (click)="isVisible = false"
          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
          [analyticsEventParameters]="{'target': 'spotlight-close-spotlight-button'}">
    <fa-icon icon="times"></fa-icon>
  </button>

  <div class="default-padding" fxLayout="row" fxLayoutGap="15px" *ngIf="store">
    <mtn-square-banner-logo [store]="store" *ngIf="store.hasMarkerImage()"></mtn-square-banner-logo>
    <div class="header-container" fxFlex>
      <h2 class="mat-h2 no-bottom-margin text-ellipsis" *ngIf="store.name">{{store.name}}</h2>
      <small class="text-ellipsis" *ngIf="store.number">Store Number: {{store.number}}</small>
    </div>
  </div>

  <div class="content-container container default-padding" *ngIf="store" fxLayout="column" fxLayoutGap="15px">
    <div class="upper-section" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">

      <div class="store-status-highlight-container" *ngIf="isHistoricalStore || isFutureStore">
        <mtn-simple-panel color="blue">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <fa-icon icon="info-circle" size="2x"></fa-icon>
            <div *ngIf="isHistoricalStore">This store is no longer in operation and represents a potential future
              competitive incursion.
            </div>
            <div *ngIf="isFutureStore">This store is not yet in operation but represents a known potential future
              competitive incursion.
            </div>
          </div>
        </mtn-simple-panel>
      </div>

      <div *ngIf="space?.location?.addressLine1">
        <div>{{space?.location?.addressLine1}}</div>
        <div *ngIf="space?.location?.addressLine2">{{space?.location?.addressLine2}}</div>
        <span>{{space?.location?.city}}, {{space?.location?.state}} {{space?.location?.postalCode}}</span>
      </div>
      <mtn-intersection-view [location]="space?.location"
                             *ngIf="space?.location?.getFormattedIntersection()"></mtn-intersection-view>
      <div fxLayout="row" fxLayoutGap="10px" style="width: 100%">
        <button class="details-button" mat-raised-button color="accent" [routerLink]="['/detail', 'store', store.uuid]"
                mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                [analyticsEventParameters]="{'target': 'spotlight-store-details-button'}"
                fxFlex>
          <span>View Details</span>
        </button>
        <mtn-store-actions-menu-button [storeUuids]="[store.uuid]"
                                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                       [analyticsEventParameters]="{'target': 'spotlight-actions-menu-button'}"></mtn-store-actions-menu-button>
      </div>
    </div>
  </div>


  <div class="other-info-container container scrollable" *ngIf="store">
    <div class="container default-padding" fxLayout="column" fxLayoutGap="15px">
      <mtn-restricted-license-overlay *ngIf="!hasStandardLicense"
                                      [buttonSize]="Size.MEDIUM"></mtn-restricted-license-overlay>

      <div>
        <h3 class="mat-h3 text-small-caps color-seafoam">Store Information</h3>
        <mtn-key-value key="Status">
          <mtn-store-status-view [type]="store.getCurrentStatus().getStatusSystemName()"
                                 style="width:100%"
                                 *ngIf="store.getCurrentStatus(); else notAvailable"></mtn-store-status-view>
        </mtn-key-value>
        <div class="container">
          <mtn-key-value key="Est. Sales Volume" [whatIsThisText]="Dictionary.tooltipSalesVolume">
            <mtn-sales-volume-view [volume]="volume"
                                   *ngIf="volume?.total; else volumeNotAvailable"></mtn-sales-volume-view>
          </mtn-key-value>
          <mtn-key-value key="Est. Sales/sqft" [whatIsThisText]="Dictionary.tooltipSalesSqft">
            <mtn-sales-sqft-view [volume]="volume"
                                 *ngIf="volume?.salesSqft; else volumeNotAvailable"></mtn-sales-sqft-view>
          </mtn-key-value>
        </div>
        <mtn-key-value key="FIT Category" [whatIsThisText]="Dictionary.tooltipFitCategory">
          <mtn-definition-view [definitionType]="DefinitionType.FIT" [systemName]="store.fit"
                               *ngIf="store.fit; else notAvailable"></mtn-definition-view>
        </mtn-key-value>
      </div>

      <div>
        <h3 class="mat-h3 text-small-caps color-seafoam">Space Information</h3>
        <mtn-key-value-with-placeholder key="Position in Center" [value]="space.positionInCenter"
                                        [whatIsThisText]="Dictionary.tooltipPositionInCenter"></mtn-key-value-with-placeholder>
        <mtn-key-value key="Total Area" [whatIsThisText]="Dictionary.tooltipTotalArea">
          <span *ngIf="store.totalArea; else notAvailable">{{store.totalArea.toLocaleString()}} sqft</span>
        </mtn-key-value>
        <mtn-key-value key="Sales Area" [whatIsThisText]="Dictionary.tooltipSalesArea">
          <span *ngIf="store.grocerySalesArea; else notAvailable">{{store.grocerySalesArea.toLocaleString()}} sqft <span
            *ngIf="salesAreaPercentage">({{salesAreaPercentage}}% of Total Area)</span></span>
        </mtn-key-value>
      </div>

      <div>
        <h3 class="mat-h3 text-small-caps color-seafoam">Shopping Center Information</h3>
        <mtn-key-value-with-placeholder key="Name" [value]="shoppingCenter.name"></mtn-key-value-with-placeholder>
        <mtn-key-value-with-placeholder key="Owner"
                                        [value]="shoppingCenter.ownerCompany?.name"></mtn-key-value-with-placeholder>
        <mtn-key-value key="Type" [whatIsThisText]="definitionShoppingCenterType?.definition?.description">
          <mtn-definition-view #shoppingCenterType [definitionType]="DefinitionType.SHOPPING_CENTER_TYPE"
                               [systemName]="shoppingCenter.type"
                               *ngIf="shoppingCenter.type; else notAvailable"></mtn-definition-view>
        </mtn-key-value>
        <mtn-key-value key="GLA" [whatIsThisText]="Dictionary.tooltipGrossLeasableArea">
          <span
            *ngIf="shoppingCenter.grossLeasableArea; else notAvailable">{{shoppingCenter.grossLeasableArea.toLocaleString()}}
            sqft</span>
        </mtn-key-value>
        <mtn-key-value key="Website URL">
          <a [href]="shoppingCenter.websiteUrl" target="_blank" fxLayout="row" fxLayoutAlign="start center"
             fxLayoutGap="3px" *ngIf="shoppingCenter.websiteUrl; else notAvailable">
            <span>Click to Visit</span>
            <fa-icon icon="external-link-square-alt"></fa-icon>
          </a>
        </mtn-key-value>
      </div>

      <mat-accordion>
        <mat-expansion-panel *ngIf="otherStores?.length">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div fxLayout="row" fxLayoutAlign="start center" style="width:100%">
                <span class="color-accent">Other Stores at this Location</span>
                <div fxFlex></div>
                <mtn-what-is-this [text]="Dictionary.otherStoresAtThisLocation"></mtn-what-is-this>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mtn-other-stores-table [otherStores]="otherStores" [isDefaultBehaviorDisabled]="true"
                                  (onLinkClick)="handleOtherStoreSelected($event)"></mtn-other-stores-table>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <ng-template #notAvailable>
    <span class="color-muted">Not Available</span>
  </ng-template>

  <ng-template #volumeNotAvailable>
    <span class="color-muted" *ngIf="volume; else notAvailable">Not Purchased</span>
  </ng-template>

  <div fxLayout="column" fxLayoutGap="15px" *ngIf="isFailed">
    <mtn-simple-panel color="red">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <fa-icon icon="exclamation-triangle" size="2x"></fa-icon>
        <div><strong>Uh-oh!</strong> Something went wrong! If the problem persists, please contact a system
          administrator.
        </div>
      </div>
    </mtn-simple-panel>
    <button mat-raised-button color="accent" (click)="loadStore()">
      Retry
    </button>
  </div>

  <mtn-spinner [on]="isLoading" color="primary" [size]="SpinnerSize.LARGE"></mtn-spinner>
</div>

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnalyticsEventType } from '../google-analytics/analytics-event-type';
import { AnalyticsService } from '../google-analytics/analytics.service';
import { AppUtils } from '../app-utils';
import { DefinitionType } from './definition/definition-type.enum';
import { SpinnerSize } from './util/spinner/spinner-size.enum';
import { Dictionary } from './dictionary/dictionary';
import { isLicenseOfLevel, LicenseType } from '../auth/authorization/license-type.enum';
import { Size } from './util/size.enum';

/**
 * This base component should be extended by anything subscribing to observables. It ensures that
 * all subscriptions are properly closed when the component is destroyed, among other shared
 * functionality yet to come.
 */
@Component({
  selector: 'mtn-base-component',
  template: '<div></div>'
})
export abstract class BaseComponent implements OnDestroy {

  AnalyticsEventType = AnalyticsEventType;
  Dictionary = Dictionary;
  DefinitionType = DefinitionType;
  isLicenseOfLevel = isLicenseOfLevel;
  LicenseType = LicenseType;
  Size = Size;
  SpinnerSize = SpinnerSize;

  private readonly subscriptionPool: Subscription[] = [];

  ngOnDestroy() {
    this.subscriptionPool.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  /**
   * Wrap any observable subscription with this to have any necessary closing handling taken care of
   * for you.
   * @param subscription
   */
  addSubscription(subscription: Subscription): Subscription {
    this.subscriptionPool.push(subscription);
    return subscription;
  }

  analyticsEvent(eventType: string, eventParameters?: {}): void {
    const analyticsService: AnalyticsService = AppUtils.injector.get(AnalyticsService);
    analyticsService.event(eventType, eventParameters);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../../core/authorization-aware-component';
import { AppState } from '../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { BannerComparison } from '../../../../core/banner/banner-comparison';
import * as chroma from 'chroma-js';
import { ChartOptions, ChartTooltipModel } from 'chart.js';
import { BannerRanking } from '../../../../core/banner/banner-ranking';
import { Store } from '../../../../core/models';
import { buildRankingDescriptor } from '../../store-sales-performance-panel/competitive-analysis-card/comparison-utils';
import { convertToNth } from '../../../../core/util/string-utils';
import { Dictionary } from '../../../../core/dictionary/dictionary';
import { BannerComparisonWizard } from './banner-comparison-wizard/banner-comparison-wizard';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import { KeyIndicatorType } from '../../../../core/user-preferences/key-indicator-type.enum';

@Component({
  selector: 'mtn-banner-analysis-card',
  templateUrl: './banner-analysis-card.component.html',
  styleUrls: ['./banner-analysis-card.component.scss']
})
export class BannerAnalysisCardComponent extends AuthorizationAwareComponent implements OnChanges {

  private readonly GRADIENT = chroma.scale(['red', 'orange', 'limegreen']);

  @Input()
  comparison: BannerComparison;
  @Input()
  store: Store;

  chartColors: any[];
  chartDataSets: any[];
  chartLabels: string[];
  chartOptions: ChartOptions;
  color: string;
  comparisonDescriptor1: string;
  comparisonDescriptor2: string;
  prefix: string;
  ranking: BannerRanking;
  rankingDescriptor: string;
  tooltip: string;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.ranking || !changes.ranking?.isFirstChange()) {
      this.ranking = this.comparison.findSubjectBannerRanking();
      this.init();
    }
  }

  onAuthorizationChange(): void {

  }

  onAuthorizationInit(): void {
  }

  openComparisonDetailWizard(): void {
    const wizard = new BannerComparisonWizard();
    wizard.model = {
      licenseType: this.licenseType,
      store: this.store,
      comparison: this.comparison,
      userPreferences: this.userPreferences
    };

    this.wizardRunner.run(wizard);
  }

  private buildComparisonDescriptor(): void {
    switch (this.comparison.keyIndicator) {
      case KeyIndicatorType.SALES_SQFT:
        this.comparisonDescriptor1 = Dictionary.labelSalesSqft;
        this.comparisonDescriptor2 = 'Competitor';
        break;
      case KeyIndicatorType.SALES_VOLUME:
        this.comparisonDescriptor1 = Dictionary.labelSalesVolume;
        this.comparisonDescriptor2 = 'Competitor';
        break;
      case KeyIndicatorType.STORE_COUNT:
        this.comparisonDescriptor1 = 'Store Count';
        this.comparisonDescriptor2 = 'Presence';
        break;
    }
  }

  private init(): void {
    if (this.comparison.rankings.length) {
      this.tooltip = this.ranking.getFormattedPercentileString();
      this.rankingDescriptor = buildRankingDescriptor(this.ranking);
      this.buildComparisonDescriptor();

      let verb = 'is';
      if (this.comparison.keyIndicator === KeyIndicatorType.STORE_COUNT) {
        verb = 'has';
      }

      if (this.rankingDescriptor.startsWith('A')) {
        this.prefix = `${this.store.banner.name} ${verb} an`;
      } else {
        this.prefix = `${this.store.banner.name} ${verb} a`;
      }

      this.chartDataSets = [{
        data: [this.ranking.percentile, 100 - this.ranking.percentile],
        label: 'Store Ranking'
      }];
      this.chartLabels = [`${convertToNth(this.ranking.percentile)} Percentile`, null];

      this.color = this.GRADIENT(this.ranking.percentile / 100).css();
      const backgroundColor = this.color.replace(')', ',0.6)');
      const hoverBackgroundColor = this.color.replace(')', ',0.5)');
      this.chartColors = [
        {
          backgroundColor: [backgroundColor, 'rgba(0,0,0,0.1)'],
          borderColor: [this.color, 'rgba(0,0,0,0)'],
          hoverBackgroundColor: [hoverBackgroundColor, 'rgba(0,0,0,0.1)'],
          borderWidth: 2
        }
      ];
      this.chartOptions = {
        cutoutPercentage: 75,
        tooltips: {
          bodyAlign: 'center',
          displayColors: false,
          custom: (tooltipModel: ChartTooltipModel) => {
            let index = 1;
            if (tooltipModel.dataPoints) {
              index = tooltipModel.dataPoints[0].index;
              tooltipModel.body[0].lines = [this.chartLabels[0]]
            }

            tooltipModel.opacity = index ? 0 : 1;
            tooltipModel.width = 100;
          }
        }
      };
    }
    //If no rankings were returned, we had insufficient data on the banner in the market
    else {

    }
  }

}

<div [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [formControlName]="controlName" [matAutocomplete]="auto"/>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="getDisplayText"
                      (optionSelected)="handleSelection($event)">
      <mat-option *ngFor="let option of search$ | async; let index = index"
                  [value]="option" style="line-height: 22px">
        <div>{{option.getDisplayName()}}</div>
        <div class="color-muted"><small>{{option.email}}</small></div>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>
</div>

import { Wizard } from '../../core/wizard/wizard';
import { AddToCollectionPageComponent } from './add-to-collection-page/add-to-collection-page.component';
import { Collection } from '../../core/federation/collection/collection';

export class AddToCollectionWizard extends Wizard<AddToCollectionWizardModel> {
  key = 'add-to-collection';
  pageTypeMap = {
    'add-to-collection': AddToCollectionPageComponent
  };
  startingPageKey = 'add-to-collection';
}

export interface AddToCollectionWizardModel {
  collection?: Collection;
  storeUuids?: string[];
  userProfileUuid: string;
}

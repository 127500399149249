import { Pipe, PipeTransform } from '@angular/core';
import { AddOn } from '../add-on';
import * as _ from 'lodash';
import { AddOnType } from '../add-on-type.enum';

@Pipe({
  name: 'isAddOnVisible'
})
export class IsAddOnVisiblePipe implements PipeTransform {

  transform(addOns: AddOn[], hasCustomExtractionAddOn = false): AddOn[] {
    return _.filter(addOns, (addOn: AddOn) => addOn.type !== AddOnType.CUSTOM_EXTRACTION || hasCustomExtractionAddOn);
  }

}

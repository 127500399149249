import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectUserState, UserState } from '../../user-state';
import { BaseComponent } from '../../../core/base-component';
import { AppState } from '../../../app-state';
import { StorageService } from '../../../core/util/storage.service';
import { UserSetRedirectUrl } from '../../user-actions';

@Component({
  selector: 'mtn-authenticating',
  templateUrl: './authenticating.component.html',
  styleUrls: ['./authenticating.component.scss']
})
export class AuthenticatingComponent extends BaseComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              private storageService: StorageService,
              private store: Store<AppState>) {
    super();
  }

  private redirectUrl: string;

  ngOnInit(): void {
    this.loadRedirectUrlFromStorage();
    this.subscribeToAuthStateChanges();
    this.continueAuthentication();
  }

  private continueAuthentication(): void {
    this.authenticationService.continueAuthentication(window.location.hash)
      .pipe(take(1))
      .subscribe(() => {
        this.authenticationService.redirect(this.redirectUrl);
      });
  }

  private loadRedirectUrlFromStorage(): void {
    this.storageService.get(StorageService.AUTH_REDIRECT_URL)
      .subscribe((redirectUrl: string) => {
        if (redirectUrl?.indexOf('maintenance')) {
          this.store.dispatch(UserSetRedirectUrl({url: '/'}));
        } else {
          this.store.dispatch(UserSetRedirectUrl({url: redirectUrl}));
        }
      });
  }

  private subscribeToAuthStateChanges(): void {
    this.addSubscription(
      selectUserState(this.store)
        .subscribe((state: UserState) => {
          this.redirectUrl = state.redirectUrl;
        })
    );
  }

}

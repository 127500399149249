import { KeyIndicatorType } from '../../user-preferences/key-indicator-type.enum';
import { ComparisonType } from './comparison-type.enum';
import { StoreRanking } from './store-ranking';
import { RegionType } from '../../location/region-type.enum';
import { FeatureCollection, Store } from '../../models';
import { SalesSqftDisplayType } from '../../user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from '../../user-preferences/sales-volume-display-type.enum';
import * as _ from 'lodash';
import { NumberLineDataset } from '../../chart/number-line-chart/number-line-dataset';
import { StoreVolume } from '../volume/store-volume';

export class StoreComparison {

  private _comparisonType: ComparisonType;
  private _driveTimeMinutes: number;
  private _geoJson: FeatureCollection;
  private _keyIndicator: KeyIndicatorType;
  private _message: string;
  private _rankings: StoreRanking[] = [];
  private _region: RegionType;
  private _ringMiles: number;
  private _salesSqftDisplayType: SalesSqftDisplayType;
  private _salesVolumeDisplayType: SalesVolumeDisplayType;
  private _store: Store;
  private _volume: StoreVolume;

  private _unchartableReason: string;

  constructor(config: any) {
    if (config) {
      Object.assign(this, config);
      this._rankings = [];

      if (config._comparisonType) {
        // @ts-ignore
        this._comparisonType = ComparisonType[<string>config._comparisonType];
      }
      if (config.geoJson) {
        this._geoJson = new FeatureCollection(config.geoJson);
      }
      if (config.keyIndicator) {
        // @ts-ignore
        this._keyIndicator = KeyIndicatorType[<string>config.keyIndicator];
      }
      if (config.rankings) {
        this._rankings = config.rankings.map((ranking: any) => new StoreRanking(ranking));
      }
      if (config._region) {
        // @ts-ignore
        this._region = RegionType[<string>config._region];
      }
      if (config._salesSqftDisplayType) {
        // @ts-ignore
        this._salesSqftDisplayType = SalesSqftDisplayType[<string>config._salesSqftDisplayType];
      }
      if (config._salesVolumeDisplayType) {
        // @ts-ignore
        this._salesVolumeDisplayType = SalesVolumeDisplayType[<string>config._salesVolumeDisplayType];
      }
      if (config.store) {
        this._store = new Store(config.store);
      }
      if (config.volume) {
        this._volume = new StoreVolume(config.volume);
      }
      this.updateIsChartable();
    }
  }

  get unchartableReason(): string {
    return this._unchartableReason;
  }

  set unchartableReason(reason: string) {
    this._unchartableReason = reason;
  }

  get salesVolumeDisplayType(): SalesVolumeDisplayType {
    return this._salesVolumeDisplayType;
  }

  set salesVolumeDisplayType(value: SalesVolumeDisplayType) {
    this._salesVolumeDisplayType = value;
    this.updateIsChartable();
  }

  get salesSqftDisplayType(): SalesSqftDisplayType {
    return this._salesSqftDisplayType;
  }

  set salesSqftDisplayType(value: SalesSqftDisplayType) {
    this._salesSqftDisplayType = value;
    this.updateIsChartable();
  }

  get ringMiles(): number {
    return this._ringMiles;
  }

  set ringMiles(value: number) {
    this._ringMiles = value;
    this.updateIsChartable();
  }

  get region(): RegionType {
    return this._region;
  }

  set region(value: RegionType) {
    this._region = value;
    this.updateIsChartable();
  }

  get driveTimeMinutes(): number {
    return this._driveTimeMinutes;
  }

  set driveTimeMinutes(value: number) {
    this._driveTimeMinutes = value;
    this.updateIsChartable();
  }

  get comparisonType(): ComparisonType {
    return this._comparisonType;
  }

  set comparisonType(value: ComparisonType) {
    this._comparisonType = value;
    this.updateIsChartable();
  }

  get geoJson() {
    return this._geoJson;
  }

  set geoJson(geojson: FeatureCollection) {
    this._geoJson = geojson;
    this.updateIsChartable();
  }

  get message() {
    return this._message;
  }

  set message(message: string) {
    this._message = message;
    this.updateIsChartable();
  }

  get keyIndicator() {
    return this._keyIndicator;
  }

  set keyIndicator(keyIndicator: KeyIndicatorType) {
    this._keyIndicator = keyIndicator;
    this.updateIsChartable();
  }

  get rankings() {
    return this._rankings;
  }

  set rankings(rankings: StoreRanking[]) {
    this._rankings = rankings;
    this.updateIsChartable();
  }

  get store() {
    return this._store;
  }

  set store(store: Store) {
    this._store = store;
    this.updateIsChartable();
  }

  get volume(): StoreVolume {
    return this._volume;
  }

  set volume(volume: StoreVolume) {
    this._volume = volume;
    this.updateIsChartable();
  }

  equals(request: StoreComparison, compareKeyIndicator = false): boolean {
    return request !== null
      && request.comparisonType === this.comparisonType
      && request.region === this.region
      && (!compareKeyIndicator || request.keyIndicator === this.keyIndicator)
      && (request.region !== RegionType.DRIVE_TIME || (request.driveTimeMinutes === this.driveTimeMinutes))
      && (request.region !== RegionType.RING || (request.ringMiles === this.ringMiles));
  }

  getDataSet(isRelativeModeEnabled = false) {
    const filteredRankings: StoreRanking[] = _.filter(this.rankings, (ranking: StoreRanking) => !Number.isNaN(ranking.value));
    //If relative mode, the subject store will have a value of zero. Make sure it gets added to the filtered rankings.
    if (isRelativeModeEnabled) {
      const subjectRanking = _.find(this.rankings, (ranking: StoreRanking) => ranking.store?.uuid === this.store?.uuid);
      if (subjectRanking) {
        filteredRankings.push(subjectRanking);
      }
    }
    return new NumberLineDataset(this.store, _.orderBy(filteredRankings, 'value', 'asc'))
  }

  getFilteredRankings(): StoreRanking[] {
    return _.filter(this.rankings, (ranking: StoreRanking) => !Number.isNaN(ranking.value));
  }

  getRankingForStore(uuid: string): StoreRanking {
    return _.find(this.rankings, (ranking: StoreRanking) => ranking.store?.uuid === uuid);
  }

  getTitle(): string {
    let title = 'vs ';

    switch (this.comparisonType) {
      case ComparisonType.ALL:
        title += 'All in ';
        break;
      case ComparisonType.BANNER:
        title += 'Same Banner in ';
        break;
      case ComparisonType.FIT_CATEGORY:
        title += 'Same FIT in ';
        break;
    }

    switch (this.region) {
      case RegionType.DRIVE_TIME:
        title += `${this.driveTimeMinutes}-Minute Drive Time`;
        break;
      case RegionType.MARKET_AREA:
        let marketName: string = null;
        if (this.geoJson) {
          // @ts-ignore
          marketName = this.geoJson.getFeature()?.properties['NAME'];
          if (marketName) {
            marketName = `${marketName} CBSA`;
          }
        }
        if (marketName) {
          title += marketName;
        } else {
          title += 'Market Area';
        }
        break;
      case RegionType.RING:
        title += `${this.ringMiles}-Mile Ring`;
        break;
      case RegionType.TRADE_AREA:
        title += 'MTN Trade Area';
        break;
    }

    return title;
  }

  private updateIsChartable(): void {
    this._unchartableReason = null;

    if (!this.store || !this.volume || !this.volume.salesArea) {
      this._unchartableReason = 'Store is missing required data and cannot be compared against other stores';
    } else if (!this.rankings || this.getFilteredRankings().length <= 1) {
      this._unchartableReason = 'No other stores with required data found in the selected region';
    } else if (this.comparisonType === ComparisonType.BANNER && !this.store.banner) {
      this._unchartableReason = 'Store does not belong to a banner, and cannot be compared against any banner';
    } else if (this.comparisonType === ComparisonType.FIT_CATEGORY && !this.store.fit) {
      this._unchartableReason = 'Store does not have a FIT category, and cannot be compared against any FIT category';
    }
  }

  isChartable(): boolean {
    return !this._unchartableReason;
  }
}

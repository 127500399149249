import { Wizard } from '../../../core/wizard/wizard';
import { License } from '../../../core/license/license';
import { LicenseExpiredPageComponent } from './license-expired-page/license-expired-page.component';

export class LicenseExpiredWizard extends Wizard<LicenseExpiredWizardModel> {
  key = 'license-expired';
  pageTypeMap = {
    'license-expired': LicenseExpiredPageComponent
  };
  startingPageKey = 'license-expired';
}

export interface LicenseExpiredWizardModel {
  license: License;
}

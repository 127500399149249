<div class="dashboard-container container" fxFill fxLayout="column" fxLayoutAlign="center center">
  <img src="/assets/images/dashboard-wip.png" style="width:100%" alt="wip-dashboard"/>
  <div class="coming-soon-panel mat-elevation-z1 container default-padding" fxLayout="column"
       fxLayoutAlign="center center">
    <h1 class="mat-h1">Coming Soon!</h1>
    <p>We're hard at work on all kinds of new features for you, and a powerful Dashboard experience is on its way. In
      the meantime, if there are things you're hoping to see on the Dashboard, please let us know with the feedback
      button at the top of the screen.</p>
  </div>
</div>

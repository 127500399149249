import { Injectable } from '@angular/core';
import { CrudService } from '../../service/crud-service';
import { SavedSearch } from './saved-search';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class SavedSearchService extends CrudService<SavedSearch> {

  protected path = '/saved-search';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): SavedSearch {
    return new SavedSearch(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<SavedSearch> {
    return new Pageable<SavedSearch>(raw, SavedSearch);
  }
}

import { Wizard } from '../../wizard/wizard';
import { UpgradeLicensePageComponent } from './upgrade-license-page/upgrade-license-page.component';

export class UpgradeLicenseWizard extends Wizard<UpgradeLicenseWizardModel> {
  key = 'upgrade-license';
  pageTypeMap = {
    'upgrade-license': UpgradeLicensePageComponent
  };
  startingPageKey = 'upgrade-license';

  constructor() {
    super();
  }
}

export interface UpgradeLicenseWizardModel {

}

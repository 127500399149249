<div class="store-feature-card-container container mat-elevation-z1 lighter-shade-1">
  <div class="action-toolbar-container">
    <div fxLayout="column" fxLayoutGap="5px">
      <mtn-icon-button color="warn" matTooltip="Deselect" (onClick)="removeSelection(feature.id)"
                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                       [analyticsEventParameters]="{'target': 'store-feature-card-remove-selection-button'}">
        <fa-icon icon="ban"></fa-icon>
      </mtn-icon-button>
      <a [href]="'/detail/store/' + feature.id" target="_blank">
        <mtn-icon-button matTooltip="View Details" mtnAnalyticsEventTrigger
                         [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                         [analyticsEventParameters]="{'target': 'store-feature-card-view-details-button'}">
          <fa-icon [icon]="['far', 'external-link-alt']"></fa-icon>
        </mtn-icon-button>
      </a>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mtn-square-banner-logo [store]="feature.getStore()"></mtn-square-banner-logo>
    <div class="content-container container">
      <div fxLayout="row" fxLayoutGap="5px">
        <strong>{{feature.getStore().name}}</strong>
        <span class="color-muted" *ngIf="feature.getStore().number">({{feature.getStore().number}})</span>
      </div>
      <div class="address-container" fxLayout="column">
        <small>{{feature.getStore().space.location.addressLine1}}</small>
        <small>{{feature.getStore().space.location.getFormattedPrincipality()}}</small>
      </div>
      <div class="detail-container" fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="3px">
          <span
            matTooltip="Sales Area">{{formatNumericalShorthand(feature.getStore().grocerySalesArea, 0) || 'Unknown'}}</span>
          <span>/</span>
          <span
            matTooltip="Total Area">{{formatNumericalShorthand(feature.getStore().totalArea, 0) || 'Unknown'}}</span>
          <span>sqft</span>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" *ngIf="feature.getVolume()?.total; else noVolume">
          <mtn-sales-volume-view [volume]="feature.getVolume()" [includeSuffix]="false"
                                 [shorthand]="true"></mtn-sales-volume-view>
          <span>|</span>
          <mtn-sales-sqft-view [volume]="feature.getVolume()"></mtn-sales-sqft-view>
        </div>
        <ng-template #noVolume>
          <span
            class="color-muted">{{feature.getVolume()?.date ? 'Sales Volume Not Purchased' : 'Sales Volume Not Available'}}</span>
        </ng-template>
      </div>
    </div>
  </div>

</div>

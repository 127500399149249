import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { CreateCompanyWizard } from '../create-company-wizard';
import { CompanyService } from '../../../../../core/company/company.service';
import { ToastService } from '../../../../../core/toast/toast.service';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Company } from '../../../../../core/company/company';
import { License } from '../../../../../core/license/license';
import { map, switchMap } from 'rxjs/operators';
import { HubspotCompanyService } from '../../../../../core/hubspot/hubspot-company.service';
import { HubspotCompany } from '../../../../../core/hubspot/hubspot-company';

@Component({
  selector: 'mtn-create-company-page',
  templateUrl: './create-company-page.component.html',
  styleUrls: ['./create-company-page.component.scss']
})
export class CreateCompanyPageComponent extends WizardPageComponent<CreateCompanyWizard> {

  key = 'create-company';

  constructor(private companyService: CompanyService,
              private hubSpotCompanyService: HubspotCompanyService,
              private toaster: ToastService) {
    super();
  }

  onLoad(): Observable<any> {
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
    this.title = 'Create Company';
    this.stepperText = 'Create';

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
      description: new UntypedFormControl(null, [Validators.maxLength(255)]),
      type: new UntypedFormControl(null, [Validators.required]),
      expirationDate: new UntypedFormControl(),
      startDate: new UntypedFormControl(new Date(), [Validators.required])
    });

    return of(null);
  }

  onNext(): Observable<string> {
    const request = this.buildRequest();
    this.wizard.model.wipRequest = request;

    return this.hubSpotCompanyService.search(request.name)
      .pipe(switchMap((hubSpotResults: HubspotCompany[]) => {
        this.wizard.model.hubSpotCompanies = hubSpotResults;

        //If we found multiple results, proceed to the next page
        if (hubSpotResults.length > 1) {
          return of('select-hubspot-company');
        }
        //Else go ahead and create the company, taking the id of any single hubspot result
        else {
          if (hubSpotResults.length === 1) {
            request.hubspotId = hubSpotResults[0].id;
          }
          return this.companyService.registerOne(request)
            .pipe(map((result: Company) => {
              this.toaster.info('Successfully created Company');
              this.wizard.model.company = result;
              return null;
            }));
        }
      }));
  }

  private buildRequest(): Company {
    const request = new Company();
    const formValue = this.form.getRawValue();

    request.name = formValue.name;
    request.description = formValue.description;

    const license = new License();
    license.expirationDate = formValue.expirationDate;
    license.startDate = formValue.startDate;
    license.type = formValue.type;
    request.license = license;

    return request;
  }
}

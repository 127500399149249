import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/api')) {
      return next.handle(request)
        .pipe(catchError((response: HttpErrorResponse) => {
          if (response.status === 503) {
            this.router.navigate(['/maintenance']);
            return throwError('Maintenance Redirect');
          }
          return throwError(response);
        }));
    } else {
      return next.handle(request);
    }
  }
}

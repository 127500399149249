import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { LicenseSuspendedWizard } from '../license-suspended-wizard';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../core/date/insights-date.pipe';

@Component({
  selector: 'mtn-license-suspended-page',
  templateUrl: './license-suspended-page.component.html',
  styleUrls: ['./license-suspended-page.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class LicenseSuspendedPageComponent extends WizardPageComponent<LicenseSuspendedWizard> {

  key = 'license-suspended';

  constructor() {
    super();
    this.title = 'License Suspended';
    this.closeButtonText = 'Close';
  }

}

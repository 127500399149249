<div class="company-alerts-panel-container container" fxLayout="row wrap" fxLayoutAlign="center center"
     fxLayoutGap="15px">
  <mtn-simple-alert title="License Expiring" color="purple"
                    [text]="'This company\'s license expires as of ' + expirationDateString"
                    *ngIf="isExpiring" (onClick)="openUpdateLicenseWizard()"></mtn-simple-alert>
  <mtn-simple-alert title="License Expired" [text]="'This company\'s license expired as of ' + expirationDateString"
                    *ngIf="isExpired" icon="history" (onClick)="openUpdateLicenseWizard()"></mtn-simple-alert>
  <mtn-simple-alert title="License Suspended"
                    [text]="'This company\'s license was suspended as of ' + suspendedDateString"
                    *ngIf="isSuspended" icon="exclamation-circle" color="red"></mtn-simple-alert>
</div>

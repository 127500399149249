import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { StoreServicesWizard } from '../store-services-wizard';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../../../core/date/insights-date.pipe';
import { StoreServices } from '../../../../../../core/store/store-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'mtn-store-services-page',
  templateUrl: './store-services-page.component.html',
  styleUrls: ['./store-services-page.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class StoreServicesPageComponent extends WizardPageComponent<StoreServicesWizard> {

  key = 'store-services';

  services: StoreServices;

  constructor() {
    super();
    this.title = 'Store Services';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.services = this.wizard.model.services;
    return super.onLoad();
  }

}

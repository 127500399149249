import { Component } from '@angular/core';
import { AuthorizationAwareComponent } from '../../core/authorization-aware-component';
import { AppState } from '../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { CollectionService } from '../../core/federation/collection/collection.service';
import { finalize, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreadcrumbSetUuidLabelAction } from '../../core/breadcrumbs/breadcrumb-actions';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../core/date/insights-date.pipe';
import { ToastService } from '../../core/toast/toast.service';
import * as _ from 'lodash';
import { Collection } from '../../core/federation/collection/collection';
import { CompanyCollection } from '../../core/federation/collection/company-collection';
import { Feature } from '../../core/models';
import { ClipboardUtil } from '../../core/util/clipboard-util';
import { FeatureService } from '../../core/federation/feature/feature.service';
import { Observable } from 'rxjs';
import { FeatureType } from '../../core/federation/feature/feature-type.enum';
import { CollectionFilter } from '../../core/federation/filter/filter';
import { FeatureRequest } from '../../core/federation/feature/feature-request';
import { FilterGroup } from '../../core/federation/filter/filter-group';
import { StoreFeatureRequest } from '../../core/federation/feature/store-feature-request';
import { Pageable } from '../../core/service/pageable';
import { StoreExportWizard } from '../../core/store/export/store-export-wizard/store-export-wizard';
import { WizardRunnerService } from '../../core/wizard/wizard-runner.service';
import { CreateOrEditCollectionWizard } from '../create-or-edit-collection-wizard/create-or-edit-collection-wizard';
import { ConfirmActionWizard } from '../../core/confirm-action-wizard/confirm-action-wizard';
import { CloneCollectionWizard } from '../clone-collection-wizard/clone-collection-wizard';

@Component({
  selector: 'mtn-collection-page',
  templateUrl: './collection-page.component.html',
  styleUrls: ['./collection-page.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class CollectionPageComponent extends AuthorizationAwareComponent {

  public readonly COPY_LINK_DISABLED_TOOLTIP = `Copy Link

To allow someone else in your company to view your collection, you need to share it with your company first.`;
  public readonly DELETE_COLLECTION_DISABLED_TOOLTIP = `Delete Collection

You are not the owner of this collection.`;
  public readonly EDIT_COLLECTION_DISABLED_TOOLTIP = `Edit Collection

You have not been granted edit rights for this collection.`;
  public readonly EXPORT_DISABLED_TOOLTIP = `Export

This collection contains nothing to export.`;

  collection: Collection;
  companyCollection: CompanyCollection;
  FeatureType = FeatureType;
  features: Feature[] = [];
  isCollectionEditor = false;
  isCollectionOwner = false;
  isLoadingCollection = false;
  isLoadingFeatures = false;
  isSavingShares = false;

  private readonly TOAST_UPDATE_SHARES = 'Successfully updated share settings';

  constructor(private activatedRoute: ActivatedRoute,
              private collectionService: CollectionService,
              private featureService: FeatureService,
              protected ngrxStore: NgrxStore<AppState>,
              private router: Router,
              private toaster: ToastService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.loadCollection();
  }

  copyUrlToClipboard(): void {
    ClipboardUtil.copyValueToClipboard(window.location.href);
    this.toaster.info("Copied URL to Clipboard");
  }

  openCloneCollectionWizard(): void {
    const wizard = new CloneCollectionWizard();
    wizard.model = {
      collection: this.collection,
      userProfileUuid: this.currentUser.uuid
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: CloneCollectionWizard) => {
        if (result.model.result) {
          this.router.navigate(['/collection', result.model.result.uuid])
            .then(() => window.location.reload());
        }
      });
  }

  openConfirmDeleteCollectionWizard(): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      title: 'Delete Collection',
      text: 'Are you sure you want to delete this collection? It will no longer be visible to anyone it was shared with, and this cannot be undone!',
      onConfirm: this.collectionService.deleteOne(this.collection.uuid)
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: ConfirmActionWizard) => {
        if (result.model.result) {
          this.toaster.info('Successfully deleted collection');
          this.router.navigate(['/collection']);
        }
      });
  }

  openDownloadDialog(): void {
    //TODO this will need to be enhanced when shopping centers are supported
    const uuids = this.features.map((feature: Feature) => feature.id);
    const wizard = new StoreExportWizard();
    wizard.model = {
      uuids: uuids
    };

    this.wizardRunner.run(wizard);
  }

  openEditCollectionWizard(): void {
    const wizard = new CreateOrEditCollectionWizard();
    wizard.model = {
      collection: this.collection,
      companyUuid: this.currentCompany.uuid,
      userProfileUuid: this.currentUser.uuid
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: CreateOrEditCollectionWizard) => {
        if (result.model.result) {
          this.collection.name = result.model.result.name;
          this.collection.description = result.model.result.description;
          this.collection.version = result.model.result.version;
          this.collection.updatedBy = result.model.result.updatedBy;
          this.collection.updatedDate = result.model.result.updatedDate;
        }
      });
  }

  toggleEditable(): void {
    if (!this.isSavingShares) {
      this.isSavingShares = true;

      //Update the existing companyCollection record
      const request = _.cloneDeep(this.companyCollection);
      request.isEditable = !request.isEditable;

      this.collectionService.updateOneShare(this.collection.uuid, request)
        .pipe(finalize(() => this.isSavingShares = false))
        .subscribe((result: CompanyCollection) => {
          this.companyCollection.isEditable = result.isEditable;
          this.toaster.info(this.TOAST_UPDATE_SHARES);
        })
    }
  }

  toggleFavorite(): void {
    let task: Observable<Collection>;
    if (this.collection.isFavoritedBy(this.currentUser.uuid)) {
      task = this.collectionService.deleteOneFavorite(this.collection.uuid)
        .pipe(tap(() => {
          const index = _.findIndex(this.collection.favoritedBy, this.currentUser.uuid);
          this.collection.favoritedBy.splice(index, 1);
        }));
    } else {
      task = this.collectionService.addOneFavorite(this.collection.uuid)
        .pipe(tap(() => {
          this.collection.favoritedBy.push(this.currentUser.uuid);
        }));
    }

    task.subscribe();
  }

  toggleShared(): void {
    if (!this.isSavingShares) {
      this.isSavingShares = true;

      //If we have a companyCollection record, delete it
      if (this.companyCollection) {
        this.collectionService.deleteOneShare(this.collection.uuid, this.companyCollection.uuid)
          .pipe(finalize(() => this.isSavingShares = false))
          .subscribe(() => {
            this.collection.shares = _.reject(this.collection.shares, (share: CompanyCollection) => share.uuid === this.companyCollection.uuid);
            this.companyCollection = null;
            this.toaster.info(this.TOAST_UPDATE_SHARES);
          });
      }
      //Else, create one
      else {
        const request = new CompanyCollection();
        request.isViewable = true;
        request.companyUuid = this.currentCompany.uuid;

        this.collectionService.shareOne(this.collection.uuid, request)
          .pipe(finalize(() => this.isSavingShares = false))
          .subscribe((result: CompanyCollection) => {
            this.companyCollection = result;
            this.collection.shares.push(result);
            this.toaster.info(this.TOAST_UPDATE_SHARES);
          });
      }
    }
  }

  private buildRequest(): FeatureRequest {
    const collectionFilter = new CollectionFilter();
    collectionFilter.value = [this.collection.uuid];

    const filterGroup = new FilterGroup()
    filterGroup.filters.push(collectionFilter);

    if (this.collection.featureType === FeatureType.STORE) {
      const request = new StoreFeatureRequest();
      request.filterGroup = filterGroup;
      request.salesSqftDisplayType = this.userPreferences.salesSqftDisplayMode;
      request.salesVolumeDisplayType = this.userPreferences.salesVolumeDisplayMode;
      return request;
    } else {
      const request = new FeatureRequest();
      request.filterGroup = filterGroup;
      return request;
    }
  }

  private loadCollection(): void {
    if (!this.isLoadingCollection) {
      this.isLoadingCollection = true;

      this.activatedRoute.params
        .pipe(take(1))
        .subscribe((params: Params) => {
          this.collectionService.findOne(params.uuid)
            .pipe(finalize(() => this.isLoadingCollection = false))
            .subscribe((collection: Collection) => {
              this.collection = collection;
              this.companyCollection = _.find(collection.shares, (share: CompanyCollection) => share.companyUuid === this.currentCompany.uuid);
              this.setCollectionAuthorization();

              const breadcrumbLabel = collection.name;
              this.ngrxStore.dispatch(BreadcrumbSetUuidLabelAction({uuidLabel: breadcrumbLabel}));

              this.loadFeatures();
            });
        });
    }
  }

  private loadFeatures(): void {
    if (!this.isLoadingFeatures) {
      this.isLoadingFeatures = true;
      this.features = [];

      const request = this.buildRequest();

      let task: Observable<Pageable<Feature>>;
      switch (this.collection.featureType) {
        case FeatureType.SHOPPING_CENTER:
          task = this.featureService.findAllShoppingCenters(request);
          break;
        case FeatureType.STORE:
          task = this.featureService.findAllStores(<StoreFeatureRequest>request);
          break;
      }

      this.addSubscription(
        task
          .pipe(finalize(() => this.isLoadingFeatures = false))
          .subscribe((page: Pageable<Feature>) => {
            this.features = [...this.features, ...page.content];
          })
      );
    }
  }

  private setCollectionAuthorization(): void {
    this.isCollectionOwner = this.hasInternalLicense
      || this.isCompanyAdministrator()
      || this.collection.owner?.uuid === this.currentUser.uuid;
    this.isCollectionEditor = this.isCollectionOwner
      || this.companyCollection?.isEditable;
  }

}

import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AnalyticsService } from './analytics.service';

@Directive({
  selector: '[mtnAnalyticsEventTrigger]'
})
export class AnalyticsEventTriggerDirective implements OnInit, OnDestroy {

  @Input()
  analyticsEventBind: string = 'click';
  @Input()
  analyticsEventName: string;
  @Input()
  analyticsEventParameters: {};

  private dispose: Function;

  constructor(private analyticsService: AnalyticsService,
              private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.dispose = this.renderer.listen(this.elementRef.nativeElement, this.analyticsEventBind, () => {
      this.analyticsService.event(this.analyticsEventName, this.analyticsEventParameters);
    });
  }

  ngOnDestroy() {
    this.dispose();
  }
}

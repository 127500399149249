import { SimpleRatingType } from './simple-rating-type.enum';

export class SimpleRating {
  type: SimpleRatingType;
  value: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.type) {
        // @ts-ignore
        this.type = SimpleRatingType[<string>raw.type];
      }
    }
  }
}

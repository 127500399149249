<div class="text-field-container" [formGroup]="form">
  <mat-form-field appearance="outline" [ngClass]="{'no-error-padding': noErrorPadding}">
    <mat-label>{{label || placeholder}}</mat-label>
    <input matInput [formControlName]="controlName" [placeholder]="placeholder" [required]="isRequired"/>
    <mat-error *ngIf="form.get(controlName).hasError('maxlength')">
      <i>Cannot exceed {{form.get(controlName).getError('maxlength').requiredLength}} characters</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('minlength')">
      <i>Must have at least {{form.get(controlName).getError('minlength').requiredLength}} characters</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
    <button *ngIf="enableClear && form.get(controlName).value" matSuffix mat-icon-button aria-label="Clear"
            (click)="form.get(controlName).setValue(null)">
      <fa-icon icon="times"></fa-icon>
    </button>
  </mat-form-field>
</div>

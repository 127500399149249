<div class="store-highlights-panel-container container">
  <div fxLayout="column" fxLayoutGap="15px">
    <mtn-store-highlight-card [highlight]="highlight" *ngFor="let highlight of highlights | slice:0:maxCount"
                              style="width: 100%;" mtnAnalyticsEventTrigger
                              [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                              [analyticsEventParameters]="{'target': 'store-highlight-button-' + highlight.getKey()}"></mtn-store-highlight-card>
  </div>
  <div fxFlex fxLayoutAlign="center center" *ngIf="!isLoading && !highlights.length">
    <span class="color-muted">No further insights available for this store</span>
  </div>
  <mtn-spinner [on]="isLoading" [size]="SpinnerSize.MEDIUM"></mtn-spinner>
  <mtn-restricted-license-overlay *ngIf="!hasStandardLicense"></mtn-restricted-license-overlay>
</div>

import { Wizard } from '../../../wizard/wizard';
import { StoreExportPageComponent } from './store-export-page/store-export-page.component';

export class StoreExportWizard extends Wizard<StoreExportWizardModel> {
  key = 'store-export';
  pageTypeMap = {
    'store-export': StoreExportPageComponent
  };
  startingPageKey = 'store-export';
}

export interface StoreExportWizardModel {
  uuids: string[];
}

<div class="companies-administration-container container">
  <div class="page-header container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div fxLayout="column">
      <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Companies Administration</h1>
      <span class="tab-description color-muted">Select a Company to manage, or click on a License to quickly update or renew.</span>
    </div>
    <div fxFlex></div>
    <mtn-icon-button color="accent" (click)="openCreateCompanyWizard()" matTooltip="Create Company">
      <fa-icon icon="plus"></fa-icon>
    </mtn-icon-button>
    <mtn-text-field [form]="filterForm" controlName="filter" placeholder="Filter" mtnAnalyticsEventTrigger
                    analyticsEventBind="blur" [analyticsEventName]="AnalyticsEventType.TOUCH_FORM_FIELD"
                    [analyticsEventParameters]="{'target': 'filter'}" [noErrorPadding]="true"></mtn-text-field>
  </div>
  <div class="table-container container">
    <mtn-spinner [size]="SpinnerSize.LARGE" #companiesSpinner></mtn-spinner>
    <div class="no-data-container" fxLayout="row" fxLayoutAlign="center center" *ngIf="!dataSource?.data?.length">No
      Results Available
    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
           [hidden]="!dataSource?.data?.length">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let company">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <mtn-company-initials-avatar [company]="company"></mtn-company-initials-avatar>
            <div>
              <div class="company-name">{{company.name}}</div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Unique Identifier Column -->
      <ng-container matColumnDef="identifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Identifier</th>
        <td mat-cell *matCellDef="let company">
          <div>{{company.uniqueIdentifier}}</div>
        </td>
      </ng-container>

      <!-- License Column -->
      <ng-container matColumnDef="license">
        <th class="column-center" mat-header-cell *matHeaderCellDef>License</th>
        <td mat-cell *matCellDef="let company">
          <span>{{getLicenseDisplayName(company.license.type)}}</span>
        </td>
      </ng-container>

      <!-- License Expiration Column -->
      <ng-container matColumnDef="licenseExpiration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>License Expiration</th>
        <td mat-cell *matCellDef="let company">
          <div
            *ngIf="company.license.expirationDate; else nonExpiring">{{company.license.expirationDate | insightsDate}}</div>
          <ng-template #nonExpiring>
            <div class="color-muted">Non-Expiring</div>
          </ng-template>
        </td>
      </ng-container>

      <!-- License Status Column -->
      <ng-container matColumnDef="licenseStatus">
        <th class="column-center" mat-header-cell *matHeaderCellDef>License Status</th>
        <td mat-cell *matCellDef="let company">
          <span class="color-success" *ngIf="company.license.isValid()">Active</span>
          <span class="color-error" *ngIf="company.license.isExpired()">Expired</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="clickable" *matRowDef="let row; columns: displayedColumns;"
          (click)="navigateToCompanyDetails(row)"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3"><strong>0</strong> of <strong>{{dataSource?.data?.length}}</strong>
          Results Match Current Filter
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="25" [length]="totalResults" [pageIndex]="currentPage"
                   [showFirstLastButtons]="true" [hidePageSize]="true" [hidden]="totalResults <= 25"></mat-paginator>
  </div>
</div>

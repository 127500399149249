<div class="user-not-registered-container container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <div class="text-container text-center" fxLayout="column" fxLayoutAlign="start center">
    <fa-icon class="user-lock-icon" icon="user-lock"></fa-icon>
    <h1 class="mat-h1 text-small-caps">Uh-oh!</h1>
    <h2 class="mat-h2">You aren't registered yet!</h2>
    <p>Contact MTN to take care of that!</p>
    <span><strong>Email:</strong>&nbsp;<a
      href="mailto:support@retailstat.com?subject=Insights%20Registration">support&#64;retailstat.com</a></span>
  </div>
</div>

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '../../core/base-component';
import * as _ from 'lodash';
import { MtnMap } from '../mtn-map';
import PlaceResult = google.maps.places.PlaceResult;
import Point = google.maps.Point;
import Autocomplete = google.maps.places.Autocomplete;
import Marker = google.maps.Marker;
import InfoWindow = google.maps.InfoWindow;
import Size = google.maps.Size;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

@Component({
  selector: 'mtn-google-search',
  templateUrl: './google-search.component.html',
  styleUrls: ['./google-search.component.scss']
})
export class GoogleSearchComponent extends BaseComponent implements OnInit, OnChanges {

  @Output()
  onSelect = new EventEmitter<PlaceResult>();

  @Input()
  map: MtnMap;

  @ViewChild('searchField', {static: true})
  searchField: ElementRef;

  autoComplete: Autocomplete;
  infoWindow: InfoWindow;
  infoWindowContent: any;
  marker: Marker;

  searchForm = new UntypedFormGroup({
    isSearchBound: new UntypedFormControl(false),
    search: new UntypedFormControl('')
  });

  private prefLimitSearchToMapBounds = false;

  constructor(private ngZone: NgZone) {
    super();
  }

  ngOnChanges(): void {
    if (this.map) {
      console.warn('Reinitializing Google Search');
      this.initMarker();
      this.initInfoWindow();
      this.initAutoComplete();
      if (this.prefLimitSearchToMapBounds) {
        this.bindSearchToMapBounds();
      }
      this.searchField.nativeElement.focus();
    }
  }

  ngOnInit() {
    this.infoWindowContent = document.getElementById('google-search-info-window-content');

    //TODO read user preference
    this.prefLimitSearchToMapBounds = false;
    this.searchForm.get('isSearchBound').setValue(this.prefLimitSearchToMapBounds);

    this.subscribeToBoundsRestrictionChanges();
  }

  private bindSearchToMapBounds(): void {
    this.autoComplete.setOptions({strictBounds: true});
  }

  private getAddressCityStatePostalFromPlace(place: PlaceResult): string {
    const cityComponent = this.getAddressComponentByType(place, 'locality');
    const stateComponent = this.getAddressComponentByType(place, 'administrative_area_level_1');
    const postalCodeComponent = this.getAddressComponentByType(place, 'postal_code');

    return `${cityComponent.short_name}, ${stateComponent.short_name} ${postalCodeComponent.short_name}`;
  }

  private getAddressComponentByType(place: PlaceResult, type: string): GeocoderAddressComponent {
    return _.find(place.address_components, (component: GeocoderAddressComponent) => {
      return _.includes(component.types, type);
    });
  }

  private getAddressLine1FromPlace(place: PlaceResult): string {
    const streetNumberComponent = this.getAddressComponentByType(place, 'street_number');
    const routeComponent = this.getAddressComponentByType(place, 'route');

    return `${streetNumberComponent.short_name} ${routeComponent.short_name}`;
  }

  private initAutoComplete(): void {
    this.autoComplete = new Autocomplete(this.searchField.nativeElement, {
      componentRestrictions: {country: 'US'},
      types: [] //search all types
    });
    this.autoComplete.bindTo('bounds', this.map);
    this.autoComplete.addListener('place_changed', () => {
      this.infoWindow.close();

      const place: PlaceResult = this.autoComplete.getPlace();
      if (place.geometry) {
        this.onSelect.emit(place);

        //Position map on selected place
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17); //Because the Google Docs say "because it looks good"
        }

        //Set a marker on that place
        this.marker.setPosition(place.geometry.location);
        this.marker.setVisible(true);

        //Fill in and show the info window
        // this.infoWindowContent.children['info-window-icon'].src = place.icon;
        this.infoWindowContent.children['info-window-title'].innerText = place.name;
        if (place.address_components && place.address_components.length) {
          this.infoWindowContent.children['info-window-address-line1'].innerText = this.getAddressLine1FromPlace(place);
          this.infoWindowContent.children['info-window-address-city-state-postal'].innerText = this.getAddressCityStatePostalFromPlace(place);
        }
        this.infoWindow.open(this.map, this.marker);

        this.ngZone.run(() => {
        });
      }
    })
  }

  private initInfoWindow(): void {
    this.infoWindow = new InfoWindow({
      pixelOffset: new Size(112, -85)
    });
    this.infoWindow.setContent(this.infoWindowContent);
  }

  private initMarker(): void {
    this.marker = new Marker({
      map: this.map,
      anchorPoint: new Point(this.map.getCenter().lng(), this.map.getCenter().lat()),
      visible: false
    });

    this.marker.addListener('click', () => {
      if (this.isInfoWindowOpen()) {
        this.infoWindow.close();
      } else {
        this.infoWindow.open(this.map, this.marker);
      }
    });
  }

  private isInfoWindowOpen(): boolean {
    //Hacky way of doing this from https://stackoverflow.com/questions/12410062/check-if-infowindow-is-opened-google-maps-v3
    const windowMap = (<any>this.infoWindow).getMap();
    return (windowMap !== null && typeof windowMap !== 'undefined');
  }

  private subscribeToBoundsRestrictionChanges(): void {
    this.addSubscription(
      this.searchForm.get('isSearchBound').valueChanges
        .subscribe((value: boolean) => {
          if (value) {
            this.bindSearchToMapBounds();
          } else {
            this.unbindSearchFromMapBounds();
          }
        })
    );
  }

  private unbindSearchFromMapBounds(): void {
    this.autoComplete.setOptions({strictBounds: false});
  }
}

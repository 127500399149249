<div class="status-filter-assembly container">
  <mtn-map-toolbar-button icon="door-open" [badgeCount]="toolbarState.badgeCountStatuses"
                          [color]="toolbarState.badgeCountStatuses ? 'green' : 'grey'"
                          [iconColor]="toolbarState.badgeCountStatuses ? 'green' : 'default'"
                          [matMenuTriggerFor]="fitMenu" [disabled]="!hasStandardLicense"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                          [analyticsEventParameters]="{'target': 'map-status-filter-menu'}"
                          [matTooltip]="hasStandardLicense ? 'Status Filter' : 'Status Filter (Upgrade to unlock!)'"></mtn-map-toolbar-button>
</div>

<mat-menu class="wide-menu" #fitMenu="matMenu">
  <div class="status-menu-container toolbar-menu container" mtnClickTrap [preventDefault]="false" fxLayout="column"
       style="width: 300px">
    <div class="header-container">
      <div fxLayout="row">
        <strong>Statuses</strong>
        <div fxFlex></div>
        <a href="javascript:void(0)" (click)="clear()"
           [ngClass]="{'color-accent': toolbarState?.filterStatuses, 'color-muted': !toolbarState?.filterStatuses}">Clear</a>
      </div>
      <hr/>
    </div>
    <div *ngIf="form" [formGroup]="form" fxLayout="column">
      <mat-checkbox color="accent" [checked]="isAllSelected" (change)="toggleSelectAll()">All</mat-checkbox>
      <mat-checkbox color="accent" *ngFor="let option of options"
                    [formControlName]="option.systemName"
                    style="margin-left: 15px">{{option.displayName}}</mat-checkbox>
      <br/>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-checkbox color="accent" formControlName="isExclusive">Exclusive</mat-checkbox>
        <mtn-what-is-this [text]="EXCLUSIVE_FILTER_DEFINITION"></mtn-what-is-this>
      </div>
    </div>
  </div>
</mat-menu>

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AnalyticsService } from '../../google-analytics/analytics.service';
import { AnalyticsEventType } from '../../google-analytics/analytics-event-type';
import { tap } from 'rxjs/operators';
import { Wizard } from './wizard';
import { WizardContainerComponent } from './wizard-container/wizard-container.component';

@Injectable({
  providedIn: 'root'
})
export class WizardRunnerService {

  constructor(private analyticsService: AnalyticsService,
              private matDialog: MatDialog) {
  }

  run<T, S extends Wizard<T>>(wizard: S): MatDialogRef<WizardContainerComponent, S> {
    const observable = this.matDialog.open(WizardContainerComponent, {
      data: wizard,
      hasBackdrop: true,
      panelClass: 'wizard',
      autoFocus: false
    });

    this.handleAnalyticsReporting(wizard.key, observable);

    return observable;
  }

  private handleAnalyticsReporting(wizardName: string, observable: MatDialogRef<any>): void {
    this.analyticsService.event(AnalyticsEventType.OPEN_WIZARD, wizardName);

    observable.afterClosed().pipe(tap(() => {
      this.analyticsService.event(AnalyticsEventType.CLOSE_WIZARD, wizardName);
    }));
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { MtnMap } from '../../mtn-map';
import { FilterToolbarState } from '../filter-toolbar-state';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { EXCLUSIVE_FILTER_DEFINITION, formatNumericalShorthand } from '../../../core/util/string-utils';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { NumberRange } from '../../../core/federation/number-range';
import { FilterType } from '../../../core/federation/filter/filter-type.enum';
import { SalesAreaFilter, TotalAreaFilter } from '../../../core/federation/filter/filter';

@Component({
  selector: 'mtn-area-filters-assembly',
  templateUrl: './area-filters-assembly.component.html',
  styleUrls: ['./area-filters-assembly.component.scss']
})
export class AreaFiltersAssemblyComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Input()
  map: MtnMap;
  @Input()
  toolbarState: FilterToolbarState;

  EXCLUSIVE_FILTER_DEFINITION = EXCLUSIVE_FILTER_DEFINITION;
  isMenuReady = false;
  salesAreaConfig: Options;
  salesAreaForm = new UntypedFormGroup({
    isExclusive: new UntypedFormControl(),
    salesArea: new UntypedFormControl()
  });
  totalAreaConfig: Options;
  totalAreaForm = new UntypedFormGroup({
    isExclusive: new UntypedFormControl(),
    totalArea: new UntypedFormControl()
  });

  constructor(protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.toolbarState && this.salesAreaConfig && this.totalAreaConfig) {
      this.updateForm();
    }
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initSliderConfigs();
    this.updateForm();
    this.subscribeToFormChanges();
  }

  clearSalesArea(): void {
    this.salesAreaForm.get('salesArea').setValue([this.salesAreaConfig.floor, this.salesAreaConfig.ceil]);
    this.salesAreaForm.get('isExclusive').setValue(false);
  }

  clearTotalArea(): void {
    this.totalAreaForm.get('totalArea').setValue([this.totalAreaConfig.floor, this.totalAreaConfig.ceil]);
    this.totalAreaForm.get('isExclusive').setValue(false);
  }

  hideSliders(): void {
    this.isMenuReady = false;
  }

  /**
   * Due to a canvas-like issue, where the menu appears open, but is still drawing and rendering its contents, if we
   * don't wait a bit to show the sliders, they don't fill the full width of the menu (likely because we're exceeding
   * angular-material's default max width for menus).
   */
  showSliders(): void {
    setTimeout(() => {
      this.isMenuReady = true;
    }, 300);
  }

  private initSliderConfigs(): void {
    this.initSalesAreaSliderConfig();
    this.initTotalAreaSliderConfig();
  }

  private initSalesAreaSliderConfig(): void {
    this.salesAreaConfig = {
      ceil: 100000,
      floor: 0,
      step: 1000,
      tickStep: 10000,
      showTicks: true,
      translate: (value: number): string => {
        return formatNumericalShorthand(value);
      }
    };

    this.salesAreaForm.get('salesArea').setValue([0, this.salesAreaConfig.ceil], {emitEvent: false});
  }

  private initTotalAreaSliderConfig(): void {
    this.totalAreaConfig = {
      ceil: 250000,
      floor: 0,
      step: 1000,
      tickStep: 25000,
      showTicks: true,
      translate: (value: number): string => {
        return formatNumericalShorthand(value);
      }
    };

    this.totalAreaForm.get('totalArea').setValue([0, this.totalAreaConfig.ceil], {emitEvent: false});
  }

  private subscribeToFormChanges(): void {
    this.subscribeToSalesAreaFormChanges();
    this.subscribeToTotalAreaFormChanges();
  }

  private subscribeToSalesAreaFormChanges(): void {
    this.addSubscription(
      this.salesAreaForm.valueChanges
        .pipe(debounceTime(300))
        .subscribe((value: any) => {
          const min = value.salesArea ? value.salesArea[0] : this.salesAreaConfig.floor;
          const max = value.salesArea ? value.salesArea[1] : this.salesAreaConfig.ceil;
          const isExclusive = value.isExclusive;

          const isMinEqualToFloor = min === this.salesAreaConfig.floor;
          const isMaxEqualToFloor = max === this.salesAreaConfig.ceil;

          const filterGroup = _.cloneDeep(this.map.options.filterGroup);

          if (!isMinEqualToFloor || !isMaxEqualToFloor || isExclusive) {
            const range = new NumberRange();
            range.min = min;
            range.max = max;

            /*If the map has a SALES_AREA filter, we need to update it*/
            let filter = filterGroup.getOwnFilter(FilterType.SALES_AREA);
           /* Else, we need to add one*/
            if (!filter) {
              filter = new SalesAreaFilter();
            }

            filter.value = range;
            filter.isExclusive = isExclusive;

            filterGroup.clearFilter(FilterType.SALES_AREA);
            filterGroup.filters.push(filter);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          } else {
           /* If the map has a SALES_AREA filter, we need to remove it*/
            if (filterGroup.hasFilter(FilterType.SALES_AREA)) {
              filterGroup.clearFilter(FilterType.SALES_AREA);
              this.map.setOptions({
                filterGroup: filterGroup
              });
            }
            /*Else, we don't need to do anything*/
          }
        })
    );
  }

  private subscribeToTotalAreaFormChanges(): void {
    this.addSubscription(
      this.totalAreaForm.valueChanges
        .pipe(debounceTime(300))
        .subscribe((value: any) => {
          const min = value.totalArea ? value.totalArea[0] : this.totalAreaConfig.floor;
          const max = value.totalArea ? value.totalArea[1] : this.totalAreaConfig.ceil;
          const isExclusive = value.isExclusive;

          const isMinEqualToFloor = min === this.totalAreaConfig.floor;
          const isMaxEqualToFloor = max === this.totalAreaConfig.ceil;

          const filterGroup = _.cloneDeep(this.map.options.filterGroup);

          if (!isMinEqualToFloor || !isMaxEqualToFloor || isExclusive) {
            const range = new NumberRange();
            range.min = min;
            range.max = max;

            /*If the map has a TOTAL_AREA filter, we need to update it*/
            let filter = filterGroup.getOwnFilter(FilterType.TOTAL_AREA);
            /*//Else, we need to add one*/
            if (!filter) {
              filter = new TotalAreaFilter();
            }

            filter.value = range;
            filter.isExclusive = isExclusive;

            filterGroup.clearFilter(FilterType.TOTAL_AREA);
            filterGroup.filters.push(filter);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          } else {
            /*//If the map has a SALES_VOLUME filter, we need to remove it*/
            if (filterGroup.hasFilter(FilterType.TOTAL_AREA)) {
              filterGroup.clearFilter(FilterType.TOTAL_AREA);
              this.map.setOptions({
                filterGroup: filterGroup
              });
            }
            /*//Else, we don't need to do anything*/
          }
        })
    );
  }

  private updateForm(): void {
    this.updateSalesAreaFormControl();
    this.updateTotalAreaFormControl();
  }

  private updateSalesAreaFormControl(): void {
    let minValue = 0;
    let maxValue = this.salesAreaConfig.ceil;

    if (this.toolbarState.filterSalesArea?.value?.min) {
      minValue = this.toolbarState.filterSalesArea.value.min;
    }
    if (this.toolbarState.filterSalesArea?.value?.max) {
      maxValue = this.toolbarState.filterSalesArea.value.max;
    }

    this.salesAreaForm.get('salesArea').setValue([minValue, maxValue], {emitEvent: false});
    this.salesAreaForm.get('isExclusive').setValue(this.toolbarState.filterSalesArea?.isExclusive, {emitEvent: false});
  }

  private updateTotalAreaFormControl(): void {
    let minValue = 0;
    let maxValue = this.totalAreaConfig.ceil;

    if (this.toolbarState.filterTotalArea?.value?.min) {
      minValue = this.toolbarState.filterTotalArea.value.min;
    }
    if (this.toolbarState.filterTotalArea?.value?.max) {
      maxValue = this.toolbarState.filterTotalArea.value.max;
    }

    this.totalAreaForm.get('totalArea').setValue([minValue, maxValue], {emitEvent: false});
    this.totalAreaForm.get('isExclusive').setValue(this.toolbarState.filterTotalArea?.isExclusive, {emitEvent: false});
  }

}

import { Injectable } from '@angular/core';
import * as localForage from "localforage";
import { from, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

localForage.config({
  name: 'insights',
  storeName: 'storage'
});

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public static readonly ADMIN_LAST_USED_TAB = 'admin-last-used-tab';
  public static readonly AUTH_CURRENT_COMPANY = 'auth-current-company';
  public static readonly AUTH_CURRENT_USER = 'auth-current-user';
  public static readonly AUTH_REDIRECT_URL = 'auth-redirect-url';
  public static readonly AUTH_TOKEN = 'auth-token';
  public static readonly DEMOGRAPHIC_PROFILE_REGION = 'demographic-profile-region';
  public static readonly DETAIL_STORE_RANKING_COMPARISONS = 'store-ranking-comparisons';
  public static readonly NAVIGATION_STATE_TOKEN = 'navigation-state';

  private storage: LocalForage;

  constructor() {
    this.storage = localForage;
  }

  clear(): Observable<any> {
    return from(this.storage.clear())
      .pipe(take(1));
  }

  get(key: string): Observable<any> {
    return from(this.storage.getItem(key))
      .pipe(take(1));
  }

  remove(key: string): Observable<any> {
    return from(this.storage.removeItem(key))
      .pipe(take(1));
  }

  set(key: string, value: any): Observable<any> {
    return from(this.storage.setItem(key, value))
      .pipe(take(1));
  }
}

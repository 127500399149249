<div class="address-view-container container" fxLayout="column">
  <span>{{location.addressLine1}}</span>
  <span *ngIf="location.addressLine2">{{location.addressLine2}}</span>
  <div fxLayout="row" fxLayoutGap="3px" *ngIf="location.city || location.state || location.postalCode">
    <span *ngIf="location.city">{{location.city}},</span>
    <span *ngIf="location.state">{{location.state}}</span>
    <span *ngIf="location.postalCode">{{location.postalCode}}</span>
  </div>
<!--  <span *ngIf="location.country">{{location.country}}</span>-->
</div>

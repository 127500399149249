import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../service/rest-service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CompleteInvitation } from './complete-invitation';
import { UserProfile } from '../user-profile/user-profile';

/**
 * This service represents the PUBLIC invitation endpoints, not the protected /api endpoints!
 */
@Injectable({
  providedIn: 'root'
})
export class InvitationService extends RestService {

  protected host = environment.SERVICE_HOST;
  protected rootPath = '/insights/invitation';

  constructor(private http: HttpClient) {
    super();
  }

  acceptOne(uuid: string, request?: UserProfile): Observable<any> {
    const url = this.buildUrl(`${uuid}/accept`);
    return this.http.put(url, request);
  }

  findOneComplete(uuid: string): Observable<CompleteInvitation> {
    const url = this.buildUrl(`/${uuid}`);
    return this.http.get(url)
      .pipe(map((raw: any) => new CompleteInvitation(raw)));
  }
}

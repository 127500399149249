import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { MtnMap } from '../../mtn-map';
import { FilterToolbarState } from '../filter-toolbar-state';
import { Definition } from '../../../core/definition/definition';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DefinitionService } from '../../../core/definition/definition.service';
import { Store as NgrxStore } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { FilterType } from '../../../core/federation/filter/filter-type.enum';
import { StatusFilter } from '../../../core/federation/filter/filter';
import { FutureStoreStatuses, StoreStatusType } from '../../../core/identity/constant/store-status-type.enum';
import { AddOnType } from '../../../core/company/add-on-type.enum';
import { EXCLUSIVE_FILTER_DEFINITION } from '../../../core/util/string-utils';

@Component({
  selector: 'mtn-status-filter-assembly',
  templateUrl: './status-filter-assembly.component.html',
  styleUrls: ['./status-filter-assembly.component.scss']
})
export class StatusFilterAssemblyComponent extends AuthorizationAwareComponent implements OnChanges, OnInit {

  @Input()
  map: MtnMap;
  @Input()
  toolbarState: FilterToolbarState;

  EXCLUSIVE_FILTER_DEFINITION = EXCLUSIVE_FILTER_DEFINITION;
  isAllSelected = false;
  options: Definition<StoreStatusType>[] = [];
  form: UntypedFormGroup;

  private readonly SUPPORTED_STATUSES = [
    StoreStatusType.CLOSED,
    StoreStatusType.NEW_UNDER_CONSTRUCTION,
    StoreStatusType.OPEN,
    StoreStatusType.PLANNED,
    StoreStatusType.PROPOSED,
    StoreStatusType.REMODEL,
    StoreStatusType.TEMPORARILY_CLOSED
  ];

  constructor(private definitionService: DefinitionService,
              protected ngrxStore: NgrxStore<AppState>) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toolbarState && this.form) {
      this.updateForm();
    }
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.initForm();
  }

  clear(): void {
    const formState = this.buildFormState(false);
    this.form.setValue(formState);
  }

  toggleSelectAll(): void {
    const newFormValue = !this.isAllSelected;
    const formState = this.buildFormState(newFormValue);
    this.form.setValue(formState);
  }

  private buildFormState(value: boolean): any {
    const formValue = this.form.getRawValue();
    let formState = {
      isExclusive: !!formValue.isExclusive
    };
    this.options.forEach((option: Definition<StoreStatusType>) => {
      // @ts-ignore
      formState[option.systemName] = value;
    });
    return formState;
  }

  private initForm(): void {
    this.definitionService.findAllStoreStatusTypes()
      .pipe(take(1))
      .subscribe((definitions: Definition<StoreStatusType>[]) => {
        this.options = _.orderBy(_.filter(definitions, (definition: Definition<StoreStatusType>) => {
          return _.includes(this.SUPPORTED_STATUSES, definition.systemName);
        }), 'displayName');

        //If the company doesn't have the PlannedGrocery add-on, they won't be able to use the future statuses
        if (!this.currentUser.company.hasAddOn(AddOnType.PLANNED_GROCERY) && !this.hasInternalLicense) {
          this.options = _.reject(this.options, (option: Definition<StoreStatusType>) => _.includes(FutureStoreStatuses, option.systemName));
        }

        let controls = {
          isExclusive: new UntypedFormControl()
        };

        this.options.forEach((definition: Definition<any>) => {
          // @ts-ignore
          controls[definition.systemName] = new UntypedFormControl();
        });

        this.form = new UntypedFormGroup(controls);

        this.updateForm();
        this.subscribeToFormChanges();
      });
  }

  private subscribeToFormChanges(): void {
    this.addSubscription(
      this.form.valueChanges.subscribe((formValue: any) => {
        const filterGroup = _.cloneDeep(this.map.options.filterGroup);
        const isExclusive = formValue.isExclusive;

        const enabledTypes: StoreStatusType[] = [];

        this.options.forEach((option: Definition<StoreStatusType>) => {
          if (formValue[option.systemName]) {
            enabledTypes.push(option.systemName);
          }
        });

        if (enabledTypes.length) {
          //If the map has a STATUS filter, we need to update it
          let filter = filterGroup.getOwnFilter(FilterType.STATUS);
          //Else, we need to add one
          if (!filter) {
            filter = new StatusFilter();
          }

          filter.value = [...enabledTypes];
          filter.isExclusive = isExclusive;

          filterGroup.clearFilter(FilterType.STATUS);
          filterGroup.filters.push(filter);
          this.map.setOptions({
            filterGroup: filterGroup
          });
        } else {
          //If the map has a STATUS filter, we need to remove it
          if (filterGroup.hasOwnFilter(FilterType.STATUS)) {
            filterGroup.clearFilter(FilterType.STATUS);
            this.map.setOptions({
              filterGroup: filterGroup
            });
          }
          //Else, we don't need to do anything
        }
      })
    );
  }

  private updateForm(): void {
    const enabledTypes: StoreStatusType[] = this.toolbarState.filterStatuses?.value;
    let isAllSelected = true;

    this.options.forEach((option: Definition<StoreStatusType>) => {
      const isEnabled = _.includes(enabledTypes, option.systemName);
      if (!isEnabled) {
        isAllSelected = false;
      }
      this.form.get(option.systemName).setValue(isEnabled, {emitEvent: false});
    });

    this.form.get('isExclusive').setValue(this.toolbarState.filterStatuses?.isExclusive, {emitEvent: false});

    this.isAllSelected = isAllSelected;
  }
}

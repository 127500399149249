import { Wizard } from '../../../../../core/wizard/wizard';
import { Company } from '../../../../../core/company/company';
import { SuspendLicensePageComponent } from './suspend-license-page/suspend-license-page.component';

export class SuspendLicenseWizard extends Wizard<SuspendLicenseWizardModel> {
  key = 'suspend-license';
  pageTypeMap = {
    'suspend-license': SuspendLicensePageComponent
  };
  startingPageKey = 'suspend-license';
}

export interface SuspendLicenseWizardModel {
  company: Company;
  isUpdated?: boolean;
}

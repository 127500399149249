import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { WelcomeWizard } from '../welcome-wizard';
import { Observable, of } from 'rxjs';
import { UserPreferencesService } from '../../../../core/user-preferences/user-preferences.service';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-welcome-wizard-start-page',
  templateUrl: './welcome-wizard-start-page.component.html',
  styleUrls: ['./welcome-wizard-start-page.component.scss']
})
export class WelcomeWizardStartPageComponent extends WizardPageComponent<WelcomeWizard> {

  key = 'start';

  constructor(private userPreferencesService: UserPreferencesService) {
    super();
    this.title = 'Welcome! Let\'s get started.';
    this.stepperText = 'Start';
    this.closeButtonText = 'Close';
    this.footerCheckboxText = 'Don\'t show again';
    this.headerText = 'First Time Setup';
    this.nextButtonText = 'Next';
    this.subheaderText = 'Welcome to Insights! We\'re glad you\'re here, and we\'re ready to start helping you get at the data you need. But first, we need to ask you a few questions so we can tailor Insights to your needs.';
  }

  onClose(): Observable<null> {
    if (this.wizard.isFooterCheckboxChecked) {
      const request = _.cloneDeep(this.wizard.model.userPreferences);
      request.isWelcomeWizardComplete = true;

      return this.userPreferencesService.updateOne(request)
        .pipe(map(() => null));
    } else {
      return super.onClose();
    }
  }

  onNext(): Observable<string> {
    return of('volume');
  }

}

import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { Definition } from '../definition/definition';
import { QuadrantType } from './constant/quadrant-type.enum';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';

@Injectable({
  providedIn: 'root'
})
export class QuadrantService extends CrudService<Definition<QuadrantType>> {

  protected path = '/quadrant';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected buildInstance(raw: any): Definition<QuadrantType> {
    return new Definition<QuadrantType>(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Definition<QuadrantType>> {
    return new Pageable<Definition<QuadrantType>>(raw, Definition);
  }
}

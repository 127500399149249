import { Component } from '@angular/core';
import { WizardPageComponent } from '../../wizard/wizard-page-component';
import { EmailVerificationWizard } from '../email-verification-wizard';
import { AuthenticationService } from '../../../auth/authentication/authentication.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'mtn-email-verification-page',
  templateUrl: './email-verification-page.component.html',
  styleUrls: ['./email-verification-page.component.scss']
})
export class EmailVerificationPageComponent extends WizardPageComponent<EmailVerificationWizard> {

  key = 'email-verification';

  constructor(private authenticationService: AuthenticationService) {
    super();
    this.title = 'Email Verified';
    this.closeButtonText = 'Close';
    this.nextButtonColor = 'accent';
    this.nextButtonText = 'Sign In';
  }

  onNext(): Observable<string> {
    this.authenticationService.openAuth0AuthenticationDialog();
    return of(null);
  }

}

<div [formGroup]="form">
  <h3 class="mat-h2 text-small-caps color-primary">Company Information</h3>
  <mtn-text-field [form]="form" controlName="name" placeholder="Name" [isRequired]="true"></mtn-text-field>
  <mtn-text-area-field [form]="form" controlName="description" placeholder="Description"
                       [maxLength]="255"></mtn-text-area-field>
  <h3 class="mat-h2 text-small-caps color-primary">License Information</h3>
  <mtn-license-types-select [form]="form" controlName="type" [isRequired]="true"></mtn-license-types-select>
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-datepicker-field [form]="form" controlName="startDate" placeholder="Start Date"
                          [isRequired]="true"></mtn-datepicker-field>
    <mtn-datepicker-field [form]="form" controlName="expirationDate"
                          placeholder="Expiration Date"></mtn-datepicker-field>
  </div>
</div>

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { Observable } from 'rxjs';
import { BannerComparison } from './banner-comparison';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, take } from 'rxjs/operators';
import { BannerRankingRequest } from './banner-ranking-request';
import { DetailState, selectDetailState } from '../../detail/detail-state';
import { AppState } from '../../app-state';
import { Store as NgrxStore } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class BannerComparisonService extends InsightsRestService {

  constructor(private http: HttpClient,
              private ngrxStore: NgrxStore<AppState>) {
    super();
  }

  findOne(request: BannerRankingRequest): Observable<BannerComparison> {
    const url = this.buildUrl('/banner/ranking');

    return this.appendFips(request)
      .pipe(switchMap((enhancedRequest: BannerRankingRequest) => {
        return this.http.post(url, enhancedRequest)
          .pipe(map((result: any) => new BannerComparison(result)));
      }));
  }

  private appendFips(request: BannerRankingRequest): Observable<any> {
    return selectDetailState(this.ngrxStore)
      .pipe(
        take(1),
        map((state: DetailState) => {
          request.fips = state.marketArea.getFeature().id;
          return request;
        })
      );
  }
}

<div class="user-administration-container container" fxLayout="column" fxLayoutGap="15px">
  <div class="page-header container" fxLayout="column" fxLayoutGap="15px" *ngIf="user">
    <div class="user-description" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="15px" fxFlex>
        <mtn-user-initials-avatar [user]="user"></mtn-user-initials-avatar>
        <div fxLayout="column">
          <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">{{user.getDisplayName()}}</h1>
          <span class="tab-description color-muted">{{user.title || 'Edit User to add a Title'}}</span>
          <a [href]="'mailto:' + user.email" class="tab-description color-help">{{user.email}}</a>
          <span class="tab-description color-muted">{{user.phoneNumber || 'Edit User to add a Phone Number'}}</span>
        </div>
        <div fxFlex></div>
        <!-- <mtn-hubspot-object-link [contact]="user"></mtn-hubspot-object-link> -->
        <!-- Buttons are wrapped in divs here to fix the tooltip positioning -->
        <div>
          <mtn-icon-button (click)="openEditUserWizard()" matTooltip="Edit User">
            <fa-icon icon="pencil-alt"></fa-icon>
          </mtn-icon-button>
        </div>
        <div>
          <mtn-icon-button color="warn" (onClick)="openConfirmRemoveAccessWizard()" [disabled]="!user.company?.uuid"
                           [matTooltip]="user.company?.uuid ? 'Revoke Access' : 'User does not have access to Insights'">
            <fa-icon icon="ban"></fa-icon>
          </mtn-icon-button>
        </div>
      </div>
    </div>
    <hr/>
  </div>
  <div fxLayout="row" fxLayoutGap="15px">
    <div fxLayout="column" fxLayoutGap="30px">
      <mtn-associated-company-card [userProfile]="user" [company]="company"></mtn-associated-company-card>
      <mtn-user-preferences-view [userProfileUuid]="user.uuid" *ngIf="user"></mtn-user-preferences-view>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="30px">
      <!-- TODO recent activity here -->
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { AuthorizationAwareComponent } from '../../authorization-aware-component';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { AddToCollectionWizard } from '../../../collections/add-to-collection-wizard/add-to-collection-wizard';
import { CollectionService } from '../../federation/collection/collection.service';
import { ToastService } from '../../toast/toast.service';
import { WizardRunnerService } from '../../wizard/wizard-runner.service';
import { CreateOrEditCollectionWizard } from '../../../collections/create-or-edit-collection-wizard/create-or-edit-collection-wizard';
import { StoreExportWizard } from '../export/store-export-wizard/store-export-wizard';
import { UserProfileService } from '../../user-profile/user-profile.service';
import { Pageable } from '../../service/pageable';
import { ClipboardUtil } from '../../util/clipboard-util';
import { Collection } from '../../federation/collection/collection';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'mtn-store-actions-menu-button',
  templateUrl: './store-actions-menu-button.component.html',
  styleUrls: ['./store-actions-menu-button.component.scss']
})
export class StoreActionsMenuButtonComponent extends AuthorizationAwareComponent {

  @Input()
  storeUuids: string[];

  collections: Collection[] = [];

  constructor(private collectionService: CollectionService,
              protected ngrxStore: NgrxStore<AppState>,
              private toaster: ToastService,
              private userProfileService: UserProfileService,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.loadCollections();
  }

  addToCollection(collection: Collection): void {
    this.collectionService.addAllIncludedFeatures(collection.uuid, this.storeUuids)
      .subscribe(() => {
        this.toaster.info('Successfully updated collection');
      });
  }

  copyUrlToClipboard(): void {
    const detailsPageUrl = `${window.location.origin}/detail/store/${this.storeUuids[0]}`;
    ClipboardUtil.copyValueToClipboard(detailsPageUrl);
    this.toaster.info("Copied Store Details URL to clipboard");
  }

  openAddToCollectionWizard(): void {
    const wizard = new AddToCollectionWizard();
    wizard.model = {
      storeUuids: this.storeUuids,
      userProfileUuid: this.currentUser.uuid
    };

    this.wizardRunner.run(wizard);
  }

  openCreateNewCollectionWizard(): void {
    const wizard = new CreateOrEditCollectionWizard();
    wizard.model = {
      companyUuid: this.currentUser.company.uuid,
      storeUuids: this.storeUuids,
      userProfileUuid: this.currentUser.uuid
    };

    this.wizardRunner.run(wizard);
  }

  openDownloadWizard(): void {
    const wizard = new StoreExportWizard();
    wizard.model = {
      uuids: this.storeUuids
    };

    this.wizardRunner.run(wizard);
  }

  /**
   * We only load the most recent 5 favorited collections.
   *
   * TODO We want to track the user's activity with each collection, not just rely on createdDate or updatedDate.
   * We want to know which were the last five favorited collections the user actually interacted with. See MTN-1742.
   */
  loadCollections(): void {
    const params = new HttpParams()
      .set('size', '5')
      .set('sort', 'createdDate,desc');

    this.userProfileService.findOnesFavoriteCollections(this.currentUser.uuid, params)
      .subscribe((result: Pageable<Collection>) => {
        if (result?.totalElements) {
          this.collections = result.content;
        }
      });
  }

}

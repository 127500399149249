import { Component, Input, OnInit } from '@angular/core';
import { StoreComparison } from '../../../../core/store/ranking/store-comparison';
import { DetailState, selectDetailState } from '../../../detail-state';
import { AppState } from '../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import * as _ from 'lodash';
import { StoreRanking } from '../../../../core/store/ranking/store-ranking';
import { RegionType } from '../../../../core/location/region-type.enum';
import { ComparisonType } from '../../../../core/store/ranking/comparison-type.enum';
import * as chroma from 'chroma-js';
import { ChartOptions, ChartTooltipModel } from 'chart.js';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import {
  CompetitiveAnalysisDetailsWizard
} from './competitive-analysis-details-wizard/competitive-analysis-details-wizard';
import { AuthorizationAwareComponent } from '../../../../core/authorization-aware-component';
import {
  buildRankingDescriptor,
  buildStoreRankingGroupDescriptor,
  buildStoreRankingTypeDescriptor
} from './comparison-utils';
import { FeatureCollection } from '../../../../core/models';
import { Size } from '../../../../core/util/size.enum';

@Component({
  selector: 'mtn-competitive-analysis-card',
  templateUrl: './competitive-analysis-card.component.html',
  styleUrls: ['./competitive-analysis-card.component.scss']
})
export class CompetitiveAnalysisCardComponent extends AuthorizationAwareComponent implements OnInit {

  private readonly GRADIENT = chroma.scale(['red', 'orange', 'limegreen']);

  @Input()
  comparisonRequest: StoreComparison;
  @Input()
  isDummyComparison = false;
  @Input()
  size = Size.LARGE;

  chartColors: any[];
  chartDataSets: any[];
  chartLabels: string[];
  chartOptions: ChartOptions;
  color: string;
  comparison: StoreComparison;
  isLoading = true;
  prefix: string;
  ranking: StoreRanking;
  rankingDescriptor: string;
  rankingGroupDescriptor: string;
  rankingTypeDescriptor: string;
  RegionType = RegionType;
  tooltip: string;
  ComparisonType = ComparisonType;

  private marketArea: FeatureCollection;

  constructor(protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.subscribeToDetailState();
  }

  openComparisonDetailWizard(): void {
    const wizard = new CompetitiveAnalysisDetailsWizard();
    wizard.model = {
      comparison: this.comparison,
      isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
      marketArea: this.marketArea
    };

    this.wizardRunner.run(wizard);
  }

  private subscribeToDetailState(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .subscribe((state: DetailState) => {
          this.marketArea = state.marketArea;
          if (!this.comparison) {
            if (this.isDummyComparison) {
              this.comparison = this.comparisonRequest;
            } else {
              this.comparison = _.find([...state.defaultComparisons, ...state.customComparisons], (comparison: StoreComparison) => this.comparisonRequest.equals(comparison, true));
            }

            if (this.comparison && this.comparison.rankings.length) {
              this.ranking = this.comparison.getRankingForStore(state.store.uuid);
              this.tooltip = this.ranking.getFormattedPercentileString();
              this.rankingDescriptor = buildRankingDescriptor(this.ranking);
              this.rankingGroupDescriptor = buildStoreRankingGroupDescriptor(this.comparison);
              this.rankingTypeDescriptor = buildStoreRankingTypeDescriptor(this.comparison);

              if (this.rankingDescriptor.startsWith('A')) {
                this.prefix = 'This Store is an';
              } else {
                this.prefix = 'This Store is a';
              }

              this.chartDataSets = [{
                data: [this.ranking.percentile, 100 - this.ranking.percentile],
                label: 'Store Ranking'
              }];
              this.chartLabels = [this.ranking.getFormattedPercentileString(), null];

              this.color = this.GRADIENT(this.ranking.percentile / 100).css();
              const backgroundColor = this.color.replace(')', ',0.6)');
              const hoverBackgroundColor = this.color.replace(')', ',0.5)');
              this.chartColors = [
                {
                  backgroundColor: [backgroundColor, 'rgba(0,0,0,0.1)'],
                  borderColor: [this.color, 'rgba(0,0,0,0)'],
                  hoverBackgroundColor: [hoverBackgroundColor, 'rgba(0,0,0,0.1)'],
                  borderWidth: this.size === Size.LARGE ? 2 : 1
                }
              ];
              this.chartOptions = {
                cutoutPercentage: 75,
                tooltips: {
                  bodyAlign: 'center',
                  displayColors: false,
                  custom: (tooltipModel: ChartTooltipModel) => {
                    let index = 1;
                    if (tooltipModel.dataPoints) {
                      index = tooltipModel.dataPoints[0].index;
                      tooltipModel.body[0].lines = [this.chartLabels[0]]
                    }

                    tooltipModel.opacity = index ? 0 : 1;
                    tooltipModel.width = 100;
                  }
                }
              };
              this.isLoading = false;
            } else if (this.comparison) {
              this.isLoading = false;
            }
          }
        })
    );
  }

}

import { createAction, props } from '@ngrx/store';
import { Definition } from './definition';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import { UserProfile } from '../user-profile/user-profile';
import { ShoppingCenterType } from '../identity/constant/shopping-center-type.enum';
import { QuadrantType } from '../identity/constant/quadrant-type.enum';
import { StoreStatusType } from '../identity/constant/store-status-type.enum';
import { IntersectionType } from '../identity/constant/intersection-type.enum';
import { FitType } from '../store/fit/fit-type.enum';
import { FormatType } from '../store/format/format-type.enum';
import { AddOnType } from '../company/add-on-type.enum';

export const DefinitionSetAddOnTypesAction = createAction('definition-set-add-on-types', props<{ definitions: Definition<AddOnType>[] }>());
export const DefinitionSetFitsAction = createAction('definition-set-fits', props<{ definitions: Definition<FitType>[] }>());
export const DefinitionSetFormatsAction = createAction('definition-set-formats', props<{ definitions: Definition<FormatType>[] }>());
export const DefinitionSetIntersectionTypesAction = createAction('definition-set-intersection-types', props<{ definitions: Definition<IntersectionType>[] }>());
export const DefinitionSetLicenseTypesAction = createAction('definition-set-license-types', props<{ definitions: Definition<LicenseType>[] }>());
export const DefinitionCacheUserProfileAction = createAction('definition-cache-user-profile', props<{ user: UserProfile }>());
export const DefinitionSetShoppingCenterTypesAction = createAction('definition-set-shopping-center-types', props<{ definitions: Definition<ShoppingCenterType>[] }>());
export const DefinitionSetQuadrantTypesAction = createAction('definition-set-quadrant-types', props<{ definitions: Definition<QuadrantType>[] }>());
export const DefinitionSetStoreStatusTypesAction = createAction('definition-set-store-status-types', props<{ definitions: Definition<StoreStatusType>[] }>());

import { Injectable } from '@angular/core';
import { AddOn } from './add-on';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Observable } from 'rxjs';
import { Definition } from '../definition/definition';
import { AddOnType } from './add-on-type.enum';
import { map } from 'rxjs/operators';
import { InsightsRestService } from '../service/insights-rest-service';

@Injectable({
  providedIn: 'root'
})
export class AddOnService extends InsightsRestService {

  constructor(protected http: HttpClient) {
    super();
  }

  findAll(): Observable<AddOn[]> {
    const url = this.buildUrl('/add-on');
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map(this.buildInstance)));
  }

  findAllTypes(): Observable<Definition<AddOnType>[]> {
    const url = this.buildUrl('/add-on/type');
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new Definition<AddOnType>(result))));
  }

  protected buildInstance(raw: any): AddOn {
    return new AddOn(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<AddOn> {
    return new Pageable<AddOn>(raw, AddOn);
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mtn-user-not-registered',
  templateUrl: './user-not-registered.component.html',
  styleUrls: ['./user-not-registered.component.scss']
})
export class UserNotRegisteredComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

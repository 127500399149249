import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { compareByUuid } from '../../../util/collection-util';
import { Definition } from '../../../definition/definition';
import { LicenseType } from '../../../../auth/authorization/license-type.enum';
import { BaseComponent } from '../../../base-component';
import { DefinitionService } from '../../../definition/definition.service';

@Component({
  selector: 'mtn-license-types-select',
  templateUrl: './license-types-select.component.html',
  styleUrls: ['./license-types-select.component.scss']
})
export class LicenseTypesSelectComponent extends BaseComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;
  @Input()
  controlName: string = 'type';
  @Input()
  placeholder: string = 'License Type';
  @Input()
  isRequired = false;

  comparator = compareByUuid;
  LicenseType = LicenseType;
  options: Definition<LicenseType>[] = [];

  constructor(private definitionService: DefinitionService) {
    super();
  }

  ngOnInit(): void {
    this.loadLicenseTypes();
  }

  private loadLicenseTypes(): void {
    this.definitionService.findAllLicenseTypes()
      .subscribe((definitions: Definition<LicenseType>[]) => {
        this.options = definitions;
      });
  }

}

import { SpatialShapeType } from '../depot/spatial-shape-type.enum';

export class FipsObject {
  abbreviation: string;
  fips: string;
  name: string;
  shapeType: SpatialShapeType;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.shapeType) {
        // @ts-ignore
        this.shapeType = SpatialShapeType[<string>raw.shapeType];
      }
    }
  }
}

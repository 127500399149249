<div class="registration-container container" fxLayout="column" fxLayoutAlign="start center">
  <div class="registration-panel-container mat-elevation-z1 container default-padding" fxLayout="column">
    <div *ngIf="!isSignedUp; else signedUp">
      <h1 class="mat-h1 color-primary text-small-caps">Welcome! Sign up here.</h1>
      <p class="color-muted">Follow these simple steps to gain access to Insights and see for yourself what it's all
        about:</p>
      <div fxLayout="row" fxLayoutGap="15px">
        <div class="stepper-container" fxLayout="column" fxLayoutGap="10px">
          <div class="step-container" *ngFor="let step of steps; let i = index;" fxLayout="row"
               fxLayoutAlign="start center" fxLayoutGap="15px" [ngClass]="{active: currentStep === i}">
            <div class="number-container" fxLayoutAlign="center center">
              <span>{{i + 1}}</span>
            </div>
            <span>{{step}}</span>
            <div fxFlex></div>
            <fa-icon class="color-accent" icon="arrow-circle-right" size="2x" *ngIf="currentStep === i"></fa-icon>
          </div>
        </div>
        <form class="form-container container" fxFlex (ngSubmit)="next()">
          <div class="user-form-container" *ngIf="currentStep === 0" [formGroup]="userForm">
            <h3 class="mat-h3">Tell us about yourself:</h3>
            <div fxLayout="row wrap" fxLayoutGap="15px">
              <mtn-text-field [form]="userForm" controlName="firstName" placeholder="First Name"></mtn-text-field>
              <mtn-text-field [form]="userForm" controlName="lastName" placeholder="Last Name"></mtn-text-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="15px">
              <mtn-text-field [form]="userForm" controlName="title" placeholder="Job Title"></mtn-text-field>
              <mtn-phone-number-field [form]="userForm"></mtn-phone-number-field>
            </div>
            <br/>
            <h3 class="mat-h3 no-bottom-margin">What do you hope to achieve with Insights?</h3>
            <mtn-text-area-field [form]="userForm" controlName="hope" [maxLength]="500"></mtn-text-area-field>
            <br/>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px" [mtnFormErrorHighlighter]="currentForm">
              <button type="submit" color="accent" mat-raised-button [disabled]="currentForm.invalid" (click)="next()">
                Next
              </button>
            </div>
          </div>
          <div class="organization-form-container" *ngIf="currentStep === 1" [formGroup]="organizationForm">
            <h3 class="mat-h3">Tell us about your organization:</h3>
            <mtn-text-field class="default-field-width" [form]="organizationForm" controlName="name"
                            placeholder="Organization Name"></mtn-text-field>
            <div fxLayout="row" fxLayoutGap="15px">
              <mat-form-field class="default-field-width" appearance="outline">
                <mat-select formControlName="type" placeholder="Organization Type">
                  <mat-option *ngFor="let option of organizationTypes" [value]="option">{{option}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mtn-text-field *ngIf="organizationForm.get('type').value === 'Other'" [form]="organizationForm"
                              controlName="description" placeholder="Description"></mtn-text-field>
            </div>
            <mat-checkbox formControlName="hasRegistered" color="primary">My organization already has registered users
            </mat-checkbox>
            <br/>
            <br/>
            <h3 class="mat-h3 no-bottom-margin">How many in your organization would you expect to use Insights?</h3>
            <mat-form-field class="default-field-width" appearance="outline">
              <mat-select formControlName="userCount">
                <mat-option *ngFor="let option of userCountOptions" [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
            <br/>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px" [mtnFormErrorHighlighter]="currentForm">
              <button mat-raised-button (click)="back()">
                Back
              </button>
              <button type="submit" color="accent" mat-raised-button [disabled]="currentForm.invalid" (click)="next()">
                Next
              </button>
            </div>
          </div>
          <div class="account-form-container" *ngIf="currentStep === 2" [formGroup]="accountForm">
            <h3 class="mat-h3">Create your account:</h3>
            <div fxLayout="row" fxLayoutGap="15px">
              <p>In order to protect your login information from breaches and bad actors, we use Auth0's
                industry-leading services. We'll help you easily create an <a href="https://auth0.com" target="_blank">Auth0</a>
                account, and you'll use that account to access MTN's platform.</p>
              <a href="https://auth0.com" target="_blank"><img height="40" src="assets/images/auth0-logo.png"
                                                               alt="Auth0 logo"/></a>
            </div>

            <mtn-email-address-field class="default-field-width" [form]="accountForm"></mtn-email-address-field>
            <div fxLayout="row wrap" fxLayoutGap="15px">
              <mat-form-field class="default-field-width container" appearance="outline">
                <mat-label>Password</mat-label>
                <input [type]="isPasswordShown ? 'text' : 'password'" matInput formControlName="password"/>
                <mat-error *ngIf="accountForm.get('password').hasError('required')">
                  <i>Must be provided</i>
                </mat-error>
                <mat-error
                  *ngIf="accountForm.get('password').hasError('missingNumber') || accountForm.get('password').hasError('missingUpperCase') || accountForm.get('password').hasError('missingLowerCase') || accountForm.get('password').hasError('missingSpecialCharacter') || accountForm.get('password').hasError('maxlength') || accountForm.get('password').hasError('minlength')">
                  <i>Invalid password</i>
                </mat-error>
                <fa-icon class="clickable show-hide-password" [icon]="isPasswordShown ? 'eye-slash' : 'eye'"
                         (click)="isPasswordShown = !isPasswordShown"></fa-icon>
              </mat-form-field>
              <mat-form-field class="default-field-width" appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input [type]="isPasswordShown ? 'text' : 'password'" matInput formControlName="confirmPassword"/>
                <mat-error *ngIf="accountForm.get('confirmPassword').hasError('required')">
                  <i>Must be provided</i>
                </mat-error>
                <mat-error *ngIf="accountForm.get('confirmPassword').hasError('noPasswordMatch')">
                  <i>Passwords do not match</i>
                </mat-error>
                <fa-icon class="clickable show-hide-password" [icon]="isPasswordShown ? 'eye-slash' : 'eye'"
                         (click)="isPasswordShown = !isPasswordShown"></fa-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <div class="color-muted" fxLayout="column" style="font-size: 12px; line-height: 12px;">
                <small>Password must include at least:</small>
                <small style="margin-left: 20px">10 characters</small>
                <small style="margin-left: 20px">1 upper-case letter</small>
                <small style="margin-left: 20px">1 lower-case letter</small>
                <small style="margin-left: 20px">1 number</small>
                <small style="margin-left: 20px">1 special character (!,&#64;,#,$,%, etc)</small>
              </div>
            </div>
            <br/>
            <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom:15px">
              <mat-checkbox color="primary" formControlName="authorization"></mat-checkbox>
              <div fxFlex>
                I acknowledge Retailstat's use of Auth0 and authorize Retailstat to create my Auth0 account on my behalf
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" [mtnFormErrorHighlighter]="currentForm">
              <button mat-raised-button (click)="back()" style="height: 40px">
                Back
              </button>
              <div fxLayout="column" fxFlex>
                <button class="container" mat-raised-button color="accent" fxFlex (click)="next()"
                        [disabled]="accountForm.invalid">
                  Register and Sign In
                  <mtn-spinner [on]="isSubmitting" [size]="SpinnerSize.EXTRA_SMALL"></mtn-spinner>
                </button>
                <br/>
                <small class="color-muted text-center">By clicking "Register and Sign In", you acknowledge that you have
                  read and
                  agree to
                  our Terms of Service and
                  Privacy Policy.</small>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
    <ng-template #signedUp>
      <div class="signed-up-container container" fxLayout="column" fxLayoutAlign="start center"
           fxLayoutGap="30px">
        <fa-icon class="color-success" size="4x" icon="check-circle"></fa-icon>
        <h2 class="mat-h2 color-primary text-small-caps">Welcome to Insights!</h2>
        <div class="text-center">Your account is ready to go!</div>
        <div class="text-center">You'll sign in with your new Auth0 account, and we'll ask for permission to use your
          Auth0 account with our
          platform. Click "Sign In" below to continue.
        </div>
        <button class="sign-in-button" mat-raised-button color="accent" (click)="signIn()">
          Sign In
        </button>
      </div>
    </ng-template>
  </div>
</div>

import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';

import { MtnRoutingModule } from './core/modules/mtn-routing.module';
import { AppComponent } from './app.component';
import { MtnCoreModule } from './core/mtn-core.module';
import { StoreModule } from '@ngrx/store';
import { MtnNavigationModule } from './navigation/mtn-navigation.module';
import { MtnHelpModule } from './help/mtn-help.module';
import { MtnMapSearchModule } from './map-search/mtn-map-search.module';
import { MtnDashboardModule } from './dashboard/mtn-dashboard.module';
import { MtnProfileModule } from './profile/mtn-profile.module';
import { MtnMapModule } from './map/mtn-map.module';
import { MtnAuthModule } from './auth/mtn-auth.module';
import { MtnPublicModule } from './public/mtn-public.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationHeaderInterceptor } from './auth/authorization/application-header.interceptor';
import {
  ActivityMonitoringInterceptor
} from './auth/authentication/activity-monitoring/activity-monitoring.interceptor';
import { AuthorizationHeaderInterceptor } from './auth/authorization/authorization-header.interceptor';
import { ContentTypeHeaderInterceptor } from './core/service/content-type-header.interceptor';
import { MtnAdministrationModule } from './administration/mtn-administration.module';
import { AppUtils } from './app-utils';
import { MtnDetailModule } from './detail/mtn-detail.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { MtnCollectionsModule } from './collections/mtn-collections.module';
import { MaintenanceInterceptor } from './auth/authorization/maintenance.interceptor';
import { MtnMaintenanceModule } from './maintenance/mtn-maintenance.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({}),
    MtnRoutingModule,
    MtnCoreModule,
    MtnAuthModule,
    MtnMapModule,
    MtnNavigationModule,
    MtnDashboardModule,
    MtnDetailModule,
    MtnHelpModule,
    MtnMapSearchModule,
    MtnProfileModule,
    MtnPublicModule,
    MtnAdministrationModule,
    MtnCollectionsModule,
    MtnMaintenanceModule,
    RecaptchaV3Module
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ContentTypeHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizationHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApplicationHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ActivityMonitoringInterceptor, multi: true},
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcwkoAbAAAAAOCR5t6XvbvwY-QntKQDQc5VO_0E'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(injector: Injector) {
    AppUtils.injector = injector;
  }
}

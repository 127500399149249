import { Injectable } from '@angular/core';
import { InsightsRestService } from '../../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { StoreRankingRequest } from './store-ranking-request';
import { Observable } from 'rxjs';
import { StoreRanking } from './store-ranking';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreRankingService extends InsightsRestService {

  constructor(private http: HttpClient) {
    super();
  }

  findAllRankings(uuid: string, request: StoreRankingRequest): Observable<StoreRanking[]> {
    const url = this.buildUrl(`/store/${uuid}/ranking`);
    return this.http.post(url, request)
      .pipe(map((results: any[]) => results.map((result: any) => new StoreRanking(result))));
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor() {
  }

  downloadBlob(data: Blob, mimeType: string, filename?: string): void {
    // @ts-ignore
    const blob = new Blob([data], {type: mimeType});
    const url = window.URL.createObjectURL(blob);

    if (!filename) {
      filename = 'insights-export.csv';
    }

    const tempLink = document.createElement("a");
    document.body.appendChild(tempLink);
    // @ts-ignore
    tempLink.style = 'display:none';
    tempLink.href = url;
    tempLink.download = filename;
    tempLink.click();

    window.URL.revokeObjectURL(url);
  }
}

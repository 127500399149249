import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { ShoppingCenter } from '../models';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Observable } from 'rxjs';
import { Definition } from '../definition/definition';
import { ShoppingCenterType } from '../identity/constant/shopping-center-type.enum';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCenterService extends CrudService<ShoppingCenter> {

  protected path = '/shopping-center';

  constructor(protected http: HttpClient) {
    super(http);
  }

  findAllTypes(): Observable<Definition<ShoppingCenterType>[]> {
    const url = this.buildUrl('/type');
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new Definition<ShoppingCenterType>(result))));
  }

  protected buildInstance(raw: any): ShoppingCenter {
    return new ShoppingCenter(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<ShoppingCenter> {
    return new Pageable<ShoppingCenter>(raw, ShoppingCenter);
  }


}

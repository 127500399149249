import { DateUtil } from '../util/date-util';

export abstract class HubspotObject {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  archived: boolean;

  protected constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.createdAt) {
        this.createdAt = DateUtil.buildFromTimestamp(raw.createdAt);
      }
      if (raw.updatedAt) {
        this.updatedAt = DateUtil.buildFromTimestamp(raw.updatedAt);
      }
    }
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BaseComponent } from '../../../../core/base-component';
import { UpdateLicenseWizard } from './update-license-wizard/update-license-wizard';
import { Company } from '../../../../core/company/company';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import { SuspendLicenseWizard } from './suspend-license-wizard/suspend-license-wizard';
import { ConfirmActionWizard } from '../../../../core/confirm-action-wizard/confirm-action-wizard';
import { LicenseService } from '../../../../core/license/license.service';
import { ToastService } from '../../../../core/toast/toast.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Definition } from '../../../../core/definition/definition';
import { LicenseType } from '../../../../auth/authorization/license-type.enum';
import { DefinitionService } from '../../../../core/definition/definition.service';

@Component({
  selector: 'mtn-license-card',
  templateUrl: './license-card.component.html',
  styleUrls: ['./license-card.component.scss']
})
export class LicenseCardComponent extends BaseComponent implements OnChanges {

  @Output()
  onUpdate = new EventEmitter<any>();

  @Input()
  company: Company;
  @Input()
  isActionsEnabled = true;

  licenseType: Definition<LicenseType>;

  constructor(private definitionService: DefinitionService,
              private licenseService: LicenseService,
              private toaster: ToastService,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnChanges() {
    this.initDefinition();
  }

  openReinstateLicenseWizard(): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      title: 'Reinstate License',
      text: 'Are you sure you want to reinstate this license?',
      onConfirm: this.reinstateLicense()
    };

    this.wizardRunner.run(wizard);
  }

  openSuspendLicenseWizard(): void {
    const wizard = new SuspendLicenseWizard();
    wizard.model = {
      company: this.company
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: SuspendLicenseWizard) => {
      if (result.model.isUpdated) {
        this.onUpdate.emit();
      }
    });
  }

  openUpdateLicenseWizard(): void {
    const wizard = new UpdateLicenseWizard();
    wizard.model = {
      company: this.company
    };

    this.wizardRunner.run(wizard)
      .afterClosed().subscribe((result: UpdateLicenseWizard) => {
      if (result.model.isUpdated) {
        this.onUpdate.emit();
      }
    });
  }

  private initDefinition(): void {
    this.definitionService.findAllLicenseTypes()
      .pipe(take(1))
      .subscribe((definitions: Definition<LicenseType>[]) => {
        if (this.company) {
          this.licenseType = _.find(definitions, (definition: Definition<LicenseType>) => definition.systemName === this.company.license.type);
        }
      });
  }

  private reinstateLicense(): Observable<any> {
    const request = _.cloneDeep(this.company.license);
    request.suspendedDate = null;
    request.suspendedReason = null;
    request.suspendedComment = null;

    return this.licenseService.updateOne(request)
      .pipe(tap(() => {
        this.toaster.info('Successfully reinstated license');
        this.onUpdate.emit();
      }));
  }
}

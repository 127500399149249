import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnCoreModule } from '../core/mtn-core.module';
import { PublicLandingPageComponent } from './public-landing-page/public-landing-page.component';
import { UserNotRegisteredComponent } from './user-not-registered/user-not-registered.component';
import { MtnMaterialModule } from '../core/modules/mtn-material.module';
import { CoverageMapComponent } from './public-landing-page/coverage-map/coverage-map.component';
import { SignUpSignInPanelComponent } from './public-landing-page/sign-up-sign-in-panel/sign-up-sign-in-panel.component';
import { QuestionViewComponent } from './public-landing-page/sign-up-sign-in-panel/question-view/question-view.component';
import { RegistrationComponent } from './registration/registration.component';
import { MtnRoutingModule } from '../core/modules/mtn-routing.module';
import { MtnMapModule } from '../map/mtn-map.module';
import { CbsaSelectionDialogComponent } from './public-landing-page/cbsa-selection-dialog/cbsa-selection-dialog.component';
import { SearchPipe } from './public-landing-page/cbsa-selection-dialog/search.pipe';
import { ChartsModule } from 'angular-bootstrap-md';
import { NotAvailableComponent } from './not-available/not-available.component';
import { InvitationComponent } from './invitation/invitation.component';


@NgModule({
  declarations: [
    PublicLandingPageComponent,
    UserNotRegisteredComponent,
    CoverageMapComponent,
    SignUpSignInPanelComponent,
    QuestionViewComponent,
    RegistrationComponent,
    RegistrationComponent,
    CbsaSelectionDialogComponent,
    SearchPipe,
    NotAvailableComponent,
    InvitationComponent
  ],
  imports: [
    CommonModule,
    MtnCoreModule,
    MtnMaterialModule,
    MtnRoutingModule,
    MtnMapModule,
    ChartsModule
  ]
})
export class MtnPublicModule {
}

/// <reference types="@types/google.maps" />

import { MultiPolygon, Polygon } from 'geojson';
import { Feature, FeatureCollection } from '../models';
import LatLng = google.maps.LatLng;

export class GeometryUtil {

  static convertGeoJsonMultiPolygonToGooglePolygon(shape: MultiPolygon): google.maps.Polygon {
    const paths: LatLng[][] = [];

    shape.coordinates.forEach((polyCoordinate: any) => {
      const path = this.extractGeoJsonPaths(polyCoordinate);
      paths.push.apply(paths, path);
    });

    return new google.maps.Polygon({paths: paths});
  }

  static convertGeoJsonPolygonToGooglePolygon(shape: Polygon): google.maps.Polygon {
    const paths = this.extractGeoJsonPaths(shape.coordinates);
    return new google.maps.Polygon({paths: paths});
  }

  static googleCircleToGeoJsonFeatureCollection(circle: google.maps.Circle): FeatureCollection {
    const feature = new Feature({
      geometry: {
        type: 'Polygon',
        coordinates: [GeometryUtil.getCirclePath(circle)]
      }
    });

    const featureCollection = new FeatureCollection();
    featureCollection.features.push(feature);
    return featureCollection;
  }

  static googleCircleToGooglePolygon(circle: google.maps.Circle): google.maps.Polygon {
    const path = this.getCirclePathAsLatLng(circle);
    return new google.maps.Polygon({
      paths: path
    });
  }

  static googlePolygonToGeoJsonFeature(polygon: google.maps.Polygon): Feature {
    const rings: number[][][] = [];
    polygon.getPaths().forEach((path, index) => {
      const ring: number[][] = [];
      path.forEach((latLng: LatLng) => ring.push([latLng.lng(), latLng.lat()]));
      ring.push(ring[0]);
      const isClockwise = GeometryUtil.ringIsClockwise(ring);
      if ((index === 0 && isClockwise) || (index !== 0 && !isClockwise)) {
        rings.push(ring.reverse());
      } else {
        rings.push(ring);
      }
    });
    return new Feature({
      geometry: {
        type: 'Polygon',
        coordinates: rings
      }
    });
  }

  // Test for right/left handedness (right = counter)
  // (https://stackoverflow.com/questions/1165647/how-to-determine-if-a-list-of-polygon-points-are-in-clockwise-order)
  static ringIsClockwise(ring: any) {
    let sum = 0;
    for (let i = 1; i < ring.length; i++) {
      const x2 = ring[i][0];
      const x1 = ring[i - 1][0];
      const y2 = ring[i][1];
      const y1 = ring[i - 1][1];
      sum += (x2 - x1) * (y2 + y1);
    }
    return sum > 0;
  }

  private static extractGeoJsonPaths(polyCoordinates: any[]): LatLng[][] {
    const paths: LatLng[][] = [];
    polyCoordinates.forEach((ring: any[]) => {
      const path: google.maps.LatLng[] = [];
      ring.forEach(coordinates => {
        const lng = coordinates[0];
        const lat = coordinates[1];
        if (path.length > 0) {
          const prevCoord = path[path.length - 1];
          if (prevCoord.lat() !== lat || prevCoord.lng() !== lng) {
            path.push(new google.maps.LatLng(lat, lng));
          }
          // else skip (eliminates duplicate points
        } else {
          path.push(new google.maps.LatLng(lat, lng));
        }
      });
      paths.push(path);
    });

    return paths
  }

  private static getCirclePath(circle: google.maps.Circle) {
    const center = circle.getCenter();
    const radius = circle.getRadius();
    const coordinates = [];
    for (let i = 0; i <= 360; i += 3) {
      const latLng = google.maps.geometry.spherical.computeOffset(center, radius, i);
      coordinates.push([latLng.lng(), latLng.lat()]);
    }
    return coordinates;
  }

  private static getCirclePathAsLatLng(circle: google.maps.Circle): LatLng[] {
    const path: LatLng[] = [];

    const center = circle.getCenter();
    const radius = circle.getRadius();

    for (let i = 0; i <= 360; i += 3) {
      const latLng = google.maps.geometry.spherical.computeOffset(center, radius, i);
      path.push(latLng);
    }

    return path;
  }

}

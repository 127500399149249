import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { CreateCompanyWizard } from '../create-company-wizard';
import { HubspotCompany } from '../../../../../core/hubspot/hubspot-company';
import { forkJoin, Observable } from 'rxjs';
import { HubspotContact } from '../../../../../core/hubspot/hubspot-contact';
import { HubspotCompanyService } from '../../../../../core/hubspot/hubspot-company.service';
import { map, tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Company } from '../../../../../core/company/company';
import { CompanyService } from '../../../../../core/company/company.service';
import { ToastService } from '../../../../../core/toast/toast.service';

@Component({
  selector: 'mtn-select-hubspot-company-page',
  templateUrl: './select-hubspot-company-page.component.html',
  styleUrls: ['./select-hubspot-company-page.component.scss']
})
export class SelectHubspotCompanyPageComponent extends WizardPageComponent<CreateCompanyWizard> {

  key = 'select-hubspot-company';

  options: HubspotCompany[] = [];

  constructor(private companyService: CompanyService,
              private hubSpotCompanyService: HubspotCompanyService,
              private toaster: ToastService) {
    super();
    this.title = 'Create Company';
    this.closeButtonText = 'Cancel';
    this.nextButtonText = 'Submit';
    this.stepperText = 'HubSpot';
  }

  onLoad(): Observable<any> {
    this.options = this.wizard.model.hubSpotCompanies;

    this.initForm();

    const contactTasks: Observable<any>[] = this.options.map((company: HubspotCompany) => {
      return this.hubSpotCompanyService.findOnesContacts(company.id)
        .pipe(tap((contacts: HubspotContact[]) => company.contacts = contacts));
    });

    return forkJoin(contactTasks);
  }

  onNext(): Observable<string> {
    const request = this.wizard.model.wipRequest;
    request.hubspotId = this.form.getRawValue().hubspotId;

    return this.companyService.registerOne(request)
      .pipe(map((result: Company) => {
        this.toaster.info('Successfully created Company');
        this.wizard.model.company = result;
        return null;
      }));
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      hubspotId: new UntypedFormControl()
    });
  }

}

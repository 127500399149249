import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { MtnCoreModule } from '../core/mtn-core.module';
import { ProfileSidebarComponent } from './profile/profile-sidebar/profile-sidebar.component';
import { ProfilePreferencesTabComponent } from './profile/profile-preferences-tab/profile-preferences-tab.component';
import { MtnMapModule } from '../map/mtn-map.module';
import { EditProfilePageComponent } from './profile/profile-sidebar/edit-profile-wizard/edit-profile-page/edit-profile-page.component';


@NgModule({
  declarations: [
    ProfileComponent,
    ProfileSidebarComponent,
    ProfilePreferencesTabComponent,
    EditProfilePageComponent
  ],
  imports: [
    CommonModule,
    MtnCoreModule,
    MtnMapModule
  ]
})
export class MtnProfileModule {
}

import { Component, Input, OnChanges } from '@angular/core';
import { Banner } from '../../models';
import { LogoUtil } from '../../util/logo-util';

@Component({
  selector: 'mtn-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.scss']
})
export class BannerImageComponent implements OnChanges {

  @Input()
  banner: Banner;
  @Input()
  width = 120;

  hasUrl = false;

  constructor() {
  }

  ngOnChanges() {
    this.hasUrl = !!this.banner?.logoCloudinaryFilename;
  }

  getLogoUrl(): string {
    let url = LogoUtil.buildLogoUrl(this.banner.logoCloudinaryFilename);
    url = url.replace('h_20,w_100', 'h_40,w_200'); //Allow these to be larger than default
    return url;
  }

}

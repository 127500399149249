export enum DemographicFieldType {
  AGE_AVERAGE = 'AGE_AVERAGE',
  EDUCATION_COLLEGE_ASSOCIATES = 'EDUCATION_COLLEGE_ASSOCIATES',
  EDUCATION_COLLEGE_BACHELORS = 'EDUCATION_COLLEGE_BACHELORS',
  EDUCATION_COLLEGE_DOCTORATE = 'EDUCATION_COLLEGE_DOCTORATE',
  EDUCATION_COLLEGE_MASTERS = 'EDUCATION_COLLEGE_MASTERS',
  EDUCATION_COLLEGE_PROFESSIONAL = 'EDUCATION_COLLEGE_PROFESSIONAL',
  ETHNICITY_ASIAN = 'ETHNICITY_ASIAN',
  ETHNICITY_BLACK = 'ETHNICITY_BLACK',
  ETHNICITY_HISPANIC = 'ETHNICITY_HISPANIC',
  ETHNICITY_OTHER = 'ETHNICITY_OTHER',
  ETHNICITY_WHITE = 'ETHNICITY_WHITE',
  HOUSEHOLDS_AVERAGE_INCOME = 'HOUSEHOLDS_AVERAGE_INCOME',
  HOUSEHOLDS_ESTIMATE = 'HOUSEHOLDS_ESTIMATE',
  HOUSEHOLDS_MEDIAN_INCOME = 'HOUSEHOLDS_MEDIAN_INCOME',
  HOUSEHOLDS_PERSONS_PER = 'HOUSEHOLDS_PERSONS_PER',
  LAND_AREA = 'LAND_AREA',
  LANGUAGE_CHINESE = 'LANGUAGE_CHINESE',
  LANGUAGE_ENGLISH_ONLY = 'LANGUAGE_ENGLISH_ONLY',
  LANGUAGE_KOREAN = 'LANGUAGE_KOREAN',
  LANGUAGE_OTHER_ASIAN = 'LANGUAGE_OTHER_ASIAN',
  LANGUAGE_SPANISH = 'LANGUAGE_SPANISH',
  LANGUAGE_TAGALOG_OTHER_PACIFIC = 'LANGUAGE_TAGALOG_OTHER_PACIFIC',
  LANGUAGE_VIETNAMESE = 'LANGUAGE_VIETNAMESE',
  PER_CAPITA_AVERAGE_INCOME = 'PER_CAPITA_AVERAGE_INCOME',
  POPULATION_2010 = 'POPULATION_2010',
  POPULATION_ESTIMATE = 'POPULATION_ESTIMATE',
  POPULATION_FORECAST_5_YEAR = 'POPULATION_FORECAST_5_YEAR',
  POPULATION_SEASONAL_ESTIMATE = 'POPULATION_SEASONAL_ESTIMATE',
  POPULATION_TRANSIENT_ESTIMATE = 'POPULATION_TRANSIENT_ESTIMATE',
  SEX_FEMALE = 'SEX_FEMALE',
  SEX_MALE = 'SEX_MALE',
  POPULATION_ESTIMATE_Q_MINUS_8 = 'POPULATION_ESTIMATE_Q_MINUS_8'
}

export namespace DemographicFieldType {

  export function values(): DemographicFieldType[] {
    // @ts-ignore
    return Object.keys(DemographicFieldType).filter((key: any) => isNaN(key) && key !== 'values').map((key: string) => DemographicFieldType[key]);
  }
}

import { Injectable } from '@angular/core';
import { DarkStyles, DefaultVisibilityStyles } from './map-styles';
import { MapMode } from '../map-mode.enum';
import { PointOfInterestType } from '../point-of-interest-type.enum';
import { MtnMapOptions } from '../mtn-map-options';
import MapTypeStyle = google.maps.MapTypeStyle;
import MapTypeId = google.maps.MapTypeId;

@Injectable({
  providedIn: 'root'
})
export class MapStyleService {

  constructor() {}

  buildStyles(options: MtnMapOptions): MapTypeStyle[] {
    const styles: any[] = [];

    // Add default visibility styles

    // Apply dark mode styles if applicable
    if (options.mapTypeId === MapTypeId.ROADMAP && options.mode === MapMode.DARK) {
      styles.push(...DarkStyles);
    }else{
      styles.push(...DefaultVisibilityStyles);
    }

    styles.push(...this.buildPointOfInterestStyles(options));

    return styles;
  }

   buildPointOfInterestStyles(options: MtnMapOptions): MapTypeStyle[] {
    const styles: MapTypeStyle[] = [];

    PointOfInterestType.values().forEach((pointOfInterest: PointOfInterestType) => {
      styles.push(this.buildPointOfInterestStyle(pointOfInterest, options.isPointsOfInterestEnabled));
    });

    return styles;
  }

  private buildPointOfInterestStyle(type: PointOfInterestType, value: boolean): MapTypeStyle {
    // @ts-ignore
    let featureType = `poi.${type.toLowerCase()}`;
    const setting = value ? 'on' : 'off';

    if (type === PointOfInterestType.TRANSIT_LINE) {
      featureType = 'transit.line';
    } else if (type === PointOfInterestType.TRANSIT_STATION) {
      featureType = 'transit.station';
    }

    return {
      featureType: featureType,
      elementType: 'labels',
      stylers: [
        {
          visibility: setting
        }
      ]
    };
  }
}

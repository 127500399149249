<div class="user-preferences-view-container container" fxLayout="column" fxLayoutGap="15px">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">User Preferences</h2>
      <mtn-what-is-this
        text="This is a view of the user's current preferences, for reference and use in troubleshooting. Only the user can change their preferences."></mtn-what-is-this>
    </div>
    <hr/>
  </div>
  <div>
    <mtn-key-value key="Key Indicator" [ngSwitch]="userPreferences?.primaryKeyIndicator">
      <span *ngSwitchCase="KeyIndicatorType.SALES_SQFT">{{Dictionary.labelSalesSqft}}</span>
      <span *ngSwitchCase="KeyIndicatorType.SALES_VOLUME">{{Dictionary.labelSalesVolume}}</span>
      <span *ngSwitchDefault class="color-muted">Not Available</span>
    </mtn-key-value>
    <mtn-key-value key="Map Type" [ngSwitch]="userPreferences?.mapDefaultType">
      <span *ngSwitchCase="MapTypeId.HYBRID">Satellite</span>
      <span *ngSwitchCase="MapTypeId.ROADMAP">Road</span>
      <span *ngSwitchDefault class="color-muted">Not Available</span>
    </mtn-key-value>
    <mtn-key-value key="Map Type (Road)" [ngSwitch]="userPreferences?.mapDefaultMode">
      <span *ngSwitchCase="MapMode.CLASSIC">Classic</span>
      <span *ngSwitchCase="MapMode.DARK">Dark</span>
      <span *ngSwitchDefault class="color-muted">Not Available</span>
    </mtn-key-value>
    <mtn-key-value key="Sales Volume Display" [ngSwitch]="userPreferences?.salesVolumeDisplayMode">
      <span *ngSwitchCase="SalesVolumeDisplayModeType.ANNUAL">Annual Sales</span>
      <span *ngSwitchCase="SalesVolumeDisplayModeType.WEEKLY">Weekly Sales</span>
      <span *ngSwitchDefault class="color-muted">Not Available</span>
    </mtn-key-value>
    <mtn-key-value key="Sales/sqft Display" [ngSwitch]="userPreferences?.salesSqftDisplayMode">
      <span *ngSwitchCase="SalesSqftDisplayModeType.SALES_AREA">Sales Area</span>
      <span *ngSwitchCase="SalesSqftDisplayModeType.TOTAL_AREA">Total Area</span>
      <span *ngSwitchDefault class="color-muted">Not Available</span>
    </mtn-key-value>
  </div>
  <mtn-spinner [on]="isLoading"></mtn-spinner>
</div>

<div class="collection-page-container container" *ngIf="collection; else loader" fxLayout="column"
     fxLayoutGap="30px">
  <div class="header-container" fxLayout="row" fxLayoutGap="15px">
    <div fxLayout="column" fxFlex>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <h1 class="mat-h1 color-accent no-bottom-margin">{{collection.name}}</h1>
        <fa-icon class="clickable" [ngClass]="{'color-accent': collection.isFavoritedBy(currentUser.uuid)}"
                 [icon]="collection.isFavoritedBy(currentUser.uuid) ? 'star' : ['far', 'star']"
                 (click)="toggleFavorite()" matTooltip="Favorite"></fa-icon>
      </div>
      <div class="description-container">
        <small class="color-muted">{{collection.description || 'Edit Collection to add a description'}}</small>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mtn-toolbar-button icon="share-alt" [disabled]="!collection.shares.length" (onClick)="copyUrlToClipboard()"
                          [matTooltip]="collection.shares.length ? 'Copy Link' : COPY_LINK_DISABLED_TOOLTIP"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'collection-details-share-button'}"
                          [matTooltipClass]="collection.shares.length ? null : 'multiline-tooltip'"></mtn-toolbar-button>
      <mtn-toolbar-button icon="cloud-download-alt" [disabled]="!features.length" (onClick)="openDownloadDialog()"
                          [matTooltip]="features.length ? 'Export' : EXPORT_DISABLED_TOOLTIP"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'collection-details-export-button'}"
                          [matTooltipClass]="features.length ? null : 'multiline-tooltip'"></mtn-toolbar-button>
      <mtn-toolbar-button icon="clone" (onClick)="openCloneCollectionWizard()"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'collection-details-clone-button'}"
                          matTooltip="Clone Collection"></mtn-toolbar-button>
      <mtn-toolbar-button icon="pencil-alt" [disabled]="!isCollectionEditor" (onClick)="openEditCollectionWizard()"
                          [matTooltip]="isCollectionEditor ? 'Edit Collection' : EDIT_COLLECTION_DISABLED_TOOLTIP"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'collection-details-edit-collection-button'}"
                          [matTooltipClass]="isCollectionEditor ? null : 'multiline-tooltip'"></mtn-toolbar-button>
      <mtn-toolbar-button icon="trash-alt" color="red" [disabled]="!isCollectionOwner"
                          (onClick)="openConfirmDeleteCollectionWizard()"
                          [matTooltip]="isCollectionOwner ? 'Delete Collection' : DELETE_COLLECTION_DISABLED_TOOLTIP"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'collection-details-delete-collection-button'}"
                          [matTooltipClass]="isCollectionOwner ? null : 'multiline-tooltip'"></mtn-toolbar-button>
    </div>
  </div>

  <div class="mid-section-container" fxLayout="row wrap" fxLayoutGap="30px">
    <div class="owner-container mat-elevation-z1 default-padding">
      <div class="panel-header-container" fxLayout="column">
        <strong>Owner</strong>
        <hr/>
      </div>
      <mtn-user-card [userUuid]="collection.owner.uuid"></mtn-user-card>
    </div>

    <div class="shares-container mat-elevation-z1 default-padding" fxLayout="column" fxLayoutGap="15px">
      <div class="panel-header-container" fxLayout="column">
        <strong>Share</strong>
        <hr/>
      </div>
      <!-- TODO enhance this to support sharing with other companies, especially for internal license -->
      <div class="standard-share-container container" fxLayout="column">
        <mat-checkbox color="accent" [checked]="!!companyCollection" (change)="toggleShared()"
                      mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                      [analyticsEventParameters]="{'target': 'collection-details-is-viewable-button'}">
          <span>Anyone in my company can view this collection</span>
        </mat-checkbox>
        <mat-checkbox color="accent" [checked]="companyCollection?.isEditable" [disabled]="!companyCollection"
                      mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                      [analyticsEventParameters]="{'target': 'collection-details-is-editable-button'}"
                      (change)="toggleEditable()">
          <span>Anyone in my company can edit this collection</span>
        </mat-checkbox>
        <mtn-spinner [on]="isSavingShares"></mtn-spinner>
      </div>
    </div>
  </div>

  <mtn-collection-feature-view [collection]="collection" [features]="features"
                               *ngIf="!isLoadingFeatures; else loader"></mtn-collection-feature-view>

</div>

<ng-template #loader>
  <div class="loader-container container">
    <mtn-spinner [size]="SpinnerSize.LARGE" [on]="true"></mtn-spinner>
  </div>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'insightsDate'
})
export class InsightsDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: Date): string {
    let format = 'MM/dd/yyyy';
    return this.datePipe.transform(value, format);
  }
}

import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { Banner } from '../models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BannerSearchRequest } from './banner-search-request';

@Injectable({
  providedIn: 'root'
})
export class BannerService extends CrudService<Banner> {

  protected path = '/banner';

  private bannerCache = new Map<string, Banner>();

  constructor(protected http: HttpClient) {
    super(http);
  }

  findOne(uuid: string): Observable<Banner> {
    if (this.bannerCache.has(uuid)) {
      return of(this.bannerCache.get(uuid));
    } else {
      return super.findOne(uuid)
        .pipe(tap((result: Banner) => this.bannerCache.set(result.uuid, result)));
    }
  }

  search(request: BannerSearchRequest, params?: HttpParams): Observable<Pageable<Banner>> {
    const url = this.buildUrl('/search');
    params = this.buildDefaultPaginationSupportedParams(params);

    return this.http.post(url, request, {params})
      .pipe(
        map((resultPage: any) => this.buildPageableInstance(resultPage)),
        tap((result: Pageable<Banner>) => {
          result.content.forEach((banner: Banner) => this.bannerCache.set(banner.uuid, banner));
        })
      );
  }

  protected buildInstance(raw: any): Banner {
    return new Banner(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Banner> {
    return new Pageable<Banner>(raw, Banner);
  }

}

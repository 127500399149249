import { Component, OnInit } from '@angular/core';
import { MaintenanceNotificationService } from '../maintenance-notification.service';
import { ToastService } from '../../toast/toast.service';
import { StorageService } from '../../util/storage.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { MaintenanceNotification } from '../maintenance-notification';
import * as moment from 'moment';

@Component({
  selector: 'mtn-maintenance-notification-worker',
  templateUrl: './maintenance-notification-worker.component.html',
  styleUrls: ['./maintenance-notification-worker.component.scss']
})
export class MaintenanceNotificationWorkerComponent implements OnInit {

  constructor(private maintenanceNotificationService: MaintenanceNotificationService,
              private storageService: StorageService,
              private toaster: ToastService) {
  }

  ngOnInit(): void {
    this.loadNotifications();
  }

  private getDateKey(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  private getTodaysDateKey(): string {
    return moment().format('YYYY-MM-DD');
  }

  private loadNotifications(): void {
    this.storageService.get('maintenanceNotifications')
      .pipe(
        take(1),
        switchMap((cache: any) => {
          //Build the notification date cache if we don't already have one
          if (!cache) {
            cache = {};
          }

          return this.maintenanceNotificationService.findAllUpcoming()
            .pipe(tap((results: MaintenanceNotification[]) => {
              if (results.length) {
                //We only need the last item in the list for our use here
                const nextMaintenanceNotification = results[results.length - 1];

                const today = this.getTodaysDateKey();

                //If we haven't acknowledged the notification today, show the notification
                const isCached = !!cache[nextMaintenanceNotification.uuid];
                const isAcknowledgedToday = isCached && cache[nextMaintenanceNotification.uuid] === today;

                if (!isAcknowledgedToday) {
                  const isMaintenanceToday = this.getDateKey(nextMaintenanceNotification.startDate) === today;
                  const time = moment(nextMaintenanceNotification.startDate).format('h:mm a');
                  let message: string;
                  if (isMaintenanceToday) {
                    message = `Scheduled maintenance will be performed today at ${time}, and should last ${nextMaintenanceNotification.estimatedDurationMinutes} minutes. The Data Suite will be unavailable during that time.`;
                  } else {
                    const date = moment(nextMaintenanceNotification.startDate).format('dddd, MMM Do, YYYY');
                    message = `Scheduled maintenance will be performed on ${date} at ${time}, and should last ${nextMaintenanceNotification.estimatedDurationMinutes} minutes. The Data Suite will be unavailable during that time.`;
                  }
                  this.toaster.info(message, -1, 'Got it')
                    .onAction()
                    .subscribe(() => {
                      cache[nextMaintenanceNotification.uuid] = today;
                      this.storageService.set('maintenanceNotifications', cache);
                    });
                }
              }
            }));
        })
      ).subscribe();
  }

}

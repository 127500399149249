import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HubspotCompany } from './hubspot-company';
import { map } from 'rxjs/operators';
import { HubspotContact } from './hubspot-contact';

@Injectable({
  providedIn: 'root'
})
export class HubspotCompanyService extends InsightsRestService {

  protected path = '/hubspot/company';

  constructor(private http: HttpClient) {
    super();
  }

  findOnesContacts(id: string): Observable<HubspotContact[]> {
    const url = this.buildUrl(`${this.path}/${id}/contact`);

    return this.http.get(url)
      .pipe(map((rawResults: any[]) => rawResults.map((rawResult: any) => new HubspotContact(rawResult))));
  }

  search(q: string): Observable<HubspotCompany[]> {
    const url = this.buildUrl(this.path);

    const params = new HttpParams()
      .set('q', q);

    return this.http.get(url, {params: params})
      .pipe(map((rawResults: any[]) => rawResults.map((rawResult: any) => new HubspotCompany(rawResult))));
  }
}

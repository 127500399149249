import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'mtn-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss']
})
export class NavigationButtonComponent {

  @Input()
  condensedMode = false;
  @Input()
  icon: IconProp;
  @Input()
  isDisabled = false;
  @Input()
  label: string;
  @Input()
  path: string;

  constructor() {
  }

}

<div class="table-container container condensed-table">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="observedDate" matSortDirection="desc">

    <!-- Link Column -->
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let store">
        <a href="javascript:void(0)" [routerLink]="isDefaultBehaviorDisabled ? null : ['/detail', 'store', store.uuid]"
           (click)="onLinkClick.emit(store)">
          <fa-icon icon="link"></fa-icon>
        </a>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let store">
        <span>{{store.name}} <span class="color-muted" *ngIf="store.number">({{store.number}})</span></span>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let store">
        <mtn-store-status-view [type]="store.getCurrentStatus().getStatusSystemName()"></mtn-store-status-view>
      </td>
    </ng-container>

    <!-- Observed Date Column -->
    <ng-container matColumnDef="observedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Observed Date</th>
      <td mat-cell *matCellDef="let store">
        <span>{{store.getCurrentStatus().statusDate | insightsDate}}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>

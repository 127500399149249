import { AuditableEntity } from '../auditable-entity';
import { Definition } from '../definition/definition';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import { DateUtil } from '../util/date-util';

export class License extends AuditableEntity {

  definition: Definition<LicenseType>;
  expirationDate: Date;
  startDate: Date;
  suspendedComment: string;
  suspendedDate: Date;
  suspendedReason: string;
  type: LicenseType;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);

      if (raw.expirationDate) {
        this.expirationDate = DateUtil.buildFromTimestamp(raw.expirationDate);
      }
      if (raw.startDate) {
        this.startDate = DateUtil.buildFromTimestamp(raw.startDate);
      }
      if (raw.suspendedDate) {
        this.suspendedDate = DateUtil.buildFromTimestamp(raw.suspendedDate);
      }
      if (raw.type) {
        // @ts-ignore
        this.type = LicenseType[<string>raw.type];
      }
    }
  }

  isExpired(): boolean {
    return this.expirationDate && this.expirationDate < new Date();
  }

  isValid(): boolean {
    return !this.suspendedDate && !this.isExpired();
  }
}

export class LicenseDefinition extends Definition<LicenseType> {

  constructor(raw?: any) {
    super(raw);
    if (raw && raw.systemName) {
      // @ts-ignore
      this.systemName = LicenseType[<string>raw.systemName];
    }
  }
}

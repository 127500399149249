import { Component, ViewChild } from '@angular/core';
import { WizardPageComponent } from '../../../../../../core/wizard/wizard-page-component';
import { AddAreaSubscriptionWizard } from '../add-area-subscription-wizard';
import { Observable } from 'rxjs';
import { AreaSubscription } from '../../../../../../core/company/area-subscription';
import { SpatialShapeType } from '../../../../../../core/depot/spatial-shape-type.enum';
import * as _ from 'lodash';
import { GeographicAreaSelectionListComponent } from '../../../../../../core/fips/geographic-area-selection-list/geographic-area-selection-list.component';
import { FipsObject } from '../../../../../../core/fips/fips-object';
import { CompanyService } from '../../../../../../core/company/company.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-add-area-subscription-page',
  templateUrl: './add-area-subscription-page.component.html',
  styleUrls: ['./add-area-subscription-page.component.scss']
})
export class AddAreaSubscriptionPageComponent extends WizardPageComponent<AddAreaSubscriptionWizard> {

  key = 'add-area-subscription';

  existingCbsaFips: string[] = [];
  existingStateFips: string[] = [];
  isSelectionsLoaded = false;

  @ViewChild("areas")
  areas: GeographicAreaSelectionListComponent;

  private selections: FipsObject[] = [];

  constructor(private companyService: CompanyService) {
    super();
    this.title = 'Add Area Subscription';
    this.nextButtonText = 'Submit';
    this.closeButtonText = 'Cancel';
  }

  onLoad(): Observable<any> {
    this.initExistingSelections();

    return super.onLoad();
  }

  onNext(): Observable<string> {
    const newSubscriptions = this.selections
      .filter((selection: FipsObject) => {
        const isExistingCbsa = _.includes(this.existingCbsaFips, selection.fips);
        const isExistingState = _.includes(this.existingStateFips, selection.fips);
        return !isExistingCbsa && !isExistingState;
      })
      .map((selection: FipsObject) => {
        const subscription = new AreaSubscription();
        subscription.fips = selection.fips;
        subscription.abbreviation = selection.abbreviation;
        subscription.name = selection.name;
        subscription.shapeType = selection.shapeType;
        return subscription;
      });

    const tasks: Observable<any>[] = [];
    newSubscriptions.forEach((subscription: AreaSubscription) => tasks.push(this.companyService.addOneAreaSubscription(this.wizard.model.company.uuid, subscription)));

    return this.companyService.addAllAreaSubscriptions(this.wizard.model.company.uuid, newSubscriptions)
      .pipe(map((results: AreaSubscription[]) => {
        this.wizard.model.results = results;
        return null;
      }));
  }

  recordSelections(selections: FipsObject[]): void {
    this.selections = selections;
  }

  private initExistingSelections(): void {
    this.existingCbsaFips = _.filter(this.wizard.model.existingSubscriptions, (subscription: AreaSubscription) => subscription.shapeType === SpatialShapeType.CBSA).map((subscription: AreaSubscription) => subscription.fips);
    this.existingStateFips = _.filter(this.wizard.model.existingSubscriptions, (subscription: AreaSubscription) => subscription.shapeType === SpatialShapeType.STATE).map((subscription: AreaSubscription) => subscription.fips);
    this.isSelectionsLoaded = true;
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { Wizard } from '../../wizard';
import { WizardPageComponent } from '../../wizard-page-component';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-wizard-stepper',
  templateUrl: './wizard-stepper.component.html',
  styleUrls: ['./wizard-stepper.component.scss']
})
export class WizardStepperComponent implements OnChanges {

  @Input()
  wizard: Wizard<any>;

  pages: WizardPageComponent<any>[] = [];

  constructor() {
  }

  ngOnChanges(): void {
    const pageKeys = _.keys(this.wizard.pageTypeMap);
    pageKeys.forEach((key: string) => {
      this.pages.push(this.wizard.pageReferenceMap[key].instance);
    });
  }

}

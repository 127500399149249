import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[mtnNumericFormatEnforcer]'
})
export class NumericFormatEnforcerDirective {

  @Input()
  mtnNumericFormatEnforcer: AbstractControl;

  constructor() {
  }

  @HostListener('input') enforce(): void {
    if (this.mtnNumericFormatEnforcer) {
      let value: string = this.mtnNumericFormatEnforcer.value;
      if (value) {
        value = value.replace(/([^0-9])/g, '');
      }
      this.mtnNumericFormatEnforcer.setValue(value);
    }
  }
}

import { SimpleRating } from './simple-rating';
import { DateUtil } from '../../util/date-util';
import { round } from '../../util/math-utils';
import * as _ from 'lodash';
import { SimpleRatingType } from './simple-rating-type.enum';

export class AnalystRatingResponse {
  ratingDate: Date;
  ratings: SimpleRating[] = [];

  constructor(raw?: any) {
    if (raw) {
      if (raw.ratingDate) {
        this.ratingDate = DateUtil.buildFromTimestamp(raw.ratingDate);
      }
      if (raw.ratings) {
        this.ratings = raw.ratings.map((rating: any) => new SimpleRating(rating));
      }
    }
  }

  getAverageRating(): number {
    if (this.ratings.length) {
      const values = this.ratings.map((rating: SimpleRating) => rating.value);
      return round(_.mean(values), 1);
    } else {
      return 0;
    }
  }

  getRatingOfType(type: SimpleRatingType): SimpleRating {
    return _.find(this.ratings, (rating: SimpleRating) => rating.type === type);
  }

  hasMinimumRequiredRatingCount(): boolean {
    const filteredRatings = _.filter(this.ratings, (rating: SimpleRating) => !!rating.value);
    return filteredRatings.length >= 12; //For now, only two of the ratings can be missing
  }
}

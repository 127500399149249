import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnCoreModule } from '../core/mtn-core.module';
import { MapSearchComponent } from './map-search.component';
import { MtnMapModule } from '../map/mtn-map.module';
import { StoreSpotlightPanelComponent } from './spotlight-panel/store-spotlight-panel/store-spotlight-panel.component';
import { MtnRoutingModule } from '../core/modules/mtn-routing.module';
import { MultiSelectSpotlightPanelComponent } from './spotlight-panel/multi-select-spotlight-panel/multi-select-spotlight-panel.component';
import { StoreFeatureCardComponent } from './spotlight-panel/multi-select-spotlight-panel/store-feature-card/store-feature-card.component';

@NgModule({
  declarations: [
    MapSearchComponent,
    StoreSpotlightPanelComponent,
    MultiSelectSpotlightPanelComponent,
    StoreFeatureCardComponent
  ],
  imports: [
    CommonModule,
    MtnCoreModule,
    MtnMapModule,
    MtnRoutingModule
  ]
})
export class MtnMapSearchModule {
}

import { Wizard } from '../../../core/wizard/wizard';
import { License } from '../../../core/license/license';
import { LicenseSuspendedPageComponent } from './license-suspended-page/license-suspended-page.component';

export class LicenseSuspendedWizard extends Wizard<LicenseSuspendedWizardModel> {
  key = 'license-suspended';
  pageTypeMap = {
    'license-suspended': LicenseSuspendedPageComponent
  };
  startingPageKey = 'license-suspended'
}

export interface LicenseSuspendedWizardModel {
  license: License;
}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mtn-ring-miles-field',
  templateUrl: './ring-miles-field.component.html',
  styleUrls: ['./ring-miles-field.component.scss']
})
export class RingMilesFieldComponent {

  @Input()
  form: UntypedFormGroup;
  @Input()
  controlName: string = 'ringMiles';
  @Input()
  placeholder: string = 'Miles';
  @Input()
  maxRadius = 10;

  @ViewChild('radiusInput')
  radiusInput: ElementRef<HTMLInputElement>;

  constructor() {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnCoreModule } from '../core/mtn-core.module';
import { MtnMapModule } from '../map/mtn-map.module';
import { MtnRoutingModule } from '../core/modules/mtn-routing.module';
import { CollectionsPageComponent } from './collections-page/collections-page.component';
import { CollectionPageComponent } from './collection-page/collection-page.component';
import { CollectionsListPageComponent } from './collections-list-page/collections-list-page.component';
import { CreateOrEditCollectionPageComponent } from './create-or-edit-collection-wizard/create-or-edit-collection-page/create-or-edit-collection-page.component';
import { AddToCollectionPageComponent } from './add-to-collection-wizard/add-to-collection-page/add-to-collection-page.component';
import { CollectionFeatureViewComponent } from './collection-page/collection-feature-view/collection-feature-view.component';
import { CloneCollectionPageComponent } from './clone-collection-wizard/clone-collection-page/clone-collection-page.component';


@NgModule({
  declarations: [
    CollectionsPageComponent,
    CollectionPageComponent,
    CollectionsListPageComponent,
    CreateOrEditCollectionPageComponent,
    AddToCollectionPageComponent,
    CollectionFeatureViewComponent,
    CloneCollectionPageComponent
  ],
  imports: [
    CommonModule,
    MtnCoreModule,
    MtnMapModule,
    MtnRoutingModule
  ]
})
export class MtnCollectionsModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnFontModule } from './modules/mtn-font.module';
import { MtnMaterialModule } from './modules/mtn-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PortfolioItemCardComponent } from './portfolio-item-card/portfolio-item-card.component';
import { StorageService } from './util/storage.service';
import { MtnFormControlsModule } from './form-controls/mtn-form-controls.module';
import { WhatIsThisComponent } from './help/help-icon/what-is-this.component';
import { UserInitialsAvatarComponent } from './user-profile/user-initials-avatar/user-initials-avatar.component';
import { WizardRunnerService } from './wizard/wizard-runner.service';
import { InsightsTimestampPipe } from './date/insights-timestamp.pipe';
import { InsightsDatePipe } from './date/insights-date.pipe';
import { StoreModule } from '@ngrx/store';
import { DEFINITION_STATE_KEY, definitionReducer } from './definition/definition-state';
import { KeyValueComponent } from './key-value/key-value.component';
import {
  KeyValueWithPlaceholderComponent
} from './key-value/key-value-with-placeholder/key-value-with-placeholder.component';
import { UserInlineViewComponent } from './user-profile/user-inline-view/user-inline-view.component';
import { CompanyInitialsAvatarComponent } from './company/company-initials-avatar/company-initials-avatar.component';
import { SimplePanelComponent } from './simple-panel/simple-panel.component';
import { UserCardComponent } from './user-profile/user-card/user-card.component';
import { IntersectionViewComponent } from './identity/intersection-view/intersection-view.component';
import { AddressViewComponent } from './identity/address-view/address-view.component';
import { CompanyChipComponent } from './identity/company/company-chip/company-chip.component';
import {
  ShoppingCenterTypeViewComponent
} from './shopping-center/shopping-center-type-view/shopping-center-type-view.component';
import { StoreStatusViewComponent } from './store/store-status-view/store-status-view.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { ConfidenceRatingComponent } from './star-rating/confidence-rating/confidence-rating.component';
import { IconButtonComponent } from './ui/icon-button/icon-button.component';
import { DefinitionViewComponent } from './definition/definition-display/definition-view.component';
import { MtnGoogleAnalyticsModule } from '../google-analytics/mtn-google-analytics.module';
import { WizardContainerComponent } from './wizard/wizard-container/wizard-container.component';
import { CreateUserPageComponent } from './auth/create-user-wizard/create-user-page/create-user-page.component';
import {
  ConfirmRemoveAccessPageComponent
} from './auth/confirm-remove-access-wizard/confirm-remove-access-page/confirm-remove-access-page.component';
import { EditUserPageComponent } from './auth/edit-user-wizard/edit-user-page/edit-user-page.component';
import { WizardStepperComponent } from './wizard/wizard-container/wizard-stepper/wizard-stepper.component';
import { BannerImageComponent } from './banner/banner-image/banner-image.component';
import { SalesVolumeViewComponent } from './store/sales-volume-view/sales-volume-view.component';
import { SalesSqftViewComponent } from './store/sales-sqft-view/sales-sqft-view.component';
import { ConfirmButtonComponent } from './ui/confirm-button/confirm-button.component';
import { PhoneNumberViewComponent } from './user-profile/phone-number-view/phone-number-view.component';
import { MtnRoutingModule } from './modules/mtn-routing.module';
import { FooterComponent } from './footer/footer.component';
import { ConfirmActionPageComponent } from './confirm-action-wizard/confirm-action-page/confirm-action-page.component';
import { UpgradeLicenseBadgeComponent } from './license/upgrade-license-badge/upgrade-license-badge.component';
import {
  UpgradeLicensePageComponent
} from './license/upgrade-license-wizard/upgrade-license-page/upgrade-license-page.component';
import {
  RestrictedLicenseOverlayComponent
} from './license/restricted-license-overlay/restricted-license-overlay.component';
import { PartnerAttributionComponent } from './footer/partner-attribution/partner-attribution.component';
import { FullPartnerAttributionComponent } from './footer/full-partner-attribution/full-partner-attribution.component';
import {
  PartnerAttributionContainerComponent
} from './footer/partner-attribution-container/partner-attribution-container.component';
import { SimpleAlertComponent } from './alert/simple-alert/simple-alert.component';
import { OtherStoresTableComponent } from './store/other-stores-table/other-stores-table.component';
import { NumberLineChartComponent } from './chart/number-line-chart/number-line-chart.component';
import { HubspotObjectLinkComponent } from './hubspot/hubspot-object-link/hubspot-object-link.component';
import { MtnSpinnerModule } from './util/spinner/mtn-spinner.module';
import { PageTrayComponent } from './page-tray/page-tray.component';
import {
  GeographicAreaSelectionListComponent
} from './fips/geographic-area-selection-list/geographic-area-selection-list.component';
import { FipsNameContainsPipe } from './fips/geographic-area-selection-list/fips-name-contains.pipe';
import { CompanyApiKeyComponent } from './company/company-api-key/company-api-key.component';
import { CompanyApiUserComponent } from './company/company-api-user/company-api-user.component';
import {
  EmailVerificationPageComponent
} from './email-verification/email-verification-page/email-verification-page.component';
import { SalesVolumeTagComponent } from './store/sales-volume-tag/sales-volume-tag.component';
import {
  StoreExportPageComponent
} from './store/export/store-export-wizard/store-export-page/store-export-page.component';
import { CompanyAddOnListComponent } from './company/company-add-on-list/company-add-on-list.component';
import {
  ToggleAddOnPageComponent
} from './company/company-add-on-list/toggle-add-on-wizard/toggle-add-on-page/toggle-add-on-page.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BREADCRUMB_STATE_KEY, breadcrumbReducer } from './breadcrumbs/breadcrumb-state';
import { StoreActionsMenuButtonComponent } from './store/store-actions-menu-button/store-actions-menu-button.component';
import { BannerViewComponent } from './banner/banner-view/banner-view.component';
import { CollectionViewComponent } from './federation/collection/collection-view/collection-view.component';
import { SquareBannerLogoComponent } from './banner/square-banner-logo/square-banner-logo.component';
import { ToolbarButtonComponent } from './button/toolbar-button/toolbar-button.component';
import { IsAddOnVisiblePipe } from './company/company-add-on-list/is-add-on-visible.pipe';
import { ClickTrapDirective } from './click-trap/click-trap.directive';
import {
  MaintenanceNotificationWorkerComponent
} from './maintenance/maintenance-notification-worker/maintenance-notification-worker.component';

@NgModule({ declarations: [
        PortfolioItemCardComponent,
        WhatIsThisComponent,
        UserInitialsAvatarComponent,
        InsightsTimestampPipe,
        InsightsDatePipe,
        KeyValueComponent,
        KeyValueWithPlaceholderComponent,
        UserInlineViewComponent,
        CompanyInitialsAvatarComponent,
        SimplePanelComponent,
        UserCardComponent,
        IntersectionViewComponent,
        AddressViewComponent,
        CompanyChipComponent,
        ShoppingCenterTypeViewComponent,
        StoreStatusViewComponent,
        StarRatingComponent,
        ConfidenceRatingComponent,
        IconButtonComponent,
        DefinitionViewComponent,
        WizardContainerComponent,
        CreateUserPageComponent,
        ConfirmRemoveAccessPageComponent,
        EditUserPageComponent,
        WizardStepperComponent,
        BannerImageComponent,
        SalesVolumeViewComponent,
        SalesSqftViewComponent,
        ConfirmButtonComponent,
        PhoneNumberViewComponent,
        FooterComponent,
        ConfirmActionPageComponent,
        UpgradeLicenseBadgeComponent,
        UpgradeLicensePageComponent,
        RestrictedLicenseOverlayComponent,
        PartnerAttributionComponent,
        FullPartnerAttributionComponent,
        PartnerAttributionContainerComponent,
        SimpleAlertComponent,
        OtherStoresTableComponent,
        NumberLineChartComponent,
        OtherStoresTableComponent,
        HubspotObjectLinkComponent,
        PageTrayComponent,
        GeographicAreaSelectionListComponent,
        FipsNameContainsPipe,
        CompanyApiKeyComponent,
        CompanyApiUserComponent,
        EmailVerificationPageComponent,
        SalesVolumeTagComponent,
        StoreExportPageComponent,
        CompanyAddOnListComponent,
        ToggleAddOnPageComponent,
        BreadcrumbsComponent,
        StoreActionsMenuButtonComponent,
        BannerViewComponent,
        CollectionViewComponent,
        SquareBannerLogoComponent,
        ToolbarButtonComponent,
        IsAddOnVisiblePipe,
        ClickTrapDirective,
        MaintenanceNotificationWorkerComponent
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MtnFontModule,
        MtnMaterialModule,
        MtnSpinnerModule,
        MtnFormControlsModule,
        MtnGoogleAnalyticsModule,
        NgxSliderModule,
        PortfolioItemCardComponent,
        WhatIsThisComponent,
        UserInitialsAvatarComponent,
        InsightsTimestampPipe,
        InsightsDatePipe,
        KeyValueComponent,
        KeyValueWithPlaceholderComponent,
        UserInlineViewComponent,
        CompanyInitialsAvatarComponent,
        SimplePanelComponent,
        UserCardComponent,
        IntersectionViewComponent,
        AddressViewComponent,
        CompanyChipComponent,
        ShoppingCenterTypeViewComponent,
        StoreStatusViewComponent,
        ConfidenceRatingComponent,
        IconButtonComponent,
        DefinitionViewComponent,
        BannerImageComponent,
        SalesVolumeViewComponent,
        SalesSqftViewComponent,
        ConfirmButtonComponent,
        PhoneNumberViewComponent,
        StarRatingComponent,
        FooterComponent,
        UpgradeLicenseBadgeComponent,
        RestrictedLicenseOverlayComponent,
        FullPartnerAttributionComponent,
        PartnerAttributionComponent,
        PartnerAttributionContainerComponent,
        SimpleAlertComponent,
        OtherStoresTableComponent,
        NumberLineChartComponent,
        OtherStoresTableComponent,
        HubspotObjectLinkComponent,
        PageTrayComponent,
        GeographicAreaSelectionListComponent,
        CompanyApiKeyComponent,
        CompanyApiUserComponent,
        SalesVolumeTagComponent,
        CompanyAddOnListComponent,
        BreadcrumbsComponent,
        StoreActionsMenuButtonComponent,
        BannerViewComponent,
        CollectionViewComponent,
        SquareBannerLogoComponent,
        ToolbarButtonComponent,
        ClickTrapDirective,
        MaintenanceNotificationWorkerComponent
    ], imports: [CommonModule,
        StoreModule.forFeature(DEFINITION_STATE_KEY, definitionReducer),
        StoreModule.forFeature(BREADCRUMB_STATE_KEY, breadcrumbReducer),
        ReactiveFormsModule,
        FormsModule,
        MtnFontModule,
        MtnMaterialModule,
        MtnSpinnerModule,
        MtnFormControlsModule,
        MtnGoogleAnalyticsModule,
        MtnRoutingModule,
        NgxSliderModule], providers: [
        StorageService,
        WizardRunnerService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class MtnCoreModule {
}

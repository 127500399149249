<div class="company-api-user-container container default-padding mat-elevation-z1" fxLayout="column" fxLayoutGap="15px">
  <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">API User</h2>
  <hr/>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <span *ngIf="company?.apiUserProfileUuid; else notCreated">This Company's API User is ready for use.</span>
    <ng-template #notCreated>
      <div fxLayout="column" fxLayoutGap="15px">
        <div style="max-width: 420px">An API User must be created before this Company can access the Insights API.</div>
        <button mat-raised-button class="default-button" (click)="createApiUser()">
          Create API User
        </button>
      </div>
    </ng-template>
  </div>
</div>

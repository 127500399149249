<div class="coverage-map-container container" fxLayout="column">
  <h2 class="mat-h2 text-small-caps">In-Depth Data Coverage</h2>
  <div class="map-container container">
    <div class="initialization-overlay" fxLayoutAlign="center center" *ngIf="!isInitialized">
      <button class="initialize-button container" mat-raised-button color="accent" (click)="initialize()">
        <mtn-spinner [on]="isInitializing" [size]="SpinnerSize.SMALL"></mtn-spinner>
        View Coverage
      </button>
    </div>
    <div class="map" #mapContainer fxFlex></div>
  </div>

  <div id="pieChart" fxLayout="row" style="visibility: hidden; margin-bottom: 10px; margin-left: 10px"
       [fxHide]="!isInitialized">
    <div style="width: 140px; height: 100px;">
      <canvas width=" 140px" height="100px" id="myChart"></canvas>
    </div>
    <div class="legend-container container" fxLayout="column" fxLayoutAlign="space-around">
      <div class="legend-item" [matTooltip]="currentToolTip">
        <div class="dot" style="background: #6cca98"></div>
        <div>Current (< 2 years)</div>
      </div>
      <div class="legend-item" [matTooltip]="historicalToolTip">
        <div class="dot" style="background: #3d7cc9"></div>
        <div>Historical</div>
      </div>
      <div class="legend-item" [matTooltip]="generatedToolTip">
        <div class="dot" style="background: #863399"></div>
        <div>Machine Learning Model</div>
      </div>
    </div>
  </div>
</div>


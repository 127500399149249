export class NumberRange {

  max: number;
  min: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
    }
  }
}

<div class="users-administration-container container">
  <div class="page-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div fxLayout="column">
      <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Users Administration</h1>
      <span class="tab-description color-muted">Select a User to manage.</span>
    </div>
    <div fxFlex></div>
    <mtn-icon-button color="accent" (onClick)="openCreateUserWizard()" matTooltip="Create User">
      <fa-icon icon="plus"></fa-icon>
    </mtn-icon-button>
    <mtn-text-field [form]="filterForm" controlName="filter" placeholder="Filter" mtnAnalyticsEventTrigger
                    [analyticsEventName]="AnalyticsEventType.TOUCH_FORM_FIELD" analyticsEventBind="blur"
                    [analyticsEventParameters]="{'target': 'filter'}" [noErrorPadding]="true"></mtn-text-field>
  </div>
  <div class="table-container container">
    <mtn-spinner [size]="SpinnerSize.LARGE" #usersSpinner></mtn-spinner>
    <div class="no-data-container" fxLayout="row" fxLayoutAlign="center center" *ngIf="!dataSource?.data?.length">No
      Results Available
    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="email" matSortDirection="asc"
           [hidden]="!dataSource?.data?.length">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let user">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <mtn-user-initials-avatar [user]="user"></mtn-user-initials-avatar>
            <div fxLayout="column">
              <strong>{{user.getDisplayName()}}</strong>
              <span class="color-muted">{{user.title}}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Email column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let user">
          {{user.email}}
        </td>
      </ng-container>

      <!-- Company column -->
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
        <td mat-cell *matCellDef="let user">
          <div><strong>{{user.company?.name}}</strong></div>
          <div><small class="color-muted">{{user.company?.uniqueIdentifier}}</small></div>
        </td>
      </ng-container>

      <!-- Last Login Date column -->
      <ng-container matColumnDef="lastLoginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Login Date</th>
        <td mat-cell *matCellDef="let user">
          <div>{{user.lastLoginDate | insightsTimestamp}}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="clickable" *matRowDef="let row; columns: displayedColumns;"
          (click)="navigateToUserDetails(row)"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3"><strong>0</strong> of <strong>{{dataSource?.data?.length}}</strong>
          Results Match Current Filter
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="25" [length]="totalResults" [pageIndex]="currentPage"
                   [showFirstLastButtons]="true" [hidePageSize]="true" [hidden]="totalResults <= 25"></mat-paginator>
  </div>
</div>

import { Wizard } from '../../../../../core/wizard/wizard';
import { Company } from '../../../../../core/company/company';
import { AreaSubscription } from '../../../../../core/company/area-subscription';
import { AddAreaSubscriptionPageComponent } from './add-area-subscription-page/add-area-subscription-page.component';

export class AddAreaSubscriptionWizard extends Wizard<AddAreaSubscriptionWizardModel> {
  key = 'add-area-subscription';
  pageTypeMap = {
    'add-area-subscription': AddAreaSubscriptionPageComponent
  };
  startingPageKey = 'add-area-subscription';

  constructor() {
    super();
    this.width = 600;
  }
}

export interface AddAreaSubscriptionWizardModel {
  company: Company;
  existingSubscriptions: AreaSubscription[];
  results?: AreaSubscription[];
}

import { Wizard } from '../../../../core/wizard/wizard';
import { Store } from '../../../../core/models';
import { SalesAreaPageComponent } from './sales-area-page/sales-area-page.component';

export class SalesAreaWizard extends Wizard<SalesAreaWizardModel> {
  key = 'sales-area';
  pageTypeMap = {
    'sales-area': SalesAreaPageComponent
  };
  startingPageKey = 'sales-area';
}

export interface SalesAreaWizardModel {
  store: Store;
}

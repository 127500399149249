<div class="profile-sidebar-container container mat-elevation-z1 default-padding" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutGap="15px" fxFlex *ngIf="profile">
    <mtn-user-initials-avatar [user]="profile"></mtn-user-initials-avatar>
    <div fxLayout="column">
      <h2 class="mat-h2 text-small-caps no-bottom-margin">{{profile.getDisplayName()}}</h2>
      <span class="tab-description color-muted">{{profile.title || 'Edit Profile to add a Title'}}</span>
    </div>
    <div fxFlex></div>
    <mtn-icon-button (onClick)="openEditProfileWizard()" matTooltip="Edit Profile">
      <fa-icon icon="pencil-alt"></fa-icon>
    </mtn-icon-button>
  </div>
  <div class="user-details-container container">
    <mtn-key-value key="Name"><strong>{{profile?.getDisplayName()}}</strong></mtn-key-value>
    <mtn-key-value key="Title"><span>{{profile?.title}}</span></mtn-key-value>
    <mtn-key-value key="Email Address"><span>{{profile?.email}}</span></mtn-key-value>
    <mtn-key-value key="Phone Number">
      <mtn-phone-number-view [phoneNumber]="profile?.phoneNumber"></mtn-phone-number-view>
    </mtn-key-value>
  </div>
</div>

<div class="license-card-container container default-padding mat-elevation-z1" fxLayout="column" fxLayoutGap="15px">
  <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">License</h2>
  <hr/>
  <div fxLayout="column">
    <mtn-key-value key="Type">
      <span>{{licenseType?.displayName}}</span>
    </mtn-key-value>
    <mtn-key-value key="Expiration Date">
        <span
          *ngIf="company.license.expirationDate; else nonExpiring">{{company.license.expirationDate | insightsDate}}</span>
      <ng-template #nonExpiring>
        <span class="color-muted">Non-Expiring</span>
      </ng-template>
    </mtn-key-value>
    <mtn-key-value key="Status">
      <span class="color-success" *ngIf="company.license.isValid()">Active</span>
      <span class="color-error" *ngIf="!company.license.suspendedDate && company.license.isExpired()">Expired</span>
      <span class="color-error" *ngIf="company.license.suspendedDate">Suspended</span>
    </mtn-key-value>
    <mtn-key-value key="Reason" *ngIf="isActionsEnabled && company.license.suspendedDate"
                   [ngSwitch]="company.license.suspendedReason">
      <span class="color-error" *ngSwitchCase="'ABUSE'">Abuse</span>
      <span class="color-error" *ngSwitchCase="'NON_PAYMENT'">Non-Payment</span>
      <span class="color-error" *ngSwitchCase="'OTHER'">Other</span>
    </mtn-key-value>
  </div>
  <div fxLayout="row" fxLayoutGap="15px" *ngIf="isActionsEnabled">
    <button mat-raised-button class="default-button" (click)="openUpdateLicenseWizard()">
      Update License
    </button>
    <button mat-raised-button color="warn" (click)="openSuspendLicenseWizard()"
            *ngIf="!company.license.suspendedDate; else reinstate">
      Suspend License
    </button>
    <ng-template #reinstate>
      <button mat-raised-button class="success-button" (click)="openReinstateLicenseWizard()">
        Reinstate License
      </button>
    </ng-template>
  </div>
</div>

export class StoreHighlight {
  action: () => void;
  description: string;
  isPositive = false;
  rank: number;
  title: string;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
    }
  }

  getKey(): string {
    return this.title.toLowerCase().replace(/\s/g, '-');
  }
}

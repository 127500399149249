import { Wizard } from '../../core/wizard/wizard';
import { CreateOrEditCollectionPageComponent } from './create-or-edit-collection-page/create-or-edit-collection-page.component';
import { Collection } from '../../core/federation/collection/collection';

export class CreateOrEditCollectionWizard extends Wizard<CreateOrEditCollectionWizardModel> {
  key = 'create-or-edit-collection';
  pageTypeMap = {
    'create-or-edit-collection': CreateOrEditCollectionPageComponent
  };
  startingPageKey = 'create-or-edit-collection';
}

export interface CreateOrEditCollectionWizardModel {
  collection?: Collection;
  companyUuid: string;
  result?: Collection;
  storeUuids?: string[];
  userProfileUuid: string;
}

import { DistanceMatrixLocation } from './distance-matrix-request';

export class DistanceMatrixResponse {

  results: DistanceMatrixResult[] = [];

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
      this.results = [];

      if (raw.results) {
        this.results = raw.results.map((result: any) => new DistanceMatrixResult(result));
      }
    }
  }
}

export class DistanceMatrixResult {

  origin: string;

  destinations: DistanceMatrixLocation[] = [];

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
      this.destinations = [];

      if (raw.destinations) {
        this.destinations = raw.destinations.map((destination: any) => new DistanceMatrixLocation(destination));
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../../../core/service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { FeatureCollection } from 'geojson';
import { Observable } from 'rxjs';
import { FipsObject } from '../../../core/fips/fips-object';

@Injectable({
  providedIn: 'root'
})
export class CoverageService extends InsightsRestService {

  protected path = '/coverage';
  rootPath = '/insights';

  protected constructor(protected http: HttpClient) {
    super();
  }

  findAll(): Observable<FeatureCollection> {
    const url = this.buildUrl();
    return this.http.get<FeatureCollection>(url);
  }

  protected buildUrl(extendedPath: string = ''): string {
    //Ensure path is relative
    if (!this.path.startsWith('/')) {
      this.path = `/${this.path}`;
    }

    //Ensure extendedPath is relative
    if (extendedPath && !extendedPath.startsWith('/')) {
      extendedPath = `/${extendedPath}`;
    }

    return super.buildUrl(`${this.path}${extendedPath}`);
  }

  getCbsas() {
    const url = this.buildUrl() + '/cbsa-list';
    return this.http.get<FipsObject[]>(url);
  }
}

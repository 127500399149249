import { Wizard } from '../../../../core/wizard/wizard';
import { Company } from '../../../../core/company/company';
import { CreateCompanyPageComponent } from './create-company-page/create-company-page.component';
import { SelectHubspotCompanyPageComponent } from './select-hubspot-company-page/select-hubspot-company-page.component';
import { HubspotCompany } from '../../../../core/hubspot/hubspot-company';

export class CreateCompanyWizard extends Wizard<CreateCompanyWizardModel> {

  key = 'create-company';
  model: CreateCompanyWizardModel = {};
  pageTypeMap = {
    'create-company': CreateCompanyPageComponent,
    'select-hubspot-company': SelectHubspotCompanyPageComponent
  };
  startingPageKey = 'create-company';

  constructor() {
    super();
    this.width = 450;
  }
}

export interface CreateCompanyWizardModel {
  company?: Company;
  hubSpotCompanies?: HubspotCompany[];
  wipRequest?: Company;
}

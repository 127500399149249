<div class="mtn-button container {{color}}" fxLayoutAlign="center center" matRipple [matRippleDisabled]="disabled"
     [ngClass]="{disabled: disabled}" (click)="handleClick($event)">
  <div class="icon-container">
    <fa-icon class="primary-icon {{iconColor}}" [icon]="icon" *ngIf="icon"></fa-icon>
    <fa-icon class="ellipsis" icon="ellipsis-h" *ngIf="showEllipsis"></fa-icon>
  </div>
  <div class="badge-container" *ngIf="badgeCount > 0" fxLayoutAlign="center center">
    <div class="badge">{{badgeCount}}</div>
  </div>
  <div class="color-bar"></div>
  <div class="disabled-overlay" *ngIf="disabled" mtnClickTrap></div>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mtn-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.scss']
})
export class QuestionViewComponent implements OnInit {

  @Input()
  question: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}

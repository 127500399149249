import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { WelcomeWizard } from '../welcome-wizard';

@Component({
  selector: 'mtn-welcome-wizard-end-page',
  templateUrl: './welcome-wizard-end-page.component.html',
  styleUrls: ['./welcome-wizard-end-page.component.scss']
})
export class WelcomeWizardEndPageComponent extends WizardPageComponent<WelcomeWizard> {

  key = 'end';

  constructor() {
    super();
    this.title = 'Welcome! Let\'s get started.';
    this.stepperText = 'Finish';
    this.closeButtonText = 'Close';
    this.headerText = 'All Done!';
    this.subheaderText = 'You can always change any of these settings in My Profile & Settings.';
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mtn-partner-attribution-container',
  templateUrl: './partner-attribution-container.component.html',
  styleUrls: ['./partner-attribution-container.component.scss']
})
export class PartnerAttributionContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

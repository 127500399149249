import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../core/base-component';
import { Company } from '../../../../core/company/company';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AreaSubscription } from '../../../../core/company/area-subscription';
import { CompanyService } from '../../../../core/company/company.service';
import { finalize } from 'rxjs/operators';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import { AddAreaSubscriptionWizard } from './add-area-subscription-wizard/add-area-subscription-wizard';
import { ConfirmActionWizard } from '../../../../core/confirm-action-wizard/confirm-action-wizard';
import { AreaSubscriptionService } from '../../../../core/company/area-subscription.service';
import { ToastService } from '../../../../core/toast/toast.service';

@Component({
  selector: 'mtn-area-subscriptions-list',
  templateUrl: './area-subscriptions-list.component.html',
  styleUrls: ['./area-subscriptions-list.component.scss']
})
export class AreaSubscriptionsListComponent extends BaseComponent implements OnInit {

  @Input()
  company: Company;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  dataSource = new MatTableDataSource<AreaSubscription>();
  displayedColumns: string[] = ['name', 'shapeType', 'actions'];
  filterForm = new UntypedFormGroup({
    filter: new UntypedFormControl()
  });
  isLoading = true;

  constructor(private areaSubscriptionService: AreaSubscriptionService,
              private companyService: CompanyService,
              private toaster: ToastService,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => this.initTable(), 50);
    this.loadAreas();
  }

  // @ts-ignore
  handleRemoveSubscription(request: AreaSubscription): void {
    const wizard = new ConfirmActionWizard();
    wizard.model = {
      onConfirm: this.areaSubscriptionService.deleteOne(request.uuid),
      title: 'Remove Area Subscription',
      text: 'Are you sure you wish to remove this Area Subscription? Doing so will stop giving this company automatic sales volume updates in the removed area.'
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: ConfirmActionWizard) => {
        if (result?.model.result) {
          this.toaster.info("Successfully removed Area Subscription");
          this.loadAreas();
        }
      });
  }

  openAddAreaSubscriptionWizard(): void {
    const wizard = new AddAreaSubscriptionWizard();
    wizard.model = {
      company: this.company,
      existingSubscriptions: this.dataSource.data
    };

    this.wizardRunner.run(wizard)
      .afterClosed()
      .subscribe((result: AddAreaSubscriptionWizard) => {
        if (result?.model?.results?.length) {
          this.toaster.info("Successfully added Area Subscription(s)");
          this.loadAreas();
        }
      });
  }

  private initFilter(): void {
    this.addSubscription(
      this.filterForm.get('filter').valueChanges
        .subscribe((value: string) => {
          this.dataSource.filter = value;
        })
    );
  }

  private initTable(): void {
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = (data: AreaSubscription, header: string): any => {
      if (header === 'name') {
        return data.name.toLowerCase();
      } else if (header === 'shapeType') {
        return data.shapeType.toLowerCase();
      }
      return null;
    };

    this.dataSource.filterPredicate = (data: AreaSubscription, filter: string): boolean => {
      const lowerFilter = filter?.toLowerCase();

      return data.name.toLowerCase().indexOf(lowerFilter) !== -1
        || (data.abbreviation && data.abbreviation.toLowerCase().indexOf(lowerFilter) !== -1)
        || data.shapeType.toLowerCase().indexOf(lowerFilter) !== -1;
    };

    this.initFilter();
  }

  private loadAreas(): void {
    this.isLoading = true;
    this.addSubscription(
      this.companyService.findAllAreaSubscriptions(this.company.uuid)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe((results: AreaSubscription[]) => {
          this.dataSource.data = results;
        })
    );
  }

}

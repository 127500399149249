export const Dictionary = {
  labelAvgSalesSqft: 'Avg. Est. Sales/sqft',
  labelAvgSalesVolume: 'Avg. Est. Sales Volume',
  labelKeyIndicatorPreferenceDescription: 'When we\'re showing you comparison information, such as comparing a store against other stores in its local area, which of these Key Indicators is most important to you?',
  labelMapDefaultModePreferenceDescription: 'When you\'re using the "Road" map, you have two choices. How would you like the "Road" maps to appear?',
  labelMapDefaultTypePreferenceDescription: '"Road" maps aren\'t your only choice. You can instead opt to see the Satellite view if you prefer.',
  labelMarketArea: 'Market Area',
  labelStoreMissingRequiredData: 'Performance cannot be analyzed due to this store missing required data',
  labelMtnTradeArea: 'MTN Trade Area',
  labelSalesSqft: 'Est. Sales/sqft',
  labelSalesSqftPreferenceDescription: 'We also show a breakdown of the store sales volume per sqft. How would you like to see this breakdown?',
  labelSalesVolume: 'Est. Sales Volume',
  labelSalesVolumePreferenceDescription: 'We can show you estimated sales volumes in a number of ways. How do you want to see sales volumes?',
  otherStoresAtThisLocation: 'These are other Stores that currently or previously occupied this Space, so you can view historical data if needed.',
  tooltipFitCategory: 'FIT Category groups similar grocery stores into various categories depending on their formats.',
  tooltipGrossLeasableArea: 'Gross Leasable Area',
  tooltipPercentile: 'Percentile is a good statistical way to rank something. A 20th Percentile rank for Estimated Sales Volume in a Trade Area, for example, means this store has a higher Estimated Sales Volume than 20% of other stores in the Trade Area. The store with the highest Estimated Sales Volume in the Trade Area would be said to be in the 100th Percentile, having a higher Estimated Sales Volume than 100% of other stores in the Trade Area.',
  tooltipPositionInCenter: 'Position in Center describes the store\'s general location within its shopping center.',
  tooltipRankingExcludedStores: 'At least one Store had to be excluded from this analysis due to it missing required data. Click to see the Stores we had to leave out.',
  tooltipSalesArea: 'Sales Area is a measure of the publicly-accessible *grocery* sales floor of the store, excluding office space, receiving, restrooms, etc.',
  tooltipSalesSqft: 'This is the Estimated Sales Volume broken down by either Sales Area or Total Area as defined by your preferences. You can change these settings via My Profile > Preferences.',
  tooltipSalesVolume: 'Estimated Sales Volume is the store\'s *grocery* sales volume as estimated by MTN Analysts or the MTN Volume Estimation Engine. A star next to the volume indicates a high-confidence estimate from an MTN Analyst. You can change display settings via My Profile > Preferences.',
  tooltipStoreStatus: 'This is the status of the store as of the last time MTN evaluated this store.',
  tooltipTotalArea: 'Total Area is a measure of the entire space occupied by the store, including sales area, office space, receiving, restrooms, etc.',
}

import { Component, Input, OnInit } from '@angular/core';
import { Company } from '../company';

@Component({
  selector: 'mtn-company-initials-avatar',
  templateUrl: './company-initials-avatar.component.html',
  styleUrls: ['./company-initials-avatar.component.scss']
})
export class CompanyInitialsAvatarComponent implements OnInit {

  @Input()
  color: string;
  @Input()
  company: Company;

  initials: string;

  constructor() {
  }

  ngOnInit(): void {
    this.generateInitials();
  }

  ngOnChanges() {
    this.generateInitials();
  }

  private generateInitials(): void {
    if (this.company) {
      this.initials = '';

      const name = this.company.name;

      //Attempt to build initials from the first string of capital letters (i.e. "MTN" in "MTN Retail Advisors")
      for (let i = 0; i < name.length; i++) {
        const letter = name.charAt(i);
        if (i === 0 || (letter === letter.toUpperCase() && letter !== ' ')) {
          this.initials += letter.toUpperCase();
        } else {
          break;
        }
        //If we end up with more than three characters, just use the first three
        if (this.initials.length > 3) {
          this.initials = this.initials.substring(0, 3);
        }
      }

      //If we only got a single letter, try now splitting the name and taking the first letters of the first two words (i.e. "DM" in "Dunder Mifflin Paper Company")
      if (this.initials.length < 3 && name.indexOf(' ') !== -1) {
        this.initials = '';
        const parts = name.split(' ');
        for (let i = 0; i < 2; i++) {
          this.initials += parts[i].charAt(0).toUpperCase();
        }
      }
    }
  }

}

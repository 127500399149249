import { Component, Input, OnChanges } from '@angular/core';
import { StoreServices } from '../../../../core/store/store-services';
import { BaseComponent } from '../../../../core/base-component';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import { StoreServicesWizard } from './store-services-wizard/store-services-wizard';

@Component({
  selector: 'mtn-store-services-card',
  templateUrl: './store-services-card.component.html',
  styleUrls: ['../additional-insights-card.scss', './store-services-card.component.scss']
})
export class StoreServicesCardComponent extends BaseComponent implements OnChanges {

  @Input()
  isRestrictedLicenseOverlayEnabled = false;
  @Input()
  services: StoreServices;

  description: string;
  title: string;

  constructor(private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnChanges() {
    this.description = this.services?.surveyDate ? 'Offered at this location' : 'Not yet evaluated';
    this.title = this.services?.surveyDate ? `${this.services.count()} Services & Amenities` : 'Unknown Services';
  }

  handleClick(): void {
    if (this.services?.surveyDate && !this.isRestrictedLicenseOverlayEnabled) {
      const wizard = new StoreServicesWizard();
      wizard.model = {
        services: this.services
      };

      this.wizardRunner.run(wizard);
    }
  }

}

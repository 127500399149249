import { Feature } from '../models';

export class DistanceMatrixRequest {

  destinations: DistanceMatrixLocation[] = [];
  origins: DistanceMatrixLocation[] = [];

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
      this.destinations = [];
      this.origins = [];

      if (raw.destinations) {
        this.destinations = raw.destinations.map((destination: any) => new DistanceMatrixLocation(destination));
      }
      if (raw.origins) {
        this.origins = raw.origins.map((origin: any) => new DistanceMatrixLocation(origin));
      }
    }
  }
}

export class DistanceMatrixPoint {
  lat: number;
  lng: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
    }
  }

  get id(): string {
    return `${this.lat},${this.lng}`
  }

  static fromStoreFeature(feature: Feature): DistanceMatrixPoint {
    let point = null;

    if (feature) {
      point = new DistanceMatrixPoint();

      const latLng = feature.getGeometryAsLatLng();
      point.lat = latLng.lat;
      point.lng = latLng.lng;
    }

    return point;
  }
}

export class DistanceMatrixLocation {

  id: string;
  point: DistanceMatrixPoint;
  travelTimeSeconds: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.point) {
        this.point = new DistanceMatrixPoint(raw.point);
      }
    }
  }

  static fromStoreFeature(feature: Feature): DistanceMatrixLocation {
    let location = null;

    if (feature) {
      location = new DistanceMatrixLocation();
      location.id = feature.id;
      location.point = DistanceMatrixPoint.fromStoreFeature(feature);
    }

    return location;
  }
}

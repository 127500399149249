import { Wizard } from '../../../../../core/wizard/wizard';
import { AnalystRatingResponse } from '../../../../../core/store/rating/analyst-rating-response';
import { StoreAnalystRatingPageComponent } from './store-analyst-rating-page/store-analyst-rating-page.component';

export class StoreAnalystRatingWizard extends Wizard<StoreAnalystRatingWizardModel> {
  key = 'store-analyst-rating';
  pageTypeMap = {
    'store-analyst-rating': StoreAnalystRatingPageComponent
  };
  startingPageKey = 'store-analyst-rating';

  constructor() {
    super();
    this.width = 650;
  }
}

export interface StoreAnalystRatingWizardModel {
  rating: AnalystRatingResponse;
}

import { Pipe, PipeTransform } from '@angular/core';
import { FipsObject } from '../fips-object';
import * as _ from 'lodash';

@Pipe({
  name: 'fipsNameContains'
})
export class FipsNameContainsPipe implements PipeTransform {

  transform(values: FipsObject[], filter: string): FipsObject[] {
    if (filter) {
      return _.filter(values, (value: FipsObject) => value.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    } else {
      return values;
    }
  }

}

import { Company } from '../../../../core/company/company';
import { ConfirmDeleteCompanyPageComponent } from './confirm-delete-company-page/confirm-delete-company-page.component';
import { Wizard } from '../../../../core/wizard/wizard';

export class ConfirmDeleteCompanyWizard extends Wizard<ConfirmDeleteCompanyWizardModel>{
  key = 'confirm-delete-company';
  pageTypeMap = {
    'confirm-delete-company': ConfirmDeleteCompanyPageComponent
  };
  startingPageKey = 'confirm-delete-company';
}

export interface ConfirmDeleteCompanyWizardModel {
  company: Company;
  isDeleted?: boolean;
}

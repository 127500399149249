<div class="store-sales-performance-container container" fxLayout="column" fxLayoutGap="30px"
     [ngClass]="{'default-padding': !hasStandardLicense}">
  <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Sales Performance Insights</h1>
  <hr/>
  <div fxLayout="column" fxLayoutGap="30px" *ngIf="store.isActive() && volume; else missingAllData">
    <div fxLayout="column" fxLayoutGap="15px">
      <h2 class="mat-h2 text-small-caps no-bottom-margin"><span class="color-muted">Comparing by</span> Estimated Sales
        Volume</h2>
      <div fxFlex fxLayout="row" fxLayoutGap="15px">
        <mtn-competitive-analysis-card [comparisonRequest]="comparisonRequest1A"></mtn-competitive-analysis-card>
        <mtn-competitive-analysis-card [comparisonRequest]="comparisonRequest1B"></mtn-competitive-analysis-card>
        <mtn-competitive-analysis-card [comparisonRequest]="comparisonRequest1C"></mtn-competitive-analysis-card>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="15px">
      <h2 class="mat-h2 text-small-caps no-bottom-margin"><span class="color-muted">Comparing by</span> Estimated
        Sales/sqft</h2>
      <div fxFlex fxLayout="row" fxLayoutGap="15px" *ngIf="volume && store.grocerySalesArea; else missingData">
        <mtn-competitive-analysis-card [comparisonRequest]="comparisonRequest2A"></mtn-competitive-analysis-card>
        <mtn-competitive-analysis-card [comparisonRequest]="comparisonRequest2B"></mtn-competitive-analysis-card>
        <mtn-competitive-analysis-card [comparisonRequest]="comparisonRequest2C"></mtn-competitive-analysis-card>
      </div>
    </div>
  </div>
  <mtn-restricted-license-overlay *ngIf="!hasStandardLicense"></mtn-restricted-license-overlay>
</div>

<ng-template #missingAllData>
  <div class="missing-all-data-row" fxLayoutAlign="center center">
    <span class="color-muted">{{Dictionary.labelStoreMissingRequiredData}}</span>
  </div>
</ng-template>

<ng-template #missingData>
  <div class="missing-data-row" fxLayoutAlign="center center">
    <span class="color-muted">{{Dictionary.labelStoreMissingRequiredData}}</span>
  </div>
</ng-template>

<div class="welcome-wziard-volume-page-container container" [formGroup]="form">
  <div class="panel-container container" fxLayout="column" fxLayoutGap="15px">
    <p>{{Dictionary.labelSalesVolumePreferenceDescription}}</p>
    <mat-radio-group color="primary" formControlName="salesVolumeDisplayMode" fxLayout="column" fxLayoutGap="5px">
      <mat-radio-button [value]="SalesVolumeDisplayModeType.ANNUAL">Annual Sales</mat-radio-button>
      <mat-radio-button [value]="SalesVolumeDisplayModeType.WEEKLY">Weekly Sales</mat-radio-button>
    </mat-radio-group>
    <div class="container">
      <p class="color-muted">Example</p>
      <mtn-sales-volume-view [volume]="sampleVolume"
                             [salesVolumeDisplayMode]="form.get('salesVolumeDisplayMode').value"></mtn-sales-volume-view>
    </div>
  </div>
  <br/>
  <div class="panel-container container" fxLayout="column" fxLayoutGap="15px">
    <p>{{Dictionary.labelSalesSqftPreferenceDescription}}</p>
    <mat-radio-group color="primary" formControlName="salesSqftDisplayMode" fxLayout="column" fxLayoutGap="5px">
      <mat-radio-button [value]="SalesSqftDisplayModeType.SALES_AREA">Use Sales Area</mat-radio-button>
      <mat-radio-button [value]="SalesSqftDisplayModeType.TOTAL_AREA">Use Total Area</mat-radio-button>
    </mat-radio-group>
    <div class="container">
      <p class="color-muted">Example</p>
      <mtn-sales-sqft-view [volume]="sampleVolume"></mtn-sales-sqft-view>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { BaseComponent } from '../../../core/base-component';
import { selectUserState, UserState } from '../../../auth/user-state';
import { Company } from '../../../core/company/company';
import { CompanyService } from '../../../core/company/company.service';
import { UserSetCurrentCompany } from '../../../auth/user-actions';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'mtn-my-company-widget',
  templateUrl: './my-company-widget.component.html',
  styleUrls: ['./my-company-widget.component.scss']
})
export class MyCompanyWidgetComponent extends BaseComponent implements OnInit {

  public readonly ROUTE = 'self-service-administration';
  public readonly MENU_TOOLTIP = 'My Company';

  @Input()
  condensedMode = false;

  company: Company;

  private isLoading = false;

  constructor(private companyService: CompanyService,
              private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAuthState();
  }

  private loadCompany(uuid: string): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.companyService.findOne(uuid)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe((result: Company) => {
          this.store.dispatch(UserSetCurrentCompany({company: result}));
        });
    }
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.store)
        .subscribe((state: UserState) => {
          if (!state.currentCompany) {
            this.loadCompany(state.currentUser.company?.uuid);
          }
          this.company = state.currentCompany;
        })
    );
  }

}

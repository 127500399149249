import { Store } from '../../models';
import { Rankable } from './rankable';
import { convertToNth } from '../../util/string-utils';

export class StoreRanking implements Rankable {
  comment: string;
  percentile: number;
  rank: number;
  store: Store;
  totalCount: number;
  value: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.store) {
        this.store = new Store(raw.store);
      }
    }
  }

  getFormattedPercentileString(): string {
    return this.percentile ? `${convertToNth(this.percentile)} Percentile` : null;
  }
}

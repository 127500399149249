import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnCoreModule } from '../core/mtn-core.module';
import { MtnRoutingModule } from '../core/modules/mtn-routing.module';
import { MaintenanceComponent } from './maintenance/maintenance.component';


@NgModule({
  declarations: [
    MaintenanceComponent
  ],
  imports: [
    CommonModule,
    MtnCoreModule,
    MtnRoutingModule
  ]
})
export class MtnMaintenanceModule {
}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../core/wizard/wizard-page-component';
import { GoogleSearchWizard } from '../google-search-wizard';
import { Observable } from 'rxjs';
import { MtnMap } from '../../../mtn-map';

@Component({
  selector: 'mtn-google-search-page',
  templateUrl: './google-search-page.component.html',
  styleUrls: ['./google-search-page.component.scss']
})
export class GoogleSearchPageComponent extends WizardPageComponent<GoogleSearchWizard> {

  key = 'google-search';

  map: MtnMap;

  constructor() {
    super();
    this.closeButtonText = 'Close';
    this.title = 'Search';
  }

  onLoad(): Observable<any> {
    this.map = this.wizard.model.map;
    return super.onLoad();
  }

}

import { Wizard } from '../../../../../core/wizard/wizard';
import { BannerComparison } from '../../../../../core/banner/banner-comparison';
import { Store } from '../../../../../core/models';
import { BannerComparisonPageComponent } from './banner-comparison-page/banner-comparison-page.component';
import { UserPreferences } from '../../../../../core/user-preferences/user-preferences';
import { LicenseType } from '../../../../../auth/authorization/license-type.enum';

export class BannerComparisonWizard extends Wizard<BannerComparisonWizardModel> {
  key = 'banner-comparison';
  pageTypeMap = {
    'banner-comparison': BannerComparisonPageComponent
  };
  startingPageKey = 'banner-comparison';

  constructor() {
    super();
    this.width = 650;
  }
}

export interface BannerComparisonWizardModel {
  comparison: BannerComparison;
  licenseType: LicenseType;
  store: Store;
  userPreferences: UserPreferences;
}

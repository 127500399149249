import { Banner } from '../models';
import { BannerRanking } from './banner-ranking';
import { KeyIndicatorType } from '../user-preferences/key-indicator-type.enum';
import * as _ from 'lodash';

export class BannerComparison {

  banner: Banner;
  keyIndicator: KeyIndicatorType;
  storeCountExcluded: number;
  storeCountTotal: number;

  rankings: BannerRanking[] = [];

  constructor(raw?: any) {
    if (raw) {
      if (raw.banner) {
        this.banner = new Banner(raw.banner);
      }
      if (raw.keyIndicator) {
        // @ts-ignore
        this.keyIndicator = KeyIndicatorType[<string>raw.keyIndicator];
      }
      if (raw.rankings) {
        this.rankings = raw.rankings.map((ranking: any) => new BannerRanking(ranking));
      }
    }
  }

  findSubjectBannerRanking(): BannerRanking {
    return _.find(this.rankings, (ranking: BannerRanking) => ranking.banner.uuid === this.banner.uuid);
  }

}

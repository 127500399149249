import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mtn-public-landing-page',
  templateUrl: './public-landing-page.component.html',
  styleUrls: ['./public-landing-page.component.scss']
})
export class PublicLandingPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }

}

<mat-form-field class="default-field-width" [formGroup]="form">
  <input #radiusInput type="number" matInput [formControlName]="controlName" [placeholder]="placeholder"/>
  <mat-error
    *ngIf="form.get(controlName).hasError('min') || form.get(controlName).hasError('max')">
    <i>Must be between 1 - {{maxRadius}} miles</i>
  </mat-error>
  <mat-error *ngIf="form.get(controlName).hasError('required')">
    <i>Must be provided</i>
  </mat-error>
</mat-form-field>

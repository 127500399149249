import { BasicEntity } from '../../basic-entity';
import { FeatureType } from '../feature/feature-type.enum';
import { DateUtil } from '../../util/date-util';
import { FilterGroup } from './filter-group';

export class SavedSearch extends BasicEntity {

  createdBy: string;
  createdDate: Date;
  description: string;
  featureType: FeatureType;
  filterGroup: FilterGroup;
  isSupport = false;
  name: string;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      if (raw.createdDate) {
        this.createdDate = DateUtil.buildFromTimestamp(raw.createdDate);
      }
      if (raw.featureType) {
        // @ts-ignore
        this.featureType = FeatureType[<string>raw.featureType];
      }
      if (raw.filterGroup) {
        this.filterGroup = new FilterGroup(raw.filterGroup);
      }
    }
  }

}

import { Injectable } from '@angular/core';
import { StoreComparison } from './ranking/store-comparison';
import {
  SAMPLE_RANKINGS_1,
  SAMPLE_RANKINGS_2,
  SAMPLE_RANKINGS_3,
  SAMPLE_RANKINGS_4,
  SAMPLE_RANKINGS_5,
  SAMPLE_RANKINGS_6
} from '../../detail/store-detail-page/store-sales-performance-panel/sample-ranking-data';
import { StoreRanking } from './ranking/store-ranking';
import { ComparisonType } from './ranking/comparison-type.enum';
import { RegionType } from '../location/region-type.enum';
import { KeyIndicatorType } from '../user-preferences/key-indicator-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SampleDataService {

  constructor() {
  }

  appendSampleStoreRankings(comparisons: StoreComparison[]): void {
    comparisons.forEach((comparison: StoreComparison) => {
      let rawValues: any[];

      if (comparison.comparisonType === ComparisonType.ALL
        && comparison.region === RegionType.DRIVE_TIME
        && comparison.keyIndicator === KeyIndicatorType.SALES_VOLUME) {
        rawValues = SAMPLE_RANKINGS_1;
      } else if (comparison.comparisonType === ComparisonType.ALL
        && comparison.region === RegionType.DRIVE_TIME
        && comparison.keyIndicator === KeyIndicatorType.SALES_SQFT) {
        rawValues = SAMPLE_RANKINGS_2;
      } else if (comparison.comparisonType === ComparisonType.BANNER
        && comparison.region === RegionType.MARKET_AREA
        && comparison.keyIndicator === KeyIndicatorType.SALES_VOLUME) {
        rawValues = SAMPLE_RANKINGS_3;
      } else if (comparison.comparisonType === ComparisonType.BANNER
        && comparison.region === RegionType.MARKET_AREA
        && comparison.keyIndicator === KeyIndicatorType.SALES_SQFT) {
        rawValues = SAMPLE_RANKINGS_4;
      } else if (comparison.comparisonType === ComparisonType.ALL
        && comparison.region === RegionType.MARKET_AREA
        && comparison.keyIndicator === KeyIndicatorType.SALES_VOLUME) {
        rawValues = SAMPLE_RANKINGS_5;
      } else if (comparison.comparisonType === ComparisonType.ALL
        && comparison.region === RegionType.MARKET_AREA
        && comparison.keyIndicator === KeyIndicatorType.SALES_SQFT) {
        rawValues = SAMPLE_RANKINGS_6;
      }

      comparison.rankings = rawValues.map((raw: any) => new StoreRanking(raw));
    });
  }
}

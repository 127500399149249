import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../models';

@Component({
  selector: 'mtn-square-banner-logo',
  templateUrl: './square-banner-logo.component.html',
  styleUrls: ['./square-banner-logo.component.scss']
})
export class SquareBannerLogoComponent implements OnInit {

  bannerImageUrl: string;

  @Input()
  store: Store;

  constructor() {
  }

  ngOnInit(): void {
    this.bannerImageUrl = this.buildIconUrl();
  }

  private buildIconUrl(): string {
    let filename = this.store.hasMarkerImage()
      ? `${this.store.banner.markerCloudinaryFilename}`
      : 'insights/preloader-icon';


    let imageBackgroundUnderlayTransformation = `/u_Map%20Markers:circle-solid,g_center,c_limit,h_40,e_replace_color:white:100:00ff00,fl_layer_apply`;

    return `https://res.cloudinary.com/mtn-retail-advisors/image/upload` +
      `/c_scale,h_24` +
      `${imageBackgroundUnderlayTransformation}` +
      `/v1571263789/${filename}`;
  }

}

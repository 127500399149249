import { Injectable } from '@angular/core';
import { CrudService } from '../../service/crud-service';
import { FilterGroup } from './filter-group';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../../service/pageable';
import { buildFilter, Filter } from './filter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterGroupService extends CrudService<FilterGroup> {

  protected path = '/filter-group';

  constructor(protected http: HttpClient) {
    super(http);
  }

  addOneFilter(filterGroupUuid: string, request: Filter<any>): Observable<Filter<any>> {
    const url = this.buildUrl(`/${filterGroupUuid}/filter`);
    return this.http.post(url, request)
      .pipe(map((raw: any) => buildFilter(raw)));
  }

  protected buildInstance(raw: any): FilterGroup {
    return new FilterGroup(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<FilterGroup> {
    return new Pageable<FilterGroup>(raw, FilterGroup);
  }
}

import { Store } from '../../models';
import { StoreRanking } from '../../store/ranking/store-ranking';
import { median } from '../../util/math-utils';

export class NumberLineDataset {
  count: number;
  maxValue: number; //Used when setting the max tick value
  minValue: number; //Used when setting the min tick value
  median: number;
  subject?: Store;
  storeRankings: StoreRanking[];

  constructor(subject: Store, rankings: StoreRanking[]) {
    this.subject = subject;
    this.storeRankings = rankings.filter(r => !Number.isNaN(r.value) || r.store?.uuid === subject.uuid);
    const values = this.storeRankings.map(sr => sr.value);
    values.sort(((a, b) => a - b));
    this.count = this.storeRankings.length;
    this.minValue = Math.min(...values);
    this.maxValue = Math.max(...values);
    this.median = median(values);
  }
}

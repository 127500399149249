<div class="invitation-container container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <div class="page-container">
    <div class="invitation-expired-container container" fxLayout="column" fxLayoutAlign="start center"
         fxLayoutGap="30px" *ngIf="invitation && isExpired">
      <h1 class="mat-h1 color-accent text-small-caps text-center">Uh-oh! Your invitation is expired!</h1>
      <p>Please contact <a class="color-help"
                           [href]="'mailto:' + invitation.inviter.email + '?subject=MTN%20Insights%20Invitation%20Expired'"
                           target="_blank">{{invitation.inviter.getDisplayName()}}</a> and ask them to re-invite you.
        For your security, invitations are only valid for seven days.</p>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="30px" *ngIf="invitation && !isExpired">
      <h1 class="mat-h1 color-primary text-small-caps text-center">You've been invited to join Insights!</h1>
      <div class="text-center" fxLayout="column" *ngIf="invitation && !isExpired">
        <strong>{{invitation.inviter.getDisplayName()}}</strong>
        <span class="color-muted">has invited you to join</span>
        <strong>{{invitation.invitation.company.name}}</strong>
        <span class="color-muted">in Insights</span>
      </div>
      <div class="other-company-alert-container constrained-width-container container"
           *ngIf="isMemberOfOtherCompany; else newUser"
           fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div class="other-company-alert" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <fa-icon class="color-accent" icon="exclamation-triangle" size="2x"></fa-icon>
          <strong>{{OTHER_COMPANY_ALERT_TITLE}}</strong>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-checkbox [formControl]="otherCompanyAcknowledgement" color="primary"></mat-checkbox>
          <div [innerHTML]="otherCompanyAlertText"></div>
        </div>
      </div>
      <ng-template #newUser>
        <div class="form-container container" fxLayout="column" fxLayoutGap="15px" [formGroup]="userForm"
             *ngIf="userForm">
          <div>
            <h3 class="mat-h3">Create your account:</h3>
            <div fxLayout="row" fxLayoutGap="15px">
              <p>In order to protect your login information from breaches and bad actors, we use Auth0's
                industry-leading services. We'll help you easily create an <a href="https://auth0.com" target="_blank">Auth0</a>
                account, and you'll use that account to access MTN's platform.</p>
              <a href="https://auth0.com" target="_blank"><img height="40" src="assets/images/auth0-logo.png"
                                                               alt="Auth0 logo"/></a>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <mtn-text-field [form]="userForm" controlName="firstName" placeholder="First Name"></mtn-text-field>
            <mtn-text-field [form]="userForm" controlName="lastName" placeholder="Last Name"></mtn-text-field>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field class="default-field-width container" appearance="outline">
              <mat-label>Password</mat-label>
              <input [type]="isPasswordShown ? 'text' : 'password'" matInput formControlName="password"/>
              <mat-error *ngIf="userForm.get('password').hasError('required')">
                <i>Must be provided</i>
              </mat-error>
              <mat-error
                *ngIf="userForm.get('password').hasError('missingNumber') || userForm.get('password').hasError('missingUpperCase') || userForm.get('password').hasError('missingLowerCase') || userForm.get('password').hasError('missingSpecialCharacter') || userForm.get('password').hasError('maxlength') || userForm.get('password').hasError('minlength')">
                <i>Invalid password</i>
              </mat-error>
              <fa-icon class="clickable show-hide-password" [icon]="isPasswordShown ? 'eye-slash' : 'eye'"
                       (click)="isPasswordShown = !isPasswordShown"></fa-icon>
            </mat-form-field>
            <mat-form-field class="default-field-width" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input [type]="isPasswordShown ? 'text' : 'password'" matInput formControlName="confirmPassword"/>
              <mat-error *ngIf="userForm.get('confirmPassword').hasError('required')">
                <i>Must be provided</i>
              </mat-error>
              <mat-error *ngIf="userForm.get('confirmPassword').hasError('noPasswordMatch')">
                <i>Passwords do not match</i>
              </mat-error>
              <fa-icon class="clickable show-hide-password" [icon]="isPasswordShown ? 'eye-slash' : 'eye'"
                       (click)="isPasswordShown = !isPasswordShown"></fa-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <div class="color-muted" fxLayout="column" style="font-size: 12px; line-height: 12px;">
              <small>Password must include at least:</small>
              <small style="margin-left: 20px">10 characters</small>
              <small style="margin-left: 20px">1 upper-case letter</small>
              <small style="margin-left: 20px">1 lower-case letter</small>
              <small style="margin-left: 20px">1 number</small>
              <small style="margin-left: 20px">1 special character (!,&#64;,#,$,%, etc)</small>
            </div>
          </div>
          <br/>
          <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom:15px">
            <mat-checkbox color="primary" formControlName="authorization"></mat-checkbox>
            <div fxFlex>
              I acknowledge Retailstat's use of Auth0 and authorize Retailstat to create my Auth0 account on my behalf
            </div>
          </div>
        </div>
      </ng-template>
      <button class="accept-button" mat-raised-button color="primary" (click)="acceptInvitation()"
              [disabled]="otherCompanyAcknowledgement?.invalid || userForm?.invalid">
        Accept Invitation
      </button>
    </div>
  </div>
  <mtn-spinner [on]="!invitation" [size]="SpinnerSize.LARGE"></mtn-spinner>
</div>

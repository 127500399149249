import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureCollection } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends InsightsRestService {

  constructor(private http: HttpClient) {
    super();
  }

  findOnesIsochrone(uuid: string, minutes: number): Observable<FeatureCollection> {
    const url = this.buildUrl(`/location/${uuid}/isochrone`);
    const params = new HttpParams()
      .set('minutes', minutes.toString());
    return this.http.get(url, {params: params})
      .pipe(map((result: any) => new FeatureCollection(result)));
  }
}

import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DemographicsRequest } from './demographics-request';
import { DemographicDataView } from './demographic-data-view';
import { FeatureCollection } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DemographicsService extends InsightsRestService {

  private readonly path = '/demographics';

  constructor(protected http: HttpClient) {
    super();
  }

  findDemographicsForFeatureCollection(featureCollection: FeatureCollection): Observable<DemographicDataView> {
    const request: DemographicsRequest = {
      geometries: [{
        geometry: featureCollection.features[0].geometry,
        label: <string>featureCollection.features[0].id
      }]
    };

    return this.search(request)
      .pipe(map((results: DemographicDataView[]) => {
        if (results.length === 1) {
          return results[0];
        } else {
          throw 'Unexpected result count';
        }
      }));
  }

  search(request: DemographicsRequest): Observable<DemographicDataView[]> {
    if (!request.mimeType) {
      request.mimeType = 'application/json';
    }

    const url = this.buildUrl(`${this.path}/search`);

    return this.http.post(url, request)
      .pipe(map((results: any[]) => results.map((result: any) => new DemographicDataView(result))));
  }
}

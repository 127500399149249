import { Wizard } from '../../../../../core/wizard/wizard';
import { UserProfile } from '../../../../../core/user-profile/user-profile';
import { AddUserToCompanyPageComponent } from './add-user-to-company-page/add-user-to-company-page.component';
import { Company } from '../../../../../core/company/company';

export class AddUserToCompanyWizard extends Wizard<AddUserToCompanyWizardModel> {
  key = 'add-user-to-company';
  pageTypeMap = {
    'add-user-to-company': AddUserToCompanyPageComponent
  };
  startingPageKey = 'add-user-to-company';
}

export interface AddUserToCompanyWizardModel {
  company: Company;
  isDowngraded?: boolean;
  isSearchEnabled?: boolean;
  isUpgraded?: boolean;
  userProfile?: UserProfile;
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base-component';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { BreadcrumbState, selectBreadcrumbState } from './breadcrumb-state';

@Component({
  selector: 'mtn-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends BaseComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = [];

  private _url: string;
  private _uuidLabel: string;

  constructor(private ngrxStore: NgrxStore<AppState>,
              private router: Router) {
    super();
  }

  ngOnInit() {
    this.subscribeToRouteChanges();
    this.subscribeToBreadcrumbState();
  }

  private buildBreadcrumbs(): void {
    const breadcrumbs: Breadcrumb[] = [];

    const paths = this.parseUrl();
    if (paths?.length) {

      const rootPath = paths[0];
      const childPath = paths.length > 1 ? paths[1] : null;
      const uuidPath = paths.length === 3 ? paths[2] : null;

      breadcrumbs.push({
        displayValue: this.buildDisplayValueForPath(rootPath),
        path: rootPath,
        routerLink: childPath ? [`/${rootPath}`] : null
      });

      if (childPath) {
        breadcrumbs.push({
          displayValue: this.buildDisplayValueForPath(childPath),
          path: childPath,
          routerLink: uuidPath ? [`/${rootPath}`, childPath] : null
        });
      }

      if (uuidPath) {
        breadcrumbs.push({
          displayValue: this.buildDisplayValueForPath(uuidPath),
          path: uuidPath,
          routerLink: null
        });
      }
    }

    this.breadcrumbs = breadcrumbs;
  }

  private buildDisplayValueForPath(value: string): string {
    const hyphenCount = (value.match(/-/g) || []).length;
    //If the value is a UUID, return the uuidLabel, if any
    if (value.length === 36 && hyphenCount === 4) {
      return this._uuidLabel || value;
    }
    //Else configure mapped values here
    else {
      switch (value) {
        case 'collection':
          return 'Collections';
        default:
          return value;
      }
    }
  }

  private parseUrl(): string[] {
    if (!this._url) {
      this._url = this.router.url;
    }
    if (this._url) {
      const parts = this._url.split('/');
      return _.filter(parts, (part: string) => !!part);
    } else {
      return [];
    }
  }

  private subscribeToBreadcrumbState(): void {
    this.addSubscription(
      selectBreadcrumbState(this.ngrxStore)
        .subscribe((state: BreadcrumbState) => {
          this._uuidLabel = state.uuidLabel;
          this.buildBreadcrumbs();
        })
    );
  }

  private subscribeToRouteChanges(): void {
    this.addSubscription(
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this._url = event.url;
          this.buildBreadcrumbs();
        })
    );
  }

}

export interface Breadcrumb {
  path: string;
  displayValue: string;
  routerLink: string[];
}

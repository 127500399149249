export class ChartAxis {

  private _tickValues: number[] = [];
  private _tickLabels: string[] = [];
  private _labelGenerator: (val: number) => string;
  private _valueKey: string;
  private _width: number;

  constructor(tickValues: number[], tickLabels: string[], labelGenerator: (val: number) => string, valueKey: string, width: number) {
    this._tickValues = tickValues;
    this._tickLabels = tickLabels;
    this._labelGenerator = labelGenerator;
    this._valueKey = valueKey;
    this._width = width;
  }

  get width() {
    return this._width;
  }

  get tickValues() {
    return this._tickValues;
  }

  get tickLabels() {
    return this._tickLabels;
  }

  get top() {
    return this._tickValues[this._tickValues.length - 1];
  }

  get bottom() {
    return this._tickValues[0];
  }

  generateLabel(val: number) {
    return this._labelGenerator(val);
  }

  get valueKey() {
    return this._valueKey;
  }

}

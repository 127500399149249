<div class="company-users-list-container container" fxLayout="column" fxLayoutGap="15px">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">Users <span
        *ngIf="dataSource.data?.length">({{dataSource.data.length}})</span></h2>
      <div fxFlex></div>
      <mtn-text-field placeholder="Filter" [form]="filterForm" controlName="filter"
                      noErrorPadding="true"></mtn-text-field>
      <mtn-icon-button color="accent" (onClick)="handleAddUser()" *ngIf="isActionsEnabled" matTooltip="Add a User">
        <fa-icon icon="plus"></fa-icon>
      </mtn-icon-button>
    </div>
    <hr/>
  </div>
  <div class="table-container container">
    <div class="no-data-container" fxLayout="row" fxLayoutAlign="center center" *ngIf="!dataSource?.data?.length">No
      Results Available
    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
           [hidden]="!dataSource?.data?.length">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let userWithInvitation">
          <span>{{userWithInvitation.userProfile.getDisplayName()}}</span>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let userWithInvitation">
          <span>{{userWithInvitation.userProfile.email}}</span>
        </td>
      </ng-container>

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let userWithInvitation">
          <span *ngIf="!userWithInvitation.invitation">{{userWithInvitation.userProfile.title}}</span>
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Type</th>
        <td mat-cell *matCellDef="let userWithInvitation">
          <span *ngIf="userWithInvitation.isAdministrator; else member">Administrator <span class="color-accent"
                                                                                            *ngIf="userWithInvitation.invitation">(Invited)</span></span>
          <ng-template #member>
            <span>Member <span class="color-accent" *ngIf="userWithInvitation.invitation">(Invited)</span></span>
          </ng-template>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let userWithInvitation">
          <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="10px" *ngIf="isActionsEnabled">
            <mtn-icon-button matTooltip="Upgrade to Administrator"
                             (onClick)="handleUpgradeDowngradeUser(userWithInvitation)"
                             *ngIf="!userWithInvitation.isAdministrator && userWithInvitation.userProfile.uuid !== currentUser.uuid">
              <fa-icon icon="user-plus"></fa-icon>
            </mtn-icon-button>
            <mtn-icon-button matTooltip="Downgrade to Member" (onClick)="handleUpgradeDowngradeUser(userWithInvitation)"
                             *ngIf="userWithInvitation.isAdministrator && userWithInvitation.userProfile.uuid !== currentUser.uuid">
              <fa-icon icon="user-minus"></fa-icon>
            </mtn-icon-button>
            <mtn-icon-button color="warn" matTooltip="Remove User" (onClick)="handleRemoveUser(userWithInvitation)"
                             [hidden]="userWithInvitation.userProfile.uuid === currentUser.uuid">
              <fa-icon icon="ban"></fa-icon>
            </mtn-icon-button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="clickable" *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3"><strong>0</strong> of <strong>{{dataSource?.data?.length}}</strong>
          Results Match Current Filter
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [showFirstLastButtons]="true" [hidePageSize]="true"
                   [hidden]="dataSource.data?.length <= 10"></mat-paginator>
  </div>
</div>

import { Wizard } from '../../../core/wizard/wizard';
import { GoogleSearchPageComponent } from './google-search-page/google-search-page.component';
import { MtnMap } from '../../mtn-map';

export class GoogleSearchWizard extends Wizard<GoogleSearchWizardModel> {
  key = 'google-search';
  pageTypeMap = {
    'google-search': GoogleSearchPageComponent
  };
  startingPageKey = 'google-search';
}

export interface GoogleSearchWizardModel {
  map: MtnMap;
}

export enum StoreStatusType {
  CLOSED = 'CLOSED',
  DEAD_DEAL = 'DEAD_DEAL',
  NEW_UNDER_CONSTRUCTION = 'NEW_UNDER_CONSTRUCTION',
  OPEN = 'OPEN',
  PLANNED = 'PLANNED',
  PROPOSED = 'PROPOSED',
  REMODEL = 'REMODEL',
  RUMORED = 'RUMORED',
  STRONG_RUMOR = 'STRONG_RUMOR',
  TEMPORARILY_CLOSED = 'TEMPORARILY_CLOSED'
}

export namespace StoreStatusType {

  export function values(): StoreStatusType[] {
    // @ts-ignore
    return Object.keys(StoreStatusType).filter((key: any) => isNaN(key) && key !== 'values').map((key: string) => StoreStatusType[key]);
  }
}

export const ActiveStoreStatuses = [
  StoreStatusType.OPEN,
  StoreStatusType.REMODEL,
  StoreStatusType.TEMPORARILY_CLOSED
];

export const FutureStoreStatuses = [
  StoreStatusType.NEW_UNDER_CONSTRUCTION,
  StoreStatusType.PLANNED,
  StoreStatusType.PROPOSED,
  StoreStatusType.RUMORED,
  StoreStatusType.STRONG_RUMOR
];

export const HistoricalStoreStatuses = [
  StoreStatusType.CLOSED,
  StoreStatusType.DEAD_DEAL
];

export function getStatusOptionsByType(type: 'ACTIVE' | 'FUTURE' | 'HISTORICAL'): StoreStatusType[] {
  switch (type) {
    case 'ACTIVE':
      return ActiveStoreStatuses;
    case 'FUTURE':
      return FutureStoreStatuses;
    case 'HISTORICAL':
      return HistoricalStoreStatuses;
  }
}

export enum LicenseType {
  FREE = 'FREE',
  STANDARD = 'STANDARD',
  INTERNAL = 'INTERNAL'
}

export const LicenseTypeOrdinal: LicenseType[] = [LicenseType.FREE, LicenseType.STANDARD, LicenseType.INTERNAL];

export function isLicenseOfLevel(a: LicenseType, b: LicenseType): boolean {
  return a && LicenseTypeOrdinal.indexOf(a) >= LicenseTypeOrdinal.indexOf(b);
}

export function getLicenseDisplayName(type: LicenseType): string {
  switch (type) {
    case LicenseType.FREE:
      return 'Free';
    case LicenseType.STANDARD:
      return 'Standard';
    case LicenseType.INTERNAL:
      return 'Internal';
  }
}

import * as p5 from 'p5';
import { StoreRanking } from '../../store/ranking/store-ranking';
import { ChartPoint } from './chart-point';

export class ChartStar extends ChartPoint {

  private outerRadius: number;
  private innerRadius: number;

  constructor(ip5: p5, pos: p5.Vector, color: p5.Color, storeRanking: StoreRanking) {
    super(ip5, pos, color, storeRanking);
    this.outerRadius = this._radius * 3;
    this.innerRadius = this.outerRadius / 2;
  }

  update() {
    this._isHovered = this.ip5.dist(this.ip5.mouseX, this.ip5.mouseY, this._position.x, this._position.y) < this.outerRadius;
  }

  draw() {
    this.ip5.push();
    this.ip5.fill(this._color);
    this.ip5.stroke(this._isHovered ? 255 : 0);
    this.ip5.strokeWeight(2);
    this.drawStar();
    this.ip5.pop();
  }

  private drawStar() {
    let angle = this.ip5.TWO_PI / 5;
    let halfAngle = angle / 2.0;
    this.ip5.beginShape();
    for (let a = this.ip5.HALF_PI; a < this.ip5.TWO_PI + this.ip5.HALF_PI; a += angle) {
      let sx = this._position.x + this.ip5.cos(a) * this.innerRadius;
      let sy = this._position.y + this.ip5.sin(a) * this.innerRadius;
      this.ip5.vertex(sx, sy);
      sx = this._position.x + this.ip5.cos(a + halfAngle) * this.outerRadius;
      sy = this._position.y + this.ip5.sin(a + halfAngle) * this.outerRadius;
      this.ip5.vertex(sx, sy);
    }
    this.ip5.endShape(this.ip5.CLOSE);
  }

}

import { ChartAxis } from './chart-axis';
import { AxisMode } from './AxisMode';
import { KeyIndicatorType } from '../../user-preferences/key-indicator-type.enum';
import { Dictionary } from '../../dictionary/dictionary';
import { EventEmitter } from '@angular/core';

export class ChartAxisBuilder {

  private readonly TARGET_TICK_SEPARATION = 100;

  private readonly PRETTY_INCREMENTS = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000];

  private _keyIndicator: KeyIndicatorType;
  private _chartWidthPx: number = 10;
  private _lowestValue = Number.POSITIVE_INFINITY;
  private _highestValue = Number.NEGATIVE_INFINITY;
  private readonly _mode: AxisMode;

  private _notifier: EventEmitter<ChartAxis> = new EventEmitter<ChartAxis>();

  private _axis: ChartAxis;

  constructor(relativeMode: AxisMode, keyIndicator: KeyIndicatorType) {
    this._mode = relativeMode;
    this._keyIndicator = keyIndicator;
  }

  get notifier() {
    return this._notifier;
  }

  extend(value: number) {
    this._lowestValue = Math.min(this._lowestValue, value);
    this._highestValue = Math.max(this._highestValue, value);
  }

  resetBounds(): void {
    this._lowestValue = 0;
    this._highestValue = 0;
  }

  setKeyIndicator(keyIndicator: KeyIndicatorType): void {
    this._keyIndicator = keyIndicator;
  }

  setWidth(width: number) {
    this._chartWidthPx = width;
    this.build();
  }

  build() {
    if (this._mode == AxisMode.ABSOLUTE) {
      this._lowestValue = 0;
    }

    // How many ticks can we fit into the width of the chart?
    let desiredTickCount = Math.floor(this._chartWidthPx / this.TARGET_TICK_SEPARATION);

    // Given that many ticks, what would the tick increment value be?
    const valueRange = this._highestValue - this._lowestValue;
    const desiredIncrementValue = valueRange / desiredTickCount;

    let incBaseUnit: number;
    if (this._mode == AxisMode.RELATIVE || this._keyIndicator == KeyIndicatorType.SALES_SQFT) {
      incBaseUnit = 0.5;
    } else {
      incBaseUnit = 5000;
    }
    const incOptions = this.PRETTY_INCREMENTS.map(i => i * incBaseUnit).reverse();
    const tickIncrementValue = this.closest(desiredIncrementValue, incOptions);

    // Adding one because division gives count of segments, need additional tick at the end of last segment
    const firstTick = Math.floor(this._lowestValue / tickIncrementValue) * tickIncrementValue;

    const tickValues = [firstTick];
    let nextTick = firstTick;
    do {
      nextTick += tickIncrementValue;
      tickValues.push(nextTick);
    } while (nextTick < this._highestValue);

    let labelGenerator: (val: number) => string;
    if (this._mode == AxisMode.RELATIVE) {
      labelGenerator = (val: number) => (val > 0 ? '+' : '') + val.toFixed(0) + '%';
    } else {
      labelGenerator = (val: number) => {
        if (this._keyIndicator == KeyIndicatorType.SALES_SQFT) {
          return '$' + val.toFixed(2);
        } else if (val >= 1000000) {
          return '$' + (val / 1000000).toFixed(2) + 'm';
        } else if (val > 1000) {
          return '$' + Math.round(val / 1000) + 'k';
        } else {
          return '$' + Math.round(val);
        }
      }
    }

    const valueKey = this._keyIndicator == KeyIndicatorType.SALES_SQFT ? Dictionary.labelSalesSqft : Dictionary.labelSalesVolume;
    let tickLabels: string[] = tickValues.map(t => labelGenerator(t));
    this._axis = new ChartAxis(tickValues, tickLabels, labelGenerator, valueKey, this._chartWidthPx);
    this.notifier.emit(this._axis);
  }

  private closest(needle: number, haystack: number[]) {
    return haystack.reduce((a, b) => {
      let aDiff = Math.abs(a - needle);
      let bDiff = Math.abs(b - needle);

      if (aDiff == bDiff) {
        return a > b ? a : b;
      } else {
        return bDiff < aDiff ? b : a;
      }
    });
  }
}

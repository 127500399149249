export class LogoUtil {

  public static buildLogoUrl(filename: string, borderColor?: string): string {
    if (!borderColor) {
      borderColor = 'rgb:6b6b6b';
    }
    return `https://res.cloudinary.com/mtn-retail-advisors/image/upload/c_limit,h_20,w_100/r_5,u_white,w_1.2,h_1.2,fl_relative,bo_2px_solid_${borderColor}/v1566937096/${filename}`;
  }

  public static buildMarkerUrl(filename: string, borderColor?: string): string {
    if (!borderColor) {
      borderColor = 'rgb:6b6b6b';
    }
    return `https://res.cloudinary.com/mtn-retail-advisors/image/upload/c_limit,h_20,w_100/r_5,u_white,w_1.2,h_1.2,fl_relative,bo_2px_solid_${borderColor}/v1566937096/${filename}`;
  }
}

<div class="store-services-page-container container">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <h3 class="mat-h3 color-primary text-small-caps no-bottom-margin">Service Departments</h3>
    <mtn-what-is-this
      text="Service departments are staffed with individuals who provide expert advice and cater a product to the customer's needs."></mtn-what-is-this>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="15px">
    <mtn-simple-service-view label="Bakery" [value]="services.bakery"></mtn-simple-service-view>
    <mtn-simple-service-view label="Cheese" [value]="services.cheese"></mtn-simple-service-view>
    <mtn-simple-service-view label="Deli" [value]="services.deli"></mtn-simple-service-view>
    <mtn-simple-service-view label="Floral" [value]="services.floral"></mtn-simple-service-view>
    <mtn-simple-service-view label="Meat" [value]="services.meat"></mtn-simple-service-view>
    <mtn-simple-service-view label="Pharmacy" [value]="services.pharmacy"></mtn-simple-service-view>
    <mtn-simple-service-view label="Seafood" [value]="services.seafood"></mtn-simple-service-view>
  </div>
  <div>
    <h3 class="mat-h3 color-primary text-small-caps no-bottom-margin">Amenities</h3>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="15px">
    <mtn-simple-service-view label="Bank" [value]="services.bank"></mtn-simple-service-view>
    <mtn-simple-service-view label="Beer" [value]="services.beer"></mtn-simple-service-view>
    <mtn-simple-service-view label="Bulk" [value]="services.bulk"></mtn-simple-service-view>
    <mtn-simple-service-view label="Coffee Shop" [value]="services.coffee"></mtn-simple-service-view>
    <mtn-simple-service-view label="Expanded General Merchandise"
                             [value]="services.expandedGM"></mtn-simple-service-view>
    <mtn-simple-service-view label="Extensive Prepared Foods"
                             [value]="services.extensivePreparedFoods"></mtn-simple-service-view>
    <mtn-simple-service-view label="Fuel" [value]="services.fuel"></mtn-simple-service-view>
    <mtn-simple-service-view label="In-Store Restaurant"
                             [value]="services.inStoreRestaurant"></mtn-simple-service-view>
    <mtn-simple-service-view label="Liquor" [value]="services.liquor"></mtn-simple-service-view>
    <mtn-simple-service-view label="Olive Bar" [value]="services.oliveBar"></mtn-simple-service-view>
    <mtn-simple-service-view label="Online Pickup" [value]="services.onlinePickup"></mtn-simple-service-view>
    <mtn-simple-service-view label="Salad Bar" [value]="services.saladBar"></mtn-simple-service-view>
    <mtn-simple-service-view label="Seating Area" [value]="services.seating"></mtn-simple-service-view>
    <mtn-simple-service-view label="Sushi" [value]="services.sushi"></mtn-simple-service-view>
    <mtn-simple-service-view label="Wine" [value]="services.wine"></mtn-simple-service-view>
  </div>
</div>

<div [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [formControlName]="controlName" [mtnNumericFormatEnforcer]="form.get(controlName)"
           [required]="isRequired"/>
    <mat-hint>Ex. 5551231234</mat-hint>
    <mat-error *ngIf="form.get(controlName).hasError('lengthequals')">
      <i>{{form.get(controlName).getError('lengthequals')}}</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>
</div>

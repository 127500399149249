import { Wizard } from '../../core/wizard/wizard';
import { Collection } from '../../core/federation/collection/collection';
import { CloneCollectionPageComponent } from './clone-collection-page/clone-collection-page.component';

export class CloneCollectionWizard extends Wizard<CloneCollectionWizardModel> {
  key = 'clone-collection';
  pageTypeMap = {
    'clone-collection': CloneCollectionPageComponent
  };
  startingPageKey = 'clone-collection';
}

export interface CloneCollectionWizardModel {
  collection: Collection;
  result?: Collection;
  userProfileUuid: string;
}

import { Wizard } from '../../../../../core/wizard/wizard';
import { CompetitiveIncursionsPageComponent } from './competitive-incursions-page/competitive-incursions-page.component';
import { Feature } from '../../../../../core/models';

export class CompetitiveIncursionsWizard extends Wizard<CompetitiveIncursionsWizardModel> {
  key = 'competitive-incursions';
  pageTypeMap = {
    'competitive-incursions': CompetitiveIncursionsPageComponent
  };
  startingPageKey = 'competitive-incursions';
}

export interface CompetitiveIncursionsWizardModel {
  description: string;
  incursions: Feature[];
  subjectStore: Feature;
}

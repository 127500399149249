import { Injectable } from '@angular/core';
import { InsightsRestService } from '../service/insights-rest-service';
import { HttpClient } from '@angular/common/http';
import { FileDownloadService } from '../file/file-download.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreExportService extends InsightsRestService {

  protected path = '/export/store';

  constructor(private fileDownloadService: FileDownloadService,
              private http: HttpClient) {
    super();
  }

  exportAsCsv(uuids: string[], includeRatings: boolean, includeServices: boolean): Observable<any> {
    const request: StoreExportRequest = {
      includeAnalystRatings: includeRatings,
      includeServices: includeServices,
      uuids: uuids
    };

    const url = this.buildUrl(this.path);

    return this.http.post(url, request, {responseType: 'blob'})
      .pipe(tap((data: Blob) => {
        this.fileDownloadService.downloadBlob(data, 'text/csv');
      }));
  }
}

export interface StoreExportRequest {
  includeAnalystRatings: boolean;
  includeServices: boolean;
  uuids: string[];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnCoreModule } from '../core/mtn-core.module';
import { AdministrationComponent } from './administration/administration.component';
import { MtnRoutingModule } from '../core/modules/mtn-routing.module';
import { CompaniesAdministrationComponent } from './administration/companies-administration/companies-administration.component';
import { UsersAdministrationComponent } from './administration/users-administration/users-administration.component';
import { AdministrationLandingPageComponent } from './administration/administration-landing-page/administration-landing-page.component';
import { StoreModule } from '@ngrx/store';
import { ADMIN_STATE_KEY, adminReducer } from './administration-state';
import { CompanyAdministrationComponent } from './administration/company-administration/company-administration.component';
import { UserAdministrationComponent } from './administration/user-administration/user-administration.component';
import { CreateCompanyPageComponent } from './administration/companies-administration/create-company-wizard/create-company-page/create-company-page.component';
import { UpdateLicensePageComponent } from './administration/company-administration/license-card/update-license-wizard/update-license-page/update-license-page.component';
import { ConfirmDeleteCompanyPageComponent } from './administration/company-administration/confirm-delete-company-wizard/confirm-delete-company-page/confirm-delete-company-page.component';
import { EditCompanyPageComponent } from './administration/company-administration/edit-company-wizard/edit-company-page/edit-company-page.component';
import { AddUserToCompanyPageComponent } from './administration/company-administration/company-users-list/add-user-to-company-wizard/add-user-to-company-page/add-user-to-company-page.component';
import { AdministrationBreadcrumbsComponent } from './administration/administration-breadcrumbs/administration-breadcrumbs.component';
import { AssociatedCompanyCardComponent } from './administration/user-administration/associated-company-card/associated-company-card.component';
import { LicenseCardComponent } from './administration/company-administration/license-card/license-card.component';
import { UserPreferencesViewComponent } from './administration/user-administration/user-preferences-view/user-preferences-view.component';
import { AssociatedBannersListComponent } from './administration/company-administration/associated-banners-list/associated-banners-list.component';
import { AssociatedBannerCardComponent } from './administration/company-administration/associated-banners-list/associated-banner-card/associated-banner-card.component';
import { AddAssociatedBannerPageComponent } from './administration/company-administration/associated-banners-list/add-associated-banner-wizard/add-associated-banner-page/add-associated-banner-page.component';
import { LicenseHistoryPanelComponent } from './administration/company-administration/license-history-panel/license-history-panel.component';
import { CompanyAlertsPanelComponent } from './administration/company-administration/company-alerts-panel/company-alerts-panel.component';
import { SuspendLicensePageComponent } from './administration/company-administration/license-card/suspend-license-wizard/suspend-license-page/suspend-license-page.component';
import { SelectHubspotCompanyPageComponent } from './administration/companies-administration/create-company-wizard/select-hubspot-company-page/select-hubspot-company-page.component';
import { SelfServiceAdministrationComponent } from './administration/self-service-administration/self-service-administration.component';
import { InviteUserToCompanyPageComponent } from './administration/company-administration/company-users-list/invite-user-to-company-wizard/invite-user-to-company-page/invite-user-to-company-page.component';
import { CompanyUsersListComponent } from './administration/company-administration/company-users-list/company-users-list.component';
import { AreaSubscriptionsListComponent } from './administration/company-administration/area-subscriptions-list/area-subscriptions-list.component';
import { AddAreaSubscriptionPageComponent } from './administration/company-administration/area-subscriptions-list/add-area-subscription-wizard/add-area-subscription-page/add-area-subscription-page.component';


@NgModule({
  declarations: [
    AdministrationComponent,
    CompaniesAdministrationComponent,
    UsersAdministrationComponent,
    AdministrationLandingPageComponent,
    CompanyAdministrationComponent,
    UserAdministrationComponent,
    CreateCompanyPageComponent,
    UpdateLicensePageComponent,
    ConfirmDeleteCompanyPageComponent,
    EditCompanyPageComponent,
    AddUserToCompanyPageComponent,
    AdministrationBreadcrumbsComponent,
    AssociatedCompanyCardComponent,
    LicenseCardComponent,
    UserPreferencesViewComponent,
    AssociatedBannersListComponent,
    AssociatedBannerCardComponent,
    AddAssociatedBannerPageComponent,
    LicenseHistoryPanelComponent,
    CompanyAlertsPanelComponent,
    SuspendLicensePageComponent,
    SelectHubspotCompanyPageComponent,
    SelfServiceAdministrationComponent,
    InviteUserToCompanyPageComponent,
    CompanyUsersListComponent,
    AreaSubscriptionsListComponent,
    AddAreaSubscriptionPageComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(ADMIN_STATE_KEY, adminReducer),
    MtnCoreModule,
    MtnRoutingModule
  ]
})
export class MtnAdministrationModule {
}

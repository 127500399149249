<div class="create-or-edit-collection-page-container container" fxLayout="column" fxLayoutGap="15px">
  <div *ngIf="wizard.model.storeUuids?.length" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    <fa-icon class="color-help" icon="info-circle" size="2x"></fa-icon>
    <span>Your {{wizard.model.storeUuids.length}} selected {{wizard.model.storeUuids.length === 1 ? 'store' : 'stores'}}
      will automatically be added to this collection.</span>
  </div>
  <div fxLayout="column" [formGroup]="form">
    <mtn-text-field [form]="form" controlName="name" placeholder="Name" [isRequired]="true"></mtn-text-field>
    <mtn-text-area-field [form]="form" controlName="description" placeholder="Description"></mtn-text-area-field>
    <mat-checkbox color="primary" formControlName="favorite" *ngIf="!wizard.model.collection?.uuid">Make Favorite?
    </mat-checkbox>
  </div>
</div>

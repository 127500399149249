import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../../../core/wizard/wizard-page-component';
import { StoreDetailsWizard } from '../store-details-wizard';
import { Observable } from 'rxjs';
import { Store } from '../../../../../../../core/models';

@Component({
  selector: 'mtn-store-details-page',
  templateUrl: './store-details-page.component.html',
  styleUrls: ['./store-details-page.component.scss']
})
export class StoreDetailsPageComponent extends WizardPageComponent<StoreDetailsWizard> {

  key = 'store-details';

  store: Store;

  constructor() {
    super();
    this.title = 'Store Details';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.store = this.wizard.model.store;
    return super.onLoad();
  }

}

<div [formGroup]="form">
  <mtn-email-address-field [form]="form" [isRequired]="true"></mtn-email-address-field>
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-text-field class="default-field-width" [form]="form" controlName="firstName" placeholder="First Name"
                    [isRequired]="true"></mtn-text-field>
    <mtn-text-field class="default-field-width" [form]="form" controlName="lastName" placeholder="Last Name"
                    [isRequired]="true"></mtn-text-field>
  </div>
  <div fxLayout="row" fxLayoutGap="15px">
    <mtn-text-field class="default-field-width" [form]="form" controlName="title"
                    placeholder="Title"></mtn-text-field>
    <mtn-phone-number-field class="default-field-width" [form]="form"></mtn-phone-number-field>
  </div>
</div>

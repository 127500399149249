import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { Company, CompanyInvitation } from './company';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AreaSubscription } from './area-subscription';
import { UserProfile } from '../user-profile/user-profile';
import { AddOn } from './add-on';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends CrudService<Company> {

  protected path = '/company';

  constructor(protected http: HttpClient) {
    super(http);
  }

  addAllAreaSubscriptions(uuid: string, requests: AreaSubscription[]): Observable<AreaSubscription[]> {
    const url = this.buildUrl(`/${uuid}/area-subscriptions`);
    return this.http.post(url, requests)
      .pipe(map((results: any[]) => results.map((result: any) => new AreaSubscription(result))));
  }

  addOneAddOn(companyUuid: string, addOnUuid: string): Observable<any> {
    const url = this.buildUrl(`/${companyUuid}/add-on/${addOnUuid}`);
    return this.http.post(url, null);
  }

  addOneAdministrator(companyUuid: string, userProfileUuid: string): Observable<Company> {
    const url = this.buildUrl(`/${companyUuid}/administrator/${userProfileUuid}`);
    return this.http.post(url, null)
      .pipe(map(this.buildInstance));
  }

  addOneAreaSubscription(uuid: string, request: AreaSubscription): Observable<AreaSubscription> {
    const url = this.buildUrl(`/${uuid}/area-subscription`);
    return this.http.post(url, request)
      .pipe(map((result: any) => new AreaSubscription(result)));
  }

  addOneBanner(companyUuid: string, bannerUuid: string): Observable<Company> {
    const url = this.buildUrl(`/${companyUuid}/banner/${bannerUuid}`);
    return this.http.post(url, null)
      .pipe(map(this.buildInstance));
  }

  addOneInvitation(companyUuid: string, request: CompanyInvitation): Observable<CompanyInvitation> {
    const url = this.buildUrl(`/${companyUuid}/invitation`);
    return this.http.post(url, request)
      .pipe(map((result: any) => new CompanyInvitation(result)));
  }

  addOneMember(companyUuid: string, userProfileUuid: string): Observable<Company> {
    const url = this.buildUrl(`/${companyUuid}/member/${userProfileUuid}`);
    return this.http.post(url, null)
      .pipe(map(this.buildInstance));
  }

  createOnesApiUser(uuid: string): Observable<UserProfile> {
    const url = this.buildUrl(`/${uuid}/api-user`);
    return this.http.post(url, null)
      .pipe(map((result: any) => new UserProfile(result)));
  }

  findAllAreaSubscriptions(uuid: string): Observable<AreaSubscription[]> {
    const url = this.buildUrl(`/${uuid}/area-subscription`);
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new AreaSubscription(result))));
  }

  findAllInvitations(companyUuid: string): Observable<CompanyInvitation[]> {
    const url = this.buildUrl(`/${companyUuid}/invitation`);
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new CompanyInvitation(result))));
  }

  findOnesAddOns(uuid: string): Observable<AddOn[]> {
    const url = this.buildUrl(`/${uuid}/add-on`);
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new AddOn(result))));
  }

  refreshOnesApiKey(uuid: string): Observable<Company> {
    const url = this.buildUrl(`${uuid}/api-key/refresh`);
    return this.http.put(url, null)
      .pipe(map(this.buildInstance));
  }

  registerOne(request: Company): Observable<Company> {
    const url = this.buildUrl(`/register`);

    return this.http.post(url, request)
      .pipe(map(this.buildInstance));
  }

  removeOneAddOn(companyUuid: string, addOnUuid: string): Observable<any> {
    const url = this.buildUrl(`/${companyUuid}/add-on/${addOnUuid}`);
    return this.http.delete(url);
  }

  removeOneAdministrator(companyUuid: string, userProfileUuid: string): Observable<Company> {
    const url = this.buildUrl(`/${companyUuid}/administrator/${userProfileUuid}`);
    return this.http.delete(url)
      .pipe(map(this.buildInstance));
  }

  removeOneBanner(companyUuid: string, bannerUuid: string): Observable<Company> {
    const url = this.buildUrl(`/${companyUuid}/banner/${bannerUuid}`);
    return this.http.delete(url)
      .pipe(map(this.buildInstance));
  }

  removeOneMember(companyUuid: string, userProfileUuid: string): Observable<Company> {
    const url = this.buildUrl(`/${companyUuid}/member/${userProfileUuid}`);
    return this.http.delete(url)
      .pipe(map(this.buildInstance));
  }

  protected buildInstance(raw: any): Company {
    return new Company(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Company> {
    return new Pageable<Company>(raw, Company);
  }
}

import { Wizard } from '../../../../../core/wizard/wizard';
import { StoreServices } from '../../../../../core/store/store-services';
import { StoreServicesPageComponent } from './store-services-page/store-services-page.component';

export class StoreServicesWizard extends Wizard<StoreServicesWizardModel> {
  key = 'store-services';
  pageTypeMap = {
    'store-services': StoreServicesPageComponent
  };
  startingPageKey = 'store-services';
}

export interface StoreServicesWizardModel {
  services: StoreServices;
}

export function median(values: number[]): number {
  if (!values || values.length === 0) {
    return 0;
  }
  if (values.length % 2 === 0) {
    const midIndex = values.length / 2;
    const medLow = values[midIndex - 1];
    const medHigh = values[midIndex];
    return (medLow + medHigh) / 2;
  } else {
    const idx = Math.floor(values.length / 2);
    return values[idx];
  }
}

export function metersToMiles(value: number): number {
  return round(value / METERS_IN_A_MILE, 2);
}

export function milesToMeters(value: number): number {
  return round(value * METERS_IN_A_MILE);
}

export function round(value: number, precision?: number) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const METERS_IN_A_MILE = 1609.34;

<div class="collection-filter-assembly container">
  <mtn-map-toolbar-button icon="folder" [badgeCount]="toolbarState.badgeCountCollections"
                          [color]="toolbarState.badgeCountCollections ? 'green' : 'grey'"
                          [iconColor]="toolbarState.badgeCountCollections ? 'green' : 'default'"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MENU"
                          [analyticsEventParameters]="{'target': 'map-collection-filter-menu'}"
                          [matMenuTriggerFor]="collectionMenu" matTooltip="Collection Filter"></mtn-map-toolbar-button>
</div>

<mat-menu class="wide-menu" #collectionMenu="matMenu">
  <div class="collection-menu-container toolbar-menu container" mtnClickTrap [preventDefault]="false" fxLayout="column"
       style="width: 300px">
    <div class="header-container">
      <div fxLayout="row">
        <strong>Collections</strong>
        <div fxFlex></div>
        <a href="javascript:void(0)" (click)="clear()"
           [ngClass]="{'color-accent': toolbarState?.filterCollections, 'color-muted': !toolbarState?.filterCollections}">Clear</a>
      </div>
      <hr/>
    </div>
    <div *ngIf="!isLoading; else loader">
      <div *ngIf="options?.length; else noCollections">
        <div class="checkbox-container container scrollable" *ngIf="form" [formGroup]="form" fxLayout="column"
             fxLayoutGap="3px" style="max-height: 300px; padding-bottom: 10px;">
          <mat-checkbox color="accent" *ngFor="let option of options" [formControlName]="option.uuid">
            <div style="max-width: 250px; line-height: 20px;">
              <div fxLayout="column" style="line-height: 16px;" *ngIf="option.description; else noDescription">
                <small class="text-ellipsis"><strong>{{option.name}}</strong></small>
                <small class="text-ellipsis color-muted" *ngIf="option.description">{{option.description}}</small>
              </div>
              <ng-template #noDescription>
                <small class="text-ellipsis"><strong>{{option.name}}</strong></small>
              </ng-template>
            </div>

          </mat-checkbox>
        </div>
        <br/>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="form" [formGroup]="form">
          <mat-checkbox color="accent" formControlName="isExclusive">Exclusive</mat-checkbox>
          <mtn-what-is-this [text]="EXCLUSIVE_FILTER_DEFINITION"></mtn-what-is-this>
        </div>
      </div>
      <ng-template #noCollections>
        <div class="loader-container container text-center" fxLayoutAlign="center center">
          <small class="color-muted">Once you create a collection, it will appear as an option here.</small>
        </div>
      </ng-template>
    </div>
  </div>
</mat-menu>

<ng-template #loader>
  <div class="loader-container container">
    <mtn-spinner [size]="SpinnerSize.EXTRA_SMALL" [on]="true"></mtn-spinner>
  </div>
</ng-template>

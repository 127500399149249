export enum SimpleRatingType {
  COTENANT_EXTERIOR_CONDITION = 'COTENANT_EXTERIOR_CONDITION',
  COTENANT_SYNERGY = 'COTENANT_SYNERGY',
  PARKING_LOT_CONDITION = 'PARKING_LOT_CONDITION',
  PARKING_LOT_FLOW = 'PARKING_LOT_FLOW',
  PARKING_LOT_INGRESS_EGRESS = 'PARKING_LOT_INGRESS_EGRESS',
  PARKING_LOT_LIGHTING = 'PARKING_LOT_LIGHTING',
  STORE_CEILING_CONDITION = 'STORE_CEILING_CONDITION',
  STORE_CHECKSTAND_CONDITION = 'STORE_CHECKSTAND_CONDITION',
  STORE_EXTERIOR_CONDITION = 'STORE_EXTERIOR_CONDITION',
  STORE_FLOOR_CONDITION = 'STORE_FLOOR_CONDITION',
  STORE_REFRIGERATION_CONDITION = 'STORE_REFRIGERATION_CONDITION',
  STORE_SHELVING_CONDITION = 'STORE_SHELVING_CONDITION',
  STORE_VISIBILITY = 'STORE_VISIBILITY',
  STORE_WALL_CONDITION = 'STORE_WALL_CONDITION'
}

export namespace SimpleRatingType {

  export function values(): SimpleRatingType[] {
    // @ts-ignore
    return Object.keys(SimpleRatingType).filter((key: any) => isNaN(key) && key !== 'values').map((key: string) => SimpleRatingType[key]);
  }

  export function getDisplayName(type: SimpleRatingType): string {
    switch (type) {
      case SimpleRatingType.COTENANT_EXTERIOR_CONDITION:
        return 'Cotenant Exterior Condition';
      case SimpleRatingType.COTENANT_SYNERGY:
        return 'Cotenant Synergy';
      case SimpleRatingType.PARKING_LOT_CONDITION:
        return 'Parking Lot Condition';
      case SimpleRatingType.PARKING_LOT_FLOW:
        return 'Traffic Flow';
      case SimpleRatingType.PARKING_LOT_INGRESS_EGRESS:
        return 'Ingress/Egress';
      case SimpleRatingType.PARKING_LOT_LIGHTING:
        return 'Parking Lot Lighting';
      case SimpleRatingType.STORE_CEILING_CONDITION:
        return 'Ceiling Condition';
      case SimpleRatingType.STORE_CHECKSTAND_CONDITION:
        return 'Checkstand Condition';
      case SimpleRatingType.STORE_EXTERIOR_CONDITION:
        return 'Exterior Condition';
      case SimpleRatingType.STORE_FLOOR_CONDITION:
        return 'Floor Condition';
      case SimpleRatingType.STORE_REFRIGERATION_CONDITION:
        return 'Refrigeration Condition';
      case SimpleRatingType.STORE_SHELVING_CONDITION:
        return 'Shelving Condition';
      case SimpleRatingType.STORE_VISIBILITY:
        return 'Visibility from Road'
      case SimpleRatingType.STORE_WALL_CONDITION:
        return 'Wall Condition';
      default:
        return type;
    }
  }
}



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegistrationRequest } from './registration-request';
import { Observable } from 'rxjs';
import { RestService } from '../../core/service/rest-service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends RestService {

  host = environment.SERVICE_HOST;
  rootPath = '/insights';

  constructor(private http: HttpClient) {
    super();
  }

  registerOne(request: RegistrationRequest): Observable<any> {
    const url = this.buildUrl('/registration');

    return this.http.post(url, request);
  }
}

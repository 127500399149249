import { Wizard } from '../../../../core/wizard/wizard';
import { Company } from '../../../../core/company/company';
import { EditCompanyPageComponent } from './edit-company-page/edit-company-page.component';

export class EditCompanyWizard extends Wizard<EditCompanyWizardModel> {
  key = 'edit-company';
  pageTypeMap = {
    'edit-company': EditCompanyPageComponent
  };
  startingPageKey = 'edit-company';
}

export interface EditCompanyWizardModel {
  company: Company;
  isEdited?: boolean;
}

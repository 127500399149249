<div class="multi-select-spotlight-panel spotlight-panel container" fxFlexFill>

  <div class="default-padding" fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h2 class="mat-h2 no-bottom-margin text-ellipsis">{{map.selectedFeatures.length.toLocaleString() || 0}}
        Selected {{layerLabel}}</h2>
      <div class="actions-toolbar-container container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <mtn-store-actions-menu-button [storeUuids]="map.selectedFeatureUuids"
                                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                                       [analyticsEventParameters]="{'target': 'multi-store-spotlight-actions-menu-button'}"></mtn-store-actions-menu-button>
        <mtn-icon-button color="warn" matTooltip="Clear Selection" (onClick)="clearSelection()"
                         mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                         [analyticsEventParameters]="{'target': 'multi-store-spotlight-clear-selection-button'}">
          <fa-icon icon="ban"></fa-icon>
        </mtn-icon-button>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <div class="color-muted" *ngIf="filterForm.get('filter').value">
        Showing {{features.length}} of {{map.selectedFeatures.length}}
      </div>
      <div fxFlex></div>
      <mtn-icon-button matTooltip="Sort Options" [matMenuTriggerFor]="sortMenu"
                       mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                       [analyticsEventParameters]="{'target': 'multi-store-spotlight-sort-menu-button'}">
        <fa-icon [icon]="sortDirection === 'asc' ? 'sort-amount-down' : 'sort-amount-up'"></fa-icon>
      </mtn-icon-button>
      <mtn-text-field [form]="filterForm" placeholder="Filter" controlName="filter"
                      [noErrorPadding]="true"></mtn-text-field>
    </div>

  </div>

  <div class="content-container container scrollable default-padding" fxLayout="column" fxLayoutGap="10px">
    <div *ngFor="let feature of features">
      <div class="feature-card-container container">
        <mtn-store-feature-card [feature]="feature" [map]="map" *ngIf="feature.isStore()"></mtn-store-feature-card>
        <!-- TODO add shopping center card -->
      </div>
    </div>
  </div>

</div>

<mat-menu class="compact-menu" #sortMenu="matMenu">
  <button mat-menu-item (click)="setSort('name', 'asc')">
    <span [ngClass]="{'color-success': sort === 'name'}">Name</span>
  </button>
  <button mat-menu-item (click)="setSort('salesArea', 'desc')">
    <span [ngClass]="{'color-success': sort === 'salesArea' && sortDirection === 'desc'}">Sales Area (Highest)</span>
  </button>
  <button mat-menu-item (click)="setSort('salesArea', 'asc')">
    <span [ngClass]="{'color-success': sort === 'salesArea' && sortDirection === 'asc'}">Sales Area (Lowest)</span>
  </button>
  <button mat-menu-item (click)="setSort('totalArea', 'desc')">
    <span [ngClass]="{'color-success': sort === 'totalArea' && sortDirection === 'desc'}">Total Area (Highest)</span>
  </button>
  <button mat-menu-item (click)="setSort('totalArea', 'asc')">
    <span [ngClass]="{'color-success': sort === 'totalArea' && sortDirection === 'asc'}">Total Area (Lowest)</span>
  </button>
  <button mat-menu-item (click)="setSort('salesVolume', 'desc')">
    <span
      [ngClass]="{'color-success': sort === 'salesVolume' && sortDirection === 'desc'}">Sales Volume (Highest)</span>
  </button>
  <button mat-menu-item (click)="setSort('salesVolume', 'asc')">
    <span [ngClass]="{'color-success': sort === 'salesVolume' && sortDirection === 'asc'}">Sales Volume (Lowest)</span>
  </button>
  <button mat-menu-item (click)="setSort('salesSqft', 'desc')">
    <span [ngClass]="{'color-success': sort === 'salesSqft' && sortDirection === 'desc'}">Sales/sqft (Highest)</span>
  </button>
  <button mat-menu-item (click)="setSort('salesSqft', 'asc')">
    <span [ngClass]="{'color-success': sort === 'salesSqft' && sortDirection === 'asc'}">Sales/sqft (Lowest)</span>
  </button>
</mat-menu>



import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';

export function getConfiguredPath(route: ActivatedRouteSnapshot): string {
  return '/' + route.pathFromRoot
    .filter(v => v.routeConfig)
    .map(v => v.routeConfig!.path)
    .join('/');
}

export function getQueryString(route: ActivatedRouteSnapshot): string {
  const parts: string[] = [];
  _.forOwn(route.queryParams, (value, key) => {
    parts.push(`${key}=${value}`);
  });
  return _.join(parts, '&');
}

export function getResolvedPath(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
}

export function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  const path = getResolvedPath(route);
  const queryString = getQueryString(route);
  return queryString ? `${path}?${queryString}` : path;
}

import { Action, createReducer, on, select, Store } from '@ngrx/store';
import {
  UserSetCollections,
  UserSetCurrentCompany,
  UserSetCurrentUser,
  UserSetLastActivityDate,
  UserSetMtnAuthToken,
  UserSetRedirectUrl,
  UserSetUserPreferences
} from './user-actions';
import { MtnAccessToken } from './authentication/mtn-access-token';
import { UserProfile } from '../core/user-profile/user-profile';
import { filter } from 'rxjs/operators';
import { Company } from '../core/company/company';
import { UserPreferences } from '../core/user-preferences/user-preferences';
import * as localForage from "localforage";
import { StorageService } from '../core/util/storage.service';
import { Collection } from '../core/federation/collection/collection';

export const USER_STATE_KEY = 'userState';

export interface UserState {
  collections: Collection[];
  currentCompany: Company;
  currentUser: UserProfile;
  lastActivityDate: Date;
  mtnAuthToken: MtnAccessToken;
  redirectUrl: string;
  userPreferences: UserPreferences;
}

const initialState: UserState = {
  collections: [],
  currentCompany: null,
  currentUser: null,
  lastActivityDate: null,
  mtnAuthToken: null,
  redirectUrl: null,
  userPreferences: null
};

export const selectUserState = (store: Store<any>) => store.pipe(select((state: any) => state.userState), filter(state => state !== undefined));

const _userReducer = createReducer(
  initialState,
  on(UserSetCollections, (state, {collections}) => ({...state, collections: collections})),
  on(UserSetCurrentCompany, (state, {company}) => {
    localForage.setItem(StorageService.AUTH_CURRENT_COMPANY, company);

    return ({
      ...state,
      currentCompany: company
    });
  }),
  on(UserSetCurrentUser, (state, {currentUser}) => {
    localForage.setItem(StorageService.AUTH_CURRENT_USER, currentUser);

    return ({...state, currentUser: currentUser});
  }),
  on(UserSetMtnAuthToken, (state, {token}) => ({...state, mtnAuthToken: token})),
  on(UserSetRedirectUrl, (state, {url}) => ({...state, redirectUrl: url})),
  on(UserSetLastActivityDate, (state, {lastActivityDate}) => ({...state, lastActivityDate: lastActivityDate})),
  on(UserSetUserPreferences, (state, {preferences}) => ({...state, userPreferences: preferences}))
);

export function userReducer(state: UserState | undefined, action: Action) {
  return _userReducer(state, action);
}

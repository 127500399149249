<div class="collection-feature-view container" fxLayout="column" fxLayoutGap="15px">
  <div class="header-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" [formGroup]="form">
    <h2 class="mat-h2 color-accent no-bottom-margin">Features ({{features?.length || 0}})</h2>
    <div fxFlex></div>
    <mtn-text-field [form]="form" controlName="filter" placeholder="Filter" [noErrorPadding]="true"></mtn-text-field>
    <mat-button-toggle-group formControlName="viewMode">
      <mat-button-toggle [value]="false" mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                         [analyticsEventParameters]="{'target': 'collection-details-list-view-button'}">List View
      </mat-button-toggle>
      <mat-button-toggle [value]="true" mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                         [analyticsEventParameters]="{'target': 'collection-details-map-view-button'}">Map View
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="features-container container" *ngIf="features?.length; else noFeatures">

    <div class="map-container container" *ngIf="isMapViewEnabled; else tableContainer">
      <mtn-map [options]="mapOptions" *ngIf="mapOptions" (ready)="onMapReady($event)"></mtn-map>
    </div>

    <ng-template #tableContainer>
      <div class="table-container container">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let feature">
              <div *ngIf="feature.isStore()" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="5px">
                  <strong>{{feature.getStore().name}}</strong>
                  <span class="color-muted" *ngIf="feature.getStore().number">({{feature.getStore().number}})</span>
                </div>
                <small class="color-muted" *ngIf="feature.getStore().banner">{{feature.getStore().banner.name}}</small>
              </div>
              <span *ngIf="feature.isShoppingCenter()">{{feature.getShoppingCenter().name}}</span>
            </td>
          </ng-container>

          <!-- Banner Column -->
          <ng-container matColumnDef="banner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Banner</th>
            <td mat-cell *matCellDef="let feature">
              <span *ngIf="feature.isStore()">{{feature.getStore().banner?.name}}</span>
            </td>
          </ng-container>

          <!-- Owner Column -->
          <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner</th>
            <td mat-cell *matCellDef="let feature">
              <span *ngIf="feature.isShoppingCenter()">{{feature.getShoppingCenter().ownerCompany?.name}}</span>
            </td>
          </ng-container>

          <!-- Shopping Center Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let feature">
              <span *ngIf="feature.isShoppingCenter()"><mtn-shopping-center-type-view
                [type]="feature.getShoppingCenter().type"></mtn-shopping-center-type-view></span>
            </td>
          </ng-container>

          <!-- FIT Column -->
          <ng-container matColumnDef="fit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>FIT Category</th>
            <td mat-cell *matCellDef="let feature">
              <span *ngIf="feature.isStore()">
                <mtn-definition-view [definitionType]="DefinitionType.FIT"
                                     [systemName]="feature.getStore().fit"
                                     *ngIf="feature.getStore().fit; else na"></mtn-definition-view>
              </span>
            </td>
          </ng-container>

          <!-- Address Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
            <td mat-cell *matCellDef="let feature">
              <mtn-address-view [location]="feature.getStore().space.location"
                                *ngIf="feature.isStore()"></mtn-address-view>
              <mtn-address-view [location]="feature.getShoppingCenter().location"
                                *ngIf="feature.isShoppingCenter()"></mtn-address-view>
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let feature">
              <mtn-store-status-view [type]="feature.getStore().currentStatus.type.systemName"
                                     *ngIf="feature.getStore().currentStatus; else na"></mtn-store-status-view>
            </td>
          </ng-container>

          <!-- GLA Column -->
          <ng-container matColumnDef="gla">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>GLA</th>
            <td mat-cell *matCellDef="let feature">
              <span
                *ngIf="feature.isShoppingCenter()">{{feature.getShoppingCenter().grossLeasableArea.toLocaleString() + ' sqft' || 'N/A'}}</span>
            </td>
          </ng-container>

          <!-- Sales Area Column -->
          <ng-container matColumnDef="salesArea">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Area</th>
            <td mat-cell *matCellDef="let feature">
              <span
                *ngIf="feature.isStore() && feature.getStore().salesArea; else na">{{feature.getStore().salesArea?.toLocaleString() + ' sqft' || 'N/A'}}</span>
            </td>
          </ng-container>

          <!-- Total Area Column -->
          <ng-container matColumnDef="totalArea">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Area</th>
            <td mat-cell *matCellDef="let feature">
              <span
                *ngIf="feature.isStore() && feature.getStore().space.area; else na">{{feature.getStore().space.area?.toLocaleString() + ' sqft' || 'N/A'}}</span>
            </td>
          </ng-container>

          <!-- Volume Column -->
          <ng-container matColumnDef="volume">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Volume</th>
            <td mat-cell *matCellDef="let feature">
              <mtn-sales-volume-view [volume]="feature.getVolume()"
                                     *ngIf="feature.hasVolume(); else na"></mtn-sales-volume-view>
            </td>
          </ng-container>

          <!-- Sales/Sqft Column -->
          <ng-container matColumnDef="salesSqft">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales/Sqft</th>
            <td mat-cell *matCellDef="let feature">
              <mtn-sales-sqft-view [volume]="feature.getVolume()"
                                   *ngIf="feature.hasVolume(); else na"></mtn-sales-sqft-view>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let feature">
              <button class="remove-feature-button" mat-icon-button
                      [disabled]="!isCollectionEditor || isRemovingFeatures" (click)="removeFeature(feature)"
                      mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                      [analyticsEventParameters]="{'target': 'collection-details-remove-feature-button'}"
                      matTooltip="Remove from Collection" matTooltipPosition="left">
                <fa-icon class="color-error" icon="trash-alt"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row class="feature-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="20" [length]="dataSource?.data.length" [showFirstLastButtons]="true"
                       [hidePageSize]="true" [hidden]="dataSource?.data.length <= 20"></mat-paginator>
      </div>
    </ng-template>

  </div>
</div>

<ng-template #noFeatures>
  <div class="no-features-container container" fxLayoutAlign="center center">
    <span
      class="color-muted">No features found for this collection. Add some items to this collection to see them here.</span>
  </div>
</ng-template>

<ng-template #na>
  <span>N/A</span>
</ng-template>

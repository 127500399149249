import { Component, Input } from '@angular/core';
import { DetailState, selectDetailState } from '../../../detail-state';
import { debounceTime, filter, switchMap, take } from 'rxjs/operators';
import { AppState } from '../../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { AuthorizationAwareComponent } from '../../../../core/authorization-aware-component';
import { StoreHighlight } from './store-highlight';
import * as _ from 'lodash';
import { DateUtil } from '../../../../core/util/date-util';
import { CompetitiveIncursionsWizard } from './competitive-incursions-wizard/competitive-incursions-wizard';
import { WizardRunnerService } from '../../../../core/wizard/wizard-runner.service';
import { StoreComparison } from '../../../../core/store/ranking/store-comparison';
import { ComparisonType } from '../../../../core/store/ranking/comparison-type.enum';
import { RegionType } from '../../../../core/location/region-type.enum';
import { KeyIndicatorType } from '../../../../core/user-preferences/key-indicator-type.enum';
import { Dictionary } from '../../../../core/dictionary/dictionary';
import {
  CompetitiveAnalysisDetailsWizard
} from '../../store-sales-performance-panel/competitive-analysis-card/competitive-analysis-details-wizard/competitive-analysis-details-wizard';
import { SimpleRating } from '../../../../core/store/rating/simple-rating';
import { InsightsDatePipe } from '../../../../core/date/insights-date.pipe';
import {
  StoreAnalystRatingWizard
} from '../../store-additional-insights-panel/analyst-ratings-card/store-analyst-rating-wizard/store-analyst-rating-wizard';
import { SimpleRatingType } from '../../../../core/store/rating/simple-rating-type.enum';
import { DatePipe } from '@angular/common';
import {
  BannerComparisonWizard
} from '../../store-banner-analysis/banner-analysis-card/banner-comparison-wizard/banner-comparison-wizard';
import { BannerComparison } from '../../../../core/banner/banner-comparison';
import { of } from 'rxjs';
import { Feature } from '../../../../core/models';

@Component({
  selector: 'mtn-store-highlights-panel',
  templateUrl: './store-highlights-panel.component.html',
  styleUrls: ['./store-highlights-panel.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class StoreHighlightsPanelComponent extends AuthorizationAwareComponent {

  private readonly EXTERIOR_RATING_TYPES = [
    SimpleRatingType.STORE_EXTERIOR_CONDITION,
    SimpleRatingType.STORE_VISIBILITY,
    SimpleRatingType.PARKING_LOT_CONDITION,
    SimpleRatingType.PARKING_LOT_LIGHTING,
    SimpleRatingType.PARKING_LOT_INGRESS_EGRESS,
    SimpleRatingType.PARKING_LOT_FLOW
  ];
  private readonly GROUP_ONE_RATING_TYPES = [
    SimpleRatingType.STORE_EXTERIOR_CONDITION,
    SimpleRatingType.STORE_VISIBILITY,
    SimpleRatingType.PARKING_LOT_CONDITION,
    SimpleRatingType.PARKING_LOT_LIGHTING,
    SimpleRatingType.PARKING_LOT_INGRESS_EGRESS,
    SimpleRatingType.PARKING_LOT_FLOW
  ];
  private readonly GROUP_TWO_RATING_TYPES = [
    SimpleRatingType.STORE_CEILING_CONDITION,
    SimpleRatingType.STORE_CHECKSTAND_CONDITION,
    SimpleRatingType.STORE_FLOOR_CONDITION,
    SimpleRatingType.STORE_REFRIGERATION_CONDITION,
    SimpleRatingType.STORE_SHELVING_CONDITION,
    SimpleRatingType.STORE_WALL_CONDITION,
    SimpleRatingType.COTENANT_EXTERIOR_CONDITION,
    SimpleRatingType.COTENANT_SYNERGY
  ];
  private readonly INTERIOR_RATING_TYPES = [
    SimpleRatingType.STORE_CEILING_CONDITION,
    SimpleRatingType.STORE_CHECKSTAND_CONDITION,
    SimpleRatingType.STORE_FLOOR_CONDITION,
    SimpleRatingType.STORE_REFRIGERATION_CONDITION,
    SimpleRatingType.STORE_SHELVING_CONDITION,
    SimpleRatingType.STORE_WALL_CONDITION
  ];

  @Input()
  maxCount = 4;

  highlights: StoreHighlight[] = [];
  isLoading = true;

  private state: DetailState;

  constructor(private insightsDatePipe: InsightsDatePipe,
              protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
    this.loadStore();
  }

  private buildAnalystRatingHighlights(highlights: StoreHighlight[]): void {
    if (this.state.analystRating?.ratingDate) {
      const ratingDate = this.insightsDatePipe.transform(this.state.analystRating.ratingDate);
      const ratings = this.state.analystRating.ratings;
      const ratingCounts = _.countBy(ratings, 'value');
      const oneStarRatings = _.filter(ratings, (rating: SimpleRating) => rating.value === 1);
      const fiveStarRatings = _.filter(ratings, (rating: SimpleRating) => rating.value === 5);
      const interiorRatings = _.filter(ratings, (rating: SimpleRating) => _.includes(this.INTERIOR_RATING_TYPES, rating.type));
      const interiorRatingCounts = _.countBy(interiorRatings, 'value');
      const exteriorRatings = _.filter(ratings, (rating: SimpleRating) => _.includes(this.EXTERIOR_RATING_TYPES, rating.type));
      const exteriorRatingCounts = _.countBy(exteriorRatings, 'value');

      /*
      General Rating Highlights
       */
      if ((ratingCounts['4'] || 0) + (ratingCounts['5'] || 0) >= 8) {
        const highlight = new StoreHighlight();
        highlight.title = 'Exceptionally High Ratings';
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 50;
        highlight.isPositive = true;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      }
      if ((ratingCounts['1'] || 0) + (ratingCounts['2'] || 0) >= 8) {
        const highlight = new StoreHighlight();
        highlight.title = 'Exceptionally Low Ratings';
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 51;
        highlight.isPositive = false;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      }

      /*
      Interior Rating Highlights
       */
      if ((interiorRatingCounts['4'] || 0) + (interiorRatingCounts['5'] || 0) >= 5) {
        const highlight = new StoreHighlight();
        highlight.title = 'Good Interior Ratings';
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 52;
        highlight.isPositive = true;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      } else if ((interiorRatingCounts['1'] || 0) + (interiorRatingCounts['2'] || 0) >= 5) {
        const highlight = new StoreHighlight();
        highlight.title = 'Poor Interior Ratings';
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 53;
        highlight.isPositive = false;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      }

      /*
      Exterior Rating Highlights
       */
      if ((exteriorRatingCounts['4'] || 0) + (exteriorRatingCounts['5'] || 0) >= 5) {
        const highlight = new StoreHighlight();
        highlight.title = 'Good Exterior Ratings';
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 54;
        highlight.isPositive = true;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      } else if ((exteriorRatingCounts['1'] || 0) + (exteriorRatingCounts['2'] || 0) >= 5) {
        const highlight = new StoreHighlight();
        highlight.title = 'Poor Exterior Ratings';
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 55;
        highlight.isPositive = false;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      }

      /*
      Individual Rating Highlights (Group 1)
       */
      const groupOneFiveStarRatings = _.filter(fiveStarRatings, (rating: SimpleRating) => _.includes(this.GROUP_ONE_RATING_TYPES, rating.type));
      groupOneFiveStarRatings.forEach((rating: SimpleRating) => {
        const highlight = new StoreHighlight();
        highlight.title = `Excellent ${SimpleRatingType.getDisplayName(rating.type)} Rating`;
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 56;
        highlight.isPositive = true;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      });

      const groupOneOneStarRatings = _.filter(oneStarRatings, (rating: SimpleRating) => _.includes(this.GROUP_ONE_RATING_TYPES, rating.type));
      groupOneOneStarRatings.forEach((rating: SimpleRating) => {
        const highlight = new StoreHighlight();
        highlight.title = `Poor ${SimpleRatingType.getDisplayName(rating.type)} Rating`;
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 57;
        highlight.isPositive = false;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      });

      /*
      Individual Rating Highlights (Group 2)
       */
      const groupTwoFiveStarRatings = _.filter(fiveStarRatings, (rating: SimpleRating) => _.includes(this.GROUP_TWO_RATING_TYPES, rating.type));
      groupTwoFiveStarRatings.forEach((rating: SimpleRating) => {
        const highlight = new StoreHighlight();
        highlight.title = `Excellent ${SimpleRatingType.getDisplayName(rating.type)} Rating`;
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 58;
        highlight.isPositive = true;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      });

      const groupTwoOneStarRatings = _.filter(oneStarRatings, (rating: SimpleRating) => _.includes(this.GROUP_TWO_RATING_TYPES, rating.type));
      groupTwoOneStarRatings.forEach((rating: SimpleRating) => {
        const highlight = new StoreHighlight();
        highlight.title = `Poor ${SimpleRatingType.getDisplayName(rating.type)} Rating`;
        highlight.description = `Per MTN Analysts as of ${ratingDate}`;
        highlight.rank = 59;
        highlight.isPositive = false;
        highlight.action = () => this.openRatingDetailsWizard();

        highlights.push(highlight);
      });
    }
  }

  private buildBannerSalesHighlights(highlights: StoreHighlight[]): void {
    if (this.hasStandardLicense
      && this.state.store.banner?.uuid
      && this.state.bannerComparisonSalesVolume?.rankings?.length
      && this.state.bannerComparisonSalesSqft?.rankings?.length
      && this.state.bannerComparisonStoreCount?.rankings?.length) {
      const keyIndicator = this.userPreferences.primaryKeyIndicator;
      const keyIndicatorLabel = keyIndicator === KeyIndicatorType.SALES_SQFT ? Dictionary.labelSalesSqft : Dictionary.labelSalesVolume;

      const storeCountComparison = this.state.bannerComparisonStoreCount;

      let salesComparison: BannerComparison;
      if (keyIndicator === KeyIndicatorType.SALES_SQFT) {
        salesComparison = this.state.bannerComparisonSalesSqft;
      } else {
        salesComparison = this.state.bannerComparisonSalesVolume;
      }

      let salesRanking = salesComparison.findSubjectBannerRanking();

      const storeCountRanking = this.state.bannerComparisonStoreCount.findSubjectBannerRanking();

      if (salesRanking.percentile > 80) {
        const highlight = new StoreHighlight();
        highlight.title = 'Market-Leading Banner';
        highlight.description = `Banner ranks in Top 20% for ${keyIndicatorLabel} within Market Area`;
        highlight.rank = 21;
        highlight.isPositive = true;
        highlight.action = () => {
          this.openBannerComparisonDetailWizard(salesComparison);
        }

        highlights.push(highlight);
      } else if (salesRanking.percentile <= 20) {
        const highlight = new StoreHighlight();
        highlight.title = 'Market-Lagging Banner';
        highlight.description = `Banner ranks in Bottom 20% for ${keyIndicatorLabel} within Market Area`;
        highlight.rank = 22;
        highlight.isPositive = false;
        highlight.action = () => {
          this.openBannerComparisonDetailWizard(salesComparison);
        }

        highlights.push(highlight);
      }

      if (storeCountRanking.percentile > 80) {
        const highlight = new StoreHighlight();
        highlight.title = 'Market-Dominating Banner';
        highlight.description = `Banner ranks in Top 20% for Store Count within Market Area`;
        highlight.rank = 23;
        highlight.isPositive = true;
        highlight.action = () => {
          this.openBannerComparisonDetailWizard(storeCountComparison);
        }

        highlights.push(highlight);
      } else if (storeCountRanking.percentile <= 20) {
        const highlight = new StoreHighlight();
        highlight.title = 'Weak Banner Presence';
        highlight.description = `Banner ranks in Bottom 20% for Store Count within Market Area`;
        highlight.rank = 24;
        highlight.isPositive = false;
        highlight.action = () => {
          this.openBannerComparisonDetailWizard(storeCountComparison);
        }

        highlights.push(highlight);
      }
    }
  }

  private buildCompetitionHighlights(highlights: StoreHighlight[]): void {
    const competition = this.state.competition;
    const subjectStoreFeature: Feature = _.find(competition, (feature: Feature) => feature.getStore().uuid === this.state.store.uuid);

    if (competition?.length > 1) {
      const oneYearAgo = DateUtil.nDaysAgo(365);
      const recentlyOpened: Feature[] = _.filter(competition, (feature: Feature) => {
        return feature.getStore().uuid !== this.state.store.uuid
          && feature.getStore().actualOpenedDate
          && feature.getStore().actualOpenedDate >= oneYearAgo;
      });

      if (recentlyOpened.length) {
        const highlight = new StoreHighlight();
        highlight.title = 'Recent Competitive Incursions';
        highlight.description = `Sales may be significantly impacted by ${recentlyOpened.length} nearby competitor stores that have recently opened!`;
        highlight.rank = 8;
        highlight.isPositive = false;
        highlight.action = () => {
          const wizard = new CompetitiveIncursionsWizard();
          wizard.model = {
            description: 'These stores opened nearby in the last 12 months, and may be significantly impacting sales.',
            incursions: recentlyOpened,
            subjectStore: subjectStoreFeature
          };

          this.wizardRunner.run(wizard);
        }

        highlights.push(highlight);
      }
    }
  }

  private buildSalesHighlights(highlights: StoreHighlight[]): void {
    const MINIMUM_COUNT_THRESHOLD = 3;

    const keyIndicator = this.userPreferences.primaryKeyIndicator;
    const keyIndicatorLabel = keyIndicator === KeyIndicatorType.SALES_SQFT ? Dictionary.labelSalesSqft : Dictionary.labelSalesVolume;

    const allInLocalArea = _.find(this.state.defaultComparisons, (comparison: StoreComparison) => comparison.keyIndicator === keyIndicator && comparison.comparisonType === ComparisonType.ALL && comparison.region === RegionType.DRIVE_TIME);
    const allInMarketArea = _.find(this.state.defaultComparisons, (comparison: StoreComparison) => comparison.keyIndicator === keyIndicator && comparison.comparisonType === ComparisonType.ALL && comparison.region === RegionType.MARKET_AREA);
    const bannerInMarketArea = _.find(this.state.defaultComparisons, (comparison: StoreComparison) => comparison.keyIndicator === keyIndicator && comparison.comparisonType === ComparisonType.BANNER && comparison.region === RegionType.MARKET_AREA);

    const allInLocalAreaRanking = allInLocalArea.getRankingForStore(this.state.store.uuid);
    const allInMarketAreaRanking = allInMarketArea.getRankingForStore(this.state.store.uuid);
    const bannerInMarketAreaRanking = bannerInMarketArea.getRankingForStore(this.state.store.uuid);

    if (allInMarketArea.getFilteredRankings().length >= MINIMUM_COUNT_THRESHOLD) {
      if (allInMarketAreaRanking?.percentile > 80) {
        const highlight = new StoreHighlight();
        highlight.title = 'Leading Market Sales';
        highlight.description = `Ranks in Top 20% for ${keyIndicatorLabel} within Market Area`;
        highlight.rank = 10;
        highlight.isPositive = true;
        highlight.action = () => {
          const wizard = new CompetitiveAnalysisDetailsWizard()
          wizard.model = {
            comparison: allInMarketArea,
            isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
            marketArea: this.state.marketArea
          };

          this.wizardRunner.run(wizard);
        }

        highlights.push(highlight);
      } else if (allInMarketAreaRanking?.percentile <= 20) {
        const highlight = new StoreHighlight();
        highlight.title = 'Lagging Market Sales';
        highlight.description = `Ranks in Bottom 20% for ${keyIndicatorLabel} within Market Area`;
        highlight.rank = 11;
        highlight.isPositive = false;
        highlight.action = () => {
          const wizard = new CompetitiveAnalysisDetailsWizard()
          wizard.model = {
            comparison: allInMarketArea,
            isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
            marketArea: this.state.marketArea
          };

          this.wizardRunner.run(wizard);
        }

        highlights.push(highlight);
      }
    }

    if (bannerInMarketArea.getFilteredRankings().length >= MINIMUM_COUNT_THRESHOLD) {
      if (bannerInMarketAreaRanking?.percentile > 80) {
        const highlight = new StoreHighlight();
        highlight.title = 'Leading Banner Sales';
        highlight.description = `Ranks in Top 20% for ${keyIndicatorLabel} for Banner within Market Area`;
        highlight.rank = 12;
        highlight.isPositive = true;
        highlight.action = () => {
          const wizard = new CompetitiveAnalysisDetailsWizard()
          wizard.model = {
            comparison: bannerInMarketArea,
            isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
            marketArea: this.state.marketArea
          };

          this.wizardRunner.run(wizard);
        }

        highlights.push(highlight);
      } else if (bannerInMarketAreaRanking?.percentile <= 20) {
        const highlight = new StoreHighlight();
        highlight.title = 'Lagging Banner Sales';
        highlight.description = `Ranks in Bottom 20% for ${keyIndicatorLabel} for Banner within Market Area`;
        highlight.rank = 13;
        highlight.isPositive = false;
        highlight.action = () => {
          const wizard = new CompetitiveAnalysisDetailsWizard()
          wizard.model = {
            comparison: bannerInMarketArea,
            isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
            marketArea: this.state.marketArea
          };

          this.wizardRunner.run(wizard);
        }

        highlights.push(highlight);
      }
    }

    if (allInLocalArea.getFilteredRankings().length >= MINIMUM_COUNT_THRESHOLD) {
      if (allInLocalAreaRanking?.percentile > 80) {
        const highlight = new StoreHighlight();
        highlight.title = 'Leading Local Sales';
        highlight.description = `Ranks in Top 20% for ${keyIndicatorLabel} within a 7-Minute Drive Time`;
        highlight.rank = 14;
        highlight.isPositive = true;
        highlight.action = () => {
          const wizard = new CompetitiveAnalysisDetailsWizard()
          wizard.model = {
            comparison: allInLocalArea,
            isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
            marketArea: this.state.marketArea
          };

          this.wizardRunner.run(wizard);
        }

        highlights.push(highlight);
      } else if (allInLocalAreaRanking?.percentile <= 20) {
        const highlight = new StoreHighlight();
        highlight.title = 'Lagging Local Sales';
        highlight.description = `Ranks in Bottom 20% for ${keyIndicatorLabel} within 7-Minute Drive Time`;
        highlight.rank = 15;
        highlight.isPositive = false;
        highlight.action = () => {
          const wizard = new CompetitiveAnalysisDetailsWizard()
          wizard.model = {
            comparison: allInLocalArea,
            isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
            marketArea: this.state.marketArea
          };

          this.wizardRunner.run(wizard);
        }

        highlights.push(highlight);
      }
    } else if (allInLocalArea.rankings.length === 1) {
      const highlight = new StoreHighlight();
      highlight.title = 'Unchallenged in Local Area';
      highlight.description = `No significant competition within 7-Minute Drive Time`;
      highlight.rank = 16;
      highlight.isPositive = true;
      highlight.action = () => {
        const wizard = new CompetitiveAnalysisDetailsWizard()
        wizard.model = {
          comparison: allInLocalArea,
          isRestrictedLicenseOverlayEnabled: !this.hasStandardLicense,
          marketArea: this.state.marketArea
        };

        this.wizardRunner.run(wizard);
      }

      highlights.push(highlight);
    }
  }

  private initDummyHighlights(): void {
    const highlights: StoreHighlight[] = [];

    const highlight = new StoreHighlight();
    highlight.title = 'Recent Competitive Incursions';
    highlight.description = `Sales may be significantly impacted by 3 nearby competitor stores that have recently opened!`;
    highlight.rank = 8;
    highlight.isPositive = false;
    highlights.push(highlight);

    const highlight1 = new StoreHighlight();
    highlight1.title = 'Leading Local Sales';
    highlight1.description = `Ranks in Top 20% for Est. Sales/sqft within a 7-Minute Drive Time`;
    highlight1.rank = 14;
    highlight1.isPositive = true;
    highlights.push(highlight1);

    const highlight2 = new StoreHighlight();
    highlight2.title = 'Good Exterior Ratings';
    highlight2.description = `Per MTN Analysts as of 01/01/2021`;
    highlight2.rank = 54;
    highlight2.isPositive = true;
    highlights.push(highlight2);

    const highlight3 = new StoreHighlight();
    highlight3.title = 'Weak Banner Presence';
    highlight3.description = `Banner ranks in Bottom 20% for Store Count within Market Area`;
    highlight3.rank = 24;
    highlight3.isPositive = false;
    highlights.push(highlight3);

    this.highlights = _.orderBy(highlights, 'rank', 'asc');
    this.isLoading = false;
  }

  private initHighlights(): void {
    const highlights: StoreHighlight[] = [];

    this.buildCompetitionHighlights(highlights);
    this.buildSalesHighlights(highlights);
    this.buildBannerSalesHighlights(highlights);
    this.buildAnalystRatingHighlights(highlights);

    this.highlights = _.orderBy(highlights, 'rank', 'asc');
    this.isLoading = false;
  }

  private loadStore(): void {
    if (this.hasStandardLicense) {
      this.addSubscription(
        selectDetailState(this.ngrxStore)
          .pipe(switchMap((state: DetailState) => {
            if (!state.store.isActive()) {
              return of(null);
            } else {
              return selectDetailState(this.ngrxStore)
                .pipe(
                  filter((state: DetailState) => !!state.store
                    && (!state.store.banner || (!state.marketArea?.features.length || (state.bannerComparisonStoreCount && state.bannerComparisonSalesSqft && state.bannerComparisonSalesVolume)))
                    && state.defaultComparisons?.length === 6
                    && !!state.analystRating
                    && !!state.competition),
                  debounceTime(300),
                  take(1))
            }
          }))
          .subscribe((state: DetailState) => {
            if (state) {
              this.state = state;
              this.initHighlights();
            } else {
              this.isLoading = false;
            }
          })
      );
    } else {
      this.initDummyHighlights();
    }
  }

  private openRatingDetailsWizard(): void {
    const wizard = new StoreAnalystRatingWizard();
    wizard.model = {
      rating: this.state.analystRating
    };

    this.wizardRunner.run(wizard);
  }

  private openBannerComparisonDetailWizard(comparison: BannerComparison): void {
    const wizard = new BannerComparisonWizard();
    wizard.model = {
      licenseType: this.licenseType,
      store: this.state.store,
      comparison: comparison,
      userPreferences: this.userPreferences
    };

    this.wizardRunner.run(wizard);
  }
}

import { Wizard } from '../../wizard/wizard';
import { UserProfile } from '../../user-profile/user-profile';
import { CreateUserPageComponent } from './create-user-page/create-user-page.component';

export class CreateUserWizard extends Wizard<CreateUserWizardModel> {
  key = 'create-user';
  pageTypeMap = {
    'create-user': CreateUserPageComponent
  };
  startingPageKey = 'create-user';

  constructor() {
    super();
    this.width = 450;
    this.model = {};
  }
}

export interface CreateUserWizardModel {
  user?: UserProfile;
}

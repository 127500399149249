import { Banner } from '../models';
import { Rankable } from '../store/ranking/rankable';
import { convertToNth } from '../util/string-utils';
import { round } from '../util/math-utils';

export class BannerRanking implements Rankable {
  banner: Banner;
  percentile: number;
  rank: number;
  totalCount: number;
  value: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.banner) {
        this.banner = new Banner(raw.banner);
      }
    }
  }

  getFormattedPercentileString(): string {
    return `${convertToNth(this.percentile)} Percentile`;
  }

  getValueAsPercentage(): string {
    const prefix = this.value > 0 ? '+' : '';
    return `${prefix}${round(this.value)}%`;
  }

}

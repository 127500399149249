import { Wizard } from '../../../../core/wizard/wizard';
import { Store } from '../../../../core/models';
import { HistoricalStoresPageComponent } from './historical-stores-page/historical-stores-page.component';

export class HistoricalStoresWizard extends Wizard<HistoricalStoresWizardModel> {
  key = 'historical-stores';
  pageTypeMap = {
    'historical-stores': HistoricalStoresPageComponent
  };
  startingPageKey = 'historical-stores';
}

export interface HistoricalStoresWizardModel {
  otherStores: Store[];
}

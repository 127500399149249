export const environment = {
  envName: 'test',
  defaultLandingPage: 'map-search',
  googleAnalyticsMeasurementId: 'G-3NJ71KTWRM',
  production: false,
  SERVICE_HOST: 'https://api-test.mtnra.com',
  AUTH_CONFIG: {
    clientID: 'UTgiibU2z7Honoinje5bVRbhUwyneFN4',
    domain: 'mtnra.auth0.com',
    callbackURL: 'https://insights-test.mtnra.com/authenticating'
  },
  VERSION: require('../../package.json').version
};

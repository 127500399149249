import { Wizard } from '../../../../../../core/wizard/wizard';
import { Store } from '../../../../../../core/models';
import { StoreDetailsPageComponent } from './store-details-page/store-details-page.component';
import { LicenseType } from '../../../../../../auth/authorization/license-type.enum';

export class StoreDetailsWizard extends Wizard<StoreDetailsWizardModel> {
  key = 'store-details';
  pageTypeMap = {
    'store-details': StoreDetailsPageComponent
  };
  startingPageKey = 'store-details';
}

export interface StoreDetailsWizardModel {
  licenseType: LicenseType;
  store: Store;
}

<div #panelElement class="store-header-panel-container" fxLayout="row" fxLayoutGap="5px"
     [ngClass]="{extended: isNavigationCollapsed}">
  <mtn-accordion-subpanel #storePanel [store]="store" [breakpoint]="breakpoint" mode="store"
                          [fxFlex]="storePanel.isExpanded || 'auto'" fxFlex.gt-md
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'store-details-header-store-button'}"
                          (onClick)="handlePanelClick($event)"></mtn-accordion-subpanel>
  <mtn-accordion-subpanel #spacePanel [store]="store" [breakpoint]="breakpoint" mode="space"
                          [fxFlex]="spacePanel.isExpanded || 'auto'" fxFlex.gt-md
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'store-details-header-space-button'}"
                          (onClick)="handlePanelClick($event)"></mtn-accordion-subpanel>
  <mtn-accordion-subpanel #shoppingCenterPanel [store]="store" [breakpoint]="breakpoint" mode="shopping-center"
                          [fxFlex]="shoppingCenterPanel.isExpanded || 'auto'" fxFlex.gt-md
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                          [analyticsEventParameters]="{'target': 'store-details-header-shopping-center-button'}"
                          (onClick)="handlePanelClick($event)"></mtn-accordion-subpanel>
  <div class="upper-scroll-cover"></div>
  <div class="lower-fader"></div>
</div>

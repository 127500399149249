import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-key-value-with-placeholder',
  templateUrl: './key-value-with-placeholder.component.html',
  styleUrls: ['./key-value-with-placeholder.component.scss']
})
export class KeyValueWithPlaceholderComponent {

  @Input()
  alignment: string = 'start start';
  @Input()
  key: string;
  @Input()
  keyWidthPercent: number = 40;
  @Input()
  value: any;
  @Input()
  whatIsThisText: string;

  isUndefined: boolean = false;

  constructor() {
  }

  ngOnChanges() {
    this.isUndefined = typeof this.value === 'undefined' || this.value === null || _.trim(this.value) === '';
  }

}

import { createAction, props } from '@ngrx/store';
import { MtnAccessToken } from './authentication/mtn-access-token';
import { UserProfile } from '../core/user-profile/user-profile';
import { Company } from '../core/company/company';
import { UserPreferences } from '../core/user-preferences/user-preferences';
import { Collection } from '../core/federation/collection/collection';

export const UserSetCollections = createAction('user-set-collections', props<{ collections: Collection[] }>());
export const UserSetCurrentCompany = createAction('user-set-current-company', props<{ company: Company }>());
export const UserSetCurrentUser = createAction('user-set-current-user', props<{ currentUser: UserProfile }>());
export const UserSetMtnAuthToken = createAction('user-set-mtn-auth-token', props<{ token: MtnAccessToken }>());
export const UserSetRedirectUrl = createAction('user-set-redirect-url', props<{ url: string }>());
export const UserSetLastActivityDate = createAction('user-set-last-activity-date', props<{ lastActivityDate: Date }>());
export const UserSetUserPreferences = createAction('user-set-user-preferences', props<{ preferences: UserPreferences }>());

import { Wizard } from '../../../wizard/wizard';
import { Company } from '../../company';
import { AddOn } from '../../add-on';
import { ToggleAddOnPageComponent } from './toggle-add-on-page/toggle-add-on-page.component';
import { AddOnType } from '../../add-on-type.enum';

export class ToggleAddOnWizard extends Wizard<ToggleAddOnWizardModel> {
  key = 'toggle-add-on';
  pageTypeMap = {
    'toggle-add-on': ToggleAddOnPageComponent
  };
  startingPageKey = 'toggle-add-on';
}

export interface ToggleAddOnWizardModel {
  addOn: AddOn;
  company: Company;
  companyAddOns: AddOnType[];
  isCompleted?: boolean;
}

<div class="my-company-widget container" fxLayout="row" fxLayoutAlign="start center">
  <div class="condensed-view" *ngIf="company && condensedMode" fxLayout="row" fxLayoutAlign="center center">
    <mtn-company-initials-avatar class="clickable" [company]="company" [matTooltip]="MENU_TOOLTIP"
                                 matTooltipPosition="right" [routerLink]="[ROUTE]"
                                 matRipple></mtn-company-initials-avatar>
  </div>
  <div class="company-info-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
       *ngIf="company && !condensedMode">
    <mtn-company-initials-avatar [company]="company"></mtn-company-initials-avatar>
    <div fxLayout="column" fxFlex *ngIf="!condensedMode">
      <div class="company-name text-ellipsis"><strong>{{company.name}}</strong></div>
      <small class="color-muted">{{company.uniqueIdentifier}}</small>
    </div>
    <button mat-icon-button [matTooltip]="MENU_TOOLTIP" *ngIf="!condensedMode" matTooltipPosition="right"
            [routerLink]="[ROUTE]">
      <fa-icon icon="cog"></fa-icon>
    </button>
  </div>
  <mtn-spinner [on]="!company"></mtn-spinner>
</div>

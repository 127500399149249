<div class="sales-comparison-widget-container container default-padding mat-elevation-z1 clickable"
     fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" matRipple (click)="openComparisonDetailWizard()">
  <div class="canvas-container container" [matTooltip]="tooltip">
    <canvas height="50px" width="50px" mdbChart chartType="doughnut" [legend]="false" [colors]="chartColors"
            [datasets]="chartDataSets" [options]="chartOptions" [labels]="chartLabels" *ngIf="ranking"></canvas>
    <div class="percentile-container" *ngIf="ranking">
      <span [ngStyle]="{color: color}">{{ranking.percentile}}</span>
    </div>
  </div>
  <div fxLayout="column" *ngIf="ranking">
    <small class="color-muted">{{prefix}}</small>
    <strong>{{rankingDescriptor}} {{rankingGroupDescriptor}} {{rankingTypeDescriptor}}</strong>
    <small class="color-muted">{{comparison.getTitle()}}</small>
  </div>
  <div class="comparison-label" fxLayoutAlign="center center" *ngIf="!isLoading && !ranking">
    <span class="color-muted text-center">{{Dictionary.labelStoreMissingRequiredData}}</span>
  </div>
  <mtn-spinner [size]="SpinnerSize.MEDIUM" [on]="isLoading"></mtn-spinner>
</div>



import { Component, Input, OnInit } from '@angular/core';
import { UserPreferences } from '../../../../core/user-preferences/user-preferences';
import { BaseComponent } from '../../../../core/base-component';
import { UserProfileService } from '../../../../core/user-profile/user-profile.service';
import { finalize } from 'rxjs/operators';
import { KeyIndicatorType } from '../../../../core/user-preferences/key-indicator-type.enum';
import { MapMode } from '../../../../map/map-mode.enum';
import { SalesSqftDisplayType } from '../../../../core/user-preferences/sales-sqft-display-type.enum';
import { SalesVolumeDisplayType } from '../../../../core/user-preferences/sales-volume-display-type.enum';
import MapTypeId = google.maps.MapTypeId;

@Component({
  selector: 'mtn-user-preferences-view',
  templateUrl: './user-preferences-view.component.html',
  styleUrls: ['./user-preferences-view.component.scss']
})
export class UserPreferencesViewComponent extends BaseComponent implements OnInit {

  @Input()
  userProfileUuid: string;

  isLoading = false;
  KeyIndicatorType = KeyIndicatorType;
  MapMode = MapMode;
  MapTypeId = MapTypeId;
  SalesSqftDisplayModeType = SalesSqftDisplayType;
  SalesVolumeDisplayModeType = SalesVolumeDisplayType;
  userPreferences: UserPreferences;

  constructor(private userService: UserProfileService) {
    super();
  }

  ngOnInit(): void {
    this.loadPreferences();
  }

  private loadPreferences(): void {
    this.isLoading = true;
    this.userService.findOneUserPreferences(this.userProfileUuid)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((result: UserPreferences) => {
        this.userPreferences = result;
      });
  }

}

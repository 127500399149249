import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../auth/authentication/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { UserProfile } from '../../../core/user-profile/user-profile';
import { BaseComponent } from '../../../core/base-component';
import { selectUserState, UserState } from '../../../auth/user-state';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { AboutWizard } from './about-wizard/about-wizard';

@Component({
  selector: 'mtn-current-user-chip-and-menu',
  templateUrl: './current-user-chip-and-menu.component.html',
  styleUrls: ['./current-user-chip-and-menu.component.scss']
})
export class CurrentUserChipAndMenuComponent extends BaseComponent implements OnInit {

  currentUser: UserProfile;

  constructor(private authenticationService: AuthenticationService,
              private store: Store<AppState>,
              private wizardRunner: WizardRunnerService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAuthState();
  }

  logOut(): void {
    this.authenticationService.logOut();
  }

  openAboutWizard(): void {
    this.wizardRunner.run(new AboutWizard());
  }

  private subscribeToAuthState(): void {
    this.addSubscription(
      selectUserState(this.store)
        .subscribe((state: UserState) => {
          this.currentUser = state.currentUser;
        })
    );
  }

}

<div class="store-additional-insights-panel-container container" fxLayout="column" fxLayoutGap="30px">
  <h1 class="mat-h1 color-primary text-small-caps no-bottom-margin">Additional Insights</h1>
  <hr/>
  <div fxLayout="row wrap" fxLayoutGap="15px">
    <mtn-store-services-card [services]="services"
                             [isRestrictedLicenseOverlayEnabled]="!hasStandardLicense"></mtn-store-services-card>
    <mtn-analyst-ratings-card [ratings]="ratings"
                              [isRestrictedLicenseOverlayEnabled]="!hasStandardLicense"></mtn-analyst-ratings-card>
    <mtn-simple-alert title="Need More Insight?" color="blue" text="Get personalized help from our Expert Analysts"
                      mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_BUTTON"
                      [analyticsEventParameters]="{'target': 'store-details-additional-insights-request-more-insight-button'}"
                      (onClick)="openRequestMoreInsightWizard()"></mtn-simple-alert>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'mtn-map-toolbar-button',
  templateUrl: './map-toolbar-button.component.html',
  styleUrls: ['./map-toolbar-button.component.scss']
})
export class MapToolbarButtonComponent {

  @Output()
  onClick = new EventEmitter<any>();

  @Input()
  badgeCount: number;
  @Input()
  color: 'grey' | 'green' | 'blue' | 'red' = 'grey';
  @Input()
  disabled = false;
  @Input()
  icon: IconProp;
  @Input()
  iconColor: 'default' | 'green' | 'red' = 'default';
  @Input()
  showEllipsis = false;

  constructor() {
  }

  handleClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }

}

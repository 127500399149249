import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';
import { MtnCoreModule } from '../core/mtn-core.module';
import { MtnRoutingModule } from '../core/modules/mtn-routing.module';
import { CurrentUserChipAndMenuComponent } from './header-toolbar/current-user-chip-and-menu/current-user-chip-and-menu.component';
import { NavigationSidebarComponent } from './navigation-sidebar/navigation-sidebar.component';
import { MyCompanyWidgetComponent } from './navigation-sidebar/my-company-widget/my-company-widget.component';
import { NavigationButtonComponent } from './navigation-sidebar/navigation-button/navigation-button.component';
import { StoreModule } from '@ngrx/store';
import { NAVIGATION_STATE_KEY, navigationReducer } from './navigation-state';
import { AboutWizardPageComponent } from './header-toolbar/current-user-chip-and-menu/about-wizard/about-wizard-page/about-wizard-page.component';
import { LicenseSuspendedPageComponent } from './header-toolbar/license-suspended-wizard/license-suspended-page/license-suspended-page.component';
import { LicenseExpiredPageComponent } from './header-toolbar/license-expired-wizard/license-expired-page/license-expired-page.component';


@NgModule({
  declarations: [
    HeaderToolbarComponent,
    CurrentUserChipAndMenuComponent,
    NavigationSidebarComponent,
    MyCompanyWidgetComponent,
    NavigationButtonComponent,
    AboutWizardPageComponent,
    LicenseSuspendedPageComponent,
    LicenseExpiredPageComponent
  ],
  exports: [
    HeaderToolbarComponent,
    NavigationSidebarComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(NAVIGATION_STATE_KEY, navigationReducer),
    MtnCoreModule,
    MtnRoutingModule
  ]
})
export class MtnNavigationModule {
}

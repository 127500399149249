<div class="map-controls-toolbar container" fxLayout="column" fxLayoutGap="10px"
     *ngIf="isFeatureMap || map?.options.staticFeatures?.length || isMapTypeControlsEnabled">
  <mtn-map-controls-legend-assembly *ngIf="isFeatureMap"></mtn-map-controls-legend-assembly>

  <mtn-map-toolbar-button icon="satellite" [color]="map.getMapTypeId() === MapTypeId.HYBRID ? 'green' : 'grey'"
                          [iconColor]="map.getMapTypeId() === MapTypeId.HYBRID ? 'green' : 'default'"
                          matTooltip="Toggle Satellite View" matTooltipPosition="right"
                          (onClick)="toggleSatelliteView()"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MAP_CONTROL"
                          [analyticsEventParameters]="{'target': 'map-type'}"
                          *ngIf="map?.options.controlConfiguration.isMapTypeEnabled"></mtn-map-toolbar-button>

  <mtn-map-toolbar-button [icon]="['far', 'moon']"
                          [color]="map?.options.mode === MapMode.DARK && map?.options.mapTypeId === MapTypeId.ROADMAP ? 'green' : 'grey'"
                          [iconColor]="map?.options.mode === MapMode.DARK && map?.options.mapTypeId === MapTypeId.ROADMAP ? 'green' : 'default'"
                          matTooltip="Toggle Dark Mode" matTooltipPosition="right" (onClick)="toggleDarkMode()"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.TOGGLE_MAP_CONTROL"
                          [analyticsEventParameters]="{'target': 'map-mode'}"
                          [disabled]="map?.getMapTypeId() === MapTypeId.HYBRID"
                          *ngIf="map?.options.controlConfiguration.isMapModeEnabled"></mtn-map-toolbar-button>

  <mtn-map-selection-tools-assembly [map]="map" *ngIf="isFeatureMap"></mtn-map-selection-tools-assembly>

  <mtn-map-toolbar-button icon="location" matTooltip="Center on Selection" matTooltipPosition="right"
                          (onClick)="centerOnSelection()"
                          mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.RECENTER_MAP"
                          [analyticsEventParameters]="{'target': map.options.key}" *ngIf="isFeatureMap"
                          [disabled]="!map.selections?.size"></mtn-map-toolbar-button>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../core/base-component';
import { MtnMap } from '../../mtn-map';
import { RingMilesFieldComponent } from '../../../core/form-controls/controls/ring-miles-field/ring-miles-field.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MapEvent, MapEventType } from '../../map-events';
import { MapSelectionMode } from '../../map-selection-mode.enum';
import { AnalyticsEventType } from '../../../google-analytics/analytics-event-type';
import Timeout = NodeJS.Timeout;
import Marker = google.maps.Marker;

@Component({
  selector: 'mtn-map-selection-tools-assembly',
  templateUrl: './map-selection-tools-assembly.component.html',
  styleUrls: ['./map-selection-tools-assembly.component.scss']
})
export class MapSelectionToolsAssemblyComponent extends BaseComponent implements OnInit {

  @Input()
  map: MtnMap;

  @ViewChild("radiusControl")
  radiusControl: RingMilesFieldComponent;

  AnalyticsEventType = AnalyticsEventType;
  isHidden = true;
  isHiding = false;
  isShowing = false;
  isShown = false;
  MapSelectionMode = MapSelectionMode;
  ringForm = new UntypedFormGroup({
    ringMiles: new UntypedFormControl(3, [Validators.min(1), Validators.max(100), Validators.required])
  });

  private _activeTimeout: Timeout;

  constructor() {
    super();
  }

  ngOnInit() {
    this.subscribeToMapEvents();
  }

  getSelectionIcon(): IconProp {
    switch (this.map.selectionMode) {
      case MapSelectionMode.LASSO:
        return 'lasso';
      case MapSelectionMode.MULTI_SELECT:
        return 'hand-pointer';
      case MapSelectionMode.RING:
        return ['far', 'circle'];
      default:
        return 'mouse-pointer';
    }
  }

  selectAll(): void {
    this.map.selectAll(this.map.markers.map((marker: Marker) => marker.get('id')));
    this.map.setSelectionMode(MapSelectionMode.MULTI_SELECT);
    this.closeSlide();
  }

  setSelectionMode(mode: MapSelectionMode): void {
    this.map.setSelectionMode(mode, this.ringForm.get('ringMiles'));
    if (mode === MapSelectionMode.RING) {
      setTimeout(() => this.radiusControl.radiusInput.nativeElement.focus(), 100);
    } else {
      this.closeSlide();

      if (!mode) {
        this.map.select(null);
      }
    }
  }

  toggleSelectionTools(): void {
    this.map.disableDrawing();
    this.toggleSlide();
  }

  closeSlide(): void {
    this.stopActiveTransition();

    this.isShown = false;
    this.isHiding = true;

    this._activeTimeout = setTimeout(() => {
      this.isHiding = false;
      this.isHidden = true;
    }, 600);
  }

  openSlide(): void {
    this.stopActiveTransition();

    this.isHidden = false;
    this.isShowing = true;

    this._activeTimeout = setTimeout(() => {
      this.isShowing = false;
      this.isShown = true;
    }, 100);
  }

  toggleSlide(): void {
    if (this.isShowing || this.isShown) {
      this.closeSlide();
    } else {
      this.openSlide();
    }
  }

  private stopActiveTransition(): void {
    if (this._activeTimeout) {
      clearTimeout(this._activeTimeout);
      this._activeTimeout = null;
    }
  }

  private subscribeToMapEvents(): void {
    this.addSubscription(
      this.map.events
        .subscribe((event: MapEvent<any>) => {
          if ((this.isShowing || this.isShown) && event.type === MapEventType.CLICK) {
            this.closeSlide();
          }
        })
    );
  }

}

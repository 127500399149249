import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../wizard/wizard-page-component';
import { ToggleAddOnWizard } from '../toggle-add-on-wizard';
import { Observable } from 'rxjs';
import { CompanyService } from '../../../company.service';
import { ToastService } from '../../../../toast/toast.service';
import { catchError, map } from 'rxjs/operators';
import { GENERIC_ERROR_MESSAGE } from '../../../../util/string-utils';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-toggle-add-on-page',
  templateUrl: './toggle-add-on-page.component.html',
  styleUrls: ['./toggle-add-on-page.component.scss']
})
export class ToggleAddOnPageComponent extends WizardPageComponent<ToggleAddOnWizard> {

  key = 'toggle-add-on';

  isEnabling = false;

  constructor(private companyService: CompanyService,
              private toaster: ToastService) {
    super();
    this.nextButtonText = 'Yes';
    this.closeButtonText = 'No';
  }

  onLoad(): Observable<any> {
    this.isEnabling = !_.includes(this.wizard.model.companyAddOns, this.wizard.model.addOn.type);

    const titlePrefix = this.isEnabling ? 'Enable' : 'Disable';
    this.title = `${titlePrefix} ${this.wizard.model.addOn.typeDefinition.displayName} Add-On`;

    return super.onLoad();
  }

  onNext(): Observable<string> {
    const task: Observable<any> = this.isEnabling
      ? this.companyService.addOneAddOn(this.wizard.model.company.uuid, this.wizard.model.addOn.uuid)
      : this.companyService.removeOneAddOn(this.wizard.model.company.uuid, this.wizard.model.addOn.uuid);

    return task
      .pipe(
        catchError((err) => {
          this.toaster.error(GENERIC_ERROR_MESSAGE);
          throw err;
        }),
        map(() => {
          this.toaster.info(`Successfully ${this.isEnabling ? 'enabled' : 'disabled'} the ${this.wizard.model.addOn.typeDefinition.displayName} Add-On`);
          this.wizard.model.isCompleted = true;
          return null;
        })
      );
  }

}

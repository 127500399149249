import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { MtnCoreModule } from '../core/mtn-core.module';
import { MtnMdBootstrapModule } from '../core/modules/mtn-mdbootstrap.module';


@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    MtnCoreModule,
    MtnMdBootstrapModule
  ]
})
export class MtnDashboardModule {
}

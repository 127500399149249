import { AuditableEntity } from './auditable-entity';
import { ShoppingCenterType } from './identity/constant/shopping-center-type.enum';
import { IntersectionType } from './identity/constant/intersection-type.enum';
import { QuadrantType } from './identity/constant/quadrant-type.enum';
import { LocationType } from './identity/constant/location-type.enum';
import { Definition } from './definition/definition';
import {
  ActiveStoreStatuses,
  FutureStoreStatuses,
  HistoricalStoreStatuses,
  StoreStatusType
} from './identity/constant/store-status-type.enum';
import { DateUtil } from './util/date-util';
import * as _ from 'lodash';
import { Geometry, Point } from 'geojson';
import { LogoUtil } from './util/logo-util';
import { FitType } from './store/fit/fit-type.enum';
import { FormatType } from './store/format/format-type.enum';
import { StoreVolume } from './store/volume/store-volume';
import LatLngLiteral = google.maps.LatLngLiteral;

/**
 * These models combined here in this file only in order to avoid circular dependencies...
 */

export class Banner extends AuditableEntity {

  name: string;
  company: Company;
  logoCloudinaryFilename: string;
  markerCloudinaryFilename: string;
  parentCompany: Company;

  companies: Company[];

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);
      this.companies = [];

      if (raw.companies) {
        raw.companies.forEach((rawCompany: any) => this.companies.push(new Company(rawCompany)));
      }
    }
  }
}

export class Company extends AuditableEntity {

  logoCloudinaryFilename: string;
  name: string;
  parentCompany: Company;
  websiteUrl: string;

  banners: Banner[];

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);
      this.banners = [];

      if (raw.parentCompany != null) {
        this.parentCompany = new Company(raw.parentCompany);
      }
      if (raw.banners != null) {
        this.banners = raw.banners.map((banner: any) => new Banner(banner));
      }
    }
  }
}

export class LatLng {
  lat: number;
  lng: number;

  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }
}

export class Location extends AuditableEntity {

  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  county: string;
  intersectionStreet1: string;
  intersectionStreet2: string;
  intersectionType: IntersectionType;
  point: FeatureCollection;
  postalCode: string;
  quadrant: QuadrantType;
  state: string;
  type: LocationType;

  getFormattedIntersection(): string {
    let intersection = '';

    if (this.quadrant && this.quadrant !== QuadrantType.NONE) {
      intersection += this.quadrant;
      if (this.intersectionStreet1 || this.intersectionStreet2) {
        intersection += ' of ';
      }
    }
    if (this.intersectionStreet1) {
      intersection += this.intersectionStreet1;
      if (this.intersectionStreet2) {
        intersection += ' & ';
      }
    }
    if (this.intersectionStreet2) {
      intersection += this.intersectionStreet2;
    }
    return intersection;
  }

  getFormattedPrincipality(): string {
    let principality = '';

    if (this.city) {
      principality += this.city;
      if (this.state) {
        principality += ', ';
      }
    }
    if (this.state) {
      principality += this.state;
    }
    if (this.postalCode) {
      if (principality.length > 0) {
        principality += ' ';
      }
      principality += this.postalCode;
    }
    return principality;
  }

  getPointAsLatLng(): LatLng {
    const point = this.getPoint();
    return point ? new LatLng(this.getPointLatitude(), this.getPointLongitude()) : null;
  }

  getPointAsString(): string {
    return `${this.getPointLatitude()},${this.getPointLatitude()}`;
  }

  getPointLatitude(): number {
    const point = this.getPoint();
    return point ? point.coordinates[1] : null;
  }

  getPointLongitude(): number {
    const point = this.getPoint();
    return point ? point.coordinates[0] : null;
  }

  getPoint(): Point {
    if (this.point && this.point.features && this.point.features.length) {
      return this.point.features[0].getGeometryAsPoint();
    } else {
      this.point = new FeatureCollection();
      this.point.features.push(new Feature());
      return null;
    }
  }

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);

      if (raw.intersectionType) {
        // @ts-ignore
        this.intersectionType = IntersectionType[<string>raw.intersectionType];
      }
      if (raw.point) {
        this.point = new FeatureCollection(raw.point);
      }
      if (raw.quadrant) {
        // @ts-ignore
        this.quadrant = QuadrantType[<string>raw.quadrant];
      }
      if (raw.type) {
        // @ts-ignore
        this.type = LocationType[<string>raw.type];
      }
    }
  }
}

export class ShoppingCenter extends AuditableEntity {

  grossLeasableArea: number;
  location: Location;
  name: string;
  ownerCompany: Company;
  type: ShoppingCenterType;
  websiteUrl: string;

  spaces: Space[];

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);
      this.spaces = [];

      if (raw.location) {
        this.location = new Location(raw.location);
      }
      if (raw.spaces) {
        this.spaces = raw.spaces.map((space: any) => new Space(space));
      }
      if (raw.type) {
        // @ts-ignore
        this.type = ShoppingCenterType[<string>raw.type];
      }
      if (raw.ownerCompany) {
        this.ownerCompany = new Company(raw.ownerCompany);
      }
    }
  }
}

export class Space extends AuditableEntity {

  location: Location;
  positionInCenter: string;
  shoppingCenter: ShoppingCenter;

  stores: Store[];

  hasIntersection(): boolean {
    return this.location && !!this.location.intersectionStreet1 && !!this.location.intersectionStreet2;
  }

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);
      this.stores = [];

      if (raw.location) {
        this.location = new Location(raw.location);
      }
      if (raw.shoppingCenter) {
        this.shoppingCenter = new ShoppingCenter(raw.shoppingCenter);
      }
      if (raw.stores) {
        raw.stores.forEach((store: any) => this.stores.push(new Store(store)));
      }
    }
  }
}

export class Store extends AuditableEntity {

  actualClosedDate: Date;
  actualOpenedDate: Date;
  banner: Banner;
  currentStatus: StoreStatus;
  fit: FitType;
  format: FormatType;
  isOpen24Hours: boolean = false;
  name: string;
  number: string;
  ownerCompany: Company;
  grocerySalesArea: number;
  space: Space;
  totalArea: number;
  volume: StoreVolume; //Deprecated and no longer populated by the back-end. Some lingering front-end usages, though...

  statuses: StoreStatus[] = [];

  getCurrentStatus(): StoreStatus {
    let currentStatus: StoreStatus;

    if (this.currentStatus) {
      currentStatus = this.currentStatus;
    } else {
      currentStatus = _.orderBy(_.filter(this.statuses, (status: StoreStatus) => {
        return status.statusDate < new Date();
      }), 'statusDate', 'desc')[0];
    }

    return currentStatus;
  }

  getNameAndNumber(): string {
    if (this.number) {
      return `${this.name} (${this.number})`;
    } else {
      return this.name;
    }
  }

  getLogoUrl(): string {
    return (this.banner && this.banner.logoCloudinaryFilename) ? LogoUtil.buildLogoUrl(this.banner.logoCloudinaryFilename) : null;
  }

  getSalesAreaPercentage(): number {
    if (this.grocerySalesArea && this.totalArea) {
      return Math.round(this.grocerySalesArea / this.totalArea * 100);
    } else {
      return null;
    }
  }

  hasLogoImage(): boolean {
    return this.banner
      && !!this.banner.logoCloudinaryFilename;
  }

  hasMarkerImage(): boolean {
    return this.banner
      && !!this.banner.markerCloudinaryFilename;
  }

  isActive(): boolean {
    return _.includes(ActiveStoreStatuses, this.getCurrentStatus()?.getStatusSystemName());
  }

  isFuture(): boolean {
    return _.includes(FutureStoreStatuses, this.getCurrentStatus()?.getStatusSystemName());
  }

  isHistorical(): boolean {
    return _.includes(HistoricalStoreStatuses, this.getCurrentStatus()?.getStatusSystemName());
  }

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);
      this.statuses = [];

      if (raw.actualClosedDate) {
        this.actualClosedDate = DateUtil.buildFromTimestamp(raw.actualClosedDate);
      }
      if (raw.actualOpenedDate) {
        this.actualOpenedDate = DateUtil.buildFromTimestamp(raw.actualOpenedDate);
      }
      if (raw.currentStatus) {
        this.currentStatus = new StoreStatus(raw.currentStatus);
      }
      if (raw.ownerCompany) {
        this.ownerCompany = new Company(raw.ownerCompany);
      }
      if (raw.space) {
        this.space = new Space(raw.space);
      }
      if (raw.banner) {
        this.banner = new Banner(raw.banner);
      }
      if (raw.currentStoreStatus) {
        this.currentStatus = new StoreStatus(raw.currentStoreStatus);
      }
      if (raw.volume) {
        this.volume = new StoreVolume(raw.volume);
      }
      if (raw.storeStatuses) {
        this.statuses = raw.storeStatuses
          .map((status: any) => new StoreStatus(status))
          .sort((a: StoreStatus, b: StoreStatus) => {
            return b.statusDate.getTime() - a.statusDate.getTime();
          });
      }
    }
  }
}

export class StoreStatus extends AuditableEntity {

  statusDate: Date;
  type: Definition<StoreStatusType>;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
      this.mapAuditableEntityFields(raw);

      if (raw.statusDate) {
        this.statusDate = DateUtil.buildFromTimestamp(raw.statusDate);
      }
      if (raw.type) {
        this.type = new Definition<StoreStatusType>(raw.type);
      }
    }
  }

  getStatusDisplayName(): string {
    return this.type && this.type.displayName;
  }

  getStatusSystemName(): StoreStatusType {
    return this.type && this.type.systemName;
  }
}

export class FeatureCollection {

  type = 'FeatureCollection';

  features: Feature[] = [];

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
      this.features = [];

      if (raw.features) {
        raw.features.forEach((feature: any) => this.features.push(new Feature(feature)));
      }
    }
  }

  getFeature(index: number = 0): Feature {
    return this.features.length ? this.features[index] : null;
  }
}

export class Feature {

  id: string;
  geometry: Geometry;
  properties: FeatureProperties = new FeatureProperties();
  type: 'Feature' = 'Feature';

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.properties) {
        this.properties = new FeatureProperties(raw.properties);
      }
    }
  }

  getGeometryAsLatLng(): LatLngLiteral {
    const longitude = (<Point>this.geometry).coordinates[0];
    const latitude = (<Point>this.geometry).coordinates[1];
    return {lat: latitude, lng: longitude};
  }

  getGeometryAsPoint(): Point {
    return <Point>this.geometry;
  }

  getLocationHash(): string {
    const latLng = this.getGeometryAsLatLng();
    return `${latLng.lat}|${latLng.lng}`;
  }

  getShoppingCenter(): ShoppingCenter {
    return this.properties.shoppingCenter
      || this.getStore()?.space?.shoppingCenter;
  }

  getStore(): Store {
    return this.properties.store;
  }

  getVolume(): StoreVolume {
    return this.properties.volume;
  }

  hasVolume(): boolean {
    return !!this.getVolume();
  }

  isShoppingCenter(): boolean {
    return !!this.properties.shoppingCenter;
  }

  isStore(): boolean {
    return !!this.properties.store;
  }
}

export class FeatureProperties {

  shoppingCenter: ShoppingCenter;
  store: Store;
  volume: StoreVolume;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
      if (raw.shoppingCenter) {
        this.shoppingCenter = new ShoppingCenter(raw.shoppingCenter);
      }
      if (raw.store) {
        this.store = new Store(raw.store);
      }
      if (raw.volume) {
        this.volume = new StoreVolume(raw.volume);
      }
    }
  }
}

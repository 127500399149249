import { Wizard } from '../../../../../core/wizard/wizard';
import { Company, CompanyInvitation } from '../../../../../core/company/company';
import { InviteUserToCompanyPageComponent } from './invite-user-to-company-page/invite-user-to-company-page.component';

export class InviteUserToCompanyWizard extends Wizard<InviteUserToCompanyWizardModel> {
  key = 'invite-user-to-company';
  pageTypeMap = {
    'invite-user-to-company': InviteUserToCompanyPageComponent
  };
  startingPageKey = 'invite-user-to-company';
}

export interface InviteUserToCompanyWizardModel {
  company: Company;
  invitation?: CompanyInvitation;
}

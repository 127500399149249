import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../core/base-component';
import { Company } from '../../../../core/company/company';
import { UserProfile } from '../../../../core/user-profile/user-profile';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../core/date/insights-date.pipe';

@Component({
  selector: 'mtn-associated-company-card',
  templateUrl: './associated-company-card.component.html',
  styleUrls: ['./associated-company-card.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class AssociatedCompanyCardComponent extends BaseComponent {

  @Input()
  company: Company;
  @Input()
  userProfile: UserProfile;

  constructor() {
    super();
  }

}

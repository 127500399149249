<div class="google-search-container" fxLayout="row" fxLayoutGap="15px" [formGroup]="searchForm">
  <div class="search-container" fxLayout="column">
    <mat-form-field appearance="outline">
      <mat-label>Search...</mat-label>
      <input matInput #searchField formControlName="search"/>
    </mat-form-field>
    <mat-checkbox formControlName="isSearchBound" color="primary">Limit search to map bounds</mat-checkbox>
  </div>
</div>

<!-- This content-container hides the info-window-content until Google moves the element onto the map. -->
<div class="info-window-content-container">
  <div id="google-search-info-window-content" fxLayout="column">
    <span id="info-window-title" class="mat-h3 color-primary text-small-caps"></span>
    <span id="info-window-address-line1"></span>
    <span id="info-window-address-city-state-postal"></span>
    <br/>
    <span class="color-muted">Actions could be added here</span>
  </div>
</div>

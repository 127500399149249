export enum PointOfInterestType {
  ATTRACTION = 'ATTRACTION',
  BUSINESS = 'BUSINESS',
  GOVERNMENT = 'GOVERNMENT',
  MEDICAL = 'MEDICAL',
  PARK = 'PARK',
  PLACE_OF_WORSHIP = 'PLACE_OF_WORSHIP',
  SCHOOL = 'SCHOOL',
  SPORTS_COMPLEX = 'SPORTS_COMPLEX',
  TRANSIT_LINE = 'TRANSIT_LINE',
  TRANSIT_STATION = 'TRANSIT_STATION'
}

export namespace PointOfInterestType {

  export function getDisplayName(type: PointOfInterestType): string {
    let value: string;

    switch (type) {
      case PointOfInterestType.ATTRACTION:
        value = 'Attractions';
        break;
      case PointOfInterestType.BUSINESS:
        value = 'Businesses';
        break;
      case PointOfInterestType.GOVERNMENT:
        value = 'Government Buildings';
        break;
      case PointOfInterestType.MEDICAL:
        value = 'Medical Facilities';
        break;
      case PointOfInterestType.PARK:
        value = 'Parks';
        break;
      case PointOfInterestType.PLACE_OF_WORSHIP:
        value = 'Places of Worship';
        break;
      case PointOfInterestType.SCHOOL:
        value = 'Schools';
        break;
      case PointOfInterestType.SPORTS_COMPLEX:
        value = 'Sports Complexes';
        break;
      case PointOfInterestType.TRANSIT_LINE:
        value = 'Transit Lines';
        break;
      case PointOfInterestType.TRANSIT_STATION:
        value = 'Transit Stations';
        break;
      default:
        value = type;
        break;
    }

    return value;
  }

  export function values(): PointOfInterestType[] {
    // @ts-ignore
    return Object.keys(PointOfInterestType).filter((key: any) => isNaN(key) && key !== 'values' && key !== 'getDisplayName').map((key: string) => PointOfInterestType[key]);
  }
}

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { KeyIndicatorType } from '../../../user-preferences/key-indicator-type.enum';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
  selector: 'mtn-key-indicator-field',
  templateUrl: './key-indicator-field.component.html',
  styleUrls: ['./key-indicator-field.component.scss']
})
export class KeyIndicatorFieldComponent {

  @Input()
  appearance: MatFormFieldAppearance;
  @Input()
  form: UntypedFormGroup;
  @Input()
  controlName: string = 'keyIndicator';
  @Input()
  placeholder: string = 'Compare';

  KeyIndicatorType = KeyIndicatorType;

  constructor() {
  }

}

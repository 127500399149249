import { Component } from '@angular/core';
import { BaseComponent } from '../../core/base-component';

@Component({
  selector: 'mtn-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent {

  constructor() {
    super();
  }

}

<div class="store-banner-analysis-card-container container default-padding mat-elevation-z1 clickable" fxLayout="row"
     fxLayoutAlign="start center" fxLayoutGap="15px" matRipple (click)="openComparisonDetailWizard()">
  <div class="canvas-container container" [matTooltip]="tooltip">
    <canvas height="50px" width="50px" mdbChart chartType="doughnut" [legend]="false" [colors]="chartColors"
            [datasets]="chartDataSets" [options]="chartOptions" [labels]="chartLabels" *ngIf="ranking"></canvas>
    <div class="percentile-container" *ngIf="ranking">
      <span [ngStyle]="{color: color}">{{ranking.percentile}}</span>
    </div>
  </div>
  <div fxLayout="column" *ngIf="ranking">
    <small class="color-muted">{{prefix}}</small>
    <strong>{{rankingDescriptor}} {{comparisonDescriptor2}}</strong>
    <small class="color-muted"> by {{comparisonDescriptor1}}</small>
  </div>
  <mtn-restricted-license-overlay *ngIf="!hasStandardLicense" [buttonSize]="Size.SMALL"
                                  [bannerSize]="Size.EXTRA_SMALL"></mtn-restricted-license-overlay>
  <div class="color-muted" fxLayoutAlign="center center" *ngIf="comparison && !comparison.rankings.length">
    <span>Insufficient Data for Analysis</span>
  </div>
</div>

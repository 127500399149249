import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { Company, Store, StoreStatus } from '../models';
import { HttpClient } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Observable } from 'rxjs';
import { Definition } from '../definition/definition';
import { StoreStatusType } from '../identity/constant/store-status-type.enum';
import { map } from 'rxjs/operators';
import { AnalystRatingResponse } from './rating/analyst-rating-response';
import { StoreServices } from './store-services';
import { DateUtil } from '../util/date-util';
import { StoreVolume } from './volume/store-volume';
import { FitType } from './fit/fit-type.enum';
import { ShoppingCenterType } from '../identity/constant/shopping-center-type.enum';
import { LicenseType } from '../../auth/authorization/license-type.enum';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends CrudService<Store> {

  protected path = '/store';

  constructor(protected http: HttpClient) {
    super(http);
  }

  fillWithDummyData(store: Store, licenseType: LicenseType): void {
    if (licenseType === LicenseType.FREE) {
      store.currentStatus = new StoreStatus();
      store.currentStatus.statusDate = DateUtil.nDaysAgo(30);
      store.currentStatus.type = new Definition<StoreStatusType>({
        systemName: StoreStatusType.OPEN,
        displayName: 'Open'
      });

      store.grocerySalesArea = 12345;
      store.totalArea = 23456;

      store.fit = FitType.CONVENTIONAL;

      store.space.positionInCenter = 'Mid-Center';

      store.space.shoppingCenter.name = 'Terrace Market Center';
      store.space.shoppingCenter.type = ShoppingCenterType.COMMUNITY_CENTER;
      store.space.shoppingCenter.ownerCompany = new Company({
        name: 'Vance Investments Group'
      });
      store.space.shoppingCenter.grossLeasableArea = 123456;
      store.space.shoppingCenter.websiteUrl = 'https://theoffice.fandom.com/wiki/Vance_Refrigeration';
    }
  }

  findAllStatusTypes(): Observable<Definition<StoreStatusType>[]> {
    const url = this.buildUrl('/status/type');
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new Definition<StoreStatusType>(result))));
  }

  findOnesAnalystRatings(uuid: string): Observable<AnalystRatingResponse> {
    const url = this.buildUrl(`/${uuid}/analyst-rating`);
    return this.http.get(url)
      .pipe(map((result: any) => new AnalystRatingResponse(result)));
  }

  fineOnesServices(uuid: string): Observable<StoreServices> {
    const url = this.buildUrl(`/${uuid}/service`);
    return this.http.get(url)
      .pipe(map((result: any) => result ? new StoreServices(result) : null));
  }

  findOnesStatusHistory(uuid: string): Observable<StoreStatus[]> {
    const url = this.buildUrl(`/${uuid}/status`);
    return this.http.get(url)
      .pipe(map((results: any[]) => results.map((result: any) => new StoreStatus(result))));
  }

  findOnesBestVolume(uuid: string): Observable<StoreVolume> {
    return this.findOnesVolumes(uuid)
      .pipe(map((volumes: StoreVolume[]) => {
        //Return the first volume that has a total
        let volume = _.find(volumes, (volume: StoreVolume) => !!volume.total);
        //If none, return the newest volume
        if (!volume && volumes.length) {
          volume = volumes[0];
        }
        return volume;
      }));
  }

  findOnesVolumes(uuid: string): Observable<StoreVolume[]> {
    const url = this.buildUrl(`/${uuid}/volume`);
    return this.http.get(url)
      .pipe(map((results: any[]) => _.orderBy(results.map((result: any) => new StoreVolume(result)), 'date', 'desc')));
  }

  protected buildInstance(raw: any): Store {
    return new Store(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<Store> {
    return new Pageable<Store>(raw, Store);
  }
}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { InactivitySignedOutWizard } from '../inactivity-signed-out-wizard';
import { AuthenticationService } from '../../../authentication.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'mtn-inactivity-signed-out-page',
  templateUrl: './inactivity-signed-out-page.component.html',
  styleUrls: ['./inactivity-signed-out-page.component.scss']
})
export class InactivitySignedOutPageComponent extends WizardPageComponent<InactivitySignedOutWizard> {

  key = 'inactivity-signed-out';

  constructor(private authenticationService: AuthenticationService) {
    super();
  }

  onLoad(): Observable<any> {
    this.closeButtonText = 'Close';
    this.nextButtonText = 'Sign In';
    this.title = 'Signed Out';
    this.headerText = 'Where did you go?';
    this.subheaderText = 'You were inactive for a while, so we signed you out.';

    return of(null);
  }

  onNext(): Observable<string> {
    this.authenticationService.openAuth0AuthenticationDialog();
    return of(null);
  }
}

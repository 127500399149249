import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BaseComponent } from '../../base-component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'mtn-simple-alert',
  templateUrl: './simple-alert.component.html',
  styleUrls: ['./simple-alert.component.scss']
})
export class SimpleAlertComponent extends BaseComponent implements OnChanges {

  @Output()
  onClick = new EventEmitter<any>();

  @Input()
  color: 'blue' | 'green' | 'purple' | 'red';
  @Input()
  title: string;
  @Input()
  text: string;
  @Input()
  icon: IconProp;

  constructor() {
    super();
  }

  ngOnChanges() {
    this.setIconPerColor();
  }

  handleClick(): void {
    this.onClick.emit();
  }

  private setIconPerColor(): void {
    switch (this.color) {
      case 'blue':
        this.icon = 'info-circle';
        break;
      case 'green':
        this.icon = 'check-circle';
        break;
      case 'purple':
        this.icon = 'exclamation-triangle';
        break;
      case 'red':
        this.icon = 'exclamation-circle';
        break;
    }
  }

}

<div class="profile-container container" fxLayout="row" fxLayoutGap="15px">
  <mtn-profile-sidebar></mtn-profile-sidebar>
  <div class="profile-tabs-container container" fxFlex>
    <mat-tab-group color="accent" class="mat-elevation-z1" animationDuration="0ms">
      <mat-tab label="PREFERENCES">
        <ng-template matTabContent>
          <mtn-profile-preferences-tab></mtn-profile-preferences-tab>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>

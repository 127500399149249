import { Action, createReducer, on, select, Store } from '@ngrx/store';
import { UserProfile } from '../core/user-profile/user-profile';
import { filter } from 'rxjs/operators';
import { SetCompaniesList, SetNamedSubject, SetUsersList } from './administration-actions';
import { Company } from '../core/company/company';
import { Pageable } from '../core/service/pageable';
import { Named } from '../core/util/named';

export const ADMIN_STATE_KEY = 'adminState';

export interface AdminState {
  companies: Pageable<Company>;
  namedSubject: Named;
  users: Pageable<UserProfile>;
}

const initialState: AdminState = {
  companies: null,
  namedSubject: null,
  users: null
};

export const selectAdminState = (store: Store<any>) => store.pipe(select((state: any) => state.adminState), filter(state => state !== undefined));

const _adminReducer = createReducer(
  initialState,
  on(SetCompaniesList, (state, {companies}) => ({...state, companies: companies})),
  on(SetNamedSubject, (state, {subject}) => ({...state, namedSubject: subject})),
  on(SetUsersList, (state, {users}) => ({...state, users: users}))
);

export function adminReducer(state: AdminState | undefined, action: Action) {
  return _adminReducer(state, action);
}

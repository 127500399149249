import { Wizard } from '../../../../core/wizard/wizard';
import { Store } from '../../../../core/models';
import { StatusHistoryPageComponent } from './status-history-page/status-history-page.component';

export class StatusHistoryWizard extends Wizard<StatusHistoryWizardModel> {
  key = 'status-history';
  pageTypeMap = {
    'status-history': StatusHistoryPageComponent
  };
  startingPageKey = 'status-history';
}

export interface StatusHistoryWizardModel {
  store: Store;
}

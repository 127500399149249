import { Injectable } from '@angular/core';
import { InsightsRestService } from '../../service/insights-rest-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { selectUserState, UserState } from '../../../auth/user-state';
import { UserPreferences } from '../../user-preferences/user-preferences';
import { FeatureRequest } from './feature-request';
import { StoreFeatureRequest } from './store-feature-request';
import { Pageable } from '../../service/pageable';
import { Feature } from '../../models';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FeatureService extends InsightsRestService {

  private readonly INTERRUPTION_MESSAGE = 'Request chain no longer needed, interrupting...';

  constructor(private http: HttpClient,
              private store: Store<AppState>) {
    super();
  }

  /**
   * Handles checking for and retrieving all available pages of feature results. Expect this observable to emit
   * multiple times.
   */
  findAllShoppingCenters(request: FeatureRequest): Observable<Pageable<Feature>> {
    const subject = new Subject<Pageable<Feature>>();

    const uuidSet = new Set<string>();

    this.findShoppingCenters(request, 0)
      .pipe(catchError((err: any) => {
        console.error('Failed to retrieve first page of shopping center features', err);
        subject.error(err);
        return of(null);
      }))
      .subscribe((result: Pageable<Feature>) => {
        if (result) {
          this.filterDuplicateFeatures(uuidSet, result);
          subject.next(result);

          if (result.totalPages > 1) {
            const tasks: Observable<Pageable<Feature>>[] = [];

            for (let i = 1; i <= result.totalPages + 1; i++) {
              tasks.push(
                this.findShoppingCenters(request, i)
                  .pipe(tap((additionalResult: Pageable<Feature>) => {
                    if (additionalResult) {
                      this.filterDuplicateFeatures(uuidSet, additionalResult);
                      subject.next(additionalResult);
                    }
                  }))
              );
            }

            concat(...tasks)
              .pipe(
                catchError((err: any) => {
                  console.error('Failed to retrieve additional page of shopping center features', err);
                  subject.error(err);
                  return of(null);
                }),
                finalize(() => subject.complete())
              )
              .subscribe()
          }
        } else {
          subject.complete();
        }
      })

    return subject;
  }

  findAllStores(request: StoreFeatureRequest): Observable<Pageable<Feature>> {
    const subject = new Subject<Pageable<Feature>>();

    const uuidSet = new Set<string>();

    this.findStores(request, 0)
      .pipe(catchError((err: any) => {
        console.error('Failed to retrieve first page of store features', err);
        subject.error(err);
        return of(null);
      }))
      .subscribe((result: Pageable<Feature>) => {
        if (result) {
          this.filterDuplicateFeatures(uuidSet, result);
          subject.next(result);

          if (result.totalPages > 1) {
            const tasks: Observable<Pageable<Feature>>[] = [];

            for (let i = 1; i <= result.totalPages + 1; i++) {
              tasks.push(
                this.findStores(request, i)
                  .pipe(tap((additionalResult: Pageable<Feature>) => {
                    /*
                    If we aren't still listening to this request chain (likely because the map was updated by the user
                    mid-load), throw an error to interrupt the chain and prevent any additional unnecessary server
                    requests and additional markers from this chain being drawn on the map. We'll catch and log the
                    error below in the catchError pipe and do the formal interruption of the chain there.
                     */
                    if (!subject.observers.length) {
                      throw new Error(this.INTERRUPTION_MESSAGE);
                    }
                    if (additionalResult) {
                      this.filterDuplicateFeatures(uuidSet, result);
                      subject.next(additionalResult);
                    }
                  }))
              );
            }

            concat(...tasks)
              .pipe(
                catchError((err: Error) => {
                  if (err.message === this.INTERRUPTION_MESSAGE) {
                    console.log(err.message);
                  } else {
                    console.error('Failed to retrieve additional page of store features', err);
                  }
                  subject.error(err);
                  return of(null);
                }),
                finalize(() => {
                  subject.complete()
                })
              )
              .subscribe()
          } else {
            subject.complete();
          }
        } else {
          subject.complete();
        }
      })

    return subject;
  }

  findShoppingCenters(request: FeatureRequest, page = 0): Observable<Pageable<Feature>> {
    return this.findFeatures(request, '/feature/shopping-center', page);
  }

  findStores(request: StoreFeatureRequest, page = 0): Observable<Pageable<Feature>> {
    return this.findUserPreferences()
      .pipe(switchMap((preferences: UserPreferences) => {
        if (preferences) {
          request.salesSqftDisplayType = preferences.salesSqftDisplayMode;
          request.salesVolumeDisplayType = preferences.salesVolumeDisplayMode;
        }

        return this.findFeatures(request, '/feature/store', page);
      }));
  }

  private filterDuplicateFeatures(uuidSet: Set<string>, page: Pageable<Feature>): void {
    page.content = _.reject(page.content, (feature: Feature) => {
      const alreadyExists = uuidSet.has(feature.id);
      if (!alreadyExists) {
        uuidSet.add(feature.id);
      }
      return alreadyExists;
    });
  }

  private findUserPreferences(): Observable<UserPreferences> {
    return selectUserState(this.store)
      .pipe(
        take(1),
        map((state: UserState) => state.userPreferences)
      );
  }

  private findFeatures(request: FeatureRequest, path: string, page = 0): Observable<Pageable<Feature>> {
    const url = this.buildUrl(path);

    const params = new HttpParams()
      .set("size", '1000')
      .set("page", page.toString());

    return this.http.post(url, request, {params: params})
      .pipe(map((result: any) => new Pageable<Feature>(result, Feature)));
  }
}

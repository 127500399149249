<div class="root-container container">
  <mtn-activity-monitoring></mtn-activity-monitoring>
  <mtn-token-monitoring></mtn-token-monitoring>
  <mtn-maintenance-notification-worker *ngIf="isAuthenticated"></mtn-maintenance-notification-worker>
  <mtn-header-toolbar *ngIf="!isOnMaintenancePage"></mtn-header-toolbar>
  <mtn-navigation-sidebar *ngIf="isAuthenticated && !isOnMaintenancePage"></mtn-navigation-sidebar>
  <div class="application-container container scrollable"
       [ngClass]="{'no-pad': isNoPadRoute, 'show-navigation': isAuthenticated && !isOnMaintenancePage, 'navigation-expanded': !isNavigationCollapsed && !isOnMaintenancePage, 'navigation-hidden': isOnMaintenancePage}">
    <router-outlet></router-outlet>
  </div>

  <img id="marker-metadata-placeholder" height="30" style="position: fixed; z-index: 1000; top:0; left: 0;"/>
</div>

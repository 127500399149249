<div class="competitive-incursions-page-container container" fxLayout="column" fxLayoutGap="15px">
  <p>{{wizard.model.description}}</p>
  <div class="map-container container">
    <mtn-map #map [options]="mapOptions" (ready)="handleMapReady($event)" *ngIf="mapOptions"></mtn-map>
  </div>
  <div class="table-container container condensed-table">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="distance" matSortDirection="asc"
           *ngIf="dataSource?.data.length">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let record">
          <span>{{record.store.name}}</span>
        </td>
      </ng-container>

      <!-- Link Column -->
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let record">
          <a href="javascript:void(0)" [routerLink]="['/detail', 'store', record.store.uuid]" (click)="close()">
            <fa-icon icon="link"></fa-icon>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="travelTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Travel Time</th>
        <td mat-cell *matCellDef="let record">
          <span>{{formatSecondsAsDuration(record.travelTime)}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="distance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Distance</th>
        <td mat-cell *matCellDef="let record">
          <span>{{metersToMiles(record.distance).toFixed(2)}} mi</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSize]="5" [showFirstLastButtons]="true" [length]="dataSource.data?.length"
                   [hidePageSize]="true" [hidden]="dataSource.data?.length <= 5"></mat-paginator>
  </div>

</div>

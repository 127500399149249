<div class="container" fxLayout="column" fxLayoutGap="15px">
  <div class="header-container" fxLayout="row" fxLayoutAlign="space-between">Our analysts last visited this location
    in {{rating.ratingDate | date : 'MM/yyyy'}}. Here are the ratings they gave the physical building, parking lot, and
    surrounding shopping center.
  </div>
  <div class="ratings-container">
    <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">Store Ratings</h2>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutGap="5px">
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Ceiling Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreCeiling?.value"
                           *ngIf="ratingStoreCeiling; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Checkstand Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreCheckstand?.value"
                           *ngIf="ratingStoreCheckstand; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Exterior Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreExterior?.value"
                           *ngIf="ratingStoreExterior; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Floor Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreFloor?.value"
                           *ngIf="ratingStoreFloor; else notAvailable"></mtn-star-rating>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Refrigeration Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreRefrigeration?.value"
                           *ngIf="ratingStoreRefrigeration; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Shelving Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreShelving?.value"
                           *ngIf="ratingStoreShelving; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Visibility from Road</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreVisibility?.value"
                           *ngIf="ratingStoreVisibility; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Wall Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingStoreWall?.value"
                           *ngIf="ratingStoreWall; else notAvailable"></mtn-star-rating>
        </div>
      </div>
    </div>
  </div>
  <div class="ratings-container">
    <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">Parking Lot Ratings</h2>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutGap="5px">
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>General Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingParkingCondition?.value"
                           *ngIf="ratingParkingCondition; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Ingress/Egress</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingParkingIngressEgress?.value"
                           *ngIf="ratingParkingIngressEgress; else notAvailable"></mtn-star-rating>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Lighting</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingParkingLighting?.value"
                           *ngIf="ratingParkingLighting; else notAvailable"></mtn-star-rating>
        </div>
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Traffic Flow</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingParkingFlow?.value"
                           *ngIf="ratingParkingFlow; else notAvailable"></mtn-star-rating>
        </div>
      </div>
    </div>
  </div>
  <div class="ratings-container">
    <h2 class="mat-h2 color-primary text-small-caps no-bottom-margin">Cotenant Ratings</h2>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutGap="5px">
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Exterior Condition</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingCotenantExterior?.value"
                           *ngIf="ratingCotenantExterior; else notAvailable"></mtn-star-rating>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div class="rating-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Synergy</span>
          <div fxFlex></div>
          <mtn-star-rating [count]="ratingCotenantSynergy?.value"
                           *ngIf="ratingCotenantSynergy; else notAvailable"></mtn-star-rating>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notAvailable>
  <span class="color-muted">Not Available</span>
</ng-template>

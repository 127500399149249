<div class="store-highlight-card-container container default-padding mat-elevation-z1 clickable" fxLayout="row"
     fxLayoutAlign="start center" fxLayoutGap="15px" matRipple (click)="highlight.action()">
  <div class="icon-container container" fxLayoutAlign="center center">
    <fa-icon [icon]="highlight.isPositive ? 'plus-square' : 'minus-square'"
             [ngClass]="{'color-success': highlight.isPositive, 'color-error': !highlight.isPositive}"></fa-icon>
  </div>
  <div fxLayout="column">
    <strong>{{highlight.title}}</strong>
    <small class="color-muted">{{highlight.description}}</small>
  </div>
</div>

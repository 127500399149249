<div class="administration-landing-page-container container">
  <h1 class="mat-h1 color-primary text-small-caps text-center">Welcome to Administration</h1>
  <h4 class="mat-h4 text-center">What do you need to do?</h4>
  <div class="options-container" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="15px">
    <div class="administration-option-container container" fxLayout="column" fxLayoutAlign="start center"
         mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_LANDING_PAGE_NAVIGATION_BUTTON"
         [analyticsEventParameters]="{'target': 'companies-administration'}"
         (click)="navigateToTab('company')">
      <fa-icon [icon]="['far', 'building']"></fa-icon>
      <div class="option-label">Manage Companies</div>
      <div class="option-description text-center">Create or edit Companies and their Licenses</div>
    </div>

    <div class="administration-option-container container" fxLayout="column" fxLayoutAlign="start center"
         mtnAnalyticsEventTrigger [analyticsEventName]="AnalyticsEventType.CLICK_LANDING_PAGE_NAVIGATION_BUTTON"
         [analyticsEventParameters]="{'target': 'users-administration'}"
         (click)="navigateToTab('user')">
      <fa-icon icon="user"></fa-icon>
      <div class="option-label">Manage Users</div>
      <div class="option-description text-center">Create or edit Users and their membership in Companies</div>
    </div>
  </div>
</div>

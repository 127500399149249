import { BasicEntity } from '../basic-entity';

export class Definition<T> extends BasicEntity {

  description: string;
  displayName: string;
  systemName: T;

  constructor(raw?: any) {
    super();
    if (raw) {
      Object.assign(this, raw);
    }
  }
}

import { Component, ViewChild } from '@angular/core';
import { WizardPageComponent } from '../../../../../core/wizard/wizard-page-component';
import { StatusHistoryWizard } from '../status-history-wizard';
import { Store, StoreStatus } from '../../../../../core/models';
import { Observable } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { InsightsDatePipe } from '../../../../../core/date/insights-date.pipe';
import { StoreService } from '../../../../../core/store/store.service';
import { DefinitionService } from '../../../../../core/definition/definition.service';
import { Definition } from '../../../../../core/definition/definition';
import { StoreStatusType } from '../../../../../core/identity/constant/store-status-type.enum';
import { switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'mtn-status-history-page',
  templateUrl: './status-history-page.component.html',
  styleUrls: ['./status-history-page.component.scss'],
  providers: [DatePipe, InsightsDatePipe]
})
export class StatusHistoryPageComponent extends WizardPageComponent<StatusHistoryWizard> {

  key = 'status-history';

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  dataSource = new MatTableDataSource<StoreStatus>();
  displayedColumns: string[] = ['date', 'status'];
  store: Store;

  constructor(private definitionService: DefinitionService,
              private storeService: StoreService) {
    super();
    this.title = 'Store Status History';
    this.closeButtonText = 'Close';
  }

  onLoad(): Observable<any> {
    this.store = this.wizard.model.store;

    this.initTable();

    return this.loadStatuses();
  }

  private initTable(): void {
    setTimeout(() => {
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 50);

    this.dataSource.sortingDataAccessor = (data: StoreStatus, header: string): any => {
      if (header === 'date') {
        return data.statusDate;
      } else if (header === 'status') {
        return data.type.displayName;
      }
      return null;
    };
  }

  private loadStatuses(): Observable<any> {
    return this.definitionService.findAllStoreStatusTypes()
      .pipe(switchMap((definitions: Definition<StoreStatusType>[]) => {
        return this.storeService.findOnesStatusHistory(this.store.uuid)
          .pipe(tap((results: StoreStatus[]) => {
            //Map definitions to results
            results.forEach((result: StoreStatus) => {
              result.type = _.find(definitions, (definition: Definition<StoreStatusType>) => definition.systemName === result.getStatusSystemName());
            });

            //Filter duplicate results
            const orderedStatuses: StoreStatus[] = _.orderBy(results, ['statusDate', 'asc']);
            const filteredStatuses: StoreStatus[] = [];
            let previousStatus: StoreStatus;
            orderedStatuses.forEach((result: StoreStatus) => {
              if (!previousStatus || previousStatus.getStatusSystemName() !== result.getStatusSystemName()) {
                filteredStatuses.push(result);
              }
              previousStatus = result;
            });

            this.dataSource.data = filteredStatuses;
          }));
      }));
  }

}

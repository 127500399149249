import { Component } from '@angular/core';
import { PartnerType } from '../partner-attribution/partner-type.enum';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'mtn-full-partner-attribution',
  templateUrl: './full-partner-attribution.component.html',
  styleUrls: ['./full-partner-attribution.component.scss']
})
export class FullPartnerAttributionComponent extends BaseComponent {

  PartnerType = PartnerType;

  constructor() {
    super();
  }

}

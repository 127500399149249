import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mtn-phone-number-field',
  templateUrl: './phone-number-field.component.html',
  styleUrls: ['./phone-number-field.component.scss']
})
export class PhoneNumberFieldComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;
  @Input()
  controlName: string = 'phoneNumber';
  @Input()
  isRequired = false;
  @Input()
  placeholder: string = 'Phone Number';

  constructor() {
  }

  ngOnInit() {
  }
}

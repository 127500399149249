import { HubspotObject } from './hubspot-object';
import { HubspotContact } from './hubspot-contact';

export class HubspotCompany extends HubspotObject {
  properties: HubspotCompanyProperties;

  contacts: HubspotContact[] = [];

  constructor(raw?: any) {
    super(raw);

    if (raw) {
      if (raw.properties) {
        this.properties = new HubspotCompanyProperties(raw.properties);
      }
      if (raw.contacts) {
        this.contacts = raw.contacts.map((rawContact: any) => new HubspotContact(rawContact));
      }
    }
  }

  getContactsAsString(): string {
    return this.contacts.map((contact: HubspotContact) => contact.getDisplayName()).join(', ');
  }

}

export class HubspotCompanyProperties {
  domain: string;
  industry: string;
  name: string;
  type: string;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);
    }
  }
}

import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../core/base-component';

@Component({
  selector: 'mtn-pie-chart-legend',
  templateUrl: './pie-chart-legend.component.html',
  styleUrls: ['./pie-chart-legend.component.scss']
})
export class PieChartLegendComponent extends BaseComponent {

  @Input()
  colors: any[] = [];
  @Input()
  datasets: any[] = [];
  @Input()
  labels: string[] = [];

  constructor() {
    super();
  }

}

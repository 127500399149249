import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private routerSubscription: Subscription;

  constructor(private router: Router) {
  }

  event(eventName: string, params?: {}): void {
    if (environment.googleAnalyticsMeasurementId) {
      gtag('event', eventName, params);
    }
  }

  init(): void {
    if (environment.googleAnalyticsMeasurementId) {
      this.createGtagScriptElement();
      this.subscribeToRouterEvents();
    }
  }

  destroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
      this.routerSubscription = null;
    }
  }

  private createGtagScriptElement(): void {
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsMeasurementId;
    document.head.appendChild(scriptElement);

    const configElement = document.createElement('script');
    configElement.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + environment.googleAnalyticsMeasurementId + `', {'send_page_view': false});
      `;
    document.head.appendChild(configElement);
  }

  private subscribeToRouterEvents(): void {
    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsMeasurementId, {
          'page_path': event.urlAfterRedirects
        });
      }
    });
  }
}

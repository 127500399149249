<div class="suspended-license-page-container container" [formGroup]="form">
  <p>Suspending a Company's license will downgrade all of the Company's users' experience down to that of a Bronze
    license until the license is reinstated.</p>
  <mat-form-field class="default-field-width">
    <mat-select formControlName="suspendedReason" placeholder="Reason" required>
      <mat-option value="ABUSE">Abuse</mat-option>
      <mat-option value="NON_PAYMENT">Non-Payment</mat-option>
      <mat-option value="OTHER">Other</mat-option>
    </mat-select>
  </mat-form-field>
  <mtn-text-area-field [form]="form" controlName="suspendedComment" placeholder="Comment"></mtn-text-area-field>
</div>

<div class="pie-chart-legend-container container" fxLayout="column" fxLayoutGap="5px">
  <div class="row-container" *ngFor="let label of labels; let i = index;" fxLayout="row" fxLayoutAlign="start center"
       fxLayoutGap="10px">
    <div class="color-legend" *ngIf="colors?.length"
         [ngStyle]="{'background-color': colors[0]['backgroundColor'][i], 'border-color': colors[0]['borderColor'][i]}"></div>
    <div>{{label}}</div>
    <div fxFlex></div>
    <div>{{datasets[0]['data'][i]}}%</div>
  </div>
</div>

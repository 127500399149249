import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mtn-datepicker-field',
  templateUrl: './datepicker-field.component.html',
  styleUrls: ['./datepicker-field.component.scss']
})
export class DatepickerFieldComponent {

  @Input()
  form: UntypedFormGroup;
  @Input()
  controlName: string;
  @Input()
  isRequired = false;
  @Input()
  placeholder: string;

}

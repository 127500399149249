import { Injectable } from '@angular/core';
import { CrudService } from '../service/crud-service';
import { License } from './license';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Pageable } from '../service/pageable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicenseService extends CrudService<License> {

  protected path = '/license';

  constructor(protected http: HttpClient) {
    super(http);
  }

  findAllVersions(uuid: string): Observable<License[]> {
    const url = this.buildUrl(`/${uuid}/version`);
    const params = new HttpParams()
      .set('size', '100');

    return this.http.get(url, {params: params})
      .pipe(map((results: Pageable<License>) => results.content.map((result: any) => this.buildInstance(result))));
  }

  protected buildInstance(raw: any): License {
    return new License(raw);
  }

  protected buildPageableInstance(raw: any): Pageable<License> {
    return new Pageable<License>(raw, License);
  }
}

import { Component } from '@angular/core';
import { WizardPageComponent } from '../../../../wizard/wizard-page-component';
import { StoreExportWizard } from '../store-export-wizard';
import { StoreExportService } from '../../../store-export.service';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mtn-store-export-page',
  templateUrl: './store-export-page.component.html',
  styleUrls: ['./store-export-page.component.scss']
})
export class StoreExportPageComponent extends WizardPageComponent<StoreExportWizard> {

  key = 'store-export';

  constructor(private storeExportService: StoreExportService) {
    super();
    this.title = 'Export Store Data';
    this.nextButtonText = 'Export';
    this.closeButtonText = 'Cancel';
  }

  onLoad(): Observable<any> {
    this.initForm();
    return super.onLoad();
  }

  onNext(): Observable<string> {
    const formValue = this.form.getRawValue();

    return this.storeExportService.exportAsCsv(this.wizard.model.uuids, formValue.includeRatings, formValue.includeServices)
      .pipe(map(() => null));
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      includeRatings: new UntypedFormControl(false),
      includeServices: new UntypedFormControl(false)
    });
  }

}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '../../../core/models';
import { AppState } from '../../../app-state';
import { Store as NgrxStore } from '@ngrx/store';
import { DetailState, selectDetailState } from '../../detail-state';
import { filter } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import * as _ from 'lodash';
import { AccordionSubpanelComponent } from './accordion-subpanel/accordion-subpanel.component';
import { Wizard } from '../../../core/wizard/wizard';
import { WizardRunnerService } from '../../../core/wizard/wizard-runner.service';
import { StoreDetailsWizard } from './accordion-subpanel/store-subpanel/store-details-wizard/store-details-wizard';
import { SpaceDetailsWizard } from './accordion-subpanel/space-subpanel/space-details-wizard/space-details-wizard';
import { ShoppingCenterDetailsWizard } from './accordion-subpanel/shopping-center-subpanel/shopping-center-details-wizard/shopping-center-details-wizard';
import { AuthorizationAwareComponent } from '../../../core/authorization-aware-component';
import { NavigationState, selectNavigationState } from '../../../navigation/navigation-state';

@Component({
  selector: 'mtn-store-header-panel',
  templateUrl: './store-header-panel.component.html',
  styleUrls: ['./store-header-panel.component.scss']
})
export class StoreHeaderPanelComponent extends AuthorizationAwareComponent implements OnInit, AfterViewInit {

  breakpoint: string;
  isNavigationCollapsed = false;
  store: Store;
  width: number;

  @ViewChild('panelElement')
  panelElement: ElementRef;
  @ViewChild('shoppingCenterPanel')
  shoppingCenterPanel: AccordionSubpanelComponent;
  @ViewChild('spacePanel')
  spacePanel: AccordionSubpanelComponent;
  @ViewChild('storePanel')
  storePanel: AccordionSubpanelComponent;

  constructor(private breakpointObserver: BreakpointObserver,
              protected ngrxStore: NgrxStore<AppState>,
              private wizardRunner: WizardRunnerService) {
    super(ngrxStore);
  }

  onAuthorizationChange(): void {
  }

  onAuthorizationInit(): void {
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToBreakpointChanges();
    this.subscribeToDetailState();
    this.subscribeToNavigationState();
  }

  ngAfterViewInit() {
    this.handleWidthChange();
  }

  handlePanelClick(panel: 'store' | 'space' | 'shopping-center'): void {
    if (_.includes([Breakpoints.XLarge, Breakpoints.Large], this.breakpoint)) {
      this.openDetailWizard(panel);
    } else {
      this.togglePanels(panel);
    }
  }

  private handleWidthChange(): void {
    if (this.panelElement) {
      this.width = this.panelElement.nativeElement.offsetWidth;

      switch (this.breakpoint) {
        case Breakpoints.XLarge:
        case Breakpoints.Large:
          this.storePanel.isExpanded = true;
          this.spacePanel.isExpanded = true;
          this.shoppingCenterPanel.isExpanded = true;
          break;
        case Breakpoints.Medium:
          this.storePanel.isExpanded = true;
          this.spacePanel.isExpanded = false;
          this.shoppingCenterPanel.isExpanded = true;
          break;
        case Breakpoints.Small:
        case Breakpoints.XSmall:
          this.storePanel.isExpanded = true;
          this.spacePanel.isExpanded = false;
          this.shoppingCenterPanel.isExpanded = false;
          break;
      }
    }
  }

  private openDetailWizard(panel: 'store' | 'space' | 'shopping-center'): void {
    let wizard: Wizard<any>;

    switch (panel) {
      case 'store':
        wizard = new StoreDetailsWizard();
        wizard.model = {
          licenseType: this.licenseType,
          store: this.store
        };
        break;
      case 'space':
        wizard = new SpaceDetailsWizard();
        wizard.model = {
          licenseType: this.licenseType,
          space: this.store.space
        };
        break;
      case 'shopping-center':
        wizard = new ShoppingCenterDetailsWizard();
        wizard.model = {
          shoppingCenter: this.store.space.shoppingCenter
        };
        break;
    }

    if (wizard) {
      this.wizardRunner.run(wizard);
    }
  }

  private subscribeToBreakpointChanges(): void {
    this.addSubscription(
      this.breakpointObserver.observe([Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
        .subscribe((state: BreakpointState) => {
          //Record the current breakpoint so we can use it in our logic
          if (state.breakpoints[Breakpoints.XSmall]) {
            this.breakpoint = Breakpoints.XSmall;
          } else if (state.breakpoints[Breakpoints.Small]) {
            this.breakpoint = Breakpoints.Small;
          } else if (state.breakpoints[Breakpoints.Medium]) {
            this.breakpoint = Breakpoints.Medium;
          } else if (state.breakpoints[Breakpoints.Large]) {
            this.breakpoint = Breakpoints.Large;
          } else if (state.breakpoints[Breakpoints.XLarge]) {
            this.breakpoint = Breakpoints.XLarge;
          }

          this.handleWidthChange();
        })
    );
  }

  private subscribeToDetailState(): void {
    this.addSubscription(
      selectDetailState(this.ngrxStore)
        .pipe(filter((state: DetailState) => !!state.store))
        .subscribe((state: DetailState) => {
          this.store = state.store;
        })
    )
  }

  private subscribeToNavigationState(): void {
    this.addSubscription(
      selectNavigationState(this.ngrxStore)
        .subscribe((state: NavigationState) => {
          this.isNavigationCollapsed = state.isCollapsed;
        })
    );
  }

  private togglePanels(panel: 'store' | 'space' | 'shopping-center'): void {
    //If md display, only the space and shopping center panels get toggled when clicked
    if (this.breakpoint === Breakpoints.Medium && panel !== 'store') {
      this.spacePanel.isExpanded = !this.spacePanel.isExpanded;
      this.shoppingCenterPanel.isExpanded = !this.shoppingCenterPanel.isExpanded;
    }
    //Else if smaller display, things are more complex
    else if (_.includes([Breakpoints.Small, Breakpoints.XSmall], this.breakpoint)) {
      switch (panel) {
        case 'store':
          //If store panel is being collapsed, open the shopping-center panel
          if (this.storePanel.isExpanded) {
            this.storePanel.isExpanded = false;
            this.spacePanel.isExpanded = false;
            this.shoppingCenterPanel.isExpanded = true;
          } else {
            this.storePanel.isExpanded = true;
            this.spacePanel.isExpanded = false;
            this.shoppingCenterPanel.isExpanded = false;
          }
          break;
        case 'space':
          //If space panel is being collapsed, open the store panel
          if (this.spacePanel.isExpanded) {
            this.storePanel.isExpanded = true;
            this.spacePanel.isExpanded = false;
            this.shoppingCenterPanel.isExpanded = false;
          } else {
            this.storePanel.isExpanded = false;
            this.spacePanel.isExpanded = true;
            this.shoppingCenterPanel.isExpanded = false;
          }
          break;
        case 'shopping-center':
          //If shopping-center panel is being collapsed, open the store panel
          if (this.shoppingCenterPanel.isExpanded) {
            this.storePanel.isExpanded = true;
            this.spacePanel.isExpanded = false;
            this.shoppingCenterPanel.isExpanded = false;
          } else {
            this.storePanel.isExpanded = false;
            this.spacePanel.isExpanded = false;
            this.shoppingCenterPanel.isExpanded = true;
          }
          break;
      }
    }
  }
}

import { Store as NgrxStore } from '@ngrx/store/src/store';
import { Action, createReducer, on, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { BreadcrumbSetUuidLabelAction } from './breadcrumb-actions';

export const BREADCRUMB_STATE_KEY = 'breadcrumbState';

export interface BreadcrumbState {
  uuidLabel: string;
}

const initialState: BreadcrumbState = {
  uuidLabel: null
};

export const selectBreadcrumbState = (store: NgrxStore<any>) => store.pipe(select((state: any) => state.breadcrumbState), filter(state => state !== undefined));

const _breadcrumbReducer = createReducer(
  initialState,
  on(BreadcrumbSetUuidLabelAction, (state, {uuidLabel}) => ({...state, uuidLabel: uuidLabel}))
);

export function breadcrumbReducer(state: BreadcrumbState | undefined, action: Action) {
  return _breadcrumbReducer(state, action);
}

import { Component, Input, OnInit } from '@angular/core';
import { UserProfile } from '../user-profile';
import { UserProfileService } from '../user-profile.service';
import { KeyValuePair } from '../../util/key-value-pair';
import { buildKeyValueTooltipText } from '../../util/string-utils';

@Component({
  selector: 'mtn-user-inline-view',
  templateUrl: './user-inline-view.component.html',
  styleUrls: ['./user-inline-view.component.scss']
})
export class UserInlineViewComponent implements OnInit {

  @Input()
  userUuid: string;
  @Input()
  user: UserProfile;

  tooltip: string;

  constructor(private userService: UserProfileService) {
  }

  ngOnInit(): void {
    this.loadUser();
  }

  private buildTooltip(): void {
    const keyValuePairs: KeyValuePair[] = [
      {key: 'Name', value: this.user && this.user.getDisplayName()},
      {key: 'Title', value: this.user && this.user.title},
      {key: 'Email', value: this.user && this.user.email},
      {key: 'UUID', value: this.user && this.user.uuid}
    ];

    this.tooltip = buildKeyValueTooltipText(keyValuePairs, 2, 36);
  }

  private loadUser(): void {
    if (this.userUuid && !this.user) {
      this.userService.findOneCached(this.userUuid)
        .subscribe((user: UserProfile) => {
          this.user = user;
          this.buildTooltip();
        });
    }
  }

}

<div [formGroup]="form">
  <mat-form-field style="width:100%" appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <textarea matInput rows="5" [formControlName]="controlName"
              [errorStateMatcher]="instantErrorMatcher" style="resize: none"></textarea>
    <mat-hint align="end" *ngIf="maxLength">{{form.get(controlName).value?.length || 0}}/{{maxLength}}</mat-hint>
    <mat-error *ngIf="form.get(controlName).hasError('maxlength')">
      <i>Cannot exceed {{form.get(controlName).getError('maxlength').requiredLength}} characters</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('minlength')">
      <i>Must have at least {{form.get(controlName).getError('minlength').requiredLength}} characters</i>
    </mat-error>
    <mat-error *ngIf="form.get(controlName).hasError('required')">
      <i>Must be provided</i>
    </mat-error>
  </mat-form-field>
</div>

<div class="store-export-page-container container" [formGroup]="form">
  <p>You're about to download core store data for the selected store(s) in a CSV file. Select any additional components
    you'd like
    included in the download below.</p>
  <h3 class="mat-h3 color-primary">Optional Components</h3>
  <div fxLayout="column">
    <mat-checkbox formControlName="includeRatings" color="primary">Analyst Ratings</mat-checkbox>
    <mat-checkbox formControlName="includeServices" color="primary">Services & Amenities</mat-checkbox>
  </div>
</div>

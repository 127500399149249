import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtnCoreModule } from '../core/mtn-core.module';
import { HelpDashboardComponent } from './help-dashboard/help-dashboard.component';


@NgModule({
  declarations: [HelpDashboardComponent],
  imports: [
    CommonModule,
    MtnCoreModule
  ]
})
export class MtnHelpModule {
}

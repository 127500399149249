import LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral;
import LatLngLiteral = google.maps.LatLngLiteral;
import ControlPosition = google.maps.ControlPosition;
import MapTypeId = google.maps.MapTypeId;
import { MtnMapOptions } from './mtn-map-options';
import { MapMode } from './map-mode.enum';
import { PointOfInterestType } from './point-of-interest-type.enum';
import { FilterGroup } from '../core/federation/filter/filter-group';

export class MapSnapshot {
  bounds: LatLngBoundsLiteral;
  center: LatLngLiteral;
  options: MtnMapOptions;
  selections: string[] = [];
  zoom: number;

  constructor(raw?: any) {
    if (raw) {
      Object.assign(this, raw);

      if (raw.options?.filterGroup) {
        this.options.filterGroup = new FilterGroup(raw.options.filterGroup);
      } else {
        this.options.filterGroup = new FilterGroup();
      }
    }
  }
}

export function buildDefaultMapSnapshot(mode = MapMode.CLASSIC, type = MapTypeId.HYBRID): MapSnapshot {

  const DEFAULT_LATITUDE = 40.98432469678957;
  const DEFAULT_LONGITUDE = -111.90559507544573;
  const DEFAULT_ZOOM = 8;

  return new MapSnapshot({
    center: {
      lat: DEFAULT_LATITUDE,
      lng: DEFAULT_LONGITUDE
    },
    options: {
      controlConfiguration: {
        isGoogleSearchEnabled: true,
        isLayersEnabled: true,
        isFiltersEnabled: true,
        isMapTypeEnabled: true,
        isMapModeEnabled: true,
        isRecenterEnabled: true,
        isStreetViewEnabled: true,
        isTiltAndRotateEnabled: true
      },
      filterGroup: new FilterGroup(),
      fullscreenControl: false,
      mapTypeControl: false,
      isAutoSaveEnabled: true,
      isAutoUpdateEnabled: true,
      isFeatureMap: true,
      mapTypeId: type,
      minZoom: 4,
      maxZoom: 20,
      mode: mode,
      pointsOfInterest: PointOfInterestType.values(),
      rotateControl: true,
      rotateControlOptions: {
        position: ControlPosition.LEFT_BOTTOM
      },
      scrollwheel: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: ControlPosition.LEFT_BOTTOM
      },
      zoomControl: false
    },
    zoom: DEFAULT_ZOOM
  });
}
